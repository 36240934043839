import * as React from "react";
import {MouseEvent, MouseEventHandler} from "react";
import {JsonEventPartyInfo, JsonEventTicketInfo, JsonFloorItem, JsonFloorShape} from "../../generated-api";

const STEP_ANGLE = 30;
const STEP_ANGLE_SMOOTH = 1;
const STEP_ZOOM = 5;

export type SetBrushType = (brush: BrushType | undefined) => void;

export type InputContext<T> = {
    mouseAction: 'click' | 'drag' | 'text' | undefined,
    mouseActionTimeout: any,
    mouseX: number,
    mouseY: number,
    dirtyValues: T
}

export const AREA_CODE = 'R';

export interface JsonBaseSvgItem {
    id: number;
    x: number;
    y: number;
    originX?: number;
    originY?: number;
    r?: number;
    a?: number;
    s?: number;
}

export interface JsonCustomSvgItem extends JsonBaseSvgItem {
    type: string;
    w?: number;
    h?: number;
    text?: string;
}

export type BrushType = {
    shape: JsonFloorShape,
    svgElement: SVGGraphicsElement,
    a?: number,
    s?: number
};

export type ItemWithSvg<T extends JsonBaseSvgItem> = {
    item: T
    svgElement: SVGGraphicsElement
};

export type FloorItemWithSvg = ItemWithSvg<JsonFloorItem>;
export type CustomItemWithSvg = ItemWithSvg<JsonCustomSvgItem>;

export type ActionType<T extends JsonBaseSvgItem> = {
    action: 'add' | 'delete' | 'move' | 'rotate' | 'scale' | 'text',
    oldItems?: T[],
    newItems?: T[],
}

export type FloorActionType = ActionType<JsonFloorItem>;
export type CustomActionType = ActionType<JsonCustomSvgItem>;

export type FloorDirtyValues = {
    eventFloorDayId: number,
    title: string,
    dayNo: number,
    items: JsonFloorItem[],
    originalItems: JsonFloorItem[],
    undoStack: FloorActionType[],
    redoStack: FloorActionType[]
}

export type SeatingMap = { [key in number]: string } // indexed by partyId or ticketId

export type SeatingActionType = Readonly<{
    dayNo: number,
    addPartyTables?: SeatingMap,
    removePartyTables?: SeatingMap,
    addTicketTables?: SeatingMap,
    removeTicketTables?: SeatingMap
}>

export type SeatingActions = {
    actionAddSeating: (dayNo: number, partyTables: SeatingMap, ticketTables: SeatingMap, isAppend: boolean) => void,
    actionDeleteSeating: (dayNo: number, partyTables: SeatingMap, ticketTables: SeatingMap) => void,
    onMagicWand: (items: SeatingItem[], e: MouseEvent) => void,
    rewindLastSeatingAction: (isRedo: boolean) => void,
}

export type SeatingItem = (JsonEventPartyInfo | JsonEventTicketInfo) & {
    partyId?: number,
    ticketId?: number,
    tickets?: JsonEventTicketInfo[] // for dragging etc
}

export type TableInfo = {
    id: number,
    tableName: string,
    capacity: number
}

export type RewindType = 'undo' | 'redo';

export const ghostStyle = {
    position: 'fixed',
    zIndex: 200,
    width: '250px',
    maxHeight: 'clamp(100px, calc(100vh - 300px), 500px)',
    overflowY: 'hidden',
    fontSize: '90%',
    background: 'transparent'
}

export const ghostInitialStyle = {top: 0, left: 0, display: 'none'};

export const createId = (items?: { id: number }[]) => {
    if (items !== undefined) {
        for (let newId = 1; newId < 10000; newId++) {
            if (!items.find((other) => other.id === newId)) {
                return newId;
            }
        }
    }
    return -Math.round(Math.random() * 100000);
}

export const getNewAngle = (key: string, a?: number, isShift?: boolean) => {
    const step = (isShift ? STEP_ANGLE_SMOOTH : STEP_ANGLE) % 360;
    return (a ? (a - a % step) : 0) + (key === 'ArrowLeft' ? -step : step);
}

export const getNewScale = (key: string, s?: number) => {
    return Math.max(20, Math.min(1000, (s || 100) + (key === '-' ? -STEP_ZOOM : STEP_ZOOM)));
}

export const getSvgPoint = (e: { clientX: number, clientY: number }, svg: SVGSVGElement, snap?: number): {
    x: number,
    y: number
} => {
    const pt = svg.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const t = pt.matrixTransform(svg?.getScreenCTM()?.inverse())
    return {
        x: !snap ? t.x : (t.x - t.x % snap),
        y: !snap ? t.y : (t.y - t.y % snap),
    };
}

export const moveSvgToPoint = (item: SVGGraphicsElement, x: number, y: number) => {
    const translate = item.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
}

export const rotateSvgToAngle = (svg: SVGSVGElement, item: SVGGraphicsElement, a: number) => {
    if (item.transform.baseVal.length > 1) {
        item.transform.baseVal.getItem(1).setRotate(a, 0, 0);
    } else {
        item.transform.baseVal.appendItem(svg.createSVGTransformFromMatrix(svg.createSVGMatrix().rotate(a, 0, 0)));
    }
}

export const zoomSvgToScale = (svg: SVGSVGElement, item: SVGGraphicsElement, s: number) => {
    const newScale = s * 0.01;
    if (item.transform.baseVal.length > 2) {
        item.transform.baseVal.getItem(2).setScale(newScale, newScale);
    } else {
        if (item.transform.baseVal.length < 1) {
            item.transform.baseVal.appendItem(svg.createSVGTransformFromMatrix(svg.createSVGMatrix().rotate(0, 0, 0)));
        }
        item.transform.baseVal.appendItem(svg.createSVGTransformFromMatrix(svg.createSVGMatrix().scale(newScale, newScale)));
    }
}

export const updateGhostPositions = (pos: { x: number, y: number }, ghostItems: ItemWithSvg<JsonBaseSvgItem>[]) => {
    if (ghostItems?.[0].svgElement) {
        const dx = pos.x - ghostItems[0].item.x;
        const dy = pos.y - ghostItems[0].item.y;

        for (let ghostItem of ghostItems) {
            ghostItem.item.x += dx;
            ghostItem.item.y += dy;
            moveSvgToPoint(ghostItem.svgElement, ghostItem.item.x, ghostItem.item.y);
        }
    }
}

export const cloneSvgNode = (node: SVGGraphicsElement, cloneId: string): SVGGraphicsElement => {
    const clone = node.cloneNode(true) as SVGGraphicsElement;
    clone.id = cloneId;
    return clone;
}

export const clampItemsByBoundary = <T extends { x: number, y: number }>(items: T[], svg?: SVGSVGElement) => {
    if (!svg || !svg.viewBox.baseVal.width || !svg.viewBox.baseVal.height) {
        return items.slice();
    }
    const minX = svg.viewBox.baseVal.x;
    const maxX = svg.viewBox.baseVal.width + svg.viewBox.baseVal.x

    const minY = svg.viewBox.baseVal.y;
    const maxY = svg.viewBox.baseVal.height + svg.viewBox.baseVal.y

    return items.map((item) => {
        item.x = Math.max(minX, Math.min(item.x, maxX));
        item.y = Math.max(minY, Math.min(item.y, maxY));
        return item;
    });
}

export const areSeatingItemsEqual = (a?: SeatingItem, b?: SeatingItem) => {
    return (!!a?.partyId && a.partyId === b?.partyId) || (!!a?.ticketId && a.ticketId === b?.ticketId);
}

export const parseTableNames = (tableNames?: string) => {
    return tableNames?.split(' ')
        ?.map(s => s.trim())
        ?.filter(s => !!s) || undefined;
}

export const isAnyTableMatch = (dayTables?: string, tableName?: string) => {
    if (!tableName || !dayTables) {
        return false;
    }
    if (dayTables.indexOf(' ') >= 0) {
        return dayTables.indexOf(tableName) >= 0 && !!dayTables.match(new RegExp('(^| )' + tableName + '( |$)'));
    }
    return dayTables === tableName;
}

export const safeSvgValue = (v?: number): string => {
    if (!v || Math.abs(v) < 0.1) {
        return '0';
    }
    return String(v).split('.').map((p, i) => i === 0 ? p : p.substring(0, 2)).join('.') // 1.4500001 -> 1.45
}

export const transform = (item: JsonBaseSvgItem) => 'translate(' + safeSvgValue(item.x) + ' ' + safeSvgValue(item.y) + ')'
    + (item.a || (item.s && item.s !== 100) ? ' rotate(' + safeSvgValue(item.a) + ' 0 0)' : '')
    + (item.s && item.s !== 100 ? ' scale(' + safeSvgValue(item.s * 0.01) + ' ' + safeSvgValue(item.s * 0.01) + ')' : '');

export const SvgShapeDef = ({shape}: { shape: { svg: string, shapeCode: string } }) => {
    return <defs dangerouslySetInnerHTML={{__html: shape.svg.replace("%shapeCode%", shape.shapeCode)}}/>
}

export const SvgShapeItem = (
    {
        item,
        shape,
        onMouseDown,
    }: {
        item: JsonFloorItem,
        onMouseDown: MouseEventHandler,
        shape: JsonFloorShape,
    }) => {

    return <g
        id={'item-' + item.id}
        transform={transform(item)}
    >
        <use id={'item-' + item.id + '-use'}
            xlinkHref={'#' + item.shapeCode}
            onMouseDown={onMouseDown}
            data-capacity={shape?.capacity || undefined}
            data-text={item.text || undefined}
        />
    </g>
}
