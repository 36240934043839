import {
    JsonEventDayInfo,
    JsonEventPartyInfo,
    JsonEventPartyInfoInviteStatusEnum, JsonEventPartyInfoSafetyStatusEnum,
    JsonEventPartyInfoUpdateInviteStatusEnum,
    JsonEventPartyTravelVehicleEnum,
    JsonInviteData,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteReplyExtra,
    JsonInviteReplyExtraInfo,
    JsonInviteReplyExtraInfoStatusEnum,
    JsonInviteReplyExtraStatusEnum,
    JsonReplyData,
    JsonReplyProcessData
} from "../generated-api";
import {isExtraActive} from "../components/invite/InviteReviewForm";
import {createOption, FAKE_VALUE_CUSTOM, FAKE_VALUE_RESET, getOption, OptionValue} from "./form";
import {
    DeleteForeverRounded,
    DirectionsBusRounded,
    DirectionsCarRounded,
    DirectionsTransitRounded,
    Done, DoneRounded,
    HourglassTopRounded,
    KeyboardReturn,
    QuestionMarkRounded,
    ThumbDownAltRounded,
    ThumbUpRounded
} from "@mui/icons-material";
import {partyName} from "./party";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Chip, Link} from "@mui/material";
import {dateToGuiAs} from "../helpers/date";

export const getEffectiveExtras = (inviteData?: JsonInviteData, replyData?: JsonReplyData) => {
    const extras: JsonInviteReplyExtra[] = [];
    if (inviteData?.extraLimit
        && replyData?.extras
        && replyData?.extras.length) {
        replyData?.extras.forEach((extra) => {
            if (!extra.extraId || !isExtraActive(replyData, inviteData, extra)) {
                return;
            }
            extras[extra.extraId] = extra;
        });
    }
    return extras;
}

export const renderExtraName = (extra: JsonInviteReplyExtra | JsonInviteReplyExtraInfo | JsonEventPartyInfo, processData: JsonReplyProcessData | undefined, i: number, eventId?: number) => {
    if (!!extra.partyId) {
        return <Link key={i} underline={'hover'} to={`/parties/${extra.partyId}` + (!!eventId && '/' + eventId)} target={'_blank'}
            color={extra.status === JsonInviteReplyExtraInfoStatusEnum.Created ? 'var(--color-warning)' : undefined}
            style={extra.eventDays && extra.eventDays.indexOf('1') < 0 ? {opacity: .5} : undefined}
            component={RouterLink}>{partyName(extra)}</Link>;
    }
    let style = undefined;
    if ((extra?.hasOwnProperty('extraId') && (extra as any)?.extraId && processData?.extras?.[(extra as any).extraId]?.partyId === FAKE_VALUE_RESET)
        || extra.status === JsonInviteReplyExtraStatusEnum.Rejected) {
        style = {color: 'var(--light-red)', textDecoration: 'line-through'};
    }

    return <div key={i} style={style}>{partyName(extra)}</div>
}

export const dayDate = (ed: JsonEventDayInfo) => {
    return <>
        {dateToGuiAs(ed.dayDate, 'eeee').substring(0, 7)}<br/>
        {dateToGuiAs(ed.dayDate, 'd. M.')}<br/>
        {dateToGuiAs(ed.dayDate, 'yyyy')}
    </>
}

export const renderInviteType = (inviteType?: string) => {
    const o = getOption(inviteType, inviteTypeAllOptions);
    return <Chip size={'small'} label={o.label[0]} title={o.tooltip} color={o.color}/>
}

export const inviteTypeOptions: OptionValue[] = [
    createOption(JsonInviteInfoInviteTypeEnum.Guest, 'Host', 'Host / VIP', undefined, 'info'),
    createOption(JsonInviteInfoInviteTypeEnum.Organizer, 'Organizátor', 'Organizátor', undefined, 'warning'),
]

export const updateInviteTypeOptions: OptionValue[] = [
    createOption(JsonInviteInfoInviteTypeEnum.Supplier, 'Dodavatel', 'Výzva k doplnění personálu', undefined, 'success'),
    createOption(JsonInviteInfoInviteTypeEnum.UpdateOrg, 'Údaje', 'Výzva k aktualizaci údajů', undefined, undefined),
    createOption(JsonInviteInfoInviteTypeEnum.SafetyOrg, 'BOZP', 'Výzva k potvrzení BOZP', undefined, 'info'),
    createOption(JsonInviteInfoInviteTypeEnum.TravelOrg, 'Náhrady', 'Výzva k zadání cestovních náhrad', undefined, 'warning'),
    createOption(JsonInviteInfoInviteTypeEnum.CompOrg, 'Potvrzení', 'Výzva k potvrzení výplaty', undefined, undefined),
]

export const inviteTypeAllOptions: OptionValue[] = [
    ...inviteTypeOptions,
    ...updateInviteTypeOptions
]

export const inviteStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyInfoInviteStatusEnum.Pending, 'Odeslaná', 'Odesláno, čekáme na vyjádření', <QuestionMarkRounded/>, undefined),
    createOption(JsonEventPartyInfoInviteStatusEnum.Rejected, 'Odmítnutá', 'Účast pozvaným odmítnuta', <ThumbDownAltRounded/>, 'error'),
    createOption(JsonEventPartyInfoInviteStatusEnum.Accepted, 'Přijatá', 'Účast pozvaným přijata, čeká na potvrzení v EMS', <ThumbUpRounded/>, 'info'),
    createOption(JsonEventPartyInfoInviteStatusEnum.Returned, 'K doplnění', 'Účast pozvaným přijata, ale vrácena k doplnění', <KeyboardReturn/>, 'warning'),
    createOption(JsonEventPartyInfoInviteStatusEnum.Confirmed, 'Potvrzená', 'Účast pozvaným přijata a potvrzena v EMS', <Done/>, 'success'),
    createOption(JsonEventPartyInfoInviteStatusEnum.Deleted, 'Zrušená', 'Pozvánka stornována', <DeleteForeverRounded/>, undefined),
]

export const inviteStatusOptionsWithEmpty: OptionValue[] = [
    createOption(JsonEventPartyInfoInviteStatusEnum.None, '(bez pozvánky)'),
    ...inviteStatusOptions
]

export const updateInviteStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.Pending, 'Odeslaná', 'Odesláno, čekáme na doplnění', <QuestionMarkRounded/>, undefined),
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.Accepted, 'Ke schválení', 'Výzva vyplněna, čeká na potvrzení v EMS', <HourglassTopRounded/>, 'info'),
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.Returned, 'K doplnění', 'Výzva vyplněna, ale vrácena k doplnění', <KeyboardReturn/>, 'warning'),
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.Confirmed, 'Schválená', 'Výzva vyplněna a potvrzena v EMS', <Done/>, 'success'),
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.Deleted, 'Zrušená', 'Výzva stornována', <DeleteForeverRounded/>, undefined),
]

export const updateInviteStatusOptionsWithEmpty: OptionValue[] = [
    createOption(JsonEventPartyInfoUpdateInviteStatusEnum.None, '(bez výzvy)'),
    ...updateInviteStatusOptions
]

export const safetyStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyInfoSafetyStatusEnum.None, '(není)', 'Nevyžádáno ani nepotvrzedno'),
    createOption(JsonEventPartyInfoSafetyStatusEnum.Pending, 'Vyžádáno', 'Potvrzení vyžádáno', <HourglassTopRounded/>),
    createOption(JsonEventPartyInfoSafetyStatusEnum.Confirmed, 'Potvrzeno', 'Školení potvrzeno', <DoneRounded/>),
]

export const inviteExtraLimitOptions: OptionValue[] = [
    createOption(0, 'Bez doprovodu', 'Bez doprovodu'),
    createOption(1, '+1', 'Max. 1 další osoba'),
    createOption(2, '+2', 'Max. 2 další osoby'),
    createOption(3, '+3', 'Max. 3 další osoby'),
    createOption(10, '+10', 'Max. 10 dalších osob'),
    createOption(FAKE_VALUE_CUSTOM, '+?', 'Vlastní nastavení'),
]

export const inviteExtraLimitShortOptions: OptionValue[] = [
    createOption(0, 'Bez', 'Bez doprovodu'),
    createOption(1, '+1', 'Max. 1 další osoba'),
    createOption(2, '+2', 'Max. 2 další osoby'),
    createOption(3, '+3', 'Max. 3 další osoby'),
    createOption(5, '+5', 'Max. 5 dalších osoby'),
    createOption(10, '+10', 'Max. 10 dalších osob'),
    createOption(FAKE_VALUE_CUSTOM, '+?', 'Vlastní nastavení'),
]

export const inviteIsParkOptions: OptionValue[] = [
    createOption(true, 'Nabídnout parkování'),
    createOption(false, 'Nenabízet parkování'),
]

export const inviteIsParkShortOptions: OptionValue[] = [
    createOption(true, 'Parking'),
    createOption(false, 'Ne'),
]

export const inviteIsActiveOptions: OptionValue[] = [
    createOption(true, 'Aktuální', 'Aktuálně platné'),
    createOption(false, 'Ne', 'Platné dříve, ale nahrazené novější'),
]

export const replyIsParkOptions: OptionValue[] = [
    createOption(true, 'Mám tento den zájem o parkování'),
    createOption(false, 'Nemám zájem o parkování'),
]

export const replyIsAcm: OptionValue[] = [
    createOption(false, 'Nepotřebuji zajistit ubytování'),
    createOption(true, 'Potřebuji zajistit ubytování'),
]

export const vehicleOptions: OptionValue[] = [
    createOption(JsonEventPartyTravelVehicleEnum.Car, 'Automobil vlastní', undefined, <DirectionsCarRounded/>),
    createOption(JsonEventPartyTravelVehicleEnum.Bus, 'Autobus', undefined, <DirectionsBusRounded/>),
    createOption(JsonEventPartyTravelVehicleEnum.Train, 'Vlak', undefined, <DirectionsTransitRounded/>),
]

export const isInviteUpdate = (invite: { inviteType?: any }) => {
    return invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg
        || invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
        || invite.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg
        || invite.inviteType === JsonInviteInfoInviteTypeEnum.SafetyOrg;
}
