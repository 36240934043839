import {JsonInviteInfoInviteTypeEnum, JsonInviteInfoStatusEnum, JsonRsvp} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {Alert, Box, Card, CardContent} from "@mui/material";
import * as React from "react";
import RsvpInviteSummary from "./RsvpInviteSummary";
import {FAKE_VALUE_EMPTY} from "../../model/form";


const RsvpInviteStatusInfo = ({rsvp}: { rsvp: JsonRsvp }) => {
    const t = useAppTranslation();
    const {invite, template, eventParties} = rsvp;

    let title;
    let info;
    let help = 'V případě dotazu prosím kontaktujte podporu na emailu <a href="mailto:{{email}}?subject={{emailSubject}}">{{email}}</a>.';
    let email;
    let emailSubject;
    let isSummary = false;
    let bodyTop = undefined, bodyBottom = undefined;
    if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
        switch (invite?.status) {
            case JsonInviteInfoStatusEnum.Confirmed:
                if (invite.inviteId !== FAKE_VALUE_EMPTY || window.location.href.indexOf('/info/') <= 0) {
                    // rsvp
                    title = t('Aktualizace údajů byla potvrzena, děkujeme.');
                    info = t('Poskytnuté informace byly zaneseny do evidence a budou použity pro účely vaší účasti na sportovní události.');
                } else {
                    // info
                    const year = parseInt(rsvp.event?.eventFrom?.substring(0, 4) || "2023", 10);
                    title = t('Informace o vaší účasti na události v biatlonu NMNM ' + (year - 1) + "/" + (year - 2000));
                    info = t('Aktuálně v systému evidujeme níže uvedené informace.');
                    help = 'V případě, že si přejete nějaký údaj změnit, kontaktujte prosím podporu na emailu <a href="mailto:{{email}}?subject={{emailSubject}}">{{email}}</a>.';
                    isSummary = true;

                    bodyTop = invite.inviteData?.bodyTop;
                    bodyBottom = invite.inviteData?.bodyBottom;
                }
                break;
            case JsonInviteInfoStatusEnum.Deleted:
                title = t('Výzva k aktualizaci údajů byla stornována.');
                info = t('Žádné informace není třeba doplňovat, děkujeme za Váš čas.');
                break;
            default:
                title = t('Aktualizované údaje byly odeslány, děkujeme.');
                info = t('Poskytnuté informace byly odeslány a přijaty do zpracování. Vyčkejte prosím na potvrzení, které Vám brzy dorazí emailem.');
                break;
        }
        email = 'ems@biatlon.cz';
        emailSubject = 'Dotaz k aktualizaci údajů';

    } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.SafetyOrg) {
        switch (invite?.status) {
            case JsonInviteInfoStatusEnum.Deleted:
                title = t('Výzva k potvrzení o proškolení BOZP byla stornována.');
                info = t('Žádné informace není třeba doplňovat, děkujeme za Váš čas.');
                break;
            default:
                title = t('Proškolení BOZP bylo potvrzeno.');
                info = t('Poskytnuté informace byly zaneseny do evidence, děkujeme.');
                break;
        }
        email = 'ems@biatlon.cz';
        emailSubject = 'Dotaz k cestovním náhradám';

    } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg) {
        switch (invite?.status) {
            case JsonInviteInfoStatusEnum.Confirmed:
                title = t('Zadání cestovních náhrad bylo potvrzeno, děkujeme.');
                info = t('Poskytnuté informace byly zaneseny do evidence a budou použity pro účely vyčíslení odměny.');
                break;
            case JsonInviteInfoStatusEnum.Deleted:
                title = t('Výzva k zadání cestovních náhrad byla stornována.');
                info = t('Žádné informace není třeba doplňovat, děkujeme za Váš čas.');
                break;
            default:
                title = t('Podklady pro cestovní náhrady byly odeslány, děkujeme.');
                info = t('Poskytnuté informace byly odeslány a přijaty do zpracování. Vyčkejte prosím na potvrzení, které Vám brzy dorazí emailem.');
                break;
        }
        email = 'ems@biatlon.cz';
        emailSubject = 'Dotaz k cestovním náhradám';

    } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
        switch (invite?.status) {
            case JsonInviteInfoStatusEnum.Confirmed:
                title = t('Výše výplaty byla potvrzena, děkujeme.');
                info = t('Poskytnuté informace byly zaneseny do evidence a budou použity pro bankovní převod.');
                break;
            case JsonInviteInfoStatusEnum.Deleted:
                title = t('Výzva k potvrzení výše výplaty byla stornována.');
                info = t('Žádné informace není třeba doplňovat, děkujeme za Váš čas.');
                break;
            default:
                title = t('Vyjadření k navržené výplatě bylo odesláno, děkujeme.');
                info = t('Poskytnuté informace byly odeslány a přijaty do zpracování. Vyčkejte prosím na potvrzení, které Vám brzy dorazí emailem.');
                break;
        }
        email = 'ems@biatlon.cz';
        emailSubject = 'Dotaz k výši výplaty';

    } else {
        switch (invite?.status) {
            case JsonInviteInfoStatusEnum.Confirmed:
                if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
                    title = t('Pozvánka s personálem byla potvrzena, děkujeme.');
                } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer) {
                    title = t('Pozvánka byla potvrzena, děkujeme.');
                } else {
                    title = t('Pozvánka byla potvrzena, těšíme se na Vás.');
                }
                info = t('Pozvánka byla potvrzena a Vaše akreditace je ve zpracování. Pokud vám nebylo doručeno potvrzení, zkontrolujte prosím složku s nevyžádanou poštou.');
                break;
            case JsonInviteInfoStatusEnum.Rejected:
                title = t('Pozvánka byla vyřízena.');
                info = t('Přijali jsme odmítnutí účasti, děkujeme za Váš čas.');
                break;
            case JsonInviteInfoStatusEnum.Deleted:
                title = t('Pozvánka byla stornována.');
                info = t('Pozvánka byla stornována, děkujeme za Váš čas.');
                break;
            default:
                title = t('Pozvánka byla odeslána, děkujeme.');
                info = t('Pozvánka byla odeslána a přijata do zpracování. Vyčkejte prosím na potvrzení, které Vám brzy dorazí emailem.');
                break;
        }
        email = invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest
            ? 'vip@biatlon.cz'
            : 'ems@biatlon.cz';
        emailSubject = 'Dotaz k pozvánce';
        isSummary = !!rsvp?.reply && !!rsvp?.eventDays && !!invite && !(invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer);
    }

    return <Box>
        <h1>{title}</h1>
        <Card>
            <CardContent>
                <Alert severity={'success'}>
                    <p>{info}</p>
                </Alert>
                <Alert severity={'info'}>
                    <div dangerouslySetInnerHTML={{
                        __html: t(help, {email, emailSubject})
                    }}/>
                </Alert>
            </CardContent>
        </Card>
        {(invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg || invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer)
            && invite?.status === JsonInviteInfoStatusEnum.Confirmed && <Card>
				<CardContent>{!!template?.bodyBottom && <p dangerouslySetInnerHTML={{
                    __html: template?.bodyBottom
                }}/>}
                    {!!template?.bodyBottom && <br/>}
                    {!!eventParties?.[0]?.eventPartyGuid
                        && <a href={'/info/' + eventParties[0].eventPartyGuid}>{t('Dashboard organizátora')}</a>}
				</CardContent></Card>}
        {!!bodyTop && <Card><CardContent><div dangerouslySetInnerHTML={{__html: bodyTop}}/></CardContent></Card>}
        {!!bodyBottom && <Card><CardContent><div dangerouslySetInnerHTML={{__html: bodyBottom}}/></CardContent></Card>}
        {isSummary && <Card>
			<CardContent>
				<RsvpInviteSummary values={rsvp?.reply || {}} rsvp={rsvp}/>
			</CardContent>
		</Card>}
    </Box>;
}

export default RsvpInviteStatusInfo;
