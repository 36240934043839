import * as React from "react";
import {AttachFileRounded} from "@mui/icons-material";
import {JsonFile, JsonFileInfo} from "../generated-api";

export const getLink = (guid?: string, token?: string, type?: string, partyId?: number, photoId?: number) => {
    let url;
    if (photoId && partyId) {
        url = '/download/?fileId=' + photoId + '&partyId=' + partyId;
    } else if (token && type) {
        url = '/download/?token=' + token + '&type=' + type;
    } else {
        url = '/download/?guid=' + guid;
    }
    if (process.env.NODE_ENV !== 'production') {
        if (token) {
            return 'http://127.0.0.1:8080' + url;
        }
        return 'https://ems.biatlon.cz' + url;
    }
    return url;
}

export const renderFile = (a: JsonFile | JsonFileInfo) => {
    return <a href={getLink(a.guid)} rel="noreferrer" target={'_blank'}>
        <AttachFileRounded sx={{fontSize: '100%', marginBottom: '-2px'}}/> {a.fileName} ({Math.round((a.fileSize || 0) / 1024)} kB)</a>;
}

export const renderPaymentConfirmationFile = (token: string, year: number | string) => {
    return <a href={getLink(undefined, token, 'paymentConfirmation')} rel="noreferrer" target={'_blank'}>
        <AttachFileRounded sx={{fontSize: '100%', marginBottom: '-2px'}}/> Potvrzení příjmy {year}.pdf</a>;
}

export function saveReportAsCsv(rows: any[][], fileName: string) {
    const csv = String.fromCharCode(0xFEFF) + rows.map(cols => cols.join(';')).join('\n'); // Excel -> BOM + ';'
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    downloadFileAsBlob(blob, fileName);
}

export const downloadFileAsBlob = async (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.setAttribute(
            'download',
            fileName,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        URL.revokeObjectURL(url);
    }
}
