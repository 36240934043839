import {Button, Chip, Container, Grid, Link, Tooltip, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TAppFunction, useAppTranslation} from '../services/i18n';
import {
    clearEventParties,
    fetchEventParties,
    fetchEventPartiesCount,
    fetchEventPartiesOnline
} from '../store/eventParties';
import {Link as RouterLink} from "react-router-dom";
import PageHeader from '../components/layout/PageHeader';
import {
    GetEventPartyCountUsingGETRequest,
    GetEventPartyListUsingGETRequest,
    GetEventPartyListUsingGETStatusesEnum,
    JsonArticleInfo,
    JsonAuthUserInfoRightsEnum,
    JsonEventPartyArticle,
    JsonEventPartyCompInfo,
    JsonEventPartyImportSexEnum,
    JsonEventPartyInfo,
    JsonEventPartyInfoPartyTypeEnum,
    JsonEventPartyInfoStatusEnum,
    JsonEventPartyInfoUpdateInviteStatusEnum,
    JsonEventPartyMassActionRequestActionEnum,
    JsonEventPartyMassActionRequestUpdateTypeEnum,
} from '../generated-api';
import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridFilterProps,
    DataGridItemAction,
    DataGridMasAction,
    DataGridState,
    defaultDataGridFilterState
} from "../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectCodebooks, selectEventParties} from "../store/selectors";
import {TextFormField} from "../components/form/TextFormField";
import {ButtonGroupField} from "../components/form/ButtonGroupField";
import {SelectFormField} from "../components/form/SelectFormField";
import CodebookValue from "../components/CodebookValue";
import {datetimeToGui, dateToGuiAs} from "../helpers/date";
import {
    articleName,
    eventPartyContractStatusOptions,
    EventPartyImportResult,
    eventPartyStatusOptions,
    eventPartyTypeOptions,
    isCompOptions,
    isContractOptions,
    isMissingInfoOptions,
    isPaidOptions,
    isPhotoOptions,
    isPrefAcmOptions,
    isRectArticleOptions,
    isSiwiOptions,
    isTravelOptions,
    PartyMassActionValues,
    partyName,
    renderAccredStatus,
    sexOptions,
    siwiAccredStatusOptions
} from "../model/party";
import {createOption, createOptions, FAKE_VALUE_ALL, formatMoney, getOption, OptionValue} from "../model/form";
import EventDaysValue from "../components/EventDaysValue";
import {
    inviteStatusOptionsWithEmpty,
    safetyStatusOptions,
    updateInviteStatusOptionsWithEmpty,
    vehicleOptions
} from "../model/invite";
import {PartyPhotoTooltip} from "../components/party/PartyPhoto";
import {getApiResult} from "../helpers/api";
import PartyMassActionModal from "./PartyMassActionModal";
import EventDayDetails from "../components/party/EventDayDetails";
import {
    AccountBalanceRounded,
    AttachMoneyRounded,
    CloudUploadOutlined,
    CommuteRounded,
    DoneRounded,
    EditRounded,
    HealthAndSafetyRounded,
    HourglassEmptyRounded,
    HowToRegRounded,
    LocalPrintshopRounded,
    PriceCheckRounded,
    ScheduleSendRounded,
    WarningAmberRounded
} from "@mui/icons-material";
import PartyImportModal from "./PartyImportModal";
import {fetchArticles} from "../store/articles";
import PartyTags from "../components/PartyTags";
import EventPartyAccredDetail from "../components/EventPartyAccredDetail";
import EventPartyExtrasInfo from "../components/EventPartyExtrasInfo";
import {EventDaysFilter} from "../components/EventDaysFilter";
import {AuthState} from "../store/auth";

interface EventPartiesGridFilter extends DataGridFilter, GetEventPartyListUsingGETRequest {
}

interface EventPartiesGridState extends DataGridState<JsonEventPartyInfo, EventPartiesGridFilter> {
}

type MissingInfoProps = {
    item: JsonEventPartyInfo
}

export const EventPartyMissingInfo = (props: MissingInfoProps) => {
    const {item} = props;
    const {groupId, email, birthDate, bankAccount, photoId, permAddress} = item;

    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);
    const groupOrg = codebooks['groupOrg'];

    const status: JSX.Element | null = useMemo(() => {
        const missing = [];
        let color: 'error' | 'warning' = 'warning';

        if (!groupId || !groupOrg || !groupOrg.find((p) => p.value === '' + groupId)) {
            return null;
        }

        if (!email) {
            missing.push(t('Chybí email'));
            color = 'error';
        }
        if (!photoId) {
            missing.push(t('Chybí fotografie'));
        }
        if (!birthDate) {
            missing.push(t('Chybí datum narození'));
        }
        if (!bankAccount) {
            missing.push(t('Chybí bankovní účet'));
        }
        if (!permAddress) {
            missing.push(t('Chybí adresa'));
        }

        if (missing.length <= 0) {
            return <DoneRounded/>;
        }

        return <div title={missing.join("\n")}><WarningAmberRounded color={color}/></div>;

    }, [groupId, email, birthDate, bankAccount, photoId, permAddress, groupOrg, t]);

    return status;
}

type EventPartyArticleProps = {
    item: JsonEventPartyInfo,
    articleTypeOptions: OptionValue[],
    eventPartyArticles?: JsonEventPartyArticle[],
    articles: JsonArticleInfo[]
}

const EventPartyArticle = (props: EventPartyArticleProps) => {
    const {item, articleTypeOptions, eventPartyArticles, articles} = props;

    const t = useAppTranslation();

    const partySex = item.sex ? JsonEventPartyImportSexEnum[item.sex] : undefined;
    return useMemo(() => {
        if (!eventPartyArticles) {
            return null;
        }

        const rows: JSX.Element[] = [];
        let hasAnyRec;
        let hasAny;
        for (const o of articleTypeOptions) {
            const epa = eventPartyArticles?.find((a) => a.articleTypeId === o.value);
            const prefArticle = articles.find((a) => a.articleId === epa?.prefArticleId);
            const recArticle = articles.find((a) => a.articleId === epa?.recArticleId);

            rows.push(<tr key={rows.length}>
                <th style={{textAlign: 'left'}}>{o.label}</th>
                <td style={{textAlign: 'center'}}>{prefArticle
                    ? <>{articleName(prefArticle, true, partySex)} {!!epa?.prefCnt && epa.prefCnt > 1 &&
                        <small>({epa.prefCnt})</small>}</>
                    : '-'}</td>
                <td style={{
                    textAlign: 'center',
                    color: !prefArticle || !recArticle || recArticle.articleId === prefArticle.articleId
                        ? 'inherit'
                        : 'var(--color-warning)'
                }}>{recArticle
                    ? <>{articleName(recArticle, true, partySex)} {!!epa?.recCnt && epa.recCnt > 1 &&
                        <small>({epa.recCnt})</small>}</>
                    : '-'}</td>
            </tr>);
            hasAny = hasAny || !!prefArticle || !!recArticle;
            hasAnyRec = hasAnyRec || !!recArticle;
        }
        if (!hasAny) {
            return null;
        }

        return <Tooltip PopperProps={{sx: {'& .MuiTooltip-tooltip': {maxWidth: 'clamp(200px, 400px, 80vw)'}}}} title={
            <table>
                <thead>
                <tr>
                    <th></th>
                    <th>{t('Preference')}</th>
                    <th>{t('Vydáno')}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        }>{hasAnyRec ? <DoneRounded/> : <HourglassEmptyRounded/>}</Tooltip>;

    }, [partySex, eventPartyArticles, articles, articleTypeOptions, t]);
}

type EventPartyContractProps = {
    item: JsonEventPartyInfo,
    contractStatus?: string,
    onEdit?: () => void
}

const EventPartyContract = (props: EventPartyContractProps) => {
    const {contractStatus, item, onEdit} = props;

    const t = useAppTranslation();

    const o = contractStatus ? getOption(contractStatus, eventPartyContractStatusOptions) : undefined;
    const hasAnyRecArticle = !!item.eventArticleDetails?.find((epa) => !!epa.recArticleId)
    const b = !!onEdit
        ? !contractStatus
            ? hasAnyRecArticle
                ? <Button size={'small'} color={'warning'}
                    title={t('Kliknutím upravit vydané artikly a smlouvu')}
                    onClick={onEdit}
                ><WarningAmberRounded/></Button>
                : <Chip size={'small'} className={'party-tags-add'}
                    label={'+'} title={t('Kliknutím upravit vydané artikly a smlouvu')}
                    onClick={onEdit}
                />
            : <Button size={'small'} color={'inherit'}
                title={t('Kliknutím upravit vydané artikly a smlouvu')}
                onClick={onEdit}
            >{o?.icon || contractStatus[0]}</Button>
        : !contractStatus
            ? hasAnyRecArticle
                ? <WarningAmberRounded color={'warning'}/>
                : null
            : <span title={(o?.tooltip || o?.label)}>{o?.icon || contractStatus[0]}</span>;
    return <div className={'party-tags contract-status' + (!contractStatus && hasAnyRecArticle ? ' missing-contract' : '')}>
        {b}
    </div>;
}

type EventPartyCompProps = {
    item: JsonEventPartyInfo,
    eventPartyComps?: JsonEventPartyCompInfo[]
}

export const EventPartyComp = (props: EventPartyCompProps) => {
    const {item: {eventId, eventTravelDetails}, eventPartyComps} = props;

    return useMemo(() => {
        if (!eventPartyComps && !eventTravelDetails) {
            return null;
        }

        const compRows: JSX.Element[] = [];
        let compTotal = 0;
        eventPartyComps?.forEach((epc, i) => {
            compTotal += (epc.compAmount || 0);
            compRows.push(<div key={i}>
                <CodebookValue value={epc.tariffId} name={'tariff'} scope={eventId}/>
                {!!epc.unitCnt && !!epc.unitAmount
                    ? <> {epc.unitCnt}x {formatMoney(epc.unitAmount, true)} = </>
                    : (!!epc.unitCnt
                        ? <> {epc.unitCnt}x = </>
                        : <> </>)}
                {formatMoney(epc.compAmount, true)}</div>);
        })
        if (compRows.length > 1) {
            compRows.push(<div key={'total'}>= <strong>{formatMoney(compTotal, true)}</strong></div>);
        }

        const travelRows: JSX.Element[] = [];
        let travelTotal = 0;
        eventTravelDetails?.forEach((ept, i) => {
            const o = getOption(ept.vehicle, vehicleOptions);
            travelTotal += (ept.compAmount || 0);
            travelRows.push(<div key={i}>
                <Typography component={'span'} sx={{
                    '& svg': {
                        fontSize: '100%',
                        marginBottom: '-3px'
                    }
                }}>{o.icon || ept.vehicle} </Typography>
                {dateToGuiAs(ept.beginDate, 'd. M.')}
                <> ({ept.beginLocation})</>
                <> &raquo; </>
                {dateToGuiAs(ept.endDate, 'd. M.')}
                <> ({ept.endLocation})</>
                {!!ept.kmCnt && <> {ept.kmCnt} km</>}
                <> =</>
                <span>{formatMoney(ept.compAmount || 0, true)}</span>
            </div>);
        })
        if (travelRows.length > 1) {
            travelRows.push(<div key={'total'}>= <strong>{formatMoney(travelTotal, true)}</strong></div>);
        }

        return <Tooltip PopperProps={{sx: {'& .MuiTooltip-tooltip': {maxWidth: 'clamp(200px, 400px, 80vw)'}}}} title={
            <div>{compRows}{!!compRows.length && !!travelRows.length && <hr/>}{travelRows}</div>
        }>
            <div>
                {!!compTotal && <small>{formatMoney(Math.round(compTotal))}</small>}
                {!!compTotal && !!travelTotal && <br/>}
                {!!travelTotal && <small>{formatMoney(Math.round(travelTotal))}</small>}
            </div>
        </Tooltip>;

    }, [eventId, eventPartyComps, eventTravelDetails]);
}

type EventPartyInviteInfoProps = {
    item: JsonEventPartyInfo
}

const EventPartyInviteInfo = (props: EventPartyInviteInfoProps): JSX.Element | null => {
    const {item} = props;

    const items: JSX.Element[] = [];
    if (item.extraEventParties && item.extraEventParties.length > 0) {
        items.push(<span key={'extras'}>
                <EventPartyExtrasInfo extras={item.extraEventParties} asTooltip eventId={item.eventId}/></span>)
    } else if (item.inviteStatus) {
        const o = getOption(item.inviteStatus, inviteStatusOptionsWithEmpty);
        items.push(
            <span key={'invite'} title={(o.tooltip || o.label) + (item.inviteCreatedAt ? ' (' + datetimeToGui(item.inviteCreatedAt) + ')' : '')}>
                {o.icon || item.inviteStatus[0]}</span>)
    }
    if (item.parentEventParties && item.parentEventParties.length > 0) {
        items.push(
            <span key={'parent'}><EventPartyExtrasInfo extras={item.parentEventParties} asTooltip asParent eventId={item.eventId}/></span>)
    }

    return items.length > 0 ? <div>{items}</div> : null;
}

const renderUpdateInviteStatus = (v: string | undefined, item: JsonEventPartyInfo) => {
    if (!v) {
        return null;
    }

    const o = getOption(v, updateInviteStatusOptionsWithEmpty);
    return <span title={(o.tooltip || o.label) + (item.inviteCreatedAt ? ' (' + datetimeToGui(item.inviteCreatedAt) + ')' : '')}>{o.icon || v[0]}</span>
}

const renderStatus = (v: JsonEventPartyInfoStatusEnum | undefined, item: JsonEventPartyInfo) => {
    if (!v) {
        return null;
    }

    const o = getOption(v, eventPartyStatusOptions);
    return <span title={(o.tooltip || o.label)}>{o.icon || v[0]}</span>
}

const defaultStateTemplate: EventPartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'fullName',
        search: '',
        partyIds: [],
        eventId: -1,
        eventDay: undefined,
        foodIds: [],
        acmIds: [],
        parkIds: [],
        contingents: [],
        inviteStatuses: [],
        updateInviteStatuses: [],
        groupIds: [],
        partyTypes: [],
        sexType: undefined,
        isSiwi: undefined,
        isCeb: undefined,
        isPhoto: undefined,
        isMissingOrgInfo: undefined,
        companyIds: [],
        tagIds: [],
        reasonSearch: '',
        formatCodeSearch: '',
        areaSearch: '',
        siwiAccredStatuses: [],
        notEventDays: [],
        areas: [],
        notAreas: [],
        isContract: undefined,
        isRecArticle: undefined,
        isTravel: undefined,
        isComp: undefined,
        isPaid: undefined,
        isPrefAcm: undefined,
        safetyStatuses: [],
        statuses: [GetEventPartyListUsingGETStatusesEnum.Pending, GetEventPartyListUsingGETStatusesEnum.Active]
    },
};

const filterFields = (props: DataGridFilterProps<EventPartiesGridFilter>, t: TAppFunction): JSX.Element => {
    const {formProps, buttons, user, rights} = props;

    return <Grid container spacing={1} columns={72}>
        <Grid item xs={9}>
            <SelectFormField name="eventId" placeholder={'Událost'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}}/>
        </Grid>
        <Grid item xs={12}>
            <EventDaysFilter formProps={formProps}/>
        </Grid>
        <Grid item xs={7}>
            <SelectFormField name="foodIds" placeholder={'Strava'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'placeFood', scope: formProps.values.eventId,
                    addEmpty: t('(bez stravy)'),
                    addExtra: [createOption(FAKE_VALUE_ALL, '(jakákoli)')]
                }} isMulti/>
        </Grid>
        <Grid item xs={7}>
            <SelectFormField name="acmIds" placeholder={'Ubytování'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'placeAcm',
                    scope: formProps.values.eventId,
                    addEmpty: t('(bez ubytování)')
                }} isMulti/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isPrefAcm'} label={'Zájem o ubytování'} onChange={formProps.submitForm} options={isPrefAcmOptions} fullWidth/>
        </Grid>
        <Grid item xs={7}>
            <SelectFormField name="parkIds" placeholder={'Parkování'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'placePark', scope: formProps.values.eventId,
                    addEmpty: t('(bez parkování)'),
                    addExtra: [createOption(FAKE_VALUE_ALL, '(jakékoli)')]
                }} isMulti/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isRecArticle'} label={'Artikly vydány'} onChange={formProps.submitForm} options={isRectArticleOptions} fullWidth/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isContract'} label={'Smlouva podepsána'} onChange={formProps.submitForm} options={isContractOptions} fullWidth/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isTravel'} label={'Náhrady'} onChange={formProps.submitForm} options={isTravelOptions} fullWidth/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isComp'} label={'Odměny'} onChange={formProps.submitForm} options={isCompOptions} fullWidth/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'isPaid'} label={'Výplaty'} onChange={formProps.submitForm} options={isPaidOptions} fullWidth/>
        </Grid>
        <Grid item xs={6}>
            {buttons}
        </Grid>

        <Grid item xs={9}>
            <TextFormField name="search" type={'text'} placeholder={'Jméno, email, ...'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={12}>
            <SelectFormField name="tagIds" placeholder={'Štítky'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'tag',
                    asNumber: true,
                    addAnd: t('[+]'),
                    addNot: t('[-]')
                }} isMulti={true}/>
        </Grid>
        <Grid item xs={9}>
            <SelectFormField name="companyIds" placeholder={'Firma'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'company', asNumber: true, addEmpty: t('(bez firmy)')}} isMulti={true}/>
        </Grid>
        <Grid item xs={6}>
            {/*<ButtonGroupField name={'isCeb'} label={'ČSB'} onChange={formProps.submitForm} options={isCebOptions} fullWidth/>*/}
            <ButtonGroupField name={'isPhoto'} label={'Fotografie'} onChange={formProps.submitForm} options={isPhotoOptions} fullWidth/>
        </Grid>
        <Grid item xs={6}>
            <ButtonGroupField name={'isSiwi'} label={'Siwidata'} onChange={formProps.submitForm} options={isSiwiOptions} fullWidth/>
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'sexType'} label={'Pohlaví'} onChange={formProps.submitForm} options={sexOptions} fullWidth/>
        </Grid>
        <Grid item xs={6}>
            <ButtonGroupField name={'isMissingOrgInfo'} label={'Údaje'} onChange={formProps.submitForm} options={isMissingInfoOptions} fullWidth/>
        </Grid>
        <Grid item xs={10}>
            <SelectFormField name="updateInviteStatuses" placeholder={'Výzva'} onChange={formProps.submitForm}
                options={updateInviteStatusOptionsWithEmpty} isMulti/>
        </Grid>
        <Grid item xs={10}>
            <SelectFormField name="inviteStatuses" placeholder={'Pozvánka'} onChange={formProps.submitForm}
                options={inviteStatusOptionsWithEmpty} isMulti/>
        </Grid>


        <Grid item xs={9}>
            <SelectFormField name="groupIds" placeholder={'Skupina'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'group',
                    asNumber: true,
                    addEmpty: t('(bez skupiny)'),
                    addExtra: [
                        createOption(-11, '(organizátoři)'), // GroupType.ORG
                        createOption(-12, '(dodavatelé)'), // GroupType.SUPP
                        createOption(-13, '(hosté)') // GroupType.GUEST
                    ],
                    allowedValues: rights.includes(JsonAuthUserInfoRightsEnum.Admin) ? undefined : user?.userData?.groupIds
                }} isMulti={true}/>
        </Grid>
        <Grid item xs={12}>
            <TextFormField name="reasonSearch" type={'text'} placeholder={'Reason'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={9}>
            <TextFormField name="formatCodeSearch" type={'text'} placeholder={'Formát'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={6}>
            <TextFormField name={'areaSearch'} type={'text'} placeholder={'Zóny'} onChange={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={10}>
            <SelectFormField name="siwiAccredStatuses" placeholder={'Akreditace'} onChange={formProps.submitForm}
                options={siwiAccredStatusOptions} isMulti />
        </Grid>
        <Grid item xs={8} title={'Kontingent'}>
            <SelectFormField name="contingents" placeholder={'Kont.'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'contingent',
                    scope: formProps.values.eventId,
                    addEmpty: t('(bez kont.)')
                }} isMulti/>
        </Grid>
        <Grid item xs={8}>
            <SelectFormField name={'safetyStatuses'} placeholder={'BOZP'} onChange={formProps.submitForm} options={safetyStatusOptions} isMulti />
        </Grid>
        <Grid item xs={4}>
            <ButtonGroupField name={'partyTypes'} label={'Typ osoby'} onChange={formProps.submitForm} options={eventPartyTypeOptions} isMulti={true} fullWidth/>
        </Grid>
        <Grid item xs={6}>
            <ButtonGroupField name="statuses" label={'Stav v události'} onChange={formProps.submitForm} fullWidth
                options={eventPartyStatusOptions} isMulti iconsOnly/>
        </Grid>
    </Grid>
}

const getRowClassNames = (item: JsonEventPartyInfo): string[] | undefined => {
    if (item.status === JsonEventPartyInfoStatusEnum.Deleted) {
        return ['data-grid-inactive-row'];
    }
    if (item.status === JsonEventPartyInfoStatusEnum.Pending) {
        return ['data-grid-warning-row'];
    }
    return undefined;
}

const EventPartiesPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const {articleType} = useAppSelector(selectCodebooks);
    const eventParties = useAppSelector(selectEventParties);
    const {configuration, rights} = useAppSelector<AuthState>(selectAuthInfo);
    const isAdmin = rights.includes(JsonAuthUserInfoRightsEnum.Admin);

    const defaultState = useRef<EventPartiesGridState>({
        ...defaultStateTemplate,
        filter: {...defaultStateTemplate.filter, eventId: configuration?.defaultEvent?.eventId}
    });
    const [articles, setArticles] = useState<JsonArticleInfo[] | undefined>(undefined);
    const [gridFilter, setGridFilter] = useState<EventPartiesGridFilter>(defaultState.current.filter);
    const [massAction, setMassAction] = useState<PartyMassActionValues<EventPartiesGridFilter> | undefined>(undefined);
    const [importAction, setImportAction] = useState<EventPartiesGridFilter | undefined>(undefined);
    const {eventId} = gridFilter;

    const handleFetchItems = useCallback(async (filter: EventPartiesGridFilter) => {
        dispatch(fetchEventParties(filter));
        dispatch(fetchEventPartiesCount(filter as any as GetEventPartyCountUsingGETRequest));
    }, [dispatch]);

    const handleMassAction = useCallback((
        action: JsonEventPartyMassActionRequestActionEnum,
        filter: EventPartiesGridFilter,
        items: JsonEventPartyInfo[] | boolean,
        updateType?: JsonEventPartyMassActionRequestUpdateTypeEnum
    ) => {
        if (items === false) {
            // invalid
            return;
        }
        const f: EventPartiesGridFilter = items === true
            ? {...filter, rows: 500, start: 0}
            : {...filter, rows: 500, start: 0, partyIds: items.map((item) => item.partyId as number)};
        dispatch(fetchEventPartiesOnline(f)).then((res) => {
            setMassAction({
                filter,
                parties: [],
                eventParties: getApiResult(res) || [],
                values: {
                    updateType,
                    eventId: filter.eventId,
                    action,
                    items: []
                }
            });
        });
    }, [dispatch]);

    const handleSaveMassAction = useCallback(async (massAction: PartyMassActionValues<EventPartiesGridFilter>) => {
        setMassAction(undefined);
        handleFetchItems(massAction.filter).then();
    }, [handleFetchItems]);

    const handleCancelMassAction = useCallback(() => {
        setMassAction(undefined);
    }, []);

    const handleImportAction = useCallback((filter: EventPartiesGridFilter) => {
        setImportAction(filter)
    }, []);

    const handleSaveImport = useCallback(async (result: EventPartyImportResult, filter: EventPartiesGridFilter) => {
        setImportAction(undefined);
        handleFetchItems(filter).then();
    }, [handleFetchItems]);

    const handleCancelImport = useCallback(() => {
        setImportAction(undefined);
    }, []);

    useEffect(() => {
        dispatch(fetchArticles({eventId})).then((res) => {
            setArticles(getApiResult<JsonArticleInfo[]>(res));
        });
        return () => {
            dispatch(clearEventParties());
        }
    }, [eventId, dispatch]);

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyInfo, EventPartiesGridFilter>[] = [
            createCol('Jméno', 'fullName', 90, undefined, (v, item) =>
                <Link underline={'hover'} to={`/parties/${item.partyId}/${item.eventId}`} component={RouterLink}>{partyName(item)}</Link>),
            createCol('P', 'sex', '13C', 'Pohlaví', (v) => v ?
                <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
            createCol('R', 'birthDate', '26C', 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            // createCol('Org', 'orgCompanyName', 50, 'Organizace'),
            {
                title: 'F',
                tooltip: 'Fotografie',
                size: 16,
                align: "center",
                col: 'photoGuid',
                renderValue: (v, item) => {
                    return item.partyType === JsonEventPartyInfoPartyTypeEnum.T ? null :
                        <PartyPhotoTooltip photoGuid={v}/>
                }
            },
            {
                title: 'Ú',
                tooltip: 'Chybějící údaje',
                size: 16,
                align: "center",
                col: 'email',
                renderValue: (v, item) => {
                    return item.partyType === JsonEventPartyInfoPartyTypeEnum.T ? null :
                        <EventPartyMissingInfo item={item}/>
                }
            },
            createCol('Skupina', 'groupId', 70, 'Pracovní skupina', (v, item) => v ?
                <small><CodebookValue value={v} name={'group'} item={item}/></small> : null),
            createCol('Dny', 'eventDays', 45, undefined, (v, item) =>
                <EventDaysValue eventDays={v} eventId={item.eventId} siwiEventDays={item.siwiEventDays}/>),
            createCol('Štítky / Firma', 'tags', 85, undefined, (v, item) => {
                const items = [];
                if (v) {
                    items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} short/>);
                }
                if (item.companyId) {
                    items.push(<small style={v ? {
                        display: 'inline-block',
                        marginTop: '10px'
                    } : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
                }
                return items;
            }),
            createCol('F', 'formatCode', '25C', 'Formát', (_, item) =>
                <EventPartyAccredDetail item={item} col={'formatCode'}/>),
            createCol('Zóny', 'areas', 90, undefined, (_, item) =>
                <pre style={{
                    whiteSpace: 'pre-wrap',
                    letterSpacing: '-1px'
                }}><EventPartyAccredDetail item={item} col={'areas'}/></pre>),
            createCol('Reason', 'reason', 75, undefined, (_, item) =>
                <small><EventPartyAccredDetail item={item} col={'reason'}/></small>),
            createCol('Kon', 'contingentOwner', 25, 'Kontingent', (_, item) =>
                <small><EventPartyAccredDetail item={item} col={'contingentOwner'}/></small>),
            createCol('Ak', 'accredAt', '20C', 'Stav akreditace', renderAccredStatus),
            {
                col: 'eventDayDetails',
                title: 'Strava',
                tooltip: 'Strava, dle dnů',
                size: 48,
                align: 'left',
                renderValue: (v, item, filter) =>
                    <EventDayDetails eventId={item.eventId} dd={v} filter={filter} type={'food'}/>
            },
            {
                col: 'eventDayDetails',
                title: 'Ubyt',
                tooltip: 'Ubytování, dle dnů',
                size: 48,
                align: 'left',
                renderValue: (v, item, filter) => {
                    if (!v?.filter(dd => !!dd.acmId).length && item.prefAcm) {
                        return <span title={t('Má zájem o ubytování')}><HourglassEmptyRounded/></span>
                    }
                    return <EventDayDetails eventId={item.eventId} dd={v} filter={filter} type={'acm'}/>;
                }
            },
            {
                col: 'eventDayDetails',
                title: 'Park',
                tooltip: 'Parkování, dle dnů',
                size: 40,
                align: 'left',
                renderValue: (v, item, filter) => <EventDayDetails eventId={item.eventId}
                    dd={v}
                    filter={filter}
                    type={'park'}
                    onEdit={() => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, filter || {}, [item], JsonEventPartyMassActionRequestUpdateTypeEnum.Parking)}
                />
            }
        ];
        if (articles) {
            cols.push({
                col: 'eventArticleDetails',
                title: 'Art',
                tooltip: 'Artikly',
                size: 20,
                align: 'center',
                renderValue: (v, item, filter) => <EventPartyArticle
                    articleTypeOptions={createOptions({
                        codebookName: 'articleType',
                        scope: eventId,
                        asNumber: true,
                        sortByKeys: true
                    }, {articleType})}
                    eventPartyArticles={v} item={item} articles={articles}/>
            });
            cols.push({
                col: 'contractStatus',
                title: 'Sm',
                tooltip: 'Smlouva',
                size: 20,
                align: 'center',
                renderValue: (v, item, filter) =>
                    <EventPartyContract contractStatus={v} item={item} onEdit={isAdmin ? () => {
                        handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, filter || {}, [item], JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract)
                    } : undefined}/>
            });
        }

        cols.push({
            col: 'eventCompDetails',
            title: '$',
            tooltip: 'Odměny',
            size: 22,
            align: 'center',
            renderValue: (v, item) => <EventPartyComp item={item} eventPartyComps={v}/>
        })

        cols.push(createCol('V', 'updateInviteStatus', '15C', 'Stav výzvy', renderUpdateInviteStatus));
        cols.push(createCol('P', 'inviteStatus', '17C', 'Stav pozvánky', (_, item) =>
            <EventPartyInviteInfo item={item}/>));
        cols.push(createCol('S', 'status', '15C', 'Stav v události', renderStatus));

        return cols;
    }, [eventId, isAdmin, articleType, handleMassAction, articles, t]);

    const actions: DataGridItemAction<JsonEventPartyInfo, EventPartiesGridFilter>[] = useMemo(() => {
        const actions: DataGridItemAction<JsonEventPartyInfo, EventPartiesGridFilter>[] = [{
            title: 'Upravit',
            callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, filter, [item],
                isAdmin ? undefined : JsonEventPartyMassActionRequestUpdateTypeEnum.Days),
            icon: <EditRounded/>,
        }];

        if (isAdmin) {
            actions.push({
                title: 'Vyzvat k aktualizaci',
                callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.UpdateOrg, filter, [item]),
                icon: <ScheduleSendRounded/>,
                isApplicable: (item) => !!item.email && !(
                    item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Pending
                    || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Returned
                    || item.updateInviteStatus === JsonEventPartyInfoUpdateInviteStatusEnum.Accepted)
            });
            actions.push({
                title: 'Akreditovat',
                callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Accred, filter, [item]),
                icon: <HowToRegRounded/>,
            });
        }
        actions.push({
            title: "Vyzvat k potvrzení BOZP",
            isApplicable: (item) => !item.safetyStatus && !!item.groupId,
            callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.SafetyOrg, filter, [item]),
            icon: <HealthAndSafetyRounded/>
        });
        actions.push({
            title: "Vyzvat k zadání cestovného",
            isApplicable: (item) => !!item.groupId,
            callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.TravelOrg, filter, [item]),
            icon: <CommuteRounded/>
        });
        if (isAdmin) {
            actions.push({
                title: "Upravit odměny",
                isApplicable: (item) => !!item.groupId,
                callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Comp, filter, [item]),
                icon: <AttachMoneyRounded/>
            });
            actions.push({
                title: "Vyzvat k potvrzení výplaty",
                callback: (item: JsonEventPartyInfo, filter) => handleMassAction(JsonEventPartyMassActionRequestActionEnum.CompOrg, filter, [item]),
                icon: <PriceCheckRounded/>,
                isApplicable: (item) => !!(item.eventCompDetails?.length && item.eventCompDetails?.length > 0)
            });
        }

        return actions;
    }, [isAdmin, handleMassAction]);

    const massActions = useMemo(() => {
        const massActions: DataGridMasAction<JsonEventPartyInfo, EventPartiesGridFilter, JsonEventPartyMassActionRequestActionEnum>[] = [
            {
                action: JsonEventPartyMassActionRequestActionEnum.Update,
                title: "Hromadně upravit",
                callback: isAdmin ? handleMassAction : (a, f, i) => handleMassAction(a, f, i, JsonEventPartyMassActionRequestUpdateTypeEnum.Days),
                icon: <EditRounded/>
            },
            // {
            //     action: JsonEventPartyMassActionRequestActionEnum.Email,
            //     title: "Odeslat všem email",
            //     callback: handleMassAction
            // }
        ];

        if (isAdmin) {
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.UpdateOrg,
                title: "Vyzvat k aktualizaci",
                callback: handleMassAction,
                icon: <ScheduleSendRounded/>
            });
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.PrintContract,
                title: "Vytisknout smlouvu",
                callback: handleMassAction,
                icon: <LocalPrintshopRounded/>
            });
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.Accred,
                title: "Hromadně akreditovat",
                callback: handleMassAction,
                icon: <HowToRegRounded/>
            });
        }
        massActions.push({
            action: JsonEventPartyMassActionRequestActionEnum.SafetyOrg,
            title: "Vyzvat k potvrzení BOZP",
            callback: handleMassAction,
            icon: <HealthAndSafetyRounded/>
        });
        massActions.push({
            action: JsonEventPartyMassActionRequestActionEnum.TravelOrg,
            title: "Vyzvat k zadání cestovného",
            callback: handleMassAction,
            icon: <CommuteRounded/>
        });
        if (isAdmin) {
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.Comp,
                title: "Hromadně upravit odměny",
                callback: handleMassAction,
                icon: <AttachMoneyRounded/>
            });
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.CompOrg,
                title: "Vyzvat k potvrzení výplaty",
                callback: handleMassAction,
                icon: <PriceCheckRounded/>
            });
            massActions.push({
                action: JsonEventPartyMassActionRequestActionEnum.Payment,
                title: "Vygenerovat výplatu",
                callback: handleMassAction,
                icon: <AccountBalanceRounded/>
            });
        }

        return massActions;
    }, [isAdmin, handleMassAction]);

    return (
        <Container>
            <DataGrid
                header={(filter: EventPartiesGridFilter) => {
                    return <PageHeader title={t('Osoby v události')} buttons={isAdmin ? [{
                        icon: <CloudUploadOutlined/>,
                        title: t('Importovat do události'),
                        action: () => handleImportAction(filter)
                    }] : undefined}/>
                }}
                cols={cols}
                defaultState={defaultState.current}
                handleFetchItems={handleFetchItems}
                itemsState={eventParties}
                filterFields={filterFields}
                itemKey={'eventPartyId'}
                massActions={massActions}
                actions={actions}
                getRowClassNames={getRowClassNames}
                exportPath={'event-party/export'}
                onFilterChange={setGridFilter}
            />

            {massAction && <PartyMassActionModal
                massAction={massAction}
                onCancel={handleCancelMassAction}
                onSave={handleSaveMassAction}
            />}
            {importAction && <PartyImportModal
                filter={importAction}
                onCancel={handleCancelImport}
                onSave={handleSaveImport}
            />}
        </Container>
    );
}

export default EventPartiesPage;
