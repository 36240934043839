import CodebookValue from "../CodebookValue";
import {Box, Button} from "@mui/material";
import {EmailRounded} from "@mui/icons-material";
import InfoBox from "../layout/InfoBox";
import * as React from "react";
import {JsonPartyInfo, JsonUser, JsonUserInfo} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";

const PartyDetailBox = ({party, withAddress, user, handlePasswordEmail}: {
    party: JsonPartyInfo,
    withAddress?: boolean,
    user?: JsonUserInfo,
    handlePasswordEmail?: () => void
}) => {
    const t = useAppTranslation();

    return <InfoBox sx={{minHeight: {md: '100%'}}} title={t('Kontakt a přístupy')}>
        <table className={'info-box-table'}>
            <tbody>
            <tr>
                <th style={{width: '15%'}}>{t('Email')}</th>
                <td style={{width: '40%'}}>{party.email}</td>
                <th style={{width: '20%'}}>{t('Telefon')}</th>
                <td style={{width: '25%'}}>{party.phone}</td>
            </tr>
            <tr>
                <th>{t('Firma')}</th>
                <td>{party.orgCompanyName}</td>
                <th>{withAddress ? t('Bankovní účet') : null}</th>
                <td>{party.bankAccount}</td>
            </tr>
            </tbody>
            {withAddress && <tbody>
            <tr>
                <th>{t('Ulice')}</th>
                <td>{party.permAddress?.street}</td>
                <th>{t('PSČ')}</th>
                <td>{party.permAddress?.zip}</td>
            </tr>
            <tr>
                <th>{t('Město')}</th>
                <td>{party.permAddress?.city}</td>
                <th>{t('Stát')}</th>
                <td>{party.permAddress?.country}</td>
            </tr>
            </tbody>}
            {!!party.userId && <tbody>
            <tr>
                <th>{t('EMS přístup')}</th>
                <td colSpan={3}>
                    <CodebookValue value={party.roleId} name={'role'}/>
                    {!!user?.userData?.groupIds?.length && <>
                        &nbsp;({user.userData.groupIds.map((groupId, i) => {
                        return <span key={i}>{i > 0 ? ', ' : null}<CodebookValue value={groupId} name={'group'}/></span>
                    })})
                    </>}
                </td>
            </tr>
            </tbody>}
        </table>
        <Box className={'info-box-footer-actions'} sx={{position: {md: 'absolute'}}}>
            {/*<Button variant={'contained'} size={'small'} color={'primary'} onClick={() => handleEditPartyContact()}>*/}
            {/*	<MapRounded/>*/}
            {/*	<span>{t('Upravit kontaktní údaje')}</span></Button>*/}
            {!!party.email && handlePasswordEmail &&
                <Button variant={'text'} size={'small'} onClick={handlePasswordEmail}>
                    <EmailRounded/>
                    <span>{!!party.userId ? 'Odeslat přístupové údaje' : 'Založit uživatelský přístup'}</span>
                </Button>}
        </Box>
    </InfoBox>;
}

export default PartyDetailBox;
