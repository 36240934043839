import {Box} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {useAppTranslation} from "../../services/i18n";
import {JsonFloorShape, JsonFloorShapeShapeTypeEnum} from "../../generated-api";
import {useAppDispatch} from "../../store";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {BrushType, createId, CustomActionType, CustomItemWithSvg, JsonCustomSvgItem, SeatingItem} from "./svgUtils";
import {parseSvgIntoItems, SeatingShapeCanvas} from "./SeatingShapeCanvas";
import {fetchEvent, saveEvent} from "../../store/events";
import FormModal from "../form/FormModal";
import {ShapeStatsValueType} from "./SeatingPlan";
import {FAKE_VALUE_EMPTY} from "../../model/form";

const toolbarShapes: JsonFloorShape[] = [
    {
        id: 1,
        svg: "<g id='%shapeCode%' stroke-width='0.1'><rect x='-1.8' y='-3.1' width='3.6' height='6.2' transform='translate(0 0)'/></g>",
        text: {"a": 0, "h": 4, "w": 10, "x": -5, "y": -2},
        shapeCode: "shape-rect",
        shapeType: JsonFloorShapeShapeTypeEnum.Custom
    },
    {
        id: 2,
        svg: "<g id='%shapeCode%' stroke-width='0.1'><circle r='3.5' transform='translate(0 0)'/></g>",
        text: {"a": 0, "h": 4, "w": 10, "x": -5, "y": -2},
        shapeCode: "shape-circle",
        shapeType: JsonFloorShapeShapeTypeEnum.Custom
    },
    {
        id: 3,
        svg: "<g id='%shapeCode%' stroke-width='0.1'><rect x='-0.7' y='-0.7' width='1.4' height='1.4'  transform='translate(0 0)'/></g>",
        text: {"a": 0, "h": 4, "w": 10, "x": -5, "y": -2},
        shapeCode: "shape-place",
        shapeType: JsonFloorShapeShapeTypeEnum.Custom
    },
]

export type ShapeDirtyValues = {
    items: JsonCustomSvgItem[],
    originalItems: JsonCustomSvgItem[],
    undoStack: CustomActionType[],
    redoStack: CustomActionType[],
}

interface SeatingShapeModalProps {
    eventId: number,
    shapeCode: string,
    shapeStats: ShapeStatsValueType,
    onCancel: () => void;
    onSave: (newShapes?: JsonFloorShape[]) => void;
}

export const SeatingShapeModal = (props: SeatingShapeModalProps) => {

    const t = useAppTranslation();

    return <FormModal title={<span>{props.shapeCode !== String(FAKE_VALUE_EMPTY) ? t('Úprava nábytku') : t('Nový nábytek')}</span>}
        onCancel={() => props.onCancel()}
        dialogClassName={'rsvp-review'}
        maxWidth={'lg'}>
        <SeatingShape {...props}/>
    </FormModal>;
}

export const SeatingShape = ({eventId, shapeCode, shapeStats, onSave}: SeatingShapeModalProps) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [shape, setShape] = useState<JsonFloorShape>();

    const [brush, setBrush] = useState<BrushType | undefined>();
    const [dirtyValues, setDirtyValues] = useState<ShapeDirtyValues>();
    const [clipboard, setClipboard] = useState<CustomItemWithSvg[] | undefined>([]);

    const wrapperRef = useRef<HTMLElement | null>(null);

    const ghostItemsRef = useRef<SeatingItem[] | null>();

    const handleFetchShape = useCallback(async () => {
        const shape: JsonFloorShape = getApiResult(await dispatch(fetchEvent(eventId)))?.floorShapes?.find(s => s.shapeCode === shapeCode) || {
            id: FAKE_VALUE_EMPTY,
            shapeCode,
            svg: '',
            shapeType: JsonFloorShapeShapeTypeEnum.Table,
            text: {x: -2, y: 3, w: 6, h: 3.4, a: 270}
        };
        const items = parseSvgIntoItems(shape.svg);

        setShape(shape);
        setDirtyValues({
            items: items || [],
            originalItems: JSON.parse(JSON.stringify(items || [])),
            redoStack: [],
            undoStack: [],
        });

        ghostItemsRef.current = undefined;

    }, [eventId, shapeCode, dispatch]);

    const handleSaveShape = useCallback(async (shape: JsonFloorShape, isRemove?: boolean) => {
        let event = getApiResult(await dispatch(fetchEvent(eventId)));
        if (!event) {
            return;
        }

        if (shape.id === FAKE_VALUE_EMPTY) {
            // new
            shape.id = event.floorShapes?.length ? createId(event.floorShapes) : 1;
            shape.shapeCode = 'table-cust-' + shape.id;
        }

        if (!event.floorShapes) {
            if (isRemove) {
                return;
            }
            event.floorShapes = [shape];
        } else {
            if (isRemove) {
                event.floorShapes = event.floorShapes.filter(s => s.id !== shape.id);
            } else {
                if (event.floorShapes.find(s => s.id === shape.id)) {
                    event.floorShapes = event.floorShapes.map(s => s.id === shape.id ? shape : s);
                } else {
                    event.floorShapes.push(shape);
                }
            }
        }
        const res = await dispatch(saveEvent(event));
        event = getApiResult(res);
        if (event) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Nábytek ponechán beze změn',
                [ApiChangeType.UPDATED]: isRemove ? 'Nábytek úspěšně odstraněn' : 'Nábytek úspěšně upraven',
                [ApiChangeType.CREATED]: 'Nábytek úspěšně založen'
            }, t, dispatch);

            setDirtyValues((values) => ({
                ...values!,
                originalItems: parseSvgIntoItems(shape.svg),
            }));

            onSave(event.floorShapes);
        }

    }, [onSave, eventId, t, dispatch]);

    useEffect(() => {
        handleFetchShape().then();
    }, [handleFetchShape]);

    if (shape === undefined || dirtyValues === null) {
        return null;
    }

    return <Box ref={wrapperRef} className={'shape-wrapper'}>
        <SeatingShapeCanvas
            shape={shape}
            shapeStats={shapeStats}
            toolbarShapes={toolbarShapes}
            onSetBrush={setBrush} brush={brush}
            dirtyValues={dirtyValues!}
            onSetDirtyValues={setDirtyValues}
            onSaveShape={handleSaveShape}
            clipboard={clipboard}
            setClipboard={setClipboard}
        />
    </Box>
}
