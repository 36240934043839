import * as React from "react";
import {MouseEvent, useMemo, useState} from "react";
import {GroupType} from "../../pages/EventGuestsPage";
import {useAppTranslation} from "../../services/i18n";
import {JsonEventTicketInfo} from "../../generated-api";
import {Button, Card, CardContent} from "@mui/material";
import {TextFormFieldPlain} from "../form/TextFormField";
import {SeatingPlanGroup} from "./SeatingPlanGroup";
import {AREA_CODE, SeatingActions, SeatingItem, SeatingMap, TableInfo} from "./svgUtils";

export type GroupsViewMode = 'all' | 'empty' | 'seated' | 'tooltip' | 'hover' | 'list';

const isAnyEligibleItem = (items?: SeatingItem[], dayNo?: number) => {
    return items?.find((ep) => ep.areas && ep.areas.indexOf(AREA_CODE) >= 0 && dayNo && ep.eventDays && ep.eventDays[dayNo - 1] === '1')
}

export const SeatingPlanGroups = ({dayNo, groups, onLabelMouseClick, selectedItems, onHandleMouseDown, seating, groupsViewMode, actions, tables}: {
    dayNo: number,
    onLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
    onHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void,
    selectedItems?: SeatingItem[],
    groups: GroupType[],
    seating: SeatingItem[],
    groupsViewMode: GroupsViewMode,
    actions?: SeatingActions,
    tables?: TableInfo[]
}) => {

    const t = useAppTranslation();

    const [openIds, setOpenIds] = useState<string[]>(
        (groupsViewMode === 'tooltip' || groupsViewMode === 'hover')
            ? groups.map(g => g.groupKey)
            : (groupsViewMode === 'list'
                ? groups.filter(g => !!g.items?.length).map(g => g.groupKey)
                : []));
    const [search, setSearch] = useState('');

    const eventDaySeating = useMemo(() => {
        return seating.filter((ep) => ep.eventDays && ep.eventDays[dayNo - 1] === '1');
    }, [seating, dayNo]);

    const filteredGroups = useMemo(() => groups
            ?.filter((g) => isAnyEligibleItem(g.items, dayNo) || isAnyEligibleItem(g.tickets, dayNo))
        , [groups, dayNo]);

    const accordions = useMemo(() => filteredGroups
            ?.map((group, i) => <SeatingPlanGroup key={i}
                group={group}
                dayNo={dayNo}
                openIds={openIds}
                setOpenIds={setOpenIds}
                onLabelMouseClick={onLabelMouseClick}
                onHandleMouseDown={onHandleMouseDown}
                selectedItems={selectedItems}
                eventDaySeating={eventDaySeating}
                search={!(groupsViewMode === 'seated' || groupsViewMode === 'all' || groupsViewMode === 'empty') ? undefined : search}
                groupsViewMode={groupsViewMode}
                actions={actions}
                tables={tables}
            />)
        , [selectedItems, eventDaySeating, filteredGroups, dayNo, openIds, setOpenIds, onLabelMouseClick, onHandleMouseDown,
            search, groupsViewMode, actions, tables]);

    const buttons = useMemo(() => {
        const items: JSX.Element[] = [];
        if (groupsViewMode === 'empty') {
            const parentTables: SeatingMap = {};
            const prevDayPartyTables: SeatingMap = {};
            const prevDayTicketTables: SeatingMap = {};
            filteredGroups?.forEach((group) => {
                group.items?.forEach((ep) => {
                    if (!ep.partyId || !!seating.find((s) => s.partyId === ep.partyId)?.seating?.tables?.[dayNo]) {
                        return;
                    }
                    const parentTable = seating.find((s) => s.seating?.tables?.[dayNo]
                        && ep.parentEventParties?.find((p) => p.partyId === s.partyId))?.seating?.tables?.[dayNo];
                    if (parentTable) {
                        parentTables[ep.partyId] = parentTable;
                        return;
                    }

                    let prevDayTable = null;
                    for (let prevDay = dayNo - 1; prevDay > 0; prevDay--) {
                        prevDayTable = seating.find((s) => dayNo && s.seating?.tables?.[prevDay]
                            && ep.partyId === s.partyId)?.seating?.tables?.[prevDay];
                        if (prevDayTable) {
                            break;
                        }
                    }

                    if (prevDayTable) {
                        prevDayPartyTables[ep.partyId] = prevDayTable;
                        return;
                    }
                });
                group.tickets?.forEach((et) => {
                    if (!et.ticketId || !!seating.find((s) => s.ticketId === et.ticketId)?.seating?.tables?.[dayNo]) {
                        return;
                    }

                    let prevDayTable = null;
                    for (let prevDay = dayNo - 1; prevDay > 0; prevDay--) {
                        prevDayTable = seating.find((s) => s.seating?.tables?.[prevDay]
                            && (
                                (et.barcode === (s as JsonEventTicketInfo).barcode)
                                || (et.companyName && et.companyName === s.companyName)
                            ))?.seating?.tables?.[prevDay];
                        if (prevDayTable) {
                            break;
                        }
                    }

                    if (prevDayTable) {
                        prevDayTicketTables[et.ticketId] = prevDayTable;
                        return;
                    }
                });
            });
            if ((Object.keys(prevDayPartyTables).length || Object.keys(prevDayTicketTables).length) && actions) {
                items.push(<Button key={'prevDays'} variant={'contained'} size={'small'} sx={{marginBottom: '5px'}} color={'inherit'}
                    onClick={() => {
                        actions.actionAddSeating(dayNo, prevDayPartyTables, prevDayTicketTables, false);
                    }}
                    fullWidth>{t('Usadit dle před. ({{count}})', {count: Object.keys(prevDayPartyTables).length + Object.keys(prevDayTicketTables).length})}</Button>);
            }

            if (Object.keys(parentTables).length && actions) {
                items.push(<Button key={'parents'} variant={'contained'} size={'small'} sx={{marginBottom: '5px'}} color={'inherit'}
                    onClick={() => {
                        actions.actionAddSeating(dayNo, parentTables, {}, false);
                    }}
                    fullWidth>{t('Usadit doprovod ({{count}})', {count: Object.keys(parentTables).length})}</Button>);
            }
        }
        return items;
    }, [groupsViewMode, filteredGroups, seating, dayNo, actions, t]);

    const form = useMemo(() => {
        const items: JSX.Element[] = [...buttons];
        if ((groupsViewMode === 'all' || groupsViewMode === 'seated' || groupsViewMode === 'empty') && !!accordions?.length) {
            items.push(<TextFormFieldPlain key={'search'} currentValue={search} name={'search'} onChange={setSearch} onBlur={setSearch}
                clearable={true} placeholder={t('Stůl, jméno, firma')}/>);
        }
        return items.length ? items : null;

    }, [search, buttons, accordions?.length, groupsViewMode, t]);

    return <Card sx={{marginRight: '10px', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
        <CardContent sx={{padding: '10px 5px'}}>
            {!accordions?.length && <em>{t('Nic nenalezeno')}</em>}
            {form}
            {accordions}
        </CardContent>
    </Card>
}
