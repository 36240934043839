/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonAddress,
    JsonAddressFromJSON,
    JsonAddressFromJSONTyped,
    JsonAddressToJSON,
} from './JsonAddress';
import {
    JsonEventPartyArticle,
    JsonEventPartyArticleFromJSON,
    JsonEventPartyArticleFromJSONTyped,
    JsonEventPartyArticleToJSON,
} from './JsonEventPartyArticle';
import {
    JsonEventPartyCompInfo,
    JsonEventPartyCompInfoFromJSON,
    JsonEventPartyCompInfoFromJSONTyped,
    JsonEventPartyCompInfoToJSON,
} from './JsonEventPartyCompInfo';
import {
    JsonEventPartyDayDetails,
    JsonEventPartyDayDetailsFromJSON,
    JsonEventPartyDayDetailsFromJSONTyped,
    JsonEventPartyDayDetailsToJSON,
} from './JsonEventPartyDayDetails';
import {
    JsonEventPartySeating,
    JsonEventPartySeatingFromJSON,
    JsonEventPartySeatingFromJSONTyped,
    JsonEventPartySeatingToJSON,
} from './JsonEventPartySeating';
import {
    JsonEventPartyTravelInfo,
    JsonEventPartyTravelInfoFromJSON,
    JsonEventPartyTravelInfoFromJSONTyped,
    JsonEventPartyTravelInfoToJSON,
} from './JsonEventPartyTravelInfo';

/**
 * 
 * @export
 * @interface JsonEventPartyInfo
 */
export interface JsonEventPartyInfo {
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    accredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    accredStatus?: JsonEventPartyInfoAccredStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyInfo
     */
    allowedActions?: Array<JsonEventPartyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    areas?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    cebId?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    companyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    contractStatus?: JsonEventPartyInfoContractStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    email?: string;
    /**
     * 
     * @type {Array<JsonEventPartyArticle>}
     * @memberof JsonEventPartyInfo
     */
    eventArticleDetails?: Array<JsonEventPartyArticle>;
    /**
     * 
     * @type {Array<JsonEventPartyCompInfo>}
     * @memberof JsonEventPartyInfo
     */
    eventCompDetails?: Array<JsonEventPartyCompInfo>;
    /**
     * 
     * @type {Array<JsonEventPartyDayDetails>}
     * @memberof JsonEventPartyInfo
     */
    eventDayDetails?: Array<JsonEventPartyDayDetails>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    eventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    eventFirstName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    eventLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    eventPartyGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    eventPartyId?: number;
    /**
     * 
     * @type {Array<JsonEventPartyTravelInfo>}
     * @memberof JsonEventPartyInfo
     */
    eventTravelDetails?: Array<JsonEventPartyTravelInfo>;
    /**
     * 
     * @type {Array<JsonEventPartyInfo>}
     * @memberof JsonEventPartyInfo
     */
    extraEventParties?: Array<JsonEventPartyInfo>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    formatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    groupPosition?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    inviteCreatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    inviteId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    inviteStatus?: JsonEventPartyInfoInviteStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventPartyInfo
     */
    isAccredDiff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    orgCompanyName?: string;
    /**
     * 
     * @type {Array<JsonEventPartyInfo>}
     * @memberof JsonEventPartyInfo
     */
    parentEventParties?: Array<JsonEventPartyInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    partyNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    partyType?: JsonEventPartyInfoPartyTypeEnum;
    /**
     * 
     * @type {JsonAddress}
     * @memberof JsonEventPartyInfo
     */
    permAddress?: JsonAddress;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    photoId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventPartyInfo
     */
    prefAcm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    realEventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    replyUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    safetyStatus?: JsonEventPartyInfoSafetyStatusEnum;
    /**
     * 
     * @type {JsonEventPartySeating}
     * @memberof JsonEventPartyInfo
     */
    seating?: JsonEventPartySeating;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    sex?: JsonEventPartyInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiAccredStatus?: JsonEventPartyInfoSiwiAccredStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiAreas?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiBarcode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiContingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiEventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiEventUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiEventUpdatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiFormatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiPhotoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    siwiPhotoId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    siwiReason?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    siwiStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    status?: JsonEventPartyInfoStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonEventPartyInfo
     */
    tags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    updateInviteId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    updateInviteStatus?: JsonEventPartyInfoUpdateInviteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoAccredStatusEnum {
    SiwiManaged = 'SIWI_MANAGED',
    EmsManaged = 'EMS_MANAGED',
    EmsApproved = 'EMS_APPROVED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoContractStatusEnum {
    None = 'NONE',
    Signed = 'SIGNED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoInviteStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoPartyTypeEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoSafetyStatusEnum {
    None = 'NONE',
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoSexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoSiwiAccredStatusEnum {
    None = 'NONE',
    EmsDraft = 'EMS_DRAFT',
    EmsPending = 'EMS_PENDING',
    SiwiAny = 'SIWI_ANY',
    Undefined = 'UNDEFINED',
    Approved = 'APPROVED',
    Printed = 'PRINTED',
    NoContingent = 'NO_CONTINGENT',
    Entered = 'ENTERED',
    Printqueue = 'PRINTQUEUE',
    Batchprint = 'BATCHPRINT',
    Deleted = 'DELETED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Pending = 'PENDING'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyInfoUpdateInviteStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}

export function JsonEventPartyInfoFromJSON(json: any): JsonEventPartyInfo {
    return JsonEventPartyInfoFromJSONTyped(json, false);
}

export function JsonEventPartyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accredAt': !exists(json, 'accredAt') ? undefined : json['accredAt'],
        'accredStatus': !exists(json, 'accredStatus') ? undefined : json['accredStatus'],
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'cebId': !exists(json, 'cebId') ? undefined : json['cebId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'contractStatus': !exists(json, 'contractStatus') ? undefined : json['contractStatus'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'eventArticleDetails': !exists(json, 'eventArticleDetails') ? undefined : ((json['eventArticleDetails'] as Array<any>).map(JsonEventPartyArticleFromJSON)),
        'eventCompDetails': !exists(json, 'eventCompDetails') ? undefined : ((json['eventCompDetails'] as Array<any>).map(JsonEventPartyCompInfoFromJSON)),
        'eventDayDetails': !exists(json, 'eventDayDetails') ? undefined : ((json['eventDayDetails'] as Array<any>).map(JsonEventPartyDayDetailsFromJSON)),
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'eventFirstName': !exists(json, 'eventFirstName') ? undefined : json['eventFirstName'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventLastName': !exists(json, 'eventLastName') ? undefined : json['eventLastName'],
        'eventPartyGuid': !exists(json, 'eventPartyGuid') ? undefined : json['eventPartyGuid'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'eventTravelDetails': !exists(json, 'eventTravelDetails') ? undefined : ((json['eventTravelDetails'] as Array<any>).map(JsonEventPartyTravelInfoFromJSON)),
        'extraEventParties': !exists(json, 'extraEventParties') ? undefined : ((json['extraEventParties'] as Array<any>).map(JsonEventPartyInfoFromJSON)),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupPosition': !exists(json, 'groupPosition') ? undefined : json['groupPosition'],
        'inviteCreatedAt': !exists(json, 'inviteCreatedAt') ? undefined : json['inviteCreatedAt'],
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'inviteStatus': !exists(json, 'inviteStatus') ? undefined : json['inviteStatus'],
        'isAccredDiff': !exists(json, 'isAccredDiff') ? undefined : json['isAccredDiff'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'orgCompanyName': !exists(json, 'orgCompanyName') ? undefined : json['orgCompanyName'],
        'parentEventParties': !exists(json, 'parentEventParties') ? undefined : ((json['parentEventParties'] as Array<any>).map(JsonEventPartyInfoFromJSON)),
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'partyNote': !exists(json, 'partyNote') ? undefined : json['partyNote'],
        'partyType': !exists(json, 'partyType') ? undefined : json['partyType'],
        'permAddress': !exists(json, 'permAddress') ? undefined : JsonAddressFromJSON(json['permAddress']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'prefAcm': !exists(json, 'prefAcm') ? undefined : json['prefAcm'],
        'realEventDays': !exists(json, 'realEventDays') ? undefined : json['realEventDays'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'replyUntil': !exists(json, 'replyUntil') ? undefined : json['replyUntil'],
        'safetyStatus': !exists(json, 'safetyStatus') ? undefined : json['safetyStatus'],
        'seating': !exists(json, 'seating') ? undefined : JsonEventPartySeatingFromJSON(json['seating']),
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'siwiAccredStatus': !exists(json, 'siwiAccredStatus') ? undefined : json['siwiAccredStatus'],
        'siwiAreas': !exists(json, 'siwiAreas') ? undefined : json['siwiAreas'],
        'siwiBarcode': !exists(json, 'siwiBarcode') ? undefined : json['siwiBarcode'],
        'siwiContingentOwner': !exists(json, 'siwiContingentOwner') ? undefined : json['siwiContingentOwner'],
        'siwiEventDays': !exists(json, 'siwiEventDays') ? undefined : json['siwiEventDays'],
        'siwiEventUpdatedAt': !exists(json, 'siwiEventUpdatedAt') ? undefined : json['siwiEventUpdatedAt'],
        'siwiEventUpdatedBy': !exists(json, 'siwiEventUpdatedBy') ? undefined : json['siwiEventUpdatedBy'],
        'siwiFirstName': !exists(json, 'siwiFirstName') ? undefined : json['siwiFirstName'],
        'siwiFormatCode': !exists(json, 'siwiFormatCode') ? undefined : json['siwiFormatCode'],
        'siwiId': !exists(json, 'siwiId') ? undefined : json['siwiId'],
        'siwiLastName': !exists(json, 'siwiLastName') ? undefined : json['siwiLastName'],
        'siwiPhotoGuid': !exists(json, 'siwiPhotoGuid') ? undefined : json['siwiPhotoGuid'],
        'siwiPhotoId': !exists(json, 'siwiPhotoId') ? undefined : json['siwiPhotoId'],
        'siwiReason': !exists(json, 'siwiReason') ? undefined : json['siwiReason'],
        'siwiStatus': !exists(json, 'siwiStatus') ? undefined : json['siwiStatus'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'updateInviteId': !exists(json, 'updateInviteId') ? undefined : json['updateInviteId'],
        'updateInviteStatus': !exists(json, 'updateInviteStatus') ? undefined : json['updateInviteStatus'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventPartyInfoToJSON(value?: JsonEventPartyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accredAt': value.accredAt,
        'accredStatus': value.accredStatus,
        'allowedActions': value.allowedActions,
        'areas': value.areas,
        'bankAccount': value.bankAccount,
        'birthDate': value.birthDate,
        'cebId': value.cebId,
        'companyId': value.companyId,
        'companyName': value.companyName,
        'companyNumber': value.companyNumber,
        'contingentOwner': value.contingentOwner,
        'contractStatus': value.contractStatus,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'eventArticleDetails': value.eventArticleDetails === undefined ? undefined : ((value.eventArticleDetails as Array<any>).map(JsonEventPartyArticleToJSON)),
        'eventCompDetails': value.eventCompDetails === undefined ? undefined : ((value.eventCompDetails as Array<any>).map(JsonEventPartyCompInfoToJSON)),
        'eventDayDetails': value.eventDayDetails === undefined ? undefined : ((value.eventDayDetails as Array<any>).map(JsonEventPartyDayDetailsToJSON)),
        'eventDays': value.eventDays,
        'eventFirstName': value.eventFirstName,
        'eventId': value.eventId,
        'eventLastName': value.eventLastName,
        'eventPartyGuid': value.eventPartyGuid,
        'eventPartyId': value.eventPartyId,
        'eventTravelDetails': value.eventTravelDetails === undefined ? undefined : ((value.eventTravelDetails as Array<any>).map(JsonEventPartyTravelInfoToJSON)),
        'extraEventParties': value.extraEventParties === undefined ? undefined : ((value.extraEventParties as Array<any>).map(JsonEventPartyInfoToJSON)),
        'firstName': value.firstName,
        'formatCode': value.formatCode,
        'fullName': value.fullName,
        'groupId': value.groupId,
        'groupPosition': value.groupPosition,
        'inviteCreatedAt': value.inviteCreatedAt,
        'inviteId': value.inviteId,
        'inviteStatus': value.inviteStatus,
        'isAccredDiff': value.isAccredDiff,
        'lastName': value.lastName,
        'note': value.note,
        'orgCompanyName': value.orgCompanyName,
        'parentEventParties': value.parentEventParties === undefined ? undefined : ((value.parentEventParties as Array<any>).map(JsonEventPartyInfoToJSON)),
        'partyId': value.partyId,
        'partyNote': value.partyNote,
        'partyType': value.partyType,
        'permAddress': JsonAddressToJSON(value.permAddress),
        'phone': value.phone,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'prefAcm': value.prefAcm,
        'realEventDays': value.realEventDays,
        'reason': value.reason,
        'replyUntil': value.replyUntil,
        'safetyStatus': value.safetyStatus,
        'seating': JsonEventPartySeatingToJSON(value.seating),
        'sex': value.sex,
        'siwiAccredStatus': value.siwiAccredStatus,
        'siwiAreas': value.siwiAreas,
        'siwiBarcode': value.siwiBarcode,
        'siwiContingentOwner': value.siwiContingentOwner,
        'siwiEventDays': value.siwiEventDays,
        'siwiEventUpdatedAt': value.siwiEventUpdatedAt,
        'siwiEventUpdatedBy': value.siwiEventUpdatedBy,
        'siwiFirstName': value.siwiFirstName,
        'siwiFormatCode': value.siwiFormatCode,
        'siwiId': value.siwiId,
        'siwiLastName': value.siwiLastName,
        'siwiPhotoGuid': value.siwiPhotoGuid,
        'siwiPhotoId': value.siwiPhotoId,
        'siwiReason': value.siwiReason,
        'siwiStatus': value.siwiStatus,
        'status': value.status,
        'tags': value.tags,
        'updateInviteId': value.updateInviteId,
        'updateInviteStatus': value.updateInviteStatus,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

