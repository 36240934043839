/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonTariffInfo
 */
export interface JsonTariffInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonTariffInfo
     */
    allowedActions?: Array<JsonTariffInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    compAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    compCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTariffInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    paidCompAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    paidCompCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    paidUnitCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    pendingCompAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    pendingCompCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    pendingUnitCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    tariffId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTariffInfo
     */
    tariffType?: JsonTariffInfoTariffTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    unitCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    unitRate?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTariffInfo
     */
    unitTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonTariffInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTariffInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonTariffInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonTariffInfoTariffTypeEnum {
    Reward = 'REWARD'
}

export function JsonTariffInfoFromJSON(json: any): JsonTariffInfo {
    return JsonTariffInfoFromJSONTyped(json, false);
}

export function JsonTariffInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTariffInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'compAmount': !exists(json, 'compAmount') ? undefined : json['compAmount'],
        'compCnt': !exists(json, 'compCnt') ? undefined : json['compCnt'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'paidCompAmount': !exists(json, 'paidCompAmount') ? undefined : json['paidCompAmount'],
        'paidCompCnt': !exists(json, 'paidCompCnt') ? undefined : json['paidCompCnt'],
        'paidUnitCnt': !exists(json, 'paidUnitCnt') ? undefined : json['paidUnitCnt'],
        'pendingCompAmount': !exists(json, 'pendingCompAmount') ? undefined : json['pendingCompAmount'],
        'pendingCompCnt': !exists(json, 'pendingCompCnt') ? undefined : json['pendingCompCnt'],
        'pendingUnitCnt': !exists(json, 'pendingUnitCnt') ? undefined : json['pendingUnitCnt'],
        'tariffId': !exists(json, 'tariffId') ? undefined : json['tariffId'],
        'tariffType': !exists(json, 'tariffType') ? undefined : json['tariffType'],
        'unitCnt': !exists(json, 'unitCnt') ? undefined : json['unitCnt'],
        'unitRate': !exists(json, 'unitRate') ? undefined : json['unitRate'],
        'unitTitle': !exists(json, 'unitTitle') ? undefined : json['unitTitle'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonTariffInfoToJSON(value?: JsonTariffInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'compAmount': value.compAmount,
        'compCnt': value.compCnt,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'paidCompAmount': value.paidCompAmount,
        'paidCompCnt': value.paidCompCnt,
        'paidUnitCnt': value.paidUnitCnt,
        'pendingCompAmount': value.pendingCompAmount,
        'pendingCompCnt': value.pendingCompCnt,
        'pendingUnitCnt': value.pendingUnitCnt,
        'tariffId': value.tariffId,
        'tariffType': value.tariffType,
        'unitCnt': value.unitCnt,
        'unitRate': value.unitRate,
        'unitTitle': value.unitTitle,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

