/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonApiEventParty
 */
export interface JsonApiEventParty {
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    areas?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonApiEventParty
     */
    cateringLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    cateringMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    cateringStatus?: JsonApiEventPartyCateringStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    cateringType?: JsonApiEventPartyCateringTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonApiEventParty
     */
    cateringUsed?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    dateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonApiEventParty
     */
    dayNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    eventAbbr?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    eventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    firstVipAccessDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    firstVipAccessReaderId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    formatCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonApiEventParty
     */
    isDayAccess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonApiEventParty
     */
    isExtra?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonApiEventParty
     */
    isMediaAccess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonApiEventParty
     */
    isVipAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    parentFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    parentLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    table?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiEventParty
     */
    tags?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonApiEventPartyCateringStatusEnum {
    OkAvailable = 'OK_AVAILABLE',
    OkUsingNow = 'OK_USING_NOW',
    ErrorAlreadyUsed = 'ERROR_ALREADY_USED',
    ErrorGroupUsed = 'ERROR_GROUP_USED',
    ErrorNoCatering = 'ERROR_NO_CATERING',
    ErrorWrongPlace = 'ERROR_WRONG_PLACE'
}/**
* @export
* @enum {string}
*/
export enum JsonApiEventPartyCateringTypeEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}

export function JsonApiEventPartyFromJSON(json: any): JsonApiEventParty {
    return JsonApiEventPartyFromJSONTyped(json, false);
}

export function JsonApiEventPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiEventParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'cateringLimit': !exists(json, 'cateringLimit') ? undefined : json['cateringLimit'],
        'cateringMessage': !exists(json, 'cateringMessage') ? undefined : json['cateringMessage'],
        'cateringStatus': !exists(json, 'cateringStatus') ? undefined : json['cateringStatus'],
        'cateringType': !exists(json, 'cateringType') ? undefined : json['cateringType'],
        'cateringUsed': !exists(json, 'cateringUsed') ? undefined : json['cateringUsed'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'dateTime': !exists(json, 'dateTime') ? undefined : json['dateTime'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'eventAbbr': !exists(json, 'eventAbbr') ? undefined : json['eventAbbr'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstVipAccessDateTime': !exists(json, 'firstVipAccessDateTime') ? undefined : json['firstVipAccessDateTime'],
        'firstVipAccessReaderId': !exists(json, 'firstVipAccessReaderId') ? undefined : json['firstVipAccessReaderId'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'isDayAccess': !exists(json, 'isDayAccess') ? undefined : json['isDayAccess'],
        'isExtra': !exists(json, 'isExtra') ? undefined : json['isExtra'],
        'isMediaAccess': !exists(json, 'isMediaAccess') ? undefined : json['isMediaAccess'],
        'isVipAccess': !exists(json, 'isVipAccess') ? undefined : json['isVipAccess'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'parentFirstName': !exists(json, 'parentFirstName') ? undefined : json['parentFirstName'],
        'parentLastName': !exists(json, 'parentLastName') ? undefined : json['parentLastName'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'table': !exists(json, 'table') ? undefined : json['table'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function JsonApiEventPartyToJSON(value?: JsonApiEventParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areas': value.areas,
        'cateringLimit': value.cateringLimit,
        'cateringMessage': value.cateringMessage,
        'cateringStatus': value.cateringStatus,
        'cateringType': value.cateringType,
        'cateringUsed': value.cateringUsed,
        'companyName': value.companyName,
        'dateTime': value.dateTime,
        'dayNo': value.dayNo,
        'eventAbbr': value.eventAbbr,
        'eventDays': value.eventDays,
        'firstName': value.firstName,
        'firstVipAccessDateTime': value.firstVipAccessDateTime,
        'firstVipAccessReaderId': value.firstVipAccessReaderId,
        'formatCode': value.formatCode,
        'isDayAccess': value.isDayAccess,
        'isExtra': value.isExtra,
        'isMediaAccess': value.isMediaAccess,
        'isVipAccess': value.isVipAccess,
        'lastName': value.lastName,
        'note': value.note,
        'parentFirstName': value.parentFirstName,
        'parentLastName': value.parentLastName,
        'reason': value.reason,
        'table': value.table,
        'tags': value.tags,
    };
}

