import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps, isNumeric} from "../../model/form";
import {JsonTariff} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {useCallback} from "react";

interface Props extends FormProps<JsonTariff> {
}

const TariffForm = (props: Props) => {

    const t = useAppTranslation();

    const {item} = props;
    const isDynamic = !!item.tariffId && item.unitRate === undefined;

    const handleValidate = useCallback((values: JsonTariff) => {
        let errors = {} as FormikErrors<JsonTariff>;
        if (!values.tariffType) {
            errors.tariffType = t('Zadejte prosím typ');
        }
        if (!values.unitTitle) {
            errors.unitTitle = t('Zadejte prosím název');
        }
        if (isDynamic && values.unitRate !== undefined) {
            errors.unitRate = t('Tarif je dynamický');

        } else if (!isDynamic && !values.unitRate === undefined) {
            errors.unitRate = t('Zadejte prosím sazbu');
        }
        if (values.unitRate && !isNumeric(values.unitRate, true)) {
            errors.unitRate = t('Zadejte prosím číslo');
        }
        return errors;
    }, [t, isDynamic]);

    return (
        <FormContainer {...props} validate={handleValidate} children={<>
            <Grid item xs={12}>
                <TextFormField name="unitTitle" label={t('Název tarifu')} type={'text'} maxlength={100}/>
            </Grid>
            <Grid item xs={4}>
                <TextFormField name="unitRate" label={isDynamic ? t('Dynamická sazba') : t('Sazba')} type={'text'}
                    maxlength={6}
                    placeholder={t('Dynamická')}
                    disabled={isDynamic}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>
        </>}/>
    );
}

export default TariffForm;
