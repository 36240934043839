import * as React from "react";
import {CSSProperties, MouseEvent, useEffect, useMemo, useRef} from "react";
import {JsonEventPartyInfo, JsonEventPartySeating} from "../../generated-api";
import {GroupsViewMode} from "./SeatingPlanGroups";
import {useAppTranslation} from "../../services/i18n";
import {ChunkInfo, JsonEventPartyInfoWithChunkId} from "../party/PartyAccredToEventForm";
import {Box, Button, Grid} from "@mui/material";
import {partyName} from "../../model/party";
import {DragIndicatorOutlined} from "@mui/icons-material";
import {areSeatingItemsEqual, parseTableNames, SeatingActions, SeatingItem, SeatingMap, TableInfo} from "./svgUtils";

const labelStyle: CSSProperties = {
    cursor: 'pointer',
}

export const SeatingPlanGroupItem = ({item, dayNo, onLabelMouseClick, onHandleMouseDown, selectedItems, seating, parent, extras, groupsViewMode, actions, tables}: {
    item: SeatingItem,
    dayNo: number,
    onLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
    onHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void,
    selectedItems?: SeatingItem[],
    seating?: JsonEventPartySeating,
    parent?: SeatingItem,
    extras?: SeatingItem[],
    groupsViewMode: GroupsViewMode,
    actions?: SeatingActions,
    eventDaySeating: SeatingItem[],
    tables?: TableInfo[]
}) => {

    const t = useAppTranslation();
    const highlightedItems = useRef<HTMLElement[]>();

    const ep = item.partyId ? item as JsonEventPartyInfoWithChunkId : undefined;
    const isSelected = selectedItems?.find((other) => areSeatingItemsEqual(other, item));

    const chunkStyle = useMemo(() => ({
        background: isSelected ? 'rgba(204, 255, 0, .5)' : undefined,
        fontSize: groupsViewMode === 'list' ? '85%' : undefined,
        '& > .MuiGrid-item:first-of-type': {
            position: 'relative',
            color: 'red'
        },
        '& .accred-chunk': {
            fontSize: 'inherit',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            '& > div': {
                top: 0,
                bottom: 0,
            },
            '& > span': {
                top: '-5px',
                left: '-3px',
                position: 'relative'
            }
        },
        '& .MuiSvgIcon-root': {
            // fontSize: '16px',
            // margin: '0 0 -3px 0',
            // cursor: 'move',
            '&:hover': {
                // opacity: .7,
                // background: '#eee'
            }
        },
        '&:hover': groupsViewMode === 'list' ? undefined : {
            background: isSelected ? '#ccff00' : 'rgba(204, 255, 0, .5)',
            '&:hover .MuiSvgIcon-root': {
                display: 'inline'
            },
            '&:hover button': {
                display: 'inline-flex'
            }
        }
    }), [isSelected, groupsViewMode]);

    const currentTableNames = useMemo(() => {
        return parseTableNames(seating?.tables?.[dayNo]);
    }, [seating, dayNo]);

    const badge = useMemo(() => {
        if (groupsViewMode === 'list') {
            return null;
        }
        if (currentTableNames) {
            if (groupsViewMode === 'hover') {
                return currentTableNames.map((tableName, i) => <Box sx={{
                    fontSize: '80%',
                    lineHeight: '150%',
                    padding: '1px 2px',
                    display: 'inline-block'
                }} key={i}>{tableName}</Box>);
            }
            return currentTableNames.map((tableName, i) => <Button key={i} variant={'text'} color={'inherit'} size={'small'}
                title={t('Zrušit usazení na {{tableName}}', {tableName})}
                sx={{
                    fontSize: '80%',
                    padding: '1px 2px',
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette['error'].main + ' !important',
                        color: 'white !important'
                    }
                }}
                onClick={actions ? () => {
                    if (!tableName) {
                        return;
                    }
                    if (item.partyId) {
                        actions.actionDeleteSeating(dayNo, {[item.partyId]: tableName}, {});
                    } else if (item.tickets) {
                        actions.actionDeleteSeating(dayNo, {}, item.tickets.reduce((map, et) => {
                            if (et.ticketId) {
                                map[et.ticketId] = tableName;
                            }
                            return map;
                        }, {} as SeatingMap));
                    }

                } : undefined}
            >{tableName}</Button>);
        }
        const parentTableName = parent?.seating?.tables?.[dayNo];
        if (parentTableName && actions) {
            return <Button variant={'contained'} color={'success'} size={'small'} title={t('Usadit k {{parentName}}', {parentName: partyName(parent)})} sx={{
                fontSize: '80%',
                padding: '1px 2px'
            }} onClick={() => {
                if (!item.partyId) {
                    return;
                }
                actions.actionAddSeating(dayNo, {[item.partyId]: parentTableName}, {}, false);

            }}>{parentTableName}</Button>;

        } else if (extras) {
            const doneExtraTables: string[] = [];
            const buttons: JSX.Element[] = [];
            extras.forEach((extra) => {
                const extraTableName = extra.seating?.tables?.[dayNo];
                if (extraTableName && doneExtraTables.indexOf(extraTableName) < 0 && actions) {
                    doneExtraTables.push(extraTableName);
                    buttons.push(<Button key={extraTableName} variant={'contained'} color={'success'} size={'small'} title={t('Usadit k doprovodu {{extraName}}', {extraName: partyName(extra)})}
                        sx={{
                            fontSize: '80%',
                            padding: '1px 2px'
                        }} onClick={() => {
                        if (!item.partyId || !dayNo) {
                            return;
                        }
                        actions.actionAddSeating(dayNo, {[item.partyId]: extraTableName}, {}, false);

                    }}>{extraTableName}</Button>)
                }
            });
            if (buttons.length) {
                return buttons;
            }
        }

        return null;

    }, [currentTableNames, dayNo, parent, item.partyId, item.tickets, extras, groupsViewMode, actions, t]);

    const groupTitle = useMemo(() => {
        const title: string[] = [];
        if (item.tickets) {
            title.push(item.tickets.length + 'x');
            if (item.companyName) {
                title.push(partyName(item));
            }
        } else {
            title.push(partyName(item))
        }

        return <>
            {title.join(' ')}
            {!!(item as JsonEventPartyInfo)?.orgCompanyName && <div><em style={{color: 'gray', fontSize: '85%'}}>{(item as JsonEventPartyInfo).orgCompanyName}</em></div>}
        </>
    }, [item]);

    const seatingInfo = useMemo(() => {
        if (!item.tickets || !tables) {
            return null;
        }
        const ownedTables = tables?.filter(t => currentTableNames && currentTableNames.indexOf(t.tableName) >= 0);
        const totalCapacity = ownedTables?.reduce((sum, t) => sum + t.capacity, 0);

        return <div><em>
            ({t('{{count}} stolu', {count: ownedTables?.length || 0})} / {t('{{count}} mist', {count: totalCapacity})})
        </em></div>;

    }, [currentTableNames, item.tickets, tables, t]);

    useEffect(() => {
        return () => {
            highlightedItems.current?.forEach((el) => {
                if (el) {
                    el.style.fill = 'unset';
                }
            });
            highlightedItems.current = undefined;
        }
    });

    return <Grid container sx={chunkStyle}
        onMouseEnter={groupsViewMode === 'list' ? undefined : () => {
            tables?.filter(t => !!currentTableNames && currentTableNames.indexOf(t.tableName) >= 0).forEach((t) => {
                const el = document.getElementById('item-' + t.id);
                if (el) {
                    el.style.fill = 'yellow';
                    if (!highlightedItems.current) {
                        highlightedItems.current = [];
                    }
                    highlightedItems.current?.push(el);
                }
            });
        }}
        onMouseLeave={groupsViewMode === 'list' ? undefined : () => {
            highlightedItems.current?.forEach((el) => {
                if (el) {
                    el.style.fill = 'unset';
                }
            });
            highlightedItems.current = undefined;
        }}
    >
        <Grid item xs={1}>
            {!!(ep as any)?.['parents'] && <ChunkInfo item={ep as JsonEventPartyInfoWithChunkId} dayNo={dayNo}/>}
        </Grid>
        <Grid item xs={groupsViewMode === 'list' ? 11 : 8}
            onClick={onLabelMouseClick ? (e) => onLabelMouseClick(item, e) : undefined}
            style={groupsViewMode === 'list' ? undefined : labelStyle}
        >
            {groupTitle}
            {!!(item as JsonEventPartyInfo)?.orgCompanyName && <div><em style={{color: 'gray', fontSize: '85%'}}>{(item as JsonEventPartyInfo).orgCompanyName}</em></div>}
            {seatingInfo}
        </Grid>
        {groupsViewMode !== 'list' && <Grid item xs={2}>
            {badge}
        </Grid>}
        {groupsViewMode !== 'list' && groupsViewMode !== 'hover' && <Grid item xs={1}>
            <Button variant={'contained'} size={'small'} color={'inherit'} fullWidth
                sx={{display: isSelected ? 'inline-flex' : 'none', padding: 0, fontSize: '80%', marginTop: '-2px'}}
                onClick={onHandleMouseDown ? (e) => onHandleMouseDown(item, e) : undefined}>
                <DragIndicatorOutlined/>
            </Button>
        </Grid>}
    </Grid>;
}
