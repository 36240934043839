import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {
    CSSProperties,
    Dispatch,
    MouseEvent,
    MouseEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {JsonFloorShape, JsonFloorShapeShapeTypeEnum, JsonFloorShapeText} from "../../generated-api";
import {Box, Button, Card, CardContent, Chip, Grid} from "@mui/material";
import {RedoOutlined, UndoOutlined} from "@mui/icons-material";
import {
    BrushType,
    clampItemsByBoundary,
    cloneSvgNode,
    createId,
    CustomActionType,
    CustomItemWithSvg,
    getNewAngle,
    getNewScale,
    getSvgPoint,
    InputContext,
    JsonCustomSvgItem,
    RewindType,
    rotateSvgToAngle,
    safeSvgValue,
    SetBrushType,
    SvgShapeDef,
    transform,
    updateGhostPositions,
    zoomSvgToScale
} from "./svgUtils";
import {ShapeDirtyValues} from "./SeatingShape";
import {Formik, FormikErrors} from "formik";
import {TextFormField} from "../form/TextFormField";
import {FAKE_VALUE_EMPTY, isNumeric} from "../../model/form";
import {CheckboxPlain} from "../form/CheckboxField";
import {SeatingShapeToolbar} from "./SeatingShapeToolbar";
import {ShapeStatsValueType} from "./SeatingPlan";

const SVG_ZOOM = 5.0;

const STEP_MOVE = 0.5;
const STEP_MOVE_SMOOTH = .1;

const TEXT_SCALE = 0.25;
const TEXT_FONT_SIZE = '12px';
const textStyle: CSSProperties = {
    pointerEvents: 'none',
    fontSize: TEXT_FONT_SIZE,
    userSelect: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'visible',
    background: '#ffcc0066'
};

export function parseSvgIntoItems(svg: string): JsonCustomSvgItem[] {
    if (!svg) {
        return [];
    }
    const nodes = (new DOMParser().parseFromString(svg, "image/svg+xml").childNodes?.[0]?.childNodes as any as SVGGraphicsElement[]);
    if (!nodes) {
        return [];
    }

    const items: JsonCustomSvgItem[] = [];

    nodes.forEach((node) => {
        if (node.nodeName === '#text') {
            return;
        }
        items.push(parseSvgIntoItem(node, items.length + 1));
    })

    return items;
}

export function parseSvgIntoItem(node: SVGGraphicsElement, id: number): JsonCustomSvgItem {
    const originX = node.getAttribute('x') || node.getAttribute('cx');
    const originY = node.getAttribute('y') || node.getAttribute('cy');
    const w = node.getAttribute('width');
    const h = node.getAttribute('height');
    const r = node.getAttribute('r');

    const transform = node.getAttribute('transform');
    const a = transform && transform.indexOf('rotate') >= 0
        ? transform.split('rotate(')[1]
            .split(')')[0].split(' ')[0]
        : null;
    const x = transform && transform.indexOf('translate') >= 0
        ? transform.split('translate(')[1]
            .split(')')[0].split(' ')[0]
        : null;
    const y = transform && transform.indexOf('translate') >= 0
        ? transform.split('translate(')[1]
            .split(')')[0].split(' ')[1]
        : null;
    const s = transform && transform.indexOf('scale') >= 0
        ? transform.split('scale(')[1]
            .split(')')[0].split(' ')[0]
        : null;

    return {
        id,
        type: node.nodeName,
        x: (x ? +x : 0),
        y: (y ? +y : 0),
        originX: originX ? +originX : undefined,
        originY: originY ? +originY : undefined,
        w: w ? +w : undefined,
        h: h ? +h : undefined,
        r: r ? +r : undefined,
        s: s ? (+s * 100) : undefined,
        a: a ? +a : undefined,
    };
}

function serializeItemsIntoSvg(svg: SVGSVGElement) {
    const svgGroup = svg.querySelector('#shape-group') as SVGGraphicsElement;

    const xmlSerializer = new XMLSerializer()
    const nodes: string[] = [];
    svgGroup?.childNodes.forEach((node) => {
        const cloned = node.cloneNode(true) as SVGGraphicsElement;
        cloned.removeAttribute('id');
        cloned.removeAttribute('fill');
        cloned.removeAttribute('style');
        nodes.push(xmlSerializer.serializeToString(cloned).replace(/ xmlns="[^"]+"/, ''));
    });
    if (!nodes.length) {
        return '';
    }

    return "<g id='%shapeCode%' stroke-width='0.1'>\n\t" + nodes.join("\n\t") + "\n</g>"
}

type SaveState = {
    dirtyShape: JsonFloorShape,
    isSaving: boolean,
}

const SvgCustomElement = (
    {
        item,
        onMouseDown,
        selectedItems,
        draggedItemIds,
    }: {
        item: JsonCustomSvgItem,
        onMouseDown?: MouseEventHandler,
        selectedItems?: CustomItemWithSvg[],
        draggedItemIds?: number[],
    }) => {
    const isSelected = !!selectedItems?.find((other) => other.item.id === item.id);
    const isDragged = !!draggedItemIds?.indexOf && draggedItemIds.indexOf(item.id) >= 0;

    const style = useMemo(() => {
        return {
            opacity: isDragged ? 0.5 : undefined
        }
    }, [isDragged]);

    return useMemo(() => {
        const SvgTag = `${item.type}` as keyof JSX.IntrinsicElements;
        const isCircle = item.type === 'circle';

        return <SvgTag
            id={item.id ? 'item-' + item.id : undefined}
            r={isCircle ? item.r : undefined}
            x={item.originX}
            y={item.originY}
            cx={isCircle ? item.originX : undefined}
            cy={isCircle ? item.originY : undefined}
            width={item.w}
            height={item.h}
            transform={transform(item)}
            fill={isSelected ? '#ccff00' : undefined}
            style={style}
            onMouseDown={onMouseDown}
        />

    }, [item, isSelected, style, onMouseDown]);
}


type SeatingShapeCanvasProps = {
    shape: JsonFloorShape,
    shapeStats?: ShapeStatsValueType,
    toolbarShapes: JsonFloorShape[],
    onSetBrush: SetBrushType,
    brush?: BrushType,
    onSetDirtyValues: (dirty: ShapeDirtyValues) => void,
    dirtyValues: ShapeDirtyValues,
    onSaveShape: (item: JsonFloorShape, isRemove?: boolean) => Promise<void>,
    clipboard?: CustomItemWithSvg[] | undefined,
    setClipboard?: Dispatch<SetStateAction<CustomItemWithSvg[] | undefined>>,
}

export const SeatingShapeCanvas = (props: SeatingShapeCanvasProps) => {

    const {
        shape, shapeStats, toolbarShapes,
        brush, onSetBrush,
        onSetDirtyValues, dirtyValues, onSaveShape,
        clipboard, setClipboard,
    } = props;

    const t = useAppTranslation();

    const svgRef = useRef<SVGSVGElement | null>(null);
    const svgDragGroupRef = useRef<SVGGElement | null>(null);
    const pendingItem = useRef<CustomItemWithSvg | null>();
    const ghostItems = useRef<CustomItemWithSvg[] | null>();

    const inputContext = useRef<InputContext<ShapeDirtyValues>>({
        mouseAction: undefined,
        mouseActionTimeout: undefined,
        mouseX: 0,
        mouseY: 0,
        dirtyValues
    });

    const [undoStack, setUndoStack] = useState<CustomActionType[]>(dirtyValues.undoStack);
    const [redoStack, setRedoStack] = useState<CustomActionType[]>(dirtyValues.redoStack);
    const [items, setItems] = useState<JsonCustomSvgItem[]>(dirtyValues.items);

    const [selectedItems, setSelectedItems] = useState<CustomItemWithSvg[] | undefined>([]);
    const [draggedItemIds, setDraggedItemIds] = useState<number[] | undefined>([]);

    const [saveState, setSaveState] = useState<SaveState>({isSaving: false, dirtyShape: shape});
    const [showTextPoly, setShowTextPoly] = useState(false);

    /**
     * Actions
     */

    const dirtyShape = saveState.dirtyShape;
    const handleSaveShape = useCallback(async (mode: 'save' | 'clone' | 'remove') => {

        if (!onSaveShape || !svgRef.current) {
            return null;
        }

        setSaveState({dirtyShape, isSaving: true});

        if (mode === 'remove') {
            await onSaveShape(dirtyShape, true);
        } else {
            const svgContent = serializeItemsIntoSvg(svgRef.current)
            if (svgContent) {
                const payload: JsonFloorShape = {
                    ...dirtyShape,
                    svg: svgContent,
                    id: mode === 'clone' ? FAKE_VALUE_EMPTY : shape.id
                }
                await onSaveShape(payload);
            }
        }

        setSaveState({dirtyShape, isSaving: false});

    }, [shape, dirtyShape, onSaveShape]);

    const setRewind = useCallback((action: CustomActionType, rewindType?: RewindType) => {
        if (!rewindType) {
            setRedoStack([]);
            inputContext.current.dirtyValues.redoStack = [];
        }
        if (rewindType === 'undo') {
            setRedoStack((q) => {
                const merged = [...q.slice(q.length - 1000), action];
                inputContext.current.dirtyValues.redoStack = merged;
                return merged;
            });
        } else {
            setUndoStack((q) => {
                const merged = [...q.slice(q.length - 1000), action];
                inputContext.current.dirtyValues.undoStack = merged;
                return merged;
            });
        }

    }, []);

    const actionAddItem = useCallback((newItems: JsonCustomSvgItem[], boundingBox?: SVGSVGElement, rewindType?: RewindType) => {
        const clamped = clampItemsByBoundary(newItems, boundingBox);
        setItems((items) => {
            const merged = [...items, ...clamped];
            merged.forEach((c) => {
                if (c.id <= 0) {
                    c.id = createId(merged);
                }
            });
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'add',
            newItems: clamped
        }, rewindType);

    }, [setRewind]);

    const actionMoveItem = useCallback((currentItems: JsonCustomSvgItem[], newItems: JsonCustomSvgItem[], boundingBox?: SVGSVGElement, rewindType?: RewindType) => {
        const clamped = clampItemsByBoundary(newItems, boundingBox);
        setItems((items) => {
            const merged = items.map((item) => clamped.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'move',
            oldItems: currentItems.slice(),
            newItems: clamped
        }, rewindType);

    }, [setRewind]);

    const actionRotateItem = useCallback((currentItems: JsonCustomSvgItem[], a?: number, rewindType?: RewindType) => {
        const newItems: JsonCustomSvgItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            a
        }));
        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'rotate',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind]);

    const actionScaleItem = useCallback((currentItems: JsonCustomSvgItem[], s?: number, rewindType?: RewindType) => {
        const newItems: JsonCustomSvgItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            s
        }));
        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'scale',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind]);

    const actionUpdateItem = useCallback((currentItems: JsonCustomSvgItem[], updated: Partial<JsonCustomSvgItem>, rewindType?: RewindType) => {
        const newItems: JsonCustomSvgItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            ...updated
        }));

        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            setSaveState((s) => ({...s, errors: /*  validateFloor(merged) */ undefined}));
            return merged;
        });
        setRewind({
            action: 'text',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind, setItems]);

    const actionDeleteItem = useCallback((currentItems: JsonCustomSvgItem[], rewindType?: RewindType) => {
        setItems((items) => {
            const merged = items.filter((item) => !currentItems.find((currentItem) => currentItem.id === item.id));
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'delete',
            oldItems: currentItems.slice(),
        }, rewindType);

    }, [setRewind]);

    /**
     * Drawing
     */

    const cancelAllPendingActions = useCallback((clearBrush: boolean) => {
        if (svgDragGroupRef.current) {
            while (svgDragGroupRef.current?.hasChildNodes() && svgDragGroupRef.current.lastChild) {
                svgDragGroupRef.current.removeChild(svgDragGroupRef.current.lastChild)
            }
        }
        pendingItem.current = undefined;
        ghostItems.current = undefined;

        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }
        inputContext.current.mouseAction = undefined;
        setDraggedItemIds(undefined);

        if (clearBrush && onSetBrush) {
            onSetBrush(undefined);
        }

    }, [onSetBrush]);

    const activeItemStartDrag = useCallback((originItem: CustomItemWithSvg, selectedItems?: CustomItemWithSvg[]) => {
        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }

        if (!originItem) {
            inputContext.current.mouseAction = undefined;
            return;
        }
        inputContext.current.mouseAction = 'drag';
        ghostItems.current = [];
        const draggedIds: number[] = [];
        for (let item of [originItem, ...(selectedItems?.filter((other) => other.item.id !== originItem.item.id) || [])]) {
            const current = items.find((currentItem) => currentItem.id === item.item.id) || item.item;
            const ghostItem: CustomItemWithSvg = {
                item: {...current},
                svgElement: cloneSvgNode(item.svgElement, 'ghost-item')
            };
            ghostItems.current.push(ghostItem);
            svgDragGroupRef.current?.appendChild(ghostItem.svgElement);
            draggedIds.push(current.id);
        }
        setDraggedItemIds(draggedIds);

    }, [items]);

    const renderBrush = useCallback((svg: SVGSVGElement, brush: BrushType, pos: { x: number, y: number }) => {
        setSelectedItems(undefined);

        const svgElement = cloneSvgNode(brush.svgElement, 'ghost-shape');
        if (brush.a) {
            rotateSvgToAngle(svg, svgElement, brush.a);
        }
        if (brush.s && brush.s !== 100) {
            zoomSvgToScale(svg, svgElement, brush.s);
        }
        const fakeItem = parseSvgIntoItem(svgElement, createId(items));

        activeItemStartDrag({
            item: {
                ...fakeItem,
                x: pos.x,
                y: pos.y,
                a: brush.a,
                s: brush.s
            },
            svgElement
        });
    }, [activeItemStartDrag, items]);

    /**
     * Event handlers
     */

    const dragTimeout = useCallback(() => {
        if (pendingItem.current) {
            activeItemStartDrag(pendingItem.current, selectedItems);
        }
    }, [selectedItems, activeItemStartDrag]);

    const handleItemMouseDown = useCallback((item: JsonCustomSvgItem, e: MouseEvent) => {
        if (e.button === 2) {
            // right click
            return;
        }
        const svgElement = e.target as SVGGraphicsElement; // rect, circle etc
        if (!svgElement) {
            return;
        }
        if (!e.ctrlKey) {
            // unselect all if not selecting (any) current
            setSelectedItems((selected) => {
                return selected?.find(s => s.item.id === item.id) ? selected : undefined
            });
        }

        pendingItem.current = {
            item,
            svgElement
        };

        inputContext.current.mouseAction = 'click';

        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }
        inputContext.current.mouseActionTimeout = setTimeout(dragTimeout, 500);

    }, [dragTimeout]);

    const handleCanvasMouseUp = useCallback((e: MouseEvent) => {
        if (e.button === 2) {
            // right click
            setSelectedItems(undefined);
            cancelAllPendingActions(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (inputContext.current.mouseAction === 'text') {
            if (e.target === svgRef.current) {
                setSelectedItems(undefined);
                cancelAllPendingActions(true);
            }
            return;
        }

        if (!svgRef.current /*|| !(pendingItem.current || ghostItems.current)*/) {
            setSelectedItems(undefined);
            setDraggedItemIds(undefined);
            return;
        }

        const currentItem = pendingItem.current;
        if (inputContext.current.mouseAction === 'click') {
            if (currentItem) {
                const isAlreadySelected = selectedItems?.find((s) => s.item.id === currentItem.item.id);
                if (isAlreadySelected && !e.ctrlKey) {
                    setSelectedItems([currentItem]);
                    return; // do not clear
                }
                setSelectedItems((selected) => {
                    if (isAlreadySelected) {
                        return selected?.length === 1 ? undefined : selected?.filter(s => s.item.id !== currentItem.item.id);
                    } else {
                        if (e.ctrlKey) {
                            return selected ? [...selected, currentItem] : [currentItem];
                        } else {
                            return [currentItem];
                        }
                    }
                })
            }

        } else if (ghostItems.current?.[0]) {
            if (selectedItems?.length) {
                actionMoveItem(
                    selectedItems.map((selectedItem) => selectedItem.item),
                    ghostItems.current?.map((ghostItem) => ghostItem.item),
                    svgRef.current);

            } else if (currentItem) {
                actionMoveItem([currentItem.item], [ghostItems.current[0].item]);

            } else {
                actionAddItem(ghostItems.current?.map((ghostItem) => ghostItem.item), svgRef.current);
                if (brush && ghostItems.current?.[0] && onSetBrush) {
                    onSetBrush({
                        ...brush,
                        a: ghostItems.current[0].item?.a,
                        s: ghostItems.current[0].item?.s
                    });
                }
            }
        }
        cancelAllPendingActions(false);

    }, [cancelAllPendingActions, actionMoveItem, actionAddItem, selectedItems, brush, onSetBrush]);

    const handleCanvasMouseMove = useCallback((e: MouseEvent) => {
        inputContext.current.mouseX = e.clientX;
        inputContext.current.mouseY = e.clientY;

        if (!svgRef.current || !(ghostItems.current || brush?.svgElement || pendingItem.current)) {
            return;
        }
        const pos = getSvgPoint(e, svgRef.current, e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE);

        if (!ghostItems.current) {
            if (brush?.svgElement) {
                // first entry after toolbar pick
                renderBrush(svgRef.current, brush, pos);

            } else if (pendingItem.current?.item) {
                // pressed and moving immediately (single item only)
                if (Math.abs(pendingItem.current.item.x - pos.x) > 5 || Math.abs(pendingItem.current.item.y - pos.y) > 5) {
                    activeItemStartDrag(pendingItem.current, selectedItems);

                } else {
                    // clicking
                    return;
                }
            }
        }
        if (pos && ghostItems.current) {
            updateGhostPositions(pos, ghostItems.current);
        }

    }, [brush, activeItemStartDrag, selectedItems, renderBrush]);

    const handleRewindAction = useCallback((a: CustomActionType, rewindType: RewindType) => {
        switch (a.action) {
            case 'move':
                if (a.newItems && a.oldItems?.[0]) {
                    actionMoveItem(a.newItems, a.oldItems, undefined, rewindType);
                }
                break;
            case 'add':
                if (a.newItems) {
                    actionDeleteItem(a.newItems, rewindType);
                }
                break;
            case 'delete':
                if (a.oldItems) {
                    actionAddItem(a.oldItems, undefined, rewindType);
                }
                break;
            case 'rotate':
                if (a.newItems && a.oldItems?.[0]) {
                    actionRotateItem(a.newItems, a.oldItems[0].a, rewindType);
                }
                break;
            case 'scale':
                if (a.newItems && a.oldItems?.[0]) {
                    actionScaleItem(a.newItems, a.oldItems[0].s, rewindType);
                }
                break;
            case 'text':
                if (a.newItems && a.oldItems?.[0]) {
                    actionUpdateItem(a.newItems, a.oldItems[0], rewindType);
                }
                break;
        }

    }, [actionMoveItem, actionAddItem, actionDeleteItem, actionRotateItem, actionScaleItem, actionUpdateItem]);

    const rewindLastAction = useCallback((isRedo: boolean) => {
        (isRedo ? setRedoStack : setUndoStack)((actions) => {
            if (!actions.length) {
                return [];
            }
            const last = actions[actions.length - 1];
            if (last) {
                handleRewindAction(last, isRedo ? 'redo' : 'undo');
            }
            return actions.filter((a) => a !== last);
        });
    }, [handleRewindAction]);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (!svgRef.current) {
            return;
        }
        if ((e.target as any)?.nodeName === 'INPUT') {
            return;
        }

        switch (e.key) {
            case 'Escape':
                cancelAllPendingActions(true);
                setSelectedItems(undefined);
                break;
        }

        if (inputContext.current.mouseAction === 'text') {
            return;
        }

        const currentItems: JsonCustomSvgItem[] = items.filter((item) => selectedItems?.find((selected) => selected.item.id === item.id));

        switch (e.key) {
            case 'Delete':
                // case 'Backspace':
                if (ghostItems.current) {
                    cancelAllPendingActions(true);
                    break;
                }
                if (currentItems.length) {
                    actionDeleteItem(currentItems);
                }
                setSelectedItems(undefined);
                break;

            case 'z':
            case 'y':
                if (ghostItems.current && !brush) {
                    break;
                }
                if (e.ctrlKey) {
                    rewindLastAction(e.key === 'y');
                    e.preventDefault();
                }
                break;

            case 'a':
                if (ghostItems.current) {
                    return;
                }
                const svgElements = svgRef.current?.querySelectorAll("g[id*=item]");
                const newSelectedItems: CustomItemWithSvg[] = [];
                svgElements.forEach((svgElement) => {
                    const item = items.find((item) => 'item-' + item.id === svgElement.id)
                    if (item && svgElement instanceof SVGGraphicsElement) {
                        newSelectedItems.push({
                            item,
                            svgElement
                        })
                    }
                });
                if (newSelectedItems.length) {
                    setSelectedItems(newSelectedItems);
                    e.preventDefault();
                }
                break;

            case 'r':
            case 'R':
            case 'ArrowRight':
            case 'ArrowLeft':
            case 'ArrowUp':
            case 'ArrowDown':
                if (((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && e.ctrlKey) || e.key === 'r' || e.key === 'R') {
                    if (ghostItems.current) {
                        const a = ghostItems.current[0].item?.a;
                        for (let ghostItem of ghostItems.current) {
                            ghostItem.item.a = getNewAngle(e.key, a, e.shiftKey);
                            rotateSvgToAngle(svgRef.current, ghostItem.svgElement, ghostItem.item.a);
                        }

                    } else if (currentItems.length) {
                        actionRotateItem(currentItems, getNewAngle(e.key, currentItems?.[0]?.a, e.shiftKey));
                    }
                } else if (currentItems.length) {
                    const step = e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE;
                    actionMoveItem(currentItems, currentItems.map((currentItem) => ({
                        ...currentItem,
                        x: (e.key === 'ArrowLeft' ? currentItem.x - step : (e.key === 'ArrowRight' ? currentItem.x + step : currentItem.x)),
                        y: (e.key === 'ArrowUp' ? currentItem.y - step : (e.key === 'ArrowDown' ? currentItem.y + step : currentItem.y)),
                    })), svgRef.current);
                }
                e.preventDefault();
                break;

            case '+':
            case '-':
                if (ghostItems.current) {
                    for (let ghostItem of ghostItems.current) {
                        ghostItem.item.s = getNewScale(e.key, ghostItems.current[0].item?.s);
                        zoomSvgToScale(svgRef.current, ghostItem.svgElement, ghostItem.item.s);
                    }

                } else if (currentItems.length) {
                    const newScale = getNewScale(e.key, currentItems?.[0]?.s);
                    if (Math.abs(newScale - (currentItems?.[0]?.s || 100)) < 1) {
                        break;
                    }
                    actionScaleItem(currentItems, newScale);
                }
                e.preventDefault();
                break;

            case 'c':
                if (!e.ctrlKey || !currentItems.length || ghostItems.current) {
                    return;
                }
                // setClipboard(currentItems);
                const copy: CustomItemWithSvg[] = [];
                for (let item of currentItems) {
                    const selected = selectedItems?.find((selected) => selected.item.id === item.id);
                    if (!selected) {
                        continue;
                    }
                    copy.push({
                        item,
                        svgElement: selected.svgElement
                    })
                }
                if (copy.length && setClipboard) {
                    setClipboard(copy);
                }
                e.preventDefault();
                break;

            case 'v':
                if (!e.ctrlKey || !clipboard || ghostItems.current) {
                    return;
                }
                setSelectedItems(undefined);
                const newItems: CustomItemWithSvg[] = [];
                const pos = getSvgPoint({
                        clientX: inputContext.current.mouseX,
                        clientY: inputContext.current.mouseY
                    },
                    svgRef.current,
                    e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE);

                for (let item of clipboard) {
                    const newId = createId();
                    newItems.push({
                        item: {
                            ...item.item,
                            id: newId,
                        },
                        svgElement: cloneSvgNode(item.svgElement, 'ghost-' + newId)
                    })
                }
                if (items.length <= 0) {
                    actionAddItem(newItems.map((newItem) => newItem.item), svgRef.current);

                } else {
                    activeItemStartDrag(newItems[0], newItems.slice(1));
                    if (ghostItems.current) {
                        updateGhostPositions(pos, ghostItems.current);
                    }
                }
                e.preventDefault();
                break;

            // case 'i':
            //     if (!e.ctrlKey) {
            //         return;
            //     }
            //     let i = 1;
            //     items
            //         // .filter(x => !x.text)
            //         .forEach((item) => {
            //         actionUpdateItem([item], '' + i++);
            //     });
            //     return;
        }

    }, [cancelAllPendingActions, activeItemStartDrag, rewindLastAction,
        actionDeleteItem, actionRotateItem, actionMoveItem, actionScaleItem, actionAddItem,
        brush, selectedItems, items, clipboard, setClipboard]);

    const elements = useMemo(() => {
        const elements = items
            ?.sort((a, b) => a.y === b.y ? (a.x > b.x ? -1 : 1) : (a.y > b.y ? -1 : 1))
            ?.map((item, i) => {
                return <SvgCustomElement key={i}
                    item={item}
                    onMouseDown={(e) => handleItemMouseDown(item, e)}
                    selectedItems={selectedItems}
                    draggedItemIds={draggedItemIds}
                />
            });

        if (showTextPoly && dirtyShape.text) {
            const t = dirtyShape.text;
            const s = TEXT_SCALE;
            const w = t.w * (1 / TEXT_SCALE);
            const h = t.h * (1 / TEXT_SCALE);
            const ta = (t.a || 0);
            const tx = t.x + (ta >= 90 ? (0.3) : 0); // vertical top padding correction
            const ty = t.y + (ta < 90 ? (0.3) : 0); // vertical top padding correction

            elements?.push(<foreignObject key={'text-placeholder'} id={'item-text'}
                width={w}
                height={h}
                style={textStyle}
                transform={'translate(' + tx + ' ' + ty + ')'
                    + ' rotate(' + ta + ' 0 0)'
                    + ' scale(' + s + ' ' + s + ')'}
            >
                123
            </foreignObject>)
        }

        return elements;
    }, [showTextPoly, dirtyShape.text, items, handleItemMouseDown, selectedItems, draggedItemIds]);

    const formItem = selectedItems?.length === 1 ? items?.find(item => item.id === selectedItems[0].item.id) : undefined;

    const shapeForm = useMemo(() => {
        const NUM_KEYS: (keyof JsonFloorShapeText)[] = ['w', 'h', 'a', 'x', 'y'];

        const validate = (values: JsonFloorShape) => {
            const errors: FormikErrors<JsonFloorShape> = {};

            if (values.text) {
                for (const key of NUM_KEYS) {
                    if (values.text[key] && (!isNumeric(values.text[key]) && !isNumeric(String(values.text[key]).replace(',', '.')))) {
                        if (!errors.text) {
                            errors.text = {} as any;
                        }
                        (errors.text as any)[key] = t('Hodnota není číslo');
                    }
                }
            }

            if (!values.capacity) {
                if (values.shapeType === JsonFloorShapeShapeTypeEnum.Table) {
                    errors.capacity = t('Hodnota je povinná');
                }
            } else if (!isNumeric(values.capacity, true)) {
                errors.capacity = t('Hodnota není celé číslo');
            }

            return errors;
        }

        const onSubmit = ((values: JsonFloorShape) => {
            const fixed: JsonFloorShape = {...values};
            if (fixed.text) {
                for (const key of NUM_KEYS) {
                    if (fixed.text[key]) {
                        (fixed.text as any)[key] = +String(fixed.text[key]).replace(',', '.');
                    }
                }
            }
            return setSaveState(s => ({...s, dirtyShape: values}));
        });

        return <Card>
            <CardContent>
                <Formik
                    initialValues={shape}
                    enableReinitialize={true}
                    validate={validate}
                    validateOnChange={true}
                    onSubmit={onSubmit}
                >{({submitForm, values}) => {
                    return <Grid container spacing={1} columns={15}>
                        {values.shapeType === JsonFloorShapeShapeTypeEnum.Table && <Grid item xs={4}>
                            <TextFormField name="capacity" label={t('Kapacita')} type={'text'} minValue={0} maxValue={100} onBlur={submitForm}/>
                        </Grid>}
                        <Grid item xs={15} sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                            <CheckboxPlain name={'showText'} label={'Zobrazit umístění textu'} onChange={setShowTextPoly} currentValue={showTextPoly}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="text.x" label={t('Text X')} type={'text'} minValue={-20} maxValue={20} onBlur={submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="text.y" label={t('Text Y')} type={'text'} minValue={-20} maxValue={20} onBlur={submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="text.w" label={t('Šířka')} type={'text'} minValue={0.1} maxValue={20} onBlur={submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="text.h" label={t('Výška')} type={'text'} minValue={0.1} maxValue={20} onBlur={submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="text.a" label={t('Rotace')} type={'text'} minValue={-360} maxValue={360} onBlur={submitForm}/>
                        </Grid>
                    </Grid>;
                }}
                </Formik>
            </CardContent>
        </Card>;

    }, [showTextPoly, shape, t]);

    const selectedItemForm = useMemo(() => {
        if (!formItem) {
            return null;
        }
        const NUM_KEYS: (keyof JsonCustomSvgItem)[] = ['w', 'h', 'r', 'a', 'x', 'y', 'originX', 'originY', 's'];

        const validate = (values: JsonCustomSvgItem) => {
            const errors: FormikErrors<JsonCustomSvgItem> = {};

            for (const key of NUM_KEYS) {
                if (values[key] && (!isNumeric(values[key]) && !isNumeric(String(values[key]).replace(',', '.')))) {
                    errors[key] = t('Hodnota není číslo');
                }
            }

            if (formItem.type === 'rect') {
                if (!values.w || values.w <= 0) {
                    errors.w = t('Hodnota je povinná');
                }
                if (!values.h || values.h <= 0) {
                    errors.h = t('Hodnota je povinná');
                }
            }

            if (formItem.type === 'cicle') {
                if (!values.r || values.r < 0) {
                    errors.r = t('Hodnota je povinná');
                }
            }

            return errors;
        }

        const onSubmit = ((values: JsonCustomSvgItem) => {
            const fixed: JsonCustomSvgItem = {...values};
            for (const key of NUM_KEYS) {
                if (fixed[key]) {
                    (fixed as any)[key] = +String(fixed[key]).replace(',', '.');
                } else if (fixed[key] === '') {
                    (fixed as any)[key] = undefined;
                }
            }
            if (fixed.s === 100) {
                fixed.s = undefined;
            }
            actionUpdateItem([formItem], {...formItem, ...fixed});
        });

        const initialValues: JsonCustomSvgItem = {
            ...formItem
        };
        for (const key of NUM_KEYS) {
            const v = initialValues[key];
            (initialValues as any)[key] = v ? safeSvgValue(+v) : v;
        }

        return <Card>
            <CardContent>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={validate}
                    validateOnChange={true}
                    onSubmit={onSubmit}
                >{(formikProps) => {
                    return <Grid container spacing={1} columns={15}>
                        {formItem.type === 'rect' && <Grid item xs={3}>
                            <TextFormField name="w" label={t('Šířka')} type={'text'} onBlur={formikProps.submitForm} minValue={0.1} maxValue={20}/>
                        </Grid>}
                        {formItem.type === 'rect' && <Grid item xs={3}>
                            <TextFormField name="h" label={t('Výška')} type={'text'} onBlur={formikProps.submitForm} minValue={0.1} maxValue={20}/>
                        </Grid>}
                        {formItem.type === 'circle' && <Grid item xs={3}>
                            <TextFormField name="r" label={t('Poloměr')} type={'text'} onBlur={formikProps.submitForm} minValue={0.1} maxValue={20}/>
                        </Grid>}
                        <Grid item xs={3}>
                            <TextFormField name="s" label={t('Zoom')} type={'text'} onBlur={formikProps.submitForm} minValue={1} maxValue={1000} placeholder={'100'}/>
                        </Grid>
                        <Grid item xs={15}>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="originX" label={t('Střed X')} type={'text'} onBlur={formikProps.submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="originY" label={t('Střed Y')} type={'text'} onBlur={formikProps.submitForm}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="x" label={t('Posun X')} type={'text'} onBlur={formikProps.submitForm} minValue={-20} maxValue={20}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="y" label={t('Posun Y')} type={'text'} onBlur={formikProps.submitForm} minValue={-20} maxValue={20}/>
                        </Grid>
                        {formItem.type !== 'circle' && <Grid item xs={3}>
                            <TextFormField name="a" label={t('Rotace')} type={'text'} onBlur={formikProps.submitForm} minValue={-360} maxValue={360}/>
                        </Grid>}
                    </Grid>
                }}
                </Formik>
            </CardContent>
        </Card>;

    }, [formItem, actionUpdateItem, t]);

    const selectors = useMemo(() => {
        return <Grid container rowSpacing={1}>
            {items.map((item) => {
                const isSelected = selectedItems?.find(s => s.item.id === item.id);
                return <Grid key={item.id} item xs={2}>
                    <Chip
                        label={
                            <span>{item.type === 'rect' ? '⬜' : (item.type === 'circle' ? '⚪' : t('Polygon'))} #{item.id}</span>}
                        color={isSelected ? 'info' : 'default'}
                        onClick={(e) => {
                            const target = svgRef.current?.querySelector('#item-' + item.id);
                            if (target) {
                                handleItemMouseDown(item, {...e, target});
                                handleCanvasMouseUp(e);
                            }
                        }}
                    />
                </Grid>
            })}
        </Grid>;

    }, [items, selectedItems, handleItemMouseDown, handleCanvasMouseUp, t]);

    useEffect(() => {
        if (brush) {
            setSelectedItems(undefined);
        }
        if (!brush) {
            cancelAllPendingActions(true);
        }

    }, [brush, cancelAllPendingActions]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        const ctx = inputContext.current;
        return () => {
            if (ctx.mouseActionTimeout) {
                clearTimeout(ctx.mouseActionTimeout);
            }
            window.removeEventListener('keydown', handleKeyDown);
            if (onSetDirtyValues) {
                onSetDirtyValues(ctx.dirtyValues);
            }
        }
    }, [handleKeyDown, onSetDirtyValues]);

    const isBeingUsed = !!shapeStats?.used || !!shapeStats?.dirty;

    return <Grid container sx={{marginTop: '10px'}}>
        <Grid item xs={2} sx={{zIndex: '2'}}>
            <SeatingShapeToolbar shapes={toolbarShapes} onSetBrush={onSetBrush} brush={brush}/>
        </Grid>
        <Grid item xs={10} sx={{zIndex: '2'}}>
            <Grid container columnSpacing={2}>
                <Grid item>
                    <Box sx={{
                        position: "relative",
                        marginBottom: Math.ceil(Math.max(0, items.length - 12) / 6) * 25 + "px",
                        background: "linear-gradient(0deg, white 0, white 49.9%, #eee 49.9%, #eee 50.5%, white 50.5%, white 100%)",
                        width: 'fit-content',
                        height: 'fit-content',
                        svg: {
                            display: 'block',
                            border: "1px solid silver",
                            zoom: SVG_ZOOM,
                            background: "linear-gradient(90deg, transparent 0, transparent 50%, #eee 49.5%, #eee 50.5%, transparent 50.5%, transparent 100%)"
                        }
                    }}>
                        <svg
                            viewBox={'-10 -10 20 20'} /* make 0,0 a center */
                            width={'100px'}
                            height={'100px'}
                            ref={svgRef}
                            id={'shape-svg'}
                            onMouseMove={handleCanvasMouseMove}
                            onMouseUp={handleCanvasMouseUp}
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <defs id={'shape-svg-defs'}>
                                {toolbarShapes?.map((shape) => <SvgShapeDef key={shape.id} shape={shape}/>)}
                            </defs>
                            <g id="shape-group" stroke="#888888" fill="#dddddd" strokeWidth="0.1">
                                {elements}
                            </g>
                            <g id="shape-drag" fill="#cc00ff" strokeWidth="1" ref={svgDragGroupRef}>
                            </g>
                        </svg>
                    </Box>
                </Grid>
                <Grid item sx={{flexGrow: 1, flexBasis: 0}}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {shapeForm}
                        </Grid>
                        <Grid item xs={12}>
                            {selectors}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedItemForm}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container sx={{paddingTop: '10px'}}>
                <Grid item xs={2}>
                    {shape.id > 0 &&
                        <span title={isBeingUsed ? t('Tento nábytek nelze smazat, protože je aktuálně použitý') : undefined}>
                            <Button variant={'text'} color={'error'} disabled={saveState.isSaving || isBeingUsed}
                                onClick={() => handleSaveShape('remove')}>{t('Smazat')}</Button>
                        </span>}
                </Grid>
                <Grid item xs={3} sx={{'& > button + button': {marginLeft: '10px'}}}>
                    <Button variant={'contained'} disabled={!undoStack.length}
                        title={t('O akci zpět ({{length}})', {length: undoStack.length})}
                        onClick={() => rewindLastAction(false)}><UndoOutlined/></Button>
                    <Button variant={'contained'} disabled={!redoStack.length}
                        title={t('O akci dopředu ({{length}})', {length: redoStack.length})}
                        onClick={() => rewindLastAction(true)}><RedoOutlined/></Button>
                </Grid>
                <Grid item xs={2} sx={{textAlign: 'center'}}>
                    {/*<abbr title={shape.svg}>orig</abbr>*/}
                    {/*<abbr title={dirtyValues.items}>now</abbr>*/}
                </Grid>
                <Grid item xs={5} sx={{textAlign: 'right', 'button + button': {marginLeft: '10px'}}}>
                    {!!items?.length && shape.id > 0 &&
                        <Button variant={'contained'} color={'inherit'} disabled={saveState.isSaving} onClick={() => handleSaveShape('clone')}>{t('Uložit jako novou kopii')}</Button>}
                    {!!items?.length && shape.id > 0 &&
                        <Button variant={'contained'} disabled={saveState.isSaving} onClick={() => handleSaveShape('save')}>{t('Uložit změny')}</Button>}
                    {!!items?.length && shape.id < 0 &&
                        <Button variant={'contained'} disabled={saveState.isSaving} onClick={() => handleSaveShape('save')}>{t('Uložit nový nábytek')}</Button>}
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}
