/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonInviteData,
    JsonInviteDataFromJSON,
    JsonInviteDataFromJSONTyped,
    JsonInviteDataToJSON,
} from './JsonInviteData';
import {
    JsonInviteReplyExtraInfo,
    JsonInviteReplyExtraInfoFromJSON,
    JsonInviteReplyExtraInfoFromJSONTyped,
    JsonInviteReplyExtraInfoToJSON,
} from './JsonInviteReplyExtraInfo';
import {
    JsonReplyData,
    JsonReplyDataFromJSON,
    JsonReplyDataFromJSONTyped,
    JsonReplyDataToJSON,
} from './JsonReplyData';
import {
    JsonReplyProcessData,
    JsonReplyProcessDataFromJSON,
    JsonReplyProcessDataFromJSONTyped,
    JsonReplyProcessDataToJSON,
} from './JsonReplyProcessData';

/**
 * 
 * @export
 * @interface JsonInviteInfo
 */
export interface JsonInviteInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonInviteInfo
     */
    allowedActions?: Array<JsonInviteInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    groupPosition?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    groupTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteInfo
     */
    infoIsValid?: boolean;
    /**
     * 
     * @type {JsonInviteData}
     * @memberof JsonInviteInfo
     */
    inviteData?: JsonInviteData;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    inviteId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    inviteKind?: JsonInviteInfoInviteKindEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    inviteType?: JsonInviteInfoInviteTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteInfo
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    newFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    newLastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    newPhotoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    newUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    orgCompanyName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    partyType?: JsonInviteInfoPartyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    photoId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    processAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    processBy?: number;
    /**
     * 
     * @type {JsonReplyProcessData}
     * @memberof JsonInviteInfo
     */
    processData?: JsonReplyProcessData;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    processNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    replyAt?: string;
    /**
     * 
     * @type {JsonReplyData}
     * @memberof JsonInviteInfo
     */
    replyData?: JsonReplyData;
    /**
     * 
     * @type {Array<JsonInviteReplyExtraInfo>}
     * @memberof JsonInviteInfo
     */
    replyExtras?: Array<JsonInviteReplyExtraInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    replyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    replyNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    replyStatus?: JsonInviteInfoReplyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    replyType?: JsonInviteInfoReplyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    replyUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    sex?: JsonInviteInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    status?: JsonInviteInfoStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonInviteInfo
     */
    tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoInviteKindEnum {
    Invite = 'INVITE',
    Update = 'UPDATE',
    Travel = 'TRAVEL',
    Comp = 'COMP',
    Safety = 'SAFETY'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoInviteTypeEnum {
    Guest = 'GUEST',
    Organizer = 'ORGANIZER',
    Supplier = 'SUPPLIER',
    UpdateOrg = 'UPDATE_ORG',
    TravelOrg = 'TRAVEL_ORG',
    CompOrg = 'COMP_ORG',
    SafetyOrg = 'SAFETY_ORG'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoPartyTypeEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoReplyStatusEnum {
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Sent = 'SENT',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoReplyTypeEnum {
    Accept = 'ACCEPT',
    Reject = 'REJECT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoSexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInfoStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}

export function JsonInviteInfoFromJSON(json: any): JsonInviteInfo {
    return JsonInviteInfoFromJSONTyped(json, false);
}

export function JsonInviteInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInviteInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupPosition': !exists(json, 'groupPosition') ? undefined : json['groupPosition'],
        'groupTitle': !exists(json, 'groupTitle') ? undefined : json['groupTitle'],
        'infoIsValid': !exists(json, 'infoIsValid') ? undefined : json['infoIsValid'],
        'inviteData': !exists(json, 'inviteData') ? undefined : JsonInviteDataFromJSON(json['inviteData']),
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'inviteKind': !exists(json, 'inviteKind') ? undefined : json['inviteKind'],
        'inviteType': !exists(json, 'inviteType') ? undefined : json['inviteType'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'newFirstName': !exists(json, 'newFirstName') ? undefined : json['newFirstName'],
        'newLastName': !exists(json, 'newLastName') ? undefined : json['newLastName'],
        'newPhotoId': !exists(json, 'newPhotoId') ? undefined : json['newPhotoId'],
        'newUserId': !exists(json, 'newUserId') ? undefined : json['newUserId'],
        'orgCompanyName': !exists(json, 'orgCompanyName') ? undefined : json['orgCompanyName'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'partyType': !exists(json, 'partyType') ? undefined : json['partyType'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'processAt': !exists(json, 'processAt') ? undefined : json['processAt'],
        'processBy': !exists(json, 'processBy') ? undefined : json['processBy'],
        'processData': !exists(json, 'processData') ? undefined : JsonReplyProcessDataFromJSON(json['processData']),
        'processNote': !exists(json, 'processNote') ? undefined : json['processNote'],
        'replyAt': !exists(json, 'replyAt') ? undefined : json['replyAt'],
        'replyData': !exists(json, 'replyData') ? undefined : JsonReplyDataFromJSON(json['replyData']),
        'replyExtras': !exists(json, 'replyExtras') ? undefined : ((json['replyExtras'] as Array<any>).map(JsonInviteReplyExtraInfoFromJSON)),
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'replyNote': !exists(json, 'replyNote') ? undefined : json['replyNote'],
        'replyStatus': !exists(json, 'replyStatus') ? undefined : json['replyStatus'],
        'replyType': !exists(json, 'replyType') ? undefined : json['replyType'],
        'replyUntil': !exists(json, 'replyUntil') ? undefined : json['replyUntil'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonInviteInfoToJSON(value?: JsonInviteInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'birthDate': value.birthDate,
        'companyId': value.companyId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'eventId': value.eventId,
        'firstName': value.firstName,
        'fullName': value.fullName,
        'groupId': value.groupId,
        'groupPosition': value.groupPosition,
        'groupTitle': value.groupTitle,
        'infoIsValid': value.infoIsValid,
        'inviteData': JsonInviteDataToJSON(value.inviteData),
        'inviteId': value.inviteId,
        'inviteKind': value.inviteKind,
        'inviteType': value.inviteType,
        'isActive': value.isActive,
        'lastName': value.lastName,
        'newFirstName': value.newFirstName,
        'newLastName': value.newLastName,
        'newPhotoId': value.newPhotoId,
        'newUserId': value.newUserId,
        'orgCompanyName': value.orgCompanyName,
        'partyId': value.partyId,
        'partyType': value.partyType,
        'phone': value.phone,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'processAt': value.processAt,
        'processBy': value.processBy,
        'processData': JsonReplyProcessDataToJSON(value.processData),
        'processNote': value.processNote,
        'replyAt': value.replyAt,
        'replyData': JsonReplyDataToJSON(value.replyData),
        'replyExtras': value.replyExtras === undefined ? undefined : ((value.replyExtras as Array<any>).map(JsonInviteReplyExtraInfoToJSON)),
        'replyId': value.replyId,
        'replyNote': value.replyNote,
        'replyStatus': value.replyStatus,
        'replyType': value.replyType,
        'replyUntil': value.replyUntil,
        'sex': value.sex,
        'status': value.status,
        'tags': value.tags,
        'token': value.token,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

