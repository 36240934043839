import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {FormProps, getOption} from "../model/form";
import {JsonParty, JsonPartyInfo} from "../generated-api";
import {Alert, Box, Button, Grid} from "@mui/material";
import PartyInfoBox from "../components/party/PartyInfoBox";
import {eventPartyStatusOptions} from "../model/party";
import CodebookValue from "../components/CodebookValue";
import InfoBox from "../components/layout/InfoBox";
import {datetimeToGui} from "../helpers/date";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, MergeOutlined} from "@mui/icons-material";
import {ModalProps, useModal} from "../services/modal";
import {useAppDispatch} from "../store";
import {mergeParties} from "../store/parties";
import {getApiResult} from "../helpers/api";
import {addMessage} from "../store/localApp";
import PartyDetailBox from "../components/party/PartyDetailBox";

export interface PartyMergeType {
    party: JsonPartyInfo,
    found: JsonPartyInfo,
    eventId: number
}

const PartyMergeModal = (props: FormProps<PartyMergeType>) => {

    const {item, onCancel, onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [eventIds, setEventIds] = useState([
            ...(item.party.eventDetails?.map(ed => ed.eventId) || []),
            ...(item.found.eventDetails?.map(ed => ed.eventId) || [])
        ].filter((id, i, arr) => arr.indexOf(id) === i).sort().reverse()
    );

    const [targetPartyId, setTargetPartyId] = useState<number>();
    const [mergedParty, setMergedParty] = useState<JsonPartyInfo>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleMerge = useCallback(async () => {
        if (!targetPartyId) {
            return;
        }
        const result = await modal.confirm({
            title: t('Potvrzení sloučení'),
            message: <div>
                <Alert severity={'warning'}>
                    <p>{t('Skutečně sloučit osoby do jedné? Tato akce je nevratná.')}</p>
                    <p>{t('Po sloučení bude stránka (web) přenačtena, aby se zobrazila aktuální data.')}</p>
                </Alert>
            </div>,
            cancelText: 'Zpět',
            confirmColor: 'success',
            confirmText: t('Sloučit osoby'),
            confirmIcon: <MergeOutlined/>,
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }

        try {
            setIsSubmitting(true);
            const res = await dispatch(mergeParties({
                targetPartyId,
                deletePartyId: (targetPartyId === item.party.partyId ? item.found.partyId : item.party.partyId)!,
            }));

            const mergedParty = getApiResult(res);
            if (mergedParty) {
                dispatch(addMessage({
                    code: 'OK',
                    action: 'party/merge',
                    severity: 'success',
                    title: t('Osoba úspěšně sloučena'),
                }));
                if (onSave) {
                    onSave(item);
                }
            }
        } finally {
            setIsSubmitting(false);
        }

    }, [targetPartyId, item, onSave, dispatch]);

    useEffect(() => {
        if (!targetPartyId) {
            setMergedParty(undefined);
            return;
        }
        dispatch(mergeParties({
            targetPartyId,
            deletePartyId: (targetPartyId === item.party.partyId ? item.found.partyId : item.party.partyId)!,
            testOnly: true
        })).then((res) => {
            setMergedParty(getApiResult(res));
        })

    }, [targetPartyId]);

    const renderParty = useCallback((title: string, party: JsonPartyInfo, readonly?: boolean) => {
        const isSelected = targetPartyId === party.partyId;
        return <>
            <div>
                <PartyInfoBox title={title} party={party} eventId={item.eventId} withLink badges={[{
                    title: <>ID {party.partyId}, {datetimeToGui(party.createdAt)}, <CodebookValue value={party.createdBy} name={'user'}/></>
                }]}/>
            </div>
            <div>
                <PartyDetailBox party={party} withAddress/>
            </div>
            {eventIds?.map((eventId) => {
                const ed = party.eventDetails?.find(ed => ed.eventId === eventId);
                return <Box key={eventId} sx={!ed ? {
                    '.MuiPaper-root': {
                        backgroundColor: '#eee',
                        opacity: '.7'
                    }
                } : undefined}>
                    <InfoBox title={<CodebookValue value={eventId} name={'event'}/>}>
                        <Grid container spacing={2}>
                            {!!ed && <Grid item xs={12}>
                                {getOption(ed.status, eventPartyStatusOptions)?.label} (<CodebookValue value={ed.groupId} name={'group'}/>) {ed.formatCode} {ed.reason}
                            </Grid>}
                            {!ed && <Grid item xs={12}>
                                <em>{t('Není v události')}</em>
                            </Grid>}
                        </Grid>
                    </InfoBox>
                </Box>;
            })}
            {!readonly && <div>
                <Button variant={'contained'} size={'small'}
                    color={isSelected ? 'info' : 'inherit'}
                    onClick={() => {
                        setTargetPartyId(isSelected ? undefined : party.partyId);
                    }}>
                    {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
                    <span>{t('Tuto osobu zachovat, druhou smazat')}</span>
                </Button>
            </div>}
        </>
    }, [targetPartyId, eventIds, item.eventId]);

    return <FormModal title={t('Vyřešení konfliktu')} onCancel={onCancel} dialogClassName={'rsvp-review'} maxWidth={'lg'}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, .5fr)',
                    gridAutoFlow: 'column',
                    gridTemplateRows: 'repeat(' + (3 + eventIds.length) + ', auto)',
                    gridGap: '12px'
                }}>
                    {renderParty('Osoba na pozvánce', item.party)}
                    {renderParty('Kolizní osoba', item.found)}
                </Box>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridAutoFlow: 'column',
                    gridTemplateRows: 'repeat(' + (3 + eventIds.length) + ', auto)',
                    gridGap: '12px'
                }}>
                    {mergedParty ? renderParty('Sloučená osoba', mergedParty, true) : null}
                </Box>
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
                <Alert severity={'warning'} className={'event-action-errors'}>
                    {t('Zachováváme vždy "úplnější" osobu, což je typicky ta s vyplněným SIWI ID (nebo založená dříve).')}<br/>
                    {t('Zachovaná osoba převezme údaje mazané osoby, pokud už je sama nemá (poznámku, šítky, email atd.)')}<br/>
                    {t('Tedy pokud obě osoby mají nějaký údaj vyplněný (každá jinak), bude dále platit ten na zachované osobě.')}
                </Alert>
            </Grid>
            <Grid item>
                <Button variant="text" onClick={onCancel}>{t('Storno')}</Button>
            </Grid>
            <Grid item xs={3}>
                <Button title={undefined} variant={'contained'} fullWidth
                    color={'success'}
                    disabled={!targetPartyId || isSubmitting}
                    onClick={handleMerge}>
                    <MergeOutlined/> <span>{t('Sloučit')}</span>
                </Button>
            </Grid>
        </Grid>
    </FormModal>;
}

export default PartyMergeModal;
