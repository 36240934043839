/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventParty,
    ApiResultOfJsonEventPartyFromJSON,
    ApiResultOfJsonEventPartyToJSON,
    ApiResultOfJsonEventPartyInfo,
    ApiResultOfJsonEventPartyInfoFromJSON,
    ApiResultOfJsonEventPartyInfoToJSON,
    ApiResultOfJsonImportResultOfstringAndJsonEventPartyImport,
    ApiResultOfJsonImportResultOfstringAndJsonEventPartyImportFromJSON,
    ApiResultOfJsonImportResultOfstringAndJsonEventPartyImportToJSON,
    ApiResultOfListOfJsonEventParty,
    ApiResultOfListOfJsonEventPartyFromJSON,
    ApiResultOfListOfJsonEventPartyToJSON,
    ApiResultOfListOfJsonEventPartyDay,
    ApiResultOfListOfJsonEventPartyDayFromJSON,
    ApiResultOfListOfJsonEventPartyDayToJSON,
    ApiResultOfListOfJsonEventPartyInfo,
    ApiResultOfListOfJsonEventPartyInfoFromJSON,
    ApiResultOfListOfJsonEventPartyInfoToJSON,
    ApiResultOfMapOfstringAndApiReport,
    ApiResultOfMapOfstringAndApiReportFromJSON,
    ApiResultOfMapOfstringAndApiReportToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventParty,
    JsonEventPartyFromJSON,
    JsonEventPartyToJSON,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestFromJSON,
    JsonEventPartyMassActionRequestToJSON,
} from '../models';

export interface GenerateEventPartyPaymentUsingPOSTRequest {
    request: JsonEventPartyMassActionRequest;
}

export interface GetEventPartyCountUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    groupIds?: Array<number>;
    partyTypes?: Array<GetEventPartyCountUsingGETPartyTypesEnum>;
    sexType?: GetEventPartyCountUsingGETSexTypeEnum;
    isSiwi?: boolean;
    isCeb?: boolean;
    isPhoto?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    areaSearch?: string;
    areas?: Array<string>;
    notAreas?: Array<string>;
    notEventDays?: Array<number>;
    foodIds?: Array<number>;
    acmIds?: Array<number>;
    parkIds?: Array<number>;
    contingents?: Array<string>;
    accredStatuses?: Array<GetEventPartyCountUsingGETAccredStatusesEnum>;
    siwiAccredStatuses?: Array<GetEventPartyCountUsingGETSiwiAccredStatusesEnum>;
    inviteStatuses?: Array<GetEventPartyCountUsingGETInviteStatusesEnum>;
    updateInviteStatuses?: Array<GetEventPartyCountUsingGETUpdateInviteStatusesEnum>;
    safetyStatuses?: Array<GetEventPartyCountUsingGETSafetyStatusesEnum>;
    isMissingOrgInfo?: boolean;
    isGuest?: boolean;
    isContract?: boolean;
    isRecArticle?: boolean;
    isTravel?: boolean;
    isComp?: boolean;
    isPaid?: boolean;
    isPrefAcm?: boolean;
    statuses?: Array<GetEventPartyCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetEventPartyDaysHistoryUsingGETRequest {
    eventPartyId: number;
}

export interface GetEventPartyHistoryUsingGETRequest {
    eventPartyId: number;
}

export interface GetEventPartyInfoUsingGETRequest {
    eventPartyId: number;
}

export interface GetEventPartyListUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    groupIds?: Array<number>;
    partyTypes?: Array<GetEventPartyListUsingGETPartyTypesEnum>;
    sexType?: GetEventPartyListUsingGETSexTypeEnum;
    isSiwi?: boolean;
    isCeb?: boolean;
    isPhoto?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    areaSearch?: string;
    areas?: Array<string>;
    notAreas?: Array<string>;
    notEventDays?: Array<number>;
    foodIds?: Array<number>;
    acmIds?: Array<number>;
    parkIds?: Array<number>;
    contingents?: Array<string>;
    accredStatuses?: Array<GetEventPartyListUsingGETAccredStatusesEnum>;
    siwiAccredStatuses?: Array<GetEventPartyListUsingGETSiwiAccredStatusesEnum>;
    inviteStatuses?: Array<GetEventPartyListUsingGETInviteStatusesEnum>;
    updateInviteStatuses?: Array<GetEventPartyListUsingGETUpdateInviteStatusesEnum>;
    safetyStatuses?: Array<GetEventPartyListUsingGETSafetyStatusesEnum>;
    isMissingOrgInfo?: boolean;
    isGuest?: boolean;
    isContract?: boolean;
    isRecArticle?: boolean;
    isTravel?: boolean;
    isComp?: boolean;
    isPaid?: boolean;
    isPrefAcm?: boolean;
    statuses?: Array<GetEventPartyListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventPartyUsingGETRequest {
    eventPartyId: number;
}

export interface MassEventPartyActionUsingPOSTRequest {
    request: JsonEventPartyMassActionRequest;
    testOnly?: boolean;
}

export interface PrepareEventPartyImportUsingPOSTRequest {
    file: Blob;
    eventId?: number;
    testOnly?: boolean;
}

export interface PrintEventPartyContractUsingPOSTRequest {
    request: JsonEventPartyMassActionRequest;
}

export interface PushEventPartiesToSiwiUsingPOSTRequest {
    eventId: number;
    partyIds: Array<number>;
    testOnly?: boolean;
}

export interface SaveEventPartyUsingPOSTRequest {
    json: JsonEventParty;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventPartyControllerApi extends runtime.BaseAPI {

    /**
     * generateEventPartyPayment
     */
    async generateEventPartyPaymentUsingPOSTRaw(requestParameters: GenerateEventPartyPaymentUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling generateEventPartyPaymentUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party/generate-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyMassActionRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * generateEventPartyPayment
     */
    async generateEventPartyPaymentUsingPOST(requestParameters: GenerateEventPartyPaymentUsingPOSTRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.generateEventPartyPaymentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyCount
     */
    async getEventPartyCountUsingGETRaw(requestParameters: GetEventPartyCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.partyTypes) {
            queryParameters['partyTypes'] = requestParameters.partyTypes;
        }

        if (requestParameters.sexType !== undefined) {
            queryParameters['sexType'] = requestParameters.sexType;
        }

        if (requestParameters.isSiwi !== undefined) {
            queryParameters['isSiwi'] = requestParameters.isSiwi;
        }

        if (requestParameters.isCeb !== undefined) {
            queryParameters['isCeb'] = requestParameters.isCeb;
        }

        if (requestParameters.isPhoto !== undefined) {
            queryParameters['isPhoto'] = requestParameters.isPhoto;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.areaSearch !== undefined) {
            queryParameters['areaSearch'] = requestParameters.areaSearch;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        if (requestParameters.notAreas) {
            queryParameters['notAreas'] = requestParameters.notAreas;
        }

        if (requestParameters.notEventDays) {
            queryParameters['notEventDays'] = requestParameters.notEventDays;
        }

        if (requestParameters.foodIds) {
            queryParameters['foodIds'] = requestParameters.foodIds;
        }

        if (requestParameters.acmIds) {
            queryParameters['acmIds'] = requestParameters.acmIds;
        }

        if (requestParameters.parkIds) {
            queryParameters['parkIds'] = requestParameters.parkIds;
        }

        if (requestParameters.contingents) {
            queryParameters['contingents'] = requestParameters.contingents;
        }

        if (requestParameters.accredStatuses) {
            queryParameters['accredStatuses'] = requestParameters.accredStatuses;
        }

        if (requestParameters.siwiAccredStatuses) {
            queryParameters['siwiAccredStatuses'] = requestParameters.siwiAccredStatuses;
        }

        if (requestParameters.inviteStatuses) {
            queryParameters['inviteStatuses'] = requestParameters.inviteStatuses;
        }

        if (requestParameters.updateInviteStatuses) {
            queryParameters['updateInviteStatuses'] = requestParameters.updateInviteStatuses;
        }

        if (requestParameters.safetyStatuses) {
            queryParameters['safetyStatuses'] = requestParameters.safetyStatuses;
        }

        if (requestParameters.isMissingOrgInfo !== undefined) {
            queryParameters['isMissingOrgInfo'] = requestParameters.isMissingOrgInfo;
        }

        if (requestParameters.isGuest !== undefined) {
            queryParameters['isGuest'] = requestParameters.isGuest;
        }

        if (requestParameters.isContract !== undefined) {
            queryParameters['isContract'] = requestParameters.isContract;
        }

        if (requestParameters.isRecArticle !== undefined) {
            queryParameters['isRecArticle'] = requestParameters.isRecArticle;
        }

        if (requestParameters.isTravel !== undefined) {
            queryParameters['isTravel'] = requestParameters.isTravel;
        }

        if (requestParameters.isComp !== undefined) {
            queryParameters['isComp'] = requestParameters.isComp;
        }

        if (requestParameters.isPaid !== undefined) {
            queryParameters['isPaid'] = requestParameters.isPaid;
        }

        if (requestParameters.isPrefAcm !== undefined) {
            queryParameters['isPrefAcm'] = requestParameters.isPrefAcm;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventPartyCount
     */
    async getEventPartyCountUsingGET(requestParameters: GetEventPartyCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventPartyCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyDaysHistory
     */
    async getEventPartyDaysHistoryUsingGETRaw(requestParameters: GetEventPartyDaysHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventPartyDay>> {
        if (requestParameters.eventPartyId === null || requestParameters.eventPartyId === undefined) {
            throw new runtime.RequiredError('eventPartyId','Required parameter requestParameters.eventPartyId was null or undefined when calling getEventPartyDaysHistoryUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/{eventPartyId}/history/event-party-day`.replace(`{${"eventPartyId"}}`, encodeURIComponent(String(requestParameters.eventPartyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyDayFromJSON(jsonValue));
    }

    /**
     * getEventPartyDaysHistory
     */
    async getEventPartyDaysHistoryUsingGET(requestParameters: GetEventPartyDaysHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventPartyDay> {
        const response = await this.getEventPartyDaysHistoryUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyHistory
     */
    async getEventPartyHistoryUsingGETRaw(requestParameters: GetEventPartyHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventParty>> {
        if (requestParameters.eventPartyId === null || requestParameters.eventPartyId === undefined) {
            throw new runtime.RequiredError('eventPartyId','Required parameter requestParameters.eventPartyId was null or undefined when calling getEventPartyHistoryUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/{eventPartyId}/history`.replace(`{${"eventPartyId"}}`, encodeURIComponent(String(requestParameters.eventPartyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyFromJSON(jsonValue));
    }

    /**
     * getEventPartyHistory
     */
    async getEventPartyHistoryUsingGET(requestParameters: GetEventPartyHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventParty> {
        const response = await this.getEventPartyHistoryUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyInfo
     */
    async getEventPartyInfoUsingGETRaw(requestParameters: GetEventPartyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyInfo>> {
        if (requestParameters.eventPartyId === null || requestParameters.eventPartyId === undefined) {
            throw new runtime.RequiredError('eventPartyId','Required parameter requestParameters.eventPartyId was null or undefined when calling getEventPartyInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/info/{eventPartyId}`.replace(`{${"eventPartyId"}}`, encodeURIComponent(String(requestParameters.eventPartyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyInfo
     */
    async getEventPartyInfoUsingGET(requestParameters: GetEventPartyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyInfo> {
        const response = await this.getEventPartyInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyList
     */
    async getEventPartyListUsingGETRaw(requestParameters: GetEventPartyListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventPartyInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.partyTypes) {
            queryParameters['partyTypes'] = requestParameters.partyTypes;
        }

        if (requestParameters.sexType !== undefined) {
            queryParameters['sexType'] = requestParameters.sexType;
        }

        if (requestParameters.isSiwi !== undefined) {
            queryParameters['isSiwi'] = requestParameters.isSiwi;
        }

        if (requestParameters.isCeb !== undefined) {
            queryParameters['isCeb'] = requestParameters.isCeb;
        }

        if (requestParameters.isPhoto !== undefined) {
            queryParameters['isPhoto'] = requestParameters.isPhoto;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.areaSearch !== undefined) {
            queryParameters['areaSearch'] = requestParameters.areaSearch;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        if (requestParameters.notAreas) {
            queryParameters['notAreas'] = requestParameters.notAreas;
        }

        if (requestParameters.notEventDays) {
            queryParameters['notEventDays'] = requestParameters.notEventDays;
        }

        if (requestParameters.foodIds) {
            queryParameters['foodIds'] = requestParameters.foodIds;
        }

        if (requestParameters.acmIds) {
            queryParameters['acmIds'] = requestParameters.acmIds;
        }

        if (requestParameters.parkIds) {
            queryParameters['parkIds'] = requestParameters.parkIds;
        }

        if (requestParameters.contingents) {
            queryParameters['contingents'] = requestParameters.contingents;
        }

        if (requestParameters.accredStatuses) {
            queryParameters['accredStatuses'] = requestParameters.accredStatuses;
        }

        if (requestParameters.siwiAccredStatuses) {
            queryParameters['siwiAccredStatuses'] = requestParameters.siwiAccredStatuses;
        }

        if (requestParameters.inviteStatuses) {
            queryParameters['inviteStatuses'] = requestParameters.inviteStatuses;
        }

        if (requestParameters.updateInviteStatuses) {
            queryParameters['updateInviteStatuses'] = requestParameters.updateInviteStatuses;
        }

        if (requestParameters.safetyStatuses) {
            queryParameters['safetyStatuses'] = requestParameters.safetyStatuses;
        }

        if (requestParameters.isMissingOrgInfo !== undefined) {
            queryParameters['isMissingOrgInfo'] = requestParameters.isMissingOrgInfo;
        }

        if (requestParameters.isGuest !== undefined) {
            queryParameters['isGuest'] = requestParameters.isGuest;
        }

        if (requestParameters.isContract !== undefined) {
            queryParameters['isContract'] = requestParameters.isContract;
        }

        if (requestParameters.isRecArticle !== undefined) {
            queryParameters['isRecArticle'] = requestParameters.isRecArticle;
        }

        if (requestParameters.isTravel !== undefined) {
            queryParameters['isTravel'] = requestParameters.isTravel;
        }

        if (requestParameters.isComp !== undefined) {
            queryParameters['isComp'] = requestParameters.isComp;
        }

        if (requestParameters.isPaid !== undefined) {
            queryParameters['isPaid'] = requestParameters.isPaid;
        }

        if (requestParameters.isPrefAcm !== undefined) {
            queryParameters['isPrefAcm'] = requestParameters.isPrefAcm;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyList
     */
    async getEventPartyListUsingGET(requestParameters: GetEventPartyListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventPartyInfo> {
        const response = await this.getEventPartyListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventParty
     */
    async getEventPartyUsingGETRaw(requestParameters: GetEventPartyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventParty>> {
        if (requestParameters.eventPartyId === null || requestParameters.eventPartyId === undefined) {
            throw new runtime.RequiredError('eventPartyId','Required parameter requestParameters.eventPartyId was null or undefined when calling getEventPartyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/{eventPartyId}`.replace(`{${"eventPartyId"}}`, encodeURIComponent(String(requestParameters.eventPartyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyFromJSON(jsonValue));
    }

    /**
     * getEventParty
     */
    async getEventPartyUsingGET(requestParameters: GetEventPartyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventParty> {
        const response = await this.getEventPartyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * massEventPartyAction
     */
    async massEventPartyActionUsingPOSTRaw(requestParameters: MassEventPartyActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventParty>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling massEventPartyActionUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party/mass`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyMassActionRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyFromJSON(jsonValue));
    }

    /**
     * massEventPartyAction
     */
    async massEventPartyActionUsingPOST(requestParameters: MassEventPartyActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventParty> {
        const response = await this.massEventPartyActionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * prepareEventPartyImport
     */
    async prepareEventPartyImportUsingPOSTRaw(requestParameters: PrepareEventPartyImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonImportResultOfstringAndJsonEventPartyImport>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling prepareEventPartyImportUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.eventId !== undefined) {
            formParams.append('eventId', requestParameters.eventId as any);
        }

        if (requestParameters.testOnly !== undefined) {
            formParams.append('testOnly', requestParameters.testOnly as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/rest-service/event-party/prepare-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonImportResultOfstringAndJsonEventPartyImportFromJSON(jsonValue));
    }

    /**
     * prepareEventPartyImport
     */
    async prepareEventPartyImportUsingPOST(requestParameters: PrepareEventPartyImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonImportResultOfstringAndJsonEventPartyImport> {
        const response = await this.prepareEventPartyImportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * printEventPartyContract
     */
    async printEventPartyContractUsingPOSTRaw(requestParameters: PrintEventPartyContractUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling printEventPartyContractUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party/print-contract`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyMassActionRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * printEventPartyContract
     */
    async printEventPartyContractUsingPOST(requestParameters: PrintEventPartyContractUsingPOSTRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.printEventPartyContractUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pushEventPartiesToSiwi
     */
    async pushEventPartiesToSiwiUsingPOSTRaw(requestParameters: PushEventPartiesToSiwiUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfMapOfstringAndApiReport>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling pushEventPartiesToSiwiUsingPOST.');
        }

        if (requestParameters.partyIds === null || requestParameters.partyIds === undefined) {
            throw new runtime.RequiredError('partyIds','Required parameter requestParameters.partyIds was null or undefined when calling pushEventPartiesToSiwiUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party/siwi/push`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfMapOfstringAndApiReportFromJSON(jsonValue));
    }

    /**
     * pushEventPartiesToSiwi
     */
    async pushEventPartiesToSiwiUsingPOST(requestParameters: PushEventPartiesToSiwiUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfMapOfstringAndApiReport> {
        const response = await this.pushEventPartiesToSiwiUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventParty
     */
    async saveEventPartyUsingPOSTRaw(requestParameters: SaveEventPartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventParty>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventPartyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyFromJSON(jsonValue));
    }

    /**
     * saveEventParty
     */
    async saveEventPartyUsingPOST(requestParameters: SaveEventPartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventParty> {
        const response = await this.saveEventPartyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETPartyTypesEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETSexTypeEnum {
    M = 'M',
    F = 'F'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETAccredStatusesEnum {
    SiwiManaged = 'SIWI_MANAGED',
    EmsManaged = 'EMS_MANAGED',
    EmsApproved = 'EMS_APPROVED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETSiwiAccredStatusesEnum {
    None = 'NONE',
    EmsDraft = 'EMS_DRAFT',
    EmsPending = 'EMS_PENDING',
    SiwiAny = 'SIWI_ANY',
    Undefined = 'UNDEFINED',
    Approved = 'APPROVED',
    Printed = 'PRINTED',
    NoContingent = 'NO_CONTINGENT',
    Entered = 'ENTERED',
    Printqueue = 'PRINTQUEUE',
    Batchprint = 'BATCHPRINT',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETInviteStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETUpdateInviteStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETSafetyStatusesEnum {
    None = 'NONE',
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Pending = 'PENDING'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETPartyTypesEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETSexTypeEnum {
    M = 'M',
    F = 'F'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETAccredStatusesEnum {
    SiwiManaged = 'SIWI_MANAGED',
    EmsManaged = 'EMS_MANAGED',
    EmsApproved = 'EMS_APPROVED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETSiwiAccredStatusesEnum {
    None = 'NONE',
    EmsDraft = 'EMS_DRAFT',
    EmsPending = 'EMS_PENDING',
    SiwiAny = 'SIWI_ANY',
    Undefined = 'UNDEFINED',
    Approved = 'APPROVED',
    Printed = 'PRINTED',
    NoContingent = 'NO_CONTINGENT',
    Entered = 'ENTERED',
    Printqueue = 'PRINTQUEUE',
    Batchprint = 'BATCHPRINT',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETInviteStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETUpdateInviteStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETSafetyStatusesEnum {
    None = 'NONE',
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventPartyListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Pending = 'PENDING'
}
