import {JsonFloorItem, JsonFloorShape, JsonFloorShapeShapeTypeEnum} from "../../generated-api";
import {BrushType, SetBrushType, SvgShapeDef, SvgShapeItem} from "./svgUtils";
import * as React from "react";
import {MouseEvent, useCallback, useMemo} from "react";
import {Box, Card, CardContent} from "@mui/material";
import {AddOutlined, EditOutlined} from "@mui/icons-material";
import {ShapeStatsMapType} from "./SeatingPlan";
import {useAppTranslation} from "../../services/i18n";
import {FAKE_VALUE_EMPTY} from "../../model/form";

export const SeatingPlanToolbar = ({shapeStats, shapes, onSetBrush, brush, onEditShape}: {
    shapes?: JsonFloorShape[],
    shapeStats?: ShapeStatsMapType,
    onSetBrush: SetBrushType,
    onEditShape: (shapeCode: string) => void,
    brush: BrushType | undefined
}) => {
    const t = useAppTranslation();

    const handleClick = useCallback((e: MouseEvent, shape: JsonFloorShape) => {
        onSetBrush(brush?.shape?.id === shape.id
            ? undefined
            : {
                shape,
                svgElement: (e.target as Node).parentNode as SVGGraphicsElement // <g>
            }
        );

    }, [brush, onSetBrush]);

    const tools = useMemo(() => {
        return shapes
            ?.sort((a, b) => a.capacity === b.capacity ? 0 : ((a.capacity || 0) > (b.capacity || 0) ? 1 : -1))
            .sort((a, b) => a.shapeType === JsonFloorShapeShapeTypeEnum.Table ? (b.shapeType === JsonFloorShapeShapeTypeEnum.Table ? 0 : -1) : 1)
            .map((shape) => {
            const item: JsonFloorItem = {id: -shape.id, shapeCode: shape.shapeCode, x: 10, y: 10};

            let stats = <span>0</span>;
            if (shapeStats?.[shape.shapeCode]) {
                if (shapeStats[shape.shapeCode].used === shapeStats[shape.shapeCode].dirty) {
                    stats = <span title={t('Použito tento den')}>{shapeStats[shape.shapeCode].used}</span>;
                } else {
                    stats = <>
                        <span title={t('Použito tento den - neuloženo')}>{shapeStats[shape.shapeCode].dirty}</span>
                        &nbsp;(<span title={t('Použito tento den - původně')}>{shapeStats[shape.shapeCode].used}</span>)
                    </>
                }
            }

            return <svg key={shape.id} viewBox="0 0 20 20" width="100" height="100"
                id={"type-" + shape.id + "-svg-display"}
            >
                <defs id={'floor-svg-defs-' + shape.id}>
                    <SvgShapeDef shape={shape}/>
                </defs>
                <g id={"floor-furnitures-" + shape.id} className="floor-furnitures" stroke="#888888" fill={brush?.shape?.id === shape.id ? "#ffcc00" : "#dddddd"} strokeWidth="0.25">
                    <SvgShapeItem item={item} shape={shape} onMouseDown={(e) => handleClick(e, shape)}/>
                    <foreignObject transform={'translate(14 16.5) scale(0.5 0.5)'} width={10} height={10} style={{
                        fontSize: '4px',
                        textAlign: 'right',
                        opacity: .5
                    }}><span title={t('Kapacita')}>{shape.capacity}</span></foreignObject>
                    <foreignObject transform={'translate(1 16.5) scale(0.5 0.5)'} width={20} height={10} style={{
                        fontSize: '4px',
                        textAlign: 'left',
                        opacity: .5
                    }}>{stats}</foreignObject>
                </g>
            </svg>
        })
    }, [shapes, shapeStats, brush?.shape.id, handleClick, t]);

    const actionTool = useMemo(() => {
        return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', '&:hover': {cursor: 'pointer', background: '#eee'}, svg: {fontSize: '300%', color: 'silver'}}}
            onClick={() => onEditShape(brush?.shape?.shapeCode || String(FAKE_VALUE_EMPTY))}>
            {brush?.shape ? <EditOutlined/> : <AddOutlined/>}
        </Box>
    }, [brush, onEditShape]);

    return <>
        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '8px', '& > svg, & > div': {border: "1px solid silver", background: "#fff", width: '100px', height: '100px'}}}>
            {tools}
            {actionTool}
        </Box>
        <Card sx={{
            fontSize: '85%',
            margin: '10px 15px 0 0',
            '& p': {margin: '5px 0 0 0', lineHeight: '1.2', opacity: .8}
        }}>
            <CardContent style={{padding: '5px 10px 10px 10px'}}>
                <p>Nový stůl: klik na tvar a umisťovat klikáním (jemnější: <kbd>SHIFT</kbd>)</p>
                <p>Posun: klik a poté táhnout, nebo držet a rovnou táhnout</p>
                <p>Výběr více: <kbd>CTRL</kbd> + klik</p>
                <p>Smazání: <kbd>DEL</kbd> nebo <kbd>Backspace</kbd></p>
                <p>Kopírování: <kbd>CTRL+C</kbd> / <kbd>CTRL+V</kbd></p>
                <p>Posun o krok: <kbd>←↑→↓</kbd> (jemnější: <kbd>SHIFT</kbd>)</p>
                <p>Rotace: <kbd>R</kbd> (30º) / <kbd>SHIFT+R</kbd> (1º)</p>
                <p>Zoom: <kbd>+-</kbd></p>
                <p>Upravit název: klik a znovu klik</p>
                <p>Další název: <kbd>TAB</kbd> / <kbd>SHIFT+TAB</kbd></p>
                <p>Undo/Redo: <kbd>CTRL+Z</kbd> / <kbd>CTRL+Y</kbd></p>
                <p>Storno: <kbd>ESC</kbd> nebo klik pravým</p>
            </CardContent>
        </Card>
    </>
}
