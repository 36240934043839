/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonParty,
    ApiResultOfJsonPartyFromJSON,
    ApiResultOfJsonPartyToJSON,
    ApiResultOfJsonPartyInfo,
    ApiResultOfJsonPartyInfoFromJSON,
    ApiResultOfJsonPartyInfoToJSON,
    ApiResultOfListOfJsonFindPartyResponse,
    ApiResultOfListOfJsonFindPartyResponseFromJSON,
    ApiResultOfListOfJsonFindPartyResponseToJSON,
    ApiResultOfListOfJsonInvite,
    ApiResultOfListOfJsonInviteFromJSON,
    ApiResultOfListOfJsonInviteToJSON,
    ApiResultOfListOfJsonParty,
    ApiResultOfListOfJsonPartyFromJSON,
    ApiResultOfListOfJsonPartyToJSON,
    ApiResultOfListOfJsonPartyInfo,
    ApiResultOfListOfJsonPartyInfoFromJSON,
    ApiResultOfListOfJsonPartyInfoToJSON,
    ApiResultOfListOfJsonSiwiPartyInfo,
    ApiResultOfListOfJsonSiwiPartyInfoFromJSON,
    ApiResultOfListOfJsonSiwiPartyInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonFindPartyRequest,
    JsonFindPartyRequestFromJSON,
    JsonFindPartyRequestToJSON,
    JsonParty,
    JsonPartyFromJSON,
    JsonPartyToJSON,
    JsonPartyMassActionRequest,
    JsonPartyMassActionRequestFromJSON,
    JsonPartyMassActionRequestToJSON,
} from '../models';

export interface FindPartyListUsingPOSTRequest {
    requests: Array<JsonFindPartyRequest>;
    exactOnly?: boolean;
}

export interface GetInvitesHistoryUsingGETRequest {
    partyId: number;
    eventId: number;
}

export interface GetPartyCountUsingGETRequest {
    partyIds?: Array<number>;
    eventIds?: Array<number>;
    notEventIds?: Array<number>;
    groupIds?: Array<number>;
    partyTypes?: Array<GetPartyCountUsingGETPartyTypesEnum>;
    sexType?: GetPartyCountUsingGETSexTypeEnum;
    isSiwi?: boolean;
    isCeb?: boolean;
    isPhoto?: boolean;
    isUser?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    createdByIds?: Array<number>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    isInvited?: boolean;
    siwiSyncStatuses?: Array<GetPartyCountUsingGETSiwiSyncStatusesEnum>;
    statuses?: Array<GetPartyCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetPartyHistoryUsingGETRequest {
    partyId: number;
}

export interface GetPartyInfoUsingGETRequest {
    partyId: number;
}

export interface GetPartyListUsingGETRequest {
    partyIds?: Array<number>;
    eventIds?: Array<number>;
    notEventIds?: Array<number>;
    groupIds?: Array<number>;
    partyTypes?: Array<GetPartyListUsingGETPartyTypesEnum>;
    sexType?: GetPartyListUsingGETSexTypeEnum;
    isSiwi?: boolean;
    isCeb?: boolean;
    isPhoto?: boolean;
    isUser?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    createdByIds?: Array<number>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    isInvited?: boolean;
    siwiSyncStatuses?: Array<GetPartyListUsingGETSiwiSyncStatusesEnum>;
    statuses?: Array<GetPartyListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetPartyUsingGETRequest {
    partyId: number;
}

export interface GetSiwiPartiesUsingGETRequest {
    partyId: number;
}

export interface MassPartyActionUsingPOSTRequest {
    request: JsonPartyMassActionRequest;
    testOnly?: boolean;
}

export interface MergePartiesUsingPOSTRequest {
    targetPartyId: number;
    deletePartyId: number;
    testOnly?: boolean;
}

export interface PushPartyToSiwiUsingPOSTRequest {
    partyId: number;
    ignoreMatches: Array<string>;
    testOnly?: boolean;
}

export interface SavePartyUsingPOSTRequest {
    json: JsonParty;
    testOnly?: boolean;
}

/**
 * 
 */
export class PartyControllerApi extends runtime.BaseAPI {

    /**
     * findPartyList
     */
    async findPartyListUsingPOSTRaw(requestParameters: FindPartyListUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonFindPartyResponse>> {
        if (requestParameters.requests === null || requestParameters.requests === undefined) {
            throw new runtime.RequiredError('requests','Required parameter requestParameters.requests was null or undefined when calling findPartyListUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.exactOnly !== undefined) {
            queryParameters['exactOnly'] = requestParameters.exactOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/party/find`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requests.map(JsonFindPartyRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonFindPartyResponseFromJSON(jsonValue));
    }

    /**
     * findPartyList
     */
    async findPartyListUsingPOST(requestParameters: FindPartyListUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonFindPartyResponse> {
        const response = await this.findPartyListUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInvitesHistory
     */
    async getInvitesHistoryUsingGETRaw(requestParameters: GetInvitesHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonInvite>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling getInvitesHistoryUsingGET.');
        }

        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling getInvitesHistoryUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/{partyId}/history/{eventId}/invite`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))).replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonInviteFromJSON(jsonValue));
    }

    /**
     * getInvitesHistory
     */
    async getInvitesHistoryUsingGET(requestParameters: GetInvitesHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonInvite> {
        const response = await this.getInvitesHistoryUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPartyCount
     */
    async getPartyCountUsingGETRaw(requestParameters: GetPartyCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.eventIds) {
            queryParameters['eventIds'] = requestParameters.eventIds;
        }

        if (requestParameters.notEventIds) {
            queryParameters['notEventIds'] = requestParameters.notEventIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.partyTypes) {
            queryParameters['partyTypes'] = requestParameters.partyTypes;
        }

        if (requestParameters.sexType !== undefined) {
            queryParameters['sexType'] = requestParameters.sexType;
        }

        if (requestParameters.isSiwi !== undefined) {
            queryParameters['isSiwi'] = requestParameters.isSiwi;
        }

        if (requestParameters.isCeb !== undefined) {
            queryParameters['isCeb'] = requestParameters.isCeb;
        }

        if (requestParameters.isPhoto !== undefined) {
            queryParameters['isPhoto'] = requestParameters.isPhoto;
        }

        if (requestParameters.isUser !== undefined) {
            queryParameters['isUser'] = requestParameters.isUser;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.createdByIds) {
            queryParameters['createdByIds'] = requestParameters.createdByIds;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.isInvited !== undefined) {
            queryParameters['isInvited'] = requestParameters.isInvited;
        }

        if (requestParameters.siwiSyncStatuses) {
            queryParameters['siwiSyncStatuses'] = requestParameters.siwiSyncStatuses;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getPartyCount
     */
    async getPartyCountUsingGET(requestParameters: GetPartyCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getPartyCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPartyHistory
     */
    async getPartyHistoryUsingGETRaw(requestParameters: GetPartyHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonParty>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling getPartyHistoryUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/{partyId}/history`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPartyFromJSON(jsonValue));
    }

    /**
     * getPartyHistory
     */
    async getPartyHistoryUsingGET(requestParameters: GetPartyHistoryUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonParty> {
        const response = await this.getPartyHistoryUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPartyInfo
     */
    async getPartyInfoUsingGETRaw(requestParameters: GetPartyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPartyInfo>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling getPartyInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/info/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPartyInfoFromJSON(jsonValue));
    }

    /**
     * getPartyInfo
     */
    async getPartyInfoUsingGET(requestParameters: GetPartyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPartyInfo> {
        const response = await this.getPartyInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPartyList
     */
    async getPartyListUsingGETRaw(requestParameters: GetPartyListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonPartyInfo>> {
        const queryParameters: any = {};

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.eventIds) {
            queryParameters['eventIds'] = requestParameters.eventIds;
        }

        if (requestParameters.notEventIds) {
            queryParameters['notEventIds'] = requestParameters.notEventIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.partyTypes) {
            queryParameters['partyTypes'] = requestParameters.partyTypes;
        }

        if (requestParameters.sexType !== undefined) {
            queryParameters['sexType'] = requestParameters.sexType;
        }

        if (requestParameters.isSiwi !== undefined) {
            queryParameters['isSiwi'] = requestParameters.isSiwi;
        }

        if (requestParameters.isCeb !== undefined) {
            queryParameters['isCeb'] = requestParameters.isCeb;
        }

        if (requestParameters.isPhoto !== undefined) {
            queryParameters['isPhoto'] = requestParameters.isPhoto;
        }

        if (requestParameters.isUser !== undefined) {
            queryParameters['isUser'] = requestParameters.isUser;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.createdByIds) {
            queryParameters['createdByIds'] = requestParameters.createdByIds;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.isInvited !== undefined) {
            queryParameters['isInvited'] = requestParameters.isInvited;
        }

        if (requestParameters.siwiSyncStatuses) {
            queryParameters['siwiSyncStatuses'] = requestParameters.siwiSyncStatuses;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPartyInfoFromJSON(jsonValue));
    }

    /**
     * getPartyList
     */
    async getPartyListUsingGET(requestParameters: GetPartyListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonPartyInfo> {
        const response = await this.getPartyListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getParty
     */
    async getPartyUsingGETRaw(requestParameters: GetPartyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonParty>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling getPartyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPartyFromJSON(jsonValue));
    }

    /**
     * getParty
     */
    async getPartyUsingGET(requestParameters: GetPartyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonParty> {
        const response = await this.getPartyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSiwiParties
     */
    async getSiwiPartiesUsingGETRaw(requestParameters: GetSiwiPartiesUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonSiwiPartyInfo>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling getSiwiPartiesUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/siwi/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonSiwiPartyInfoFromJSON(jsonValue));
    }

    /**
     * getSiwiParties
     */
    async getSiwiPartiesUsingGET(requestParameters: GetSiwiPartiesUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonSiwiPartyInfo> {
        const response = await this.getSiwiPartiesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * massPartyAction
     */
    async massPartyActionUsingPOSTRaw(requestParameters: MassPartyActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonParty>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling massPartyActionUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/party/mass`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonPartyMassActionRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPartyFromJSON(jsonValue));
    }

    /**
     * massPartyAction
     */
    async massPartyActionUsingPOST(requestParameters: MassPartyActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonParty> {
        const response = await this.massPartyActionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * mergeParties
     */
    async mergePartiesUsingPOSTRaw(requestParameters: MergePartiesUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPartyInfo>> {
        if (requestParameters.targetPartyId === null || requestParameters.targetPartyId === undefined) {
            throw new runtime.RequiredError('targetPartyId','Required parameter requestParameters.targetPartyId was null or undefined when calling mergePartiesUsingPOST.');
        }

        if (requestParameters.deletePartyId === null || requestParameters.deletePartyId === undefined) {
            throw new runtime.RequiredError('deletePartyId','Required parameter requestParameters.deletePartyId was null or undefined when calling mergePartiesUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.targetPartyId !== undefined) {
            queryParameters['targetPartyId'] = requestParameters.targetPartyId;
        }

        if (requestParameters.deletePartyId !== undefined) {
            queryParameters['deletePartyId'] = requestParameters.deletePartyId;
        }

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/party/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPartyInfoFromJSON(jsonValue));
    }

    /**
     * mergeParties
     */
    async mergePartiesUsingPOST(requestParameters: MergePartiesUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPartyInfo> {
        const response = await this.mergePartiesUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pushPartyToSiwi
     */
    async pushPartyToSiwiUsingPOSTRaw(requestParameters: PushPartyToSiwiUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPartyInfo>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling pushPartyToSiwiUsingPOST.');
        }

        if (requestParameters.ignoreMatches === null || requestParameters.ignoreMatches === undefined) {
            throw new runtime.RequiredError('ignoreMatches','Required parameter requestParameters.ignoreMatches was null or undefined when calling pushPartyToSiwiUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/party/siwi/{partyId}/push`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ignoreMatches,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPartyInfoFromJSON(jsonValue));
    }

    /**
     * pushPartyToSiwi
     */
    async pushPartyToSiwiUsingPOST(requestParameters: PushPartyToSiwiUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPartyInfo> {
        const response = await this.pushPartyToSiwiUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveParty
     */
    async savePartyUsingPOSTRaw(requestParameters: SavePartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonParty>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling savePartyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/party`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonPartyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPartyFromJSON(jsonValue));
    }

    /**
     * saveParty
     */
    async savePartyUsingPOST(requestParameters: SavePartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonParty> {
        const response = await this.savePartyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetPartyCountUsingGETPartyTypesEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyCountUsingGETSexTypeEnum {
    M = 'M',
    F = 'F'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyCountUsingGETSiwiSyncStatusesEnum {
    None = 'NONE',
    Synced = 'SYNCED',
    Dirty = 'DIRTY',
    Pushing = 'PUSHING',
    Conflict = 'CONFLICT',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyListUsingGETPartyTypesEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyListUsingGETSexTypeEnum {
    M = 'M',
    F = 'F'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyListUsingGETSiwiSyncStatusesEnum {
    None = 'NONE',
    Synced = 'SYNCED',
    Dirty = 'DIRTY',
    Pushing = 'PUSHING',
    Conflict = 'CONFLICT',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPartyListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
