import {Button, Grid} from "@mui/material";
import PartyInfoBox from "../components/party/PartyInfoBox";
import {FAKE_VALUE_EMPTY} from "../model/form";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded} from "@mui/icons-material";
import {
    JsonFindPartyResponseMatchTypesEnum,
    JsonParty,
    JsonPartyInfo,
    JsonPartyInfoPartyTypeEnum,
    JsonPartyInfoSexEnum,
    JsonPartySexEnum,
    JsonPartySiwiSyncStatusEnum,
    JsonSiwiPartyInfo
} from "../generated-api";
import {FoundParty} from "../components/party/FoundParty";
import FormModal from "../components/form/FormModal";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useAppTranslation} from "../services/i18n";
import {useAppDispatch, useAppSelector} from "../store";
import {useNavigate} from "react-router-dom";
import {AuthState} from "../store/auth";
import {selectAuthInfo} from "../store/selectors";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {fetchParty, fetchSiwiPartyList, mergeParties, pushPartyToSiwi, saveParty} from "../store/parties";
import {addMessage} from "../store/localApp";
import {partyName} from "../model/party";

type Props = {
    party: JsonPartyInfo,
    onClose: (newSiwiId?: string) => void
}

export const PartySiwiConflictModal = ({party, onClose}: Props) => {
    const {partyId} = party;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const defaultEventId = configuration?.defaultEvent?.eventId as number;

    const [siwiParties, setSiwiParties] = useState<JsonSiwiPartyInfo[] | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [targetParty, setTargetParty] = useState<JsonPartyInfo | undefined>({});

    const handleFetchSiwiParties = useCallback(async () => {
        if (!partyId) {
            return;
        }
        setSiwiParties(getApiResult(await dispatch(fetchSiwiPartyList(partyId))));
    }, [partyId, dispatch]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSaveNew = useCallback(async () => {
        if (!partyId) {
            return;
        }
        const ignoreMatches = siwiParties?.map((sp) => partyId + '_' + sp.siwiId) || [];
        const item = getApiResult<JsonPartyInfo>(await dispatch(pushPartyToSiwi({partyId, ignoreMatches})));
        if (item?.siwiId) {
            setSiwiParties(undefined);
            onClose(item.siwiId);
        } else {
            await handleFetchSiwiParties();
        }

    }, [partyId, dispatch, siwiParties, handleFetchSiwiParties, onClose]);

    const handleSaveLink = useCallback(async (siwiParty: JsonSiwiPartyInfo, targetParty: JsonPartyInfo) => {
        if (!partyId) {
            return;
        }
        let item = getApiResult<JsonParty>(await dispatch(fetchParty(partyId)));
        if (!item || !!item.siwiId) {
            return;
        }
        let mergedParty;
        if (!!siwiParty.existingParty?.partyId && siwiParty.partyId) {
            // will fail if the deleted party exists in the current event
            const res = await dispatch(mergeParties({
                deletePartyId: siwiParty.partyId,
                targetPartyId: siwiParty.existingParty.partyId,
            }));
            mergedParty = getApiResult(res);
            if (mergedParty) {
                dispatch(addMessage({
                    code: 'OK',
                    action: 'party/merge',
                    severity: 'success',
                    title: t('Osoba úspěšně smazána jako duplikát, informace přesunuty do původní existující osoby {{title}}, která je nyní otevřena', {
                        title: partyName(mergedParty) + ' (' + mergedParty.siwiId + ')'
                    }),
                }));
                item = getApiResult<JsonParty>(await dispatch(fetchParty(mergedParty.partyId!)))!;
                onClose(siwiParty.siwiId);
            } else {
                return;
            }
        }

        // will fail for existing SIWI IDs
        const res = await dispatch(saveParty({
            ...item,
            siwiId: siwiParty.siwiId,
            siwiSyncStatus: JsonPartySiwiSyncStatusEnum.Dirty,
            firstName: targetParty.firstName || item.firstName,
            lastName: targetParty.lastName || item.lastName,
            photoGuid: targetParty.photoGuid || item.photoGuid,
            sex: (targetParty.sex ? JsonPartySexEnum[targetParty.sex] : undefined) || item.sex
        }));

        if (getApiResult(res)) {
            if (!mergedParty) {
                addApiResultMessage(res, {
                    [ApiChangeType.NO_CHANGE]: ['Osoba {{title}} ponechána beze změn', partyName],
                    [ApiChangeType.UPDATED]: ['Osoba {{title}} úspěšně spojena zapsáním SIWI ID', partyName],
                }, t, dispatch);
            }
            onClose(siwiParty.siwiId);
            setSiwiParties(undefined);
        }

        if (mergedParty?.partyId) {
            navigate('/parties/' + mergedParty.partyId, {replace: true});
        }

    }, [partyId, onClose, dispatch, navigate, t]);

    useEffect(() => {
        handleFetchSiwiParties().then();
    }, [handleFetchSiwiParties]);

    const isSelected = targetParty?.partyId === FAKE_VALUE_EMPTY;

    return <FormModal title={t('Vyřešení konfliktu')} onCancel={handleClose} dialogClassName={'rsvp-review'} maxWidth={'sm'}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PartyInfoBox party={party} simple eventId={defaultEventId} withLink/>
            </Grid>
            <Grid item xs={12}>
                <Button title={undefined} variant={'contained'} size={'small'} fullWidth style={{marginBottom: '-5px'}}
                    color={isSelected ? 'info' : 'inherit'}
                    onClick={() => {
                        setTargetParty((tp) => ({
                            ...tp,
                            partyId: isSelected ? undefined : FAKE_VALUE_EMPTY,
                            siwiPartyId: undefined,
                        }));
                    }}>
                    {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
                    <span>{t('Založit jako novou osobu')}</span>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {siwiParties?.map((sp) => {
                        const p = sp.existingParty ?
                            {
                                ...sp.existingParty,
                                siwiPartyId: sp.siwiPartyId
                            } : {
                                ...sp,
                                partyType: JsonPartyInfoPartyTypeEnum.Fo,
                                allowedActions: undefined,
                                sex: sp.sex === 'M' || sp.sex === 'F' ? JsonPartyInfoSexEnum[sp.sex] : undefined,
                                status: undefined
                            };
                        return <FoundParty key={sp.siwiPartyId} sourceParty={{
                            ...party,
                            partyId: undefined
                        }} foundPartyResponse={{
                            photoScore: sp.photoScore,
                            matchTypes: [JsonFindPartyResponseMatchTypesEnum.Photo, JsonFindPartyResponseMatchTypesEnum.Name],
                            party: p
                        }} targetParty={targetParty} maxWidth={80} handleValueChange={(name, value) => {
                            const isLink = (name === 'partyId' && value !== FAKE_VALUE_EMPTY);
                            setTargetParty((tp) => {
                                const p = {
                                    firstName: isLink ? party.firstName : tp?.firstName,
                                    lastName: isLink ? party.lastName : tp?.lastName,
                                    photoGuid: isLink ? party.photoGuid : tp?.photoGuid,
                                    sex: isLink ? (party.sex ? JsonPartyInfoSexEnum[party.sex] : undefined) : tp?.sex,
                                    ...tp,
                                    [name]: value,
                                    siwiPartyId: sp.siwiPartyId // overloaded
                                };
                                return p;
                            });
                        }} isSiwi={!sp.existingParty} help={<div>
                            {!!sp.existingParty
                                ? (!!sp.existingEventParty
                                    ? t('Tato osoba již existuje v EMS a bohužel také v události. Spojení osob není jednoduše možné a je nutný servisní zásah (je třeba rozhodnout, jaké platí dny, pozvánky, akreditace atd.)')
                                    : t('Tato osoba již existuje v EMS (ale naštěstí ne v události). ' +
                                        'Spojením bude řešená osoba smazána jako duplikát a všechny její informace budou přesunuty této existující osobě (pozvánky atd.).'))
                                : t('Tato osoba je zatím pouze v SIWI, nic dalšího o ní EMS tedy neví. ' +
                                    'Spojením bude řešené osobě přiřazeno toto SIWI ID.')}
                        </div>}/>;
                    })}
                </Grid>
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            </Grid>
            <Grid item>
                <Button variant="text" onClick={handleClose}>{t('Storno')}</Button>
            </Grid>
            <Grid item xs={3}>
                <Button title={undefined} variant={'contained'} size={'small'} fullWidth
                    disabled={targetParty?.partyId === undefined || isSubmitting}
                    color={'success'}
                    onClick={async () => {
                        setIsSubmitting(true);
                        if (targetParty?.partyId === FAKE_VALUE_EMPTY) {
                            await handleSaveNew();
                        } else if (targetParty) {
                            const siwiPartyId = (targetParty as any).siwiPartyId as number | undefined; // overloaded
                            const sp = siwiParties?.find((sp) => siwiPartyId && sp.siwiPartyId === siwiPartyId)
                            if (sp) {
                                await handleSaveLink(sp, targetParty);
                            }
                        }
                        setIsSubmitting(false);
                    }}>
                    <span>{t('Uložit')}</span>
                </Button>
            </Grid>
        </Grid>
    </FormModal>
}
