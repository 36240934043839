import {Box, Grid, Link, Paper} from "@mui/material";
import * as React from "react";
import {MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAppTranslation} from "../../services/i18n";
import {DoneRounded, OpenInNewOutlined} from "@mui/icons-material";
import {JsonEvent, JsonEventDayInfo, JsonEventFloorDay, JsonEventFloorDayInfo, JsonEventFloorInfo, JsonEventParty, JsonEventPartyInfo, JsonEventPartyMassActionRequestActionEnum, JsonEventPartySeating, JsonEventTicket, JsonEventTicketMassActionRequestActionEnum, JsonFloorItem, JsonFloorShape} from "../../generated-api";
import {useAppDispatch} from "../../store";
import {fetchEventFloors} from "../../store/eventFloors";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {createOption, OptionValue} from "../../model/form";
import {dateToGuiAs} from "../../helpers/date";
import {fetchEventFloorDay, fetchEventFloorDays, saveEventFloorDay} from "../../store/eventFloorDays";
import {GroupType} from "../../pages/EventGuestsPage";
import {JsonEventPartyInfoWithChunkId} from "../party/PartyAccredToEventForm";
import {SeatingPlanCanvas} from "./SeatingPlanCanvas";
import {ModalProps, useModal} from "../../services/modal";
import {massEventParty} from "../../store/eventParties";
import {massEventTicket} from "../../store/eventTickets";
import {Link as RouterLink} from "react-router-dom";
import {SeatingPlanToolbar} from "./SeatingPlanToolbar";
import {GroupsViewMode, SeatingPlanGroups} from "./SeatingPlanGroups";
import {AREA_CODE, areSeatingItemsEqual, BrushType, FloorDirtyValues, FloorItemWithSvg, ghostInitialStyle, ghostStyle, isAnyTableMatch, parseTableNames, RewindType, SeatingActionType, SeatingItem, SeatingMap, TableInfo} from "./svgUtils";
import {SeatingShapeModal} from "./SeatingShape";

export const eventDayTitle = (ed?: JsonEventDayInfo) => {
    if (!ed) {
        return '';
    }
    return dateToGuiAs(ed.dayDate, 'eeeee d. M.');
}

export type ShapeStatsValueType = {
    used: number,
    dirty: number
}

export type ShapeStatsMapType = {
    [key in JsonFloorShape['shapeCode']]: ShapeStatsValueType
}

export type ShapeStatsType = {
    total: ShapeStatsMapType,
    days: { [key in number]: ShapeStatsMapType } // dayNo
}

const removeTableNames = (currentTableNames: string | undefined, removeTableNames: string) => {
    return parseTableNames(currentTableNames)?.filter(n => !isAnyTableMatch(removeTableNames, n))?.join(' ');
}

const cleanupSeating = (seating: JsonEventPartySeating) => {
    if (seating.tables) {
        Object.keys(seating.tables).forEach((k) => {
            const dayNo = parseInt(k);
            let tables = parseTableNames(seating?.tables?.[dayNo])
                ?.sort()
                ?.join(' ')?.trim();
            if (!tables || tables === '') {
                if (seating.tables?.[dayNo] !== undefined) {
                    delete seating.tables[dayNo];
                }
            } else {
                if (!seating.tables) {
                    seating.tables = {}; // lint pls ...
                }
                seating.tables[dayNo] = tables;
            }
        });
        // if (!Object.keys(seating.tables).length) {
        //     return undefined;
        // }
    }
    return seating;
}

const getEligibleExtras = (groups: GroupType[] | undefined, ep: JsonEventPartyInfo, dayNo?: number) => {
    const extras: JsonEventPartyInfo[] = [];
    if (ep.extraEventParties) {
        groups?.forEach((group) => {
            group.items?.filter((item) => ep.extraEventParties?.find((extra) => extra.partyId === item.partyId))
                ?.forEach((extra) => {
                    if (dayNo && !(extra.eventDays && extra.eventDays[dayNo - 1] === '1')) {
                        return;
                    }
                    if (!(extra.areas && extra.areas.indexOf(AREA_CODE) >= 0)) {
                        return;
                    }
                    if (extras.find((other) => other.partyId === extra.partyId)) {
                        return;
                    }
                    extras.push(extra);
                    if (extra.extraEventParties) {
                        extras.push(...getEligibleExtras(groups, extra, dayNo));
                    }
                })
        })
    }
    return extras;
}

type SeatingPlanProps = {
    event: JsonEvent,
    tab: 'floors' | 'seating',
    eventDays?: JsonEventDayInfo[],
    groups: GroupType[],
    eventParties?: JsonEventPartyInfo[],
    onSeatingChange?: () => void
}

const SeatingPlan = ({tab, event, eventDays, groups, onSeatingChange}: SeatingPlanProps) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [eventFloors, setEventFloors] = useState<JsonEventFloorInfo[] | undefined>(undefined);
    const [eventFloorDays, setEventFloorDays] = useState<JsonEventFloorDayInfo[] | undefined>(undefined);
    const [eventFloorDay, setEventFloorDay] = useState<JsonEventFloorDayInfo | undefined>(undefined);
    const [shapes, setShapes] = useState(event.floorShapes);
    const [brush, setBrush] = useState<BrushType | undefined>();
    const [editShapeCode, setEditShapeCode] = useState<JsonFloorShape['shapeCode']>();
    const [dirtyValuesByFloor, setDirtyValuesByFloor] = useState<{ [key in number]: FloorDirtyValues }>({});
    const [shapeStats, setShapeStats] = useState<ShapeStatsType>({total: {}, days: {}});
    const [clipboard, setClipboard] = useState<FloorItemWithSvg[] | undefined>([]);

    const wrapperRef = useRef<HTMLElement | null>(null);
    const ghostRef = useRef<HTMLDivElement | null>(null);

    const originalSeating = useRef<SeatingItem[]>([
        ...(groups.map((group) => (group.items?.filter((ep) => ep.areas && ep.areas.indexOf(AREA_CODE) >= 0).slice() || [])).flatMap((x) => x) || []),
        ...(groups.map((group) => (group.tickets?.filter((et) => et.areas && et.areas.indexOf(AREA_CODE) >= 0).slice() || [])).flatMap((x) => x) || [])
    ]);
    const [seating, setSeating] = useState<SeatingItem[]>(JSON.parse(JSON.stringify(originalSeating.current)));

    const [seatingUndoStack, setSeatingUndoStack] = useState<SeatingActionType[]>([]);
    const [seatingRedoStack, setSeatingRedoStack] = useState<SeatingActionType[]>([]);

    const [selectedItems, setSelectedItems] = useState<SeatingItem[] | undefined>([]);
    const [draggedItems, setDraggedItems] = useState<SeatingItem[] | undefined>([]);
    const ghostItemsRef = useRef<SeatingItem[] | null>();

    const [groupsViewMode, setGroupsViewMode] = useState<GroupsViewMode>('empty');

    const calcShapeStats = useCallback((dirtyValuesByFloor: {[key in number]: FloorDirtyValues}) => {
        const stats: ShapeStatsType = {
            days: {},
            total: {}
        }

        const accum = (items: JsonFloorItem[], dayNo: number, key: 'used' | 'dirty') => {
            items.forEach((item) => {
                if (!item.shapeCode) {
                    return;
                }
                if (!stats['total'][item.shapeCode]) {
                    stats['total'][item.shapeCode] = {used: 0, dirty: 0};
                }
                stats['total'][item.shapeCode][key]++;

                if (!stats['days'][dayNo]?.[item.shapeCode]) {
                    if (!stats['days'][dayNo]) {
                        stats['days'][dayNo] = {};
                    }
                    stats['days'][dayNo][item.shapeCode] = {used: 0, dirty: 0};
                }
                stats['days'][dayNo][item.shapeCode][key]++;
            });
        }

        Object.values(dirtyValuesByFloor).forEach((values) => {
            accum(values.originalItems, values.dayNo, 'used');
            accum(values.items, values.dayNo, 'dirty');
        });
        return stats;
    }, []);

    const handleFetchFloor = useCallback(async () => {
        setEventFloorDay(undefined);

        const eventFloors = getApiResult<JsonEventFloorInfo[]>(await dispatch(fetchEventFloors({eventId: event.eventId})));
        setEventFloors(eventFloors);

        const eventFloorDays = getApiResult<JsonEventFloorDayInfo[]>(await dispatch(fetchEventFloorDays({eventId: event.eventId})));
        setEventFloorDays(eventFloorDays || []);

        const dirtyValuesByFloor = (eventFloorDays || []).reduce((accum, efd) => {
            if (efd.eventFloorDayId) {
                accum[efd.eventFloorDayId] = {
                    title: eventFloors?.find(ef => ef.eventFloorId === efd.eventFloorId)?.title || ('Podlaží ' || efd.eventFloorId),
                    dayNo: efd.dayNo || 1,
                    eventFloorDayId: efd.eventFloorDayId,
                    items: efd.floorData?.items || [],
                    originalItems: efd.floorData?.items ? JSON.parse(JSON.stringify(efd.floorData.items)) : [],
                    redoStack: [],
                    undoStack: []
                };
            }
            return accum;
        }, {} as { [key in number]: FloorDirtyValues });
        setDirtyValuesByFloor(dirtyValuesByFloor);
        setShapeStats(calcShapeStats(dirtyValuesByFloor))

        const firstFloorId = eventFloors?.[0]?.eventFloorId;
        setEventFloorDay(eventFloorDays?.find((efd) => efd.eventFloorId === firstFloorId));

        setSelectedItems(undefined);
        setDraggedItems(undefined);
        ghostItemsRef.current = undefined;

    }, [calcShapeStats, event.eventId, dispatch]);

    const handleSaveFloor = useCallback(async (items: JsonFloorItem[]) => {
        if (!eventFloorDay?.eventFloorDayId) {
            return;
        }
        let floor = getApiResult<JsonEventFloorDay>(await dispatch(fetchEventFloorDay(eventFloorDay.eventFloorDayId)));
        if (floor) {
            const res = await dispatch(saveEventFloorDay({...floor, floorData: {...floor.floorData, items}}));
            floor = getApiResult<JsonEventFloorDay>(res);
            if (floor) {
                addApiResultMessage(res, {
                    [ApiChangeType.NO_CHANGE]: 'Plánek ponechán beze změn',
                    [ApiChangeType.UPDATED]: 'Plánek úspěšně upraven',
                    [ApiChangeType.CREATED]: 'Plánek úspěšně založen'
                }, t, dispatch);

                setDirtyValuesByFloor((values) => {
                    if (floor?.eventFloorDayId) {
                        values[floor.eventFloorDayId] = {
                            ...values[floor.eventFloorDayId],
                            items,
                        }
                    }
                    setShapeStats(calcShapeStats(values));
                    return values;
                });
                setEventFloorDay((efd) => ({...efd, floorData: {...efd?.floorData, items}}));
                setEventFloorDays((efd) => efd?.map((efd) => (efd.eventFloorDayId === eventFloorDay.eventFloorDayId
                    ? {...efd, floorData: {...efd?.floorData, items}} : efd)));
            }
        }

    }, [eventFloorDay?.eventFloorDayId, calcShapeStats, t, dispatch]);

    const runSavePartySeating = useCallback(async (eventId: number, items: JsonEventParty[], testOnly: boolean): Promise<[any, number]> => {
        const res = await dispatch(massEventParty({
            request: {
                action: JsonEventPartyMassActionRequestActionEnum.Seating,
                eventId,
                items
            },
            testOnly
        }));
        const resItems = getApiResult<JsonEventParty[]>(res);
        const changedCount = resItems
            ?.filter((ep) => (ep.changes?.length || 0) > 0)?.map(p => p.partyId)?.length || 0;
        return [res, changedCount];
    }, [dispatch]);

    const runSaveTicketSeating = useCallback(async (eventId: number, items: JsonEventTicket[], testOnly: boolean): Promise<[any, number]> => {
        const res = await dispatch(massEventTicket({
            request: {
                action: JsonEventTicketMassActionRequestActionEnum.Seating,
                eventId,
                items
            },
            testOnly
        }));
        const resItems = getApiResult<JsonEventTicket[]>(res);
        const changedCount = resItems
            ?.filter((ep) => (ep.changes?.length || 0) > 0)?.map(p => p.ticketId)?.length || 0;
        return [res, changedCount];
    }, [dispatch]);

    const handleSaveSeating = useCallback(async (seating: SeatingItem[]) => {
        // save event parties
        const eventId = event.eventId;
        if (!eventId) {
            return;
        }

        let eventPartyRes, eventPartyChangedCount = 0;
        const eventParties: JsonEventParty[] = seating.filter((ep) => !!ep.seating && !!ep.partyId).map((ep) => ({
            eventPartyId: (ep as JsonEventPartyInfo)['eventPartyId'],
            partyId: ep.partyId,
            eventId: ep.eventId,
            seating: ep.seating
        }));
        if (eventParties?.length) {
            [eventPartyRes, eventPartyChangedCount] = await runSavePartySeating(eventId, eventParties, true);
        }

        let eventTicketRes, eventTicketChangedCount = 0;
        const eventTickets: JsonEventTicket[] = seating.filter((ep) => !!ep.seating && !!ep.ticketId).map((et) => ({
            ticketId: et.ticketId,
            eventId: et.eventId,
            seating: et.seating
        }));
        if (eventTickets?.length) {
            [eventTicketRes, eventTicketChangedCount] = await runSaveTicketSeating(eventId, eventTickets, true);
        }
        if (!eventPartyRes && !eventTicketRes) {
            return;
        }

        if (!eventPartyChangedCount && !eventTicketChangedCount) {
            addApiResultMessage(eventPartyRes || eventTicketRes, {
                [ApiChangeType.NO_CHANGE]: t('Uložením nedojde k žádným změnám'),
            }, t, dispatch);
            return;
        }

        const result = await modal.confirm({
            title: t('Povtrzení uložení'),
            message: <div>
                <p>{t('Skutečně uložit místa pro všechny dny a podlaží (celkem upraveno {{eventPartyChangedCount}} osob a {{eventTicketChangedCount}} voucherů/vstupenek?',
                    {eventPartyChangedCount, eventTicketChangedCount})}</p>
            </div>,
            cancelText: 'Zpět',
            confirmColor: 'success',
            confirmText: 'Uložit všechny osoby',
            confirmIcon: <DoneRounded/>,
        } as ModalProps);
        if (result === 'CONFIRM') {
            if (eventParties?.length) {
                [eventPartyRes, eventPartyChangedCount] = await runSavePartySeating(eventId, eventParties, false);
            }
            if (eventTickets?.length) {
                [eventTicketRes, eventTicketChangedCount] = await runSaveTicketSeating(eventId, eventTickets, false);
            }

            const h: [string, (item: any) => any] = ['Místa pro všechny dny a podlaží byla uložena ({{title}})',
                () => t('celkem upraveno {{eventPartyChangedCount}} osob a {{eventTicketChangedCount}} voucherů/vstupenek', {
                    eventPartyChangedCount,
                    eventTicketChangedCount
                })];
            if ((eventTicketRes as any)?.payload?.message === ApiChangeType.UPDATED) {
                (eventPartyRes as any).payload.message = ApiChangeType.UPDATED; // force updated even if event parties were not
            }

            addApiResultMessage(eventPartyRes, {
                [ApiChangeType.NO_CHANGE]: t('Uložením nedošlo k žádným změnám'),
                [ApiChangeType.UPDATED]: h,
            }, t, dispatch);

            if (onSeatingChange) {
                onSeatingChange();
            }
        }

    }, [event.eventId, runSavePartySeating, runSaveTicketSeating, t, onSeatingChange, dispatch, modal]);

    const eventDayOptions = useMemo(() => {
        if (!eventDays || !eventFloorDays) {
            return [];
        }
        return eventDays
            ?.filter((ed) => eventFloorDays.find((efd) => efd.dayNo === ed.dayNo))
            ?.map((ed) => createOption(ed.eventDayId || 0, eventDayTitle(ed)));

    }, [eventDays, eventFloorDays]);

    const eventDay = useMemo(() => eventDays?.find((ed) => eventFloorDay && ed.eventDayId === eventFloorDay.eventDayId) || {},
        [eventDays, eventFloorDay]);
    const eventFloor = useMemo(() => eventFloors?.find((ef) => eventFloorDay && ef.eventFloorId === eventFloorDay.eventFloorId) || {},
        [eventFloors, eventFloorDay]);

    const eventFloorOptions = useMemo(() => {
        return eventFloors?.map((f) => {
            const efd = eventFloorDays?.find((efd) => efd.eventFloorId === f.eventFloorId && efd.eventDayId === eventDay.eventDayId);
            const totals = efd?.floorData?.items?.reduce((sum, item) => {
                const shape = shapes?.find(s => s.shapeCode === item.shapeCode);
                const capacity = shape?.capacity || 0;
                const used = item.text
                    ? seating?.filter(ep => efd.dayNo && parseTableNames(ep.seating?.tables?.[efd.dayNo])?.find(tableName => tableName === item.text))?.length
                    : 0;
                return [sum[0] + capacity, sum[1] + Math.max(capacity - (used | 0), 0)];
            }, [0, 0]) || [0, 0];
            return createOption(f.eventFloorId || 0, (f.title || '')
                + ' (' + (totals[0] === totals[1] ? totals[0] : (totals[1] + ' / ' + totals[0])) + ')');
        });
    }, [eventFloors, eventFloorDays, eventDay, shapes, seating]);

    const groupsViewModeOptions: OptionValue[] = useMemo((): OptionValue[] => {
        const total = seating.filter((ep) => eventDay.dayNo && ep.eventDays && ep.eventDays[eventDay.dayNo - 1] === '1')?.length || 0;
        const seated = seating.filter((ep) => eventDay.dayNo
            && ep.eventDays
            && ep.eventDays[eventDay.dayNo - 1] === '1'
            && !!ep.seating?.tables?.[eventDay.dayNo])?.length || 0;
        return [
            {value: 'all', label: t('Vše ({{total}})', {total})},
            {value: 'empty', label: t('Bez ({{empty}})', {empty: total - seated}), tooltip: t('Bez přiděleného místa')},
            {value: 'seated', label: t('S ({{seated}})', {seated}), tooltip: t('S přiděleným místem')},
        ];

    }, [eventDay, seating, t]);

    const setRewind = useCallback((action: SeatingActionType, rewindType?: RewindType) => {
        if (!rewindType) {
            setSeatingRedoStack([]);
            // inputContext.current.dirtyValues.redoStack = [];
        }
        if (rewindType === 'undo') {
            setSeatingRedoStack((q) => [...q.slice(q.length - 1000), action]);
        } else {
            setSeatingUndoStack((q) => [...q.slice(q.length - 1000), action]);
        }

    }, []);

    const filteredGroups = useMemo(() => {
        if (!(groupsViewMode === 'seated' || groupsViewMode === 'empty')) {
            return groups;
        }
        return groups.map((group) => {
            const items = group.items?.filter((ep) => eventDay.dayNo
                && ep.eventDays && ep.eventDays[eventDay.dayNo - 1] === '1'
                && !!seating.find((s) => s.partyId === ep.partyId)?.seating?.tables?.[eventDay.dayNo] === (groupsViewMode === 'seated')
            );
            const tickets = group.tickets?.filter((ep) => eventDay.dayNo
                && ep.eventDays && ep.eventDays[eventDay.dayNo - 1] === '1'
                && !!seating.find((s) => s.ticketId === ep.ticketId)?.seating?.tables?.[eventDay.dayNo] === (groupsViewMode === 'seated')
            );
            return {...group, items, tickets};
        }).filter((group) => !!group.items?.length || !!group.tickets?.length);
    }, [seating, groups, groupsViewMode, eventDay.dayNo]);

    const actionAddSeating = useCallback((dayNo: number, addPartyTables?: SeatingMap, addTicketTables?: SeatingMap, isAppend?: boolean, rewindType?: RewindType) => {
        const removePartyTables: SeatingMap = {};
        const removeTicketTables: SeatingMap = {};
        setSeating((seating) => {
            return seating.map((s) => {
                let dayTables = s.seating?.tables?.[dayNo];
                if (s.partyId && !!addPartyTables?.[s.partyId]) {
                    if (isAppend) {
                        dayTables = (dayTables ? dayTables + ' ' : '') + addPartyTables[s.partyId];
                    } else {
                        if (dayTables) {
                            removePartyTables[s.partyId] = dayTables;
                        }
                        dayTables = addPartyTables[s.partyId];
                    }
                }
                if (s.ticketId && !!addTicketTables?.[s.ticketId]) {
                    if (isAppend) {
                        dayTables = (dayTables ? dayTables + ' ' : '') + addTicketTables[s.ticketId];
                    } else {
                        if (dayTables) {
                            removeTicketTables[s.ticketId] = dayTables;
                        }
                        dayTables = addTicketTables[s.ticketId];
                    }
                }
                if (s.seating?.tables?.[dayNo] !== dayTables) {
                    return {...s, seating: cleanupSeating({...(s.seating || {}), tables: {...(s.seating?.tables || {}), [dayNo]: dayTables}})};
                }
                return s;

            });
        });
        setRewind({
            dayNo,
            addPartyTables,
            removePartyTables: !!Object.keys(removePartyTables).length ? removePartyTables : undefined,
            addTicketTables,
            removeTicketTables: !!Object.keys(removeTicketTables).length ? removeTicketTables : undefined,
        }, rewindType);

    }, [setRewind]);

    const actionDeleteSeating = useCallback((dayNo: number, removePartyTables?: SeatingMap, removeTicketTables?: SeatingMap, rewindType?: RewindType) => {
        setSeating((seating) => {
            return seating.map((s) => {
                let dayTables = s.seating?.tables?.[dayNo];
                if (dayTables) {
                    if (s.partyId && !!removePartyTables?.[s.partyId]) {
                        dayTables = removeTableNames(dayTables, removePartyTables[s.partyId]);
                    }
                    if (s.ticketId && !!removeTicketTables?.[s.ticketId]) {
                        dayTables = removeTableNames(dayTables, removeTicketTables[s.ticketId]);
                    }
                    if (s.seating?.tables?.[dayNo] !== dayTables) {
                        return {...s, seating: cleanupSeating({...(s.seating || {}), tables: {...(s.seating?.tables || {}), [dayNo]: dayTables}})};
                    }
                }
                return s;
            });
        });
        setRewind({
            dayNo,
            removePartyTables,
            removeTicketTables
        }, rewindType);

    }, [setRewind]);

    const cancelAllPendingActions = useCallback(() => {
        ghostItemsRef.current = undefined;
        setDraggedItems(undefined);
        if (ghostRef.current?.style) {
            ghostRef.current.style.display = 'none';
        }
        if (wrapperRef.current?.className) {
            wrapperRef.current.className = wrapperRef.current.className.substring(0, wrapperRef.current.className.lastIndexOf(' ')).trim();
        }
    }, []);

    const activeItemStartDrag = useCallback((itemsToDrag: SeatingItem[], e: MouseEvent) => {
        ghostItemsRef.current = [];
        for (let item of itemsToDrag) {
            if (!item.partyId && !item.ticketId) {
                continue;
            }
            ghostItemsRef.current.push(item);
            // svgDragGroupRef.current?.appendChild(ghostItem.svgElement);
        }
        setDraggedItems(itemsToDrag);
        if (ghostRef.current?.style) {
            ghostRef.current.style.top = (e.clientY + 10) + 'px';
            ghostRef.current.style.left = (e.clientX + 10) + 'px';
            ghostRef.current.style.display = 'block';
        }
        if (wrapperRef.current?.className) {
            wrapperRef.current.className += ' is-grabbing';
        }

    }, []);

    const handleRewindSeatingAction = useCallback((a: SeatingActionType, rewindType: RewindType) => {
        const addPartyTables = a.removePartyTables;
        const removePartyTables = a.addPartyTables;
        const addTicketTables = a.removeTicketTables;
        const removeTicketTables = a.addTicketTables;

        // merged action rewind
        setSeating((seating) => {
            return seating.map((s) => {
                let dayTables = s.seating?.tables?.[a.dayNo];
                if (dayTables) {
                    if (s.partyId && !!removePartyTables?.[s.partyId]) {
                        dayTables = removeTableNames(dayTables, removePartyTables[s.partyId]);
                    }
                    if (s.ticketId && !!removeTicketTables?.[s.ticketId]) {
                        dayTables = removeTableNames(dayTables, removeTicketTables[s.ticketId]);
                    }
                }
                if (s.partyId && !!addPartyTables?.[s.partyId]) {
                    dayTables = (dayTables ? dayTables + ' ' : '') + addPartyTables[s.partyId];
                }
                if (s.ticketId && !!addTicketTables?.[s.ticketId]) {
                    dayTables = (dayTables ? dayTables + ' ' : '') + addTicketTables[s.ticketId];
                }
                if (s.seating?.tables?.[a.dayNo] !== dayTables) {
                    return {...s, seating: cleanupSeating({...(s.seating || {}), tables: {...(s.seating?.tables || {}), [a.dayNo]: dayTables}})};
                }
                return s;
            });
        });
        setRewind({
            dayNo: a.dayNo,
            addPartyTables,
            removePartyTables,
            addTicketTables,
            removeTicketTables
        }, rewindType);

    }, [setRewind]);

    const rewindLastSeatingAction = useCallback((isRedo: boolean) => {
        (isRedo ? setSeatingRedoStack : setSeatingUndoStack)((actions) => {
            if (!actions.length) {
                return [];
            }
            const last = actions[actions.length - 1];
            if (last) {
                handleRewindSeatingAction(last, isRedo ? 'redo' : 'undo');
            }
            return actions.filter((a) => a !== last);
        });
    }, [handleRewindSeatingAction]);

    /**
     * Event handlers - moving parties
     */

    const handlePartyLabelMouseClick = useCallback((item: SeatingItem, e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.button === 2) {
            // right click
            return;
        }

        const extras = item.partyId ? getEligibleExtras(groups, item as JsonEventPartyInfo, eventDay.dayNo) : [];
        const otherTickets = item.tickets?.filter(et => et.ticketId !== item.ticketId);

        setSelectedItems((selected) => {
            const wasThere = selected?.find(s => areSeatingItemsEqual(s, item));
            const allOther = selected?.filter((other) => !areSeatingItemsEqual(other, item) && !(extras && extras.find((extra) => extra.partyId === other.partyId)));
            if (wasThere) {
                if (e.ctrlKey) {
                    return [...(allOther || []), ...(extras || [])];
                } else {
                    return allOther;
                }
            }
            if (e.ctrlKey) {
                return [item, ...extras, ...(otherTickets || []), ...(allOther || [])];
            }
            return [item, ...extras, ...(otherTickets || [])];
        });

    }, [groups, eventDay.dayNo]);


    const handlePartyHandleMouseDown = useCallback((item: SeatingItem, e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.button === 2) {
            // right click
            return;
        }

        setSelectedItems((selected) => {
            const isSelected = !!selected?.find((other) => areSeatingItemsEqual(other, item));

            const itemsToDrag = isSelected
                ? selected
                : (item.partyId
                        ? [item, ...getEligibleExtras(groups, (item as JsonEventPartyInfo), eventDay.dayNo)]
                        : [item, ...(item.tickets?.filter(et => et.ticketId !== item.ticketId) || [])]
                );

            if (itemsToDrag) {
                activeItemStartDrag(itemsToDrag, e)
            }

            return selected;
        });

    }, [groups, eventDay.dayNo, activeItemStartDrag]);

    const handleWrapperMouseUp = useCallback((e: MouseEvent) => {
        if (e.button === 2) {
            // right click
            // setSelectedItems(undefined);
            cancelAllPendingActions();
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const ghostItems = ghostItemsRef.current;
        if (eventDay.dayNo && ghostItems) {
            if ((e.target as any)?.['nodeName'] === 'use') {
                const use = e.target as SVGUseElement;
                const tableName = use.getAttribute('data-text');
                const tableCapacity = use.getAttribute('data-capacity');
                let remainingCapacity: number | undefined = undefined;
                if (tableName && !!tableCapacity) {
                    // valid drop, assign
                    const partyTables: SeatingMap = {};
                    const ticketTables: SeatingMap = {};
                    const doneChunks: number[] = [];
                    const skipChunks: number[] = [];
                    ghostItems.forEach((ep) => {
                        if (ep.partyId) {
                            if (e.shiftKey) {
                                // autofill (parties only)
                                if (remainingCapacity === undefined) {
                                    remainingCapacity = parseInt(tableCapacity)
                                        - seating.filter((ep) => isAnyTableMatch(ep.seating?.tables?.[eventDay.dayNo || 0], tableName)).length;
                                }
                                if (remainingCapacity <= 0) {
                                    return;
                                }

                                const chunkNo = (ep as JsonEventPartyInfoWithChunkId).chunkNo;
                                if (chunkNo && doneChunks.indexOf(chunkNo) < 0) {
                                    doneChunks.push(chunkNo);
                                    const requiredCapacity = ghostItems?.filter(other => (other as JsonEventPartyInfoWithChunkId).parents?.find(p => p.partyId === ep.partyId))?.length || 1;
                                    if (requiredCapacity > remainingCapacity) {
                                        skipChunks.push(chunkNo);
                                    }
                                }
                                if (chunkNo && skipChunks.indexOf(chunkNo) >= 0) {
                                    return;
                                }

                                remainingCapacity--;
                            }
                            partyTables[ep.partyId] = tableName;
                        }
                        if (ep.ticketId) {
                            ticketTables[ep.ticketId] = tableName;
                        }
                    });
                    if (!!Object.keys(partyTables).length || !!Object.keys(ticketTables).length) {
                        actionAddSeating(eventDay.dayNo, partyTables, ticketTables, e.ctrlKey);
                    }
                    if (e.ctrlKey) {
                        // keep selection
                    } else if (e.shiftKey) {
                        // remove just autofilled (if any)
                        setSelectedItems((selected) => {
                            const s = selected?.filter(s => !(s.partyId && partyTables[s.partyId]));
                            return !!s?.length ? s : undefined;
                        });
                        setDraggedItems((dragged) => {
                            const s = dragged?.filter(s => !(s.partyId && partyTables[s.partyId]));
                            return !!s?.length ? s : undefined;
                        });
                        ghostItemsRef.current = ghostItemsRef.current?.filter(s => !(s.partyId && partyTables[s.partyId]));
                        if (!ghostItemsRef.current?.length) {
                            cancelAllPendingActions();
                        }
                    } else {
                        // standard clicking, clear brush
                        setSelectedItems(undefined);
                    }
                }
            }

            if (!e.ctrlKey && !e.shiftKey) {
                cancelAllPendingActions();
            }
        }

    }, [eventDay.dayNo, actionAddSeating, cancelAllPendingActions, seating]);

    const handleWrapperMouseMove = useCallback((e: MouseEvent) => {
        if (!wrapperRef.current || !(ghostItemsRef.current || brush?.svgElement)) {
            return;
        }

        if (ghostItemsRef.current && ghostRef.current?.style) {
            ghostRef.current.style.top = (e.clientY + 10) + 'px';
            ghostRef.current.style.left = (e.clientX + 10) + 'px';

            // let isValidDrop = false;
            // if ((e.target as any)?.['nodeName'] === 'use') {
            //     const use = e.target as SVGUseElement;
            //     if (!!use.getAttribute('data-capacity') && !!use.getAttribute('data-text')) {
            //         isValidDrop = true;
            //     }
            // }
        }

    }, [brush]);

    const handleMagicWand = useCallback((items: SeatingItem[], e: MouseEvent) => {
        activeItemStartDrag(items, e);
    }, [activeItemStartDrag]);

    const handleSeatingKeyDown = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'Escape':
                setSelectedItems(undefined);
                setDraggedItems(undefined);
                ghostItemsRef.current = undefined;
                break;
        }

        switch (e.key) {
            case 'z':
            case 'y':
                if (e.ctrlKey) {
                    rewindLastSeatingAction(e.key === 'y');
                    e.preventDefault();
                }
                break;

        }

    }, [rewindLastSeatingAction]);

    const handleEditShapeOpen = useCallback((shapeCode: string) => {
        setEditShapeCode(shapeCode);
        setBrush(undefined);
    }, []);

    const handleEditShapeClose = useCallback((newShapes?: JsonFloorShape[]) => {
        setEditShapeCode(undefined);
        if (newShapes) {
            setShapes(newShapes);
        }
    }, []);

    const tables = useMemo(() => {
        const tables: TableInfo[] = [];
        eventFloorDay?.floorData?.items?.forEach((item) => {
            if (!item.text) {
                return;
            }
            tables.push({
                id: item.id,
                tableName: item.text,
                capacity: shapes?.find(s => s.shapeCode === item.shapeCode)?.capacity || 0
            });
        });
        return tables;
    }, [eventFloorDay, shapes]);

    const draggedTooltips = useMemo(() => {
        if (!draggedItems?.length) {
            return null;
        }
        // return tooltipNames(draggedItems);
        const draggedGroups: GroupType[] = [];
        groups?.forEach((group) => {
            const items = group.items?.filter((item) => !!draggedItems.find((ep) => !!ep.partyId && ep.partyId === item.partyId));
            const tickets = group.tickets?.filter((item) => draggedItems.find((ep) => !!ep.ticketId && ep.ticketId === item.ticketId));
            if (items || tickets) {
                draggedGroups.push({
                    ...group,
                    items,
                    tickets
                });
            }
        });

        return <SeatingPlanGroups
            dayNo={eventFloorDay?.dayNo || 0}
            groups={draggedGroups}
            seating={seating}
            groupsViewMode={'hover'}
            tables={tables}
        />
    }, [draggedItems, groups, eventFloorDay?.dayNo, seating, tables]);

    const {dirtyValues, otherFloorsDirtyValues} = useMemo(() => {
        return {
            dirtyValues: eventFloorDay?.eventFloorDayId ? dirtyValuesByFloor[eventFloorDay.eventFloorDayId] : undefined,
            otherFloorsDirtyValues: Object.values(dirtyValuesByFloor)
                .filter(dv => dv.dayNo === eventFloorDay?.dayNo && dv.eventFloorDayId !== eventFloorDay?.eventFloorDayId)
                .map((dv => dv as FloorDirtyValues))
        }
    }, [dirtyValuesByFloor, eventFloorDay?.eventFloorDayId, eventFloorDay?.dayNo]);

    const handleSetDirtyValues = useCallback((values: FloorDirtyValues) => {
        setDirtyValuesByFloor((floors) => {
            if (eventFloorDay?.eventFloorDayId) {
                floors[eventFloorDay.eventFloorDayId] = values;
            }
            setShapeStats(calcShapeStats(floors));
            return floors;
        })
    }, [eventFloorDay?.eventFloorDayId, calcShapeStats]);

    useEffect(() => {
        handleFetchFloor().then();
    }, [handleFetchFloor, tab]);

    useEffect(() => {
        if (tab !== 'seating') {
            return;
        }
        window.addEventListener('keydown', handleSeatingKeyDown);
        return () => {
            window.removeEventListener('keydown', handleSeatingKeyDown);
        }
    }, [tab, handleSeatingKeyDown]);

    if (eventFloorDays === undefined) {
        return null;
    }
    if (!eventFloorDay?.eventFloorDayId || !eventFloorDay.dayNo || !eventFloorDays || !dirtyValues) {
        // nothing available
        return null;
    }

    return <Box ref={wrapperRef}
        onMouseMove={tab !== 'seating' ? undefined : handleWrapperMouseMove}
        onMouseUp={tab !== 'seating' ? undefined : handleWrapperMouseUp}
        className={tab + '-wrapper'}>
        <Grid container sx={{marginTop: '10px'}} columns={13}>
            <Grid item xs={2}>
                {tab === 'seating' && <ButtonGroupPlain currentValue={groupsViewMode} options={groupsViewModeOptions} name={'viewMode'} onChange={(v) => {
                    if (!v) {
                        return;
                    }
                    setGroupsViewMode(v);
                }}/>}
            </Grid>
            <Grid item sm={3} xs={12}>
                <ButtonGroupPlain currentValue={eventFloorDay.eventFloorId} options={eventFloorOptions} name={'eventFloor'} onChange={(eventFloorId) => {
                    if (!eventFloorId) {
                        return;
                    }
                    setEventFloorDay(eventFloorDays?.find((efd) => efd.eventFloorId === eventFloorId && efd.eventDayId === eventFloorDay?.eventDayId));
                }}/>
            </Grid>
            <Grid item sm={1} xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link color='primary' underline={'hover'} to={'/plan/' + eventDay.dayDate + '/' + eventFloorDay.eventFloorDayId} target={'_blank'} component={RouterLink}
                    sx={{
                        '& svg': {
                            fontSize: '100%'
                        }
                    }}>
                    <OpenInNewOutlined/> {t('Plánek')}
                </Link>
            </Grid>
            <Grid item sm={7} xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <ButtonGroupPlain currentValue={eventFloorDay.eventDayId} options={eventDayOptions} name={'eventDay'} onChange={(eventDayId) => {
                    if (!eventDayId) {
                        return;
                    }
                    setEventFloorDay(eventFloorDays?.find((efd) => efd.eventDayId === eventDayId && efd.eventFloorId === eventFloorDay?.eventFloorId));
                    setSelectedItems(undefined);
                    setDraggedItems(undefined);
                    ghostItemsRef.current = undefined;
                }}/>
            </Grid>
            <Grid item xs={2} sx={{marginTop: '-3px', zIndex: '2'}}>
                {tab === 'floors' && <SeatingPlanToolbar
                    shapeStats={shapeStats.days[eventFloorDay.dayNo]}
                    shapes={shapes}
                    onSetBrush={setBrush}
                    brush={brush}
                    onEditShape={handleEditShapeOpen}
                />}
                {tab === 'seating' && !!eventDay.dayNo &&
                    <SeatingPlanGroups dayNo={eventDay.dayNo}
                        groups={filteredGroups}
                        onLabelMouseClick={handlePartyLabelMouseClick}
                        onHandleMouseDown={handlePartyHandleMouseDown}
                        selectedItems={selectedItems}
                        seating={seating}
                        groupsViewMode={groupsViewMode}
                        actions={{
                            actionAddSeating,
                            actionDeleteSeating,
                            onMagicWand: handleMagicWand,
                            rewindLastSeatingAction
                        }}
                        tables={tables}
                    />}
            </Grid>
            <Grid item xs={11} sx={{marginTop: '-3px', zIndex: '2'}}>
                <SeatingPlanCanvas
                    key={eventFloorDay.eventFloorDayId}
                    eventFloorDay={eventFloorDay}
                    eventFloor={eventFloor}
                    eventDay={eventDay}
                    shapes={shapes}
                    onSetBrush={setBrush} brush={brush}
                    dirtyValues={dirtyValues}
                    otherFloorsDirtyValues={otherFloorsDirtyValues}
                    onSetDirtyValues={handleSetDirtyValues}
                    onSaveFloor={tab === 'floors' && !editShapeCode ? handleSaveFloor : undefined}
                    clipboard={clipboard}
                    setClipboard={setClipboard}
                    seating={seating}
                    originalSeating={originalSeating.current}
                    onSaveSeating={tab === 'seating' ? handleSaveSeating : undefined}
                    groups={groups}
                    seatingUndoStack={seatingUndoStack}
                    seatingRedoStack={seatingRedoStack}
                    seatingSelectedItems={selectedItems}
                    onSeatingLabelMouseClick={handlePartyLabelMouseClick}
                    onSeatingHandleMouseDown={handlePartyHandleMouseDown}
                    seatingActions={{
                        actionAddSeating,
                        actionDeleteSeating,
                        onMagicWand: handleMagicWand,
                        rewindLastSeatingAction
                    }}
                    tables={tables}
                />
            </Grid>
        </Grid>
        <Box component={Paper} id={'seating-ghost'} ref={ghostRef} sx={ghostStyle} style={ghostInitialStyle}>
            {draggedTooltips}
        </Box>
        {!!editShapeCode && <SeatingShapeModal
            eventId={event.eventId!}
            shapeCode={editShapeCode}
            shapeStats={shapeStats.total[editShapeCode]}
            onCancel={handleEditShapeClose}
            onSave={handleEditShapeClose}/>}
    </Box>
}

export default SeatingPlan;
