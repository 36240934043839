import format from 'date-fns/format';
import localeCS from 'date-fns/locale/cs';
import localeEN from 'date-fns/locale/en-US';
import {differenceInCalendarDays} from "date-fns";

const dateLocales: { [lng: string]: any } = {
    cs: localeCS,
    en: localeEN,
};

let locale: Locale = dateLocales.cs;

export function setDateLocale(lng: string) {
    locale = dateLocales[lng] || dateLocales.en;
}

export function dateToGui(val: Date | string | undefined) {
    return dateToGuiAs(val, 'P'); // 'd.M.yyyy'
}

export function datetimeToGui(val: Date | string | undefined, time?: string) {
    if (!val) {
        return '';
    }
    let v;
    if (time && time.length < 5 && time.indexOf(':') >= 0) {
        const p = time.split(':', 2);
        v = val
            + ' ' + ('00' + p[0]).substring(('00' + p[0]).length - 2)
            + ':' + ('00' + p[1]).substring(('00' + p[1]).length - 2);
    } else {
        v = val + (time ? ' ' + time : '');
    }
    return dateToGuiAs(v, 'P HH:mm'); // 'd.M.yyyy HH:mm'
}

export function dateToGuiAs(val: Date | string | undefined, asFormat: string, adaptive?: boolean) {
    if (!val) {
        return '';
    }
    const d: Date = typeof val === 'string' ? new Date(val) : val;
    if (isNaN(d as any as number)) {
        return val as string;
    }
    if (adaptive) {
        if (d.getFullYear() === new Date().getFullYear()) {
            return format(d, 'd.M.', {locale});
        } else {
            return format(d, 'Y', {locale});
        }
    }
    return format(d, asFormat, {locale}); // 'd.M.yyyy'
}

export function normalizeDatetime(val: Date | string | undefined) {
    return dateToGuiAs(val, 'yyyy-MM-dd HH:mm');
}

export function dateDiff(a: Date | string | undefined, b: Date | string | undefined, exclusive?: boolean): number {
    if (!a || !b) {
        return 0;
    }
    const da: Date = typeof a === 'string' ? new Date(a) : a;
    const db: Date = typeof b === 'string' ? new Date(b) : b;
    return differenceInCalendarDays(db, da) + (exclusive ? 0 : 1);
}

export function maxDateAsString(a: Date | string, b: Date | string | undefined): string {
    const na = typeof a === 'string' ? a : dateToGuiAs(a, 'yyyy-MM-dd');
    if (!b) {
        return na;
    }
    const nb = typeof b === 'string' ? b : dateToGuiAs(b, 'yyyy-MM-dd');
    return na > nb ? na : nb;
}

export function getDayOfWeekName(dow: number) {
    return locale.localize?.day(dow === 7 ? 0 : dow);
}
