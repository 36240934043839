import {JsonEventFloorDayInfo, JsonFloorItem, JsonFloorShape} from "../../generated-api";
import {GroupType} from "../../pages/EventGuestsPage";
import * as React from "react";
import {MouseEvent, useMemo, useState} from "react";
import {Box, Button, Tooltip} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import {SeatingPlanGroups} from "./SeatingPlanGroups";
import {SeatingActions, SeatingItem, TableInfo} from "./svgUtils";

export const SeatingBadge = ({
    item,
    shape,
    seatingItems,
    groups,
    eventFloorDay,
    selectedSeatingItems,
    onSeatingLabelMouseClick,
    onSeatingHandleMouseDown,
    seatingActions,
    tables,
    readonly
}: {
    item: JsonFloorItem,
    shape?: JsonFloorShape,
    seatingItems: SeatingItem[],
    groups?: GroupType[],
    eventFloorDay?: JsonEventFloorDayInfo,
    selectedSeatingItems?: SeatingItem[],
    onSeatingLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
    onSeatingHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void
    seatingActions?: SeatingActions,
    tables?: TableInfo[],
    readonly?: boolean
}) => {
    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    const [title, colors, isTickets] = useMemo(() => {
        const colors: string[] = [];
        let isTickets = false;
        const filteredGroups: GroupType[] = [];
        groups?.forEach((group) => {
            const items = group.items?.filter((item) => !!seatingItems.find((ep) => !!ep.partyId && ep.partyId === item.partyId));

            if (items?.length) {
                group.tags?.forEach((o) => {
                    if (!o.value || !o.params?.color) {
                        return;
                    }
                    const color = '' + o.params.color;
                    if (colors.indexOf(color) >= 0) {
                        return;
                    }
                    colors.push(color);
                });
            }
            const tickets = group.tickets?.filter((item) => seatingItems.find((ep) => ep.ticketId === item.ticketId));
            if (tickets?.length) {
                isTickets = true;
            }
            if (items || tickets) {
                filteredGroups.push({
                    ...group,
                    items,
                    tickets
                });
            }
        });

        if (filteredGroups && seatingActions && eventFloorDay?.dayNo) {
            const el = <div style={{position: 'relative', pointerEvents: 'all'}}>
                {clicked && <Button onClick={() => setClicked(false)} variant={'text'} size={'small'} sx={{
                    position: 'absolute',
                    top: '4px',
                    right: '10px',
                    padding: '3px',
                    fontSize: '90%'
                }}><CloseOutlined/></Button>}
                <SeatingPlanGroups
                    dayNo={eventFloorDay.dayNo}
                    groups={filteredGroups}
                    onLabelMouseClick={onSeatingLabelMouseClick}
                    onHandleMouseDown={onSeatingHandleMouseDown}
                    selectedItems={selectedSeatingItems}
                    seating={seatingItems}
                    groupsViewMode={clicked && !readonly ? 'tooltip' : 'hover'}
                    actions={seatingActions}
                    tables={tables}
                />
            </div>;
            return [el, colors, isTickets];
        }

        return [null, colors, isTickets];
    }, [clicked, eventFloorDay, seatingActions, tables, groups, onSeatingLabelMouseClick, onSeatingHandleMouseDown, selectedSeatingItems, seatingItems, readonly]);

    return <foreignObject id={'item-' + item.id + '-parties'}
        width={14}
        height={10}
        style={{
            fontSize: '6px',
            userSelect: 'none',
            textAlign: 'left',
            verticalAlign: 'middle',
            pointerEvents: 'none'
            // background: 'rgba(255, 0, 0, .2)',
        }}
        transform={'translate('
            + Math.max(0, (item.x - ((shape?.text?.y && shape.text.y < 0 && shape.text.h && shape.text.h <= 3 && item.a && item.a >= 90) ? 2.5 : 5)))
            + ' '
            + (item.y + ((shape?.text?.y && shape.text.y < 0 && shape.text.h && shape.text.h <= 3 && (!item.a || item.a < 90)) ? -0.5 : 2))
            + ') scale(0.5 0.5)'}
    >
        <Tooltip title={<div>{title}</div>}
            PopperProps={{
                sx: {
                    pointerEvents: 'none',
                    '& .MuiTooltip-tooltip': {
                        width: '230px',
                        background: 'none',
                        // fontSize: '100%',
                        '& .MuiPaper-root': {
                            background: 'lightgoldenrodyellow',
                            pointerEvents: 'all'
                        }
                    },
                }
            }}
            open={open || clicked}
            disableHoverListener
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <Box sx={{
                background: clicked
                    ? (theme) => theme.palette['info'].main
                    : isTickets
                        ? 'repeating-linear-gradient(135deg, #ffcccc, #ffcccc 50%, ' + (colors?.[0] || '#fff') + ' 50%, ' + (colors?.[0] || '#fff') + ' 100%)'
                        : (colors.length
                            ? (colors.length > 1
                                // ? 'linear-gradient(90deg, ' + colors[0] + ' 50%, ' + colors[1] + ' 50%)'
                                ? 'repeating-linear-gradient(90deg, ' + colors.map((c, i) => c
                                + ' ' + Math.round(100 / colors.length) * i + '%'
                                + ' ' + Math.round(100 / colors.length) * (i + 1) + '%').join(', ') + ')'
                                : colors[0])
                            : '#fff'),
                color: clicked
                    ? (theme) => theme.palette.secondary.contrastText
                    : 'inherit',
                borderRadius: '5px',
                border: (theme) => '1px solid ' + theme.palette[(isTickets
                    ? 'info'
                    : (seatingItems.length > (shape?.capacity || 0) ? 'error' : 'success'))].main,
                display: 'inline-block',
                padding: '0 1px',
                minWidth: '4px',
                textAlign: 'center',
                cursor: 'pointer',
                pointerEvents: 'all'
            }} onClick={() => setClicked(c => !c)}>{seatingItems.length}</Box>
            {/*<Fab color="primary" onClick={() => setOpen(false)}>x</Fab>*/}
        </Tooltip>
    </foreignObject>
}
