import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import {useCallback, useState} from "react";
import PartyImportForm from "../components/party/PartyImportForm";
import {ImportAction} from "../model/form";
import {Breakpoint} from "@mui/system";
import {EventPartyImportResult} from "../model/party";

const PartyImportModal = (props: ImportAction<EventPartyImportResult, any>) => {

    const t = useAppTranslation();

    const [maxWidth, setMaxWidth] = useState<Breakpoint>('xl');

    const onSetMaxWidth = useCallback((maxWidth: Breakpoint) => setMaxWidth(maxWidth), []);

    return (
        <FormModal title={!!props.filter.eventId ? t('Importovat nové osoby do události') : t('Importovat nové osoby')} onCancel={props.onCancel} maxWidth={maxWidth}>
            <PartyImportForm {...props} onSetMaxWidth={onSetMaxWidth}/>
        </FormModal>
    );
}

export default PartyImportModal;
