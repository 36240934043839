/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonInviteData,
    JsonInviteDataFromJSON,
    JsonInviteDataFromJSONTyped,
    JsonInviteDataToJSON,
} from './JsonInviteData';

/**
 * 
 * @export
 * @interface JsonInvite
 */
export interface JsonInvite {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonInvite
     */
    allowedActions?: Array<JsonInviteAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonInvite
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    hstRowNo?: number;
    /**
     * 
     * @type {JsonInviteData}
     * @memberof JsonInvite
     */
    inviteData?: JsonInviteData;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    inviteId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    inviteType?: JsonInviteInviteTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInvite
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInvite
     */
    isHstDelete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    processNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    replyUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    status?: JsonInviteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInvite
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInvite
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonInviteAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteInviteTypeEnum {
    Guest = 'GUEST',
    Organizer = 'ORGANIZER',
    Supplier = 'SUPPLIER',
    UpdateOrg = 'UPDATE_ORG',
    TravelOrg = 'TRAVEL_ORG',
    CompOrg = 'COMP_ORG',
    SafetyOrg = 'SAFETY_ORG'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}

export function JsonInviteFromJSON(json: any): JsonInvite {
    return JsonInviteFromJSONTyped(json, false);
}

export function JsonInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'hstRowNo': !exists(json, 'hstRowNo') ? undefined : json['hstRowNo'],
        'inviteData': !exists(json, 'inviteData') ? undefined : JsonInviteDataFromJSON(json['inviteData']),
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'inviteType': !exists(json, 'inviteType') ? undefined : json['inviteType'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isHstDelete': !exists(json, 'isHstDelete') ? undefined : json['isHstDelete'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'processNote': !exists(json, 'processNote') ? undefined : json['processNote'],
        'replyUntil': !exists(json, 'replyUntil') ? undefined : json['replyUntil'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonInviteToJSON(value?: JsonInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'groupId': value.groupId,
        'hstRowNo': value.hstRowNo,
        'inviteData': JsonInviteDataToJSON(value.inviteData),
        'inviteId': value.inviteId,
        'inviteType': value.inviteType,
        'isActive': value.isActive,
        'isHstDelete': value.isHstDelete,
        'partyId': value.partyId,
        'processNote': value.processNote,
        'replyUntil': value.replyUntil,
        'status': value.status,
        'token': value.token,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

