import {Button, Chip, CircularProgress, Container, Grid, LinearProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip} from '@mui/material';
import * as React from 'react';
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {TAppFunction, useAppTranslation} from '../services/i18n';
import {fetchEventPartiesOnline} from '../store/eventParties';
import PageHeader from '../components/layout/PageHeader';
import {GetEventPartyListUsingGETStatusesEnum, GetEventTicketListUsingGETStatusesEnum, JsonContingentInfo, JsonEvent, JsonEventDayInfo, JsonEventParty, JsonEventPartyInfo, JsonEventPartyInfoInviteStatusEnum, JsonEventPartyInfoPartyTypeEnum, JsonEventPartyInfoStatusEnum, JsonEventPartyMassActionRequestActionEnum, JsonEventTicketInfo, JsonEventTicketInfoTicketTypeEnum, JsonEventTicketMassActionRequestActionEnum, JsonEventTicketStatusEnum, JsonEventTicketTicketTypeEnum,} from '../generated-api';
import {createCol, DataGrid, DataGridCol, localCompare} from "../components/DataGrid";
import {ItemsState, useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectCodebooks, selectContingents} from "../store/selectors";
import {createOption, FAKE_VALUE_ALL, FAKE_VALUE_EMPTY, FAKE_VALUE_RESET} from "../model/form";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {fetchEventDays} from "../store/eventDays";
import {FIXED_EVENT_DAY_NOS, FIXED_INVITE_TAG_ID, FIXED_VIP_TAG_ID, LocalCodebookItem} from "../store/codebooks";
import {dateToGuiAs} from "../helpers/date";
import {mergeEventDays, PartyMassActionValues, partyName} from "../model/party";
import FormContainer from "../components/form/FormContainer";
import {ButtonGroupField, ButtonGroupPlain} from "../components/form/ButtonGroupField";
import {FormikProps, useFormikContext} from "formik";
import {fetchContingents} from "../store/contingents";
import {GroupsRounded, HowToRegRounded, QuestionMarkRounded} from "@mui/icons-material";
import {fetchEvent, saveEvent} from "../store/events";
import PartyMassActionModal from "./PartyMassActionModal";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {AuthState} from "../store/auth";
import SeatingPlan, {eventDayTitle} from "../components/seating/SeatingPlan";
import {EventFilter} from "../components/event/EventFilter";
import {fetchEventTicketsOnline, massEventTicket} from "../store/eventTickets";
import {SeatingPlanGroups} from "../components/seating/SeatingPlanGroups";
import {parseTableNames, SeatingItem} from "../components/seating/svgUtils";

export type AreaType = 'Z' | 'R';

type TabType = 'model' | 'detail' | 'listZ' | 'listR' | 'floors' | 'seating';

const showUnconfirmedOptions = [
    createOption(true, 'Potenciální bez odpovědi', undefined, <QuestionMarkRounded/>),
    // createOption(false, 'Nezahrnovat'),
];

const showContingentsOptions = [
    createOption(true, 'Potenciální kontingenty', undefined, <GroupsRounded/>),
    // createOption(false, 'Nezahrnovat'),
];

const AreaTypeColors = {
    Z: 'var(--color-info)',
    R: 'var(--color-error)',
}

export type GroupType = {
    groupKey: string,
    groupOrder: number,
    tagIds?: number[],
    tags?: LocalCodebookItem[],
    items?: JsonEventPartyInfo[],
    title?: string,
    contingent?: string,
    separator?: boolean,
    dayTotals?: { [key in number]: { [key in AreaType]: number } },
    selectedArea?: AreaType,
    isTickets?: boolean,
    tickets?: JsonEventTicketInfo[],
}

type GroupTypeWithItems = GroupType & { items: JsonEventPartyInfo[] };

const renderName = (ep: JsonEventPartyInfo | JsonEventTicketInfo, i: number, dayNo: number) => {
    const table = !!ep.seating?.tables?.[dayNo]
        ? <code> {ep.seating?.tables?.[dayNo]}</code>
        : null;
    if (ep.contingentOwner && ep.contingentOwner !== 'LOC') {
        return <small key={i}><em>{partyName(ep)} ({ep.contingentOwner})</em>{table}</small>;
    }
    if ('extraEventParties' in ep && ep.extraEventParties?.length) {
        return <small key={i}><strong>{partyName(ep)}</strong>{table}</small>;
    }
    return <small key={i}>{partyName(ep)}{table}</small>;
}

export const tooltipNames = (items: (JsonEventPartyInfo | JsonEventTicketInfo)[]) => {
    const unique: { [key in string]: number } = {};
    items.forEach((ep) => {
        const n = partyName(ep);
        if (unique[n]) {
            unique[n]++;
        } else {
            unique[n] = 1;
        }
    })
    return Object.keys(unique).map((n, i) => <div key={i}>{(unique[n] > 1 ? (unique[n] + 'x ') : '') + n}</div>)
}

const renderEventDayNumber = (group: GroupType, dayNo: number, tab: TabType, columns?: number) => {
    const dayItems = group.isTickets
        ? (group.tickets?.filter((ep) => ep.eventDays?.[dayNo - 1] === '1') || [])
        : (group.items?.filter((ep) => ep.eventDays?.[dayNo - 1] === '1') || []);

    let dayTotals: GroupType['dayTotals'];
    let tooltips: {
        Z: (JsonEventPartyInfo | JsonEventTicketInfo)[],
        R: (JsonEventPartyInfo | JsonEventTicketInfo)[]
    } = {Z: [], R: []};
    if (group.groupKey === 'otherZR' || group.groupKey === 'fixedExtra' || (!(tab === 'model') && !group.dayTotals)) {
        const t = {Z: 0, R: 0};
        dayItems.forEach((ep) => {
            if (ep.areas && ep.areas.indexOf('R') >= 0) {
                t['R'] += 1;
                tooltips.R.push(ep);
            } else if (ep.areas && ep.areas.indexOf('Z') >= 0) {
                t['Z'] += 1;
                tooltips.Z.push(ep);
            }
        });
        dayTotals = {[dayNo]: t};
    } else {
        dayTotals = group.dayTotals;
    }

    if (dayTotals) {
        const z = <span style={{color: AreaTypeColors.Z}}>{dayTotals[dayNo]?.['Z']}</span>;
        const r = <span style={{color: AreaTypeColors.R}}>{dayTotals[dayNo]?.['R']}</span>;

        if ((tab === 'listR' || tab === 'listZ') && group.groupKey !== 'unresolved') {
            const list = (!!group.isTickets ? [] : (tab === 'listZ' ? tooltips.Z : tooltips.R));
            const cols = [];
            const len = Math.max(4, Math.ceil(list.length / (columns || 2)));
            for (let i = 0; i < (columns || 2); i++) {
                cols.push(<Grid key={i} item xs={1}>
                    {list.slice(i * len, (i + 1) * len).map((item, i) => renderName(item, i, dayNo))}
                </Grid>)
            }

            return <div className={'accred-list'}>
                {tab === 'listZ' ? z : r}
                <Grid container columns={cols.length}>
                    {cols}
                </Grid>
            </div>;
        }
        if ((tab === 'listR' || tab === 'listZ') && group.groupKey === 'unresolved') {
            return <div>
                {tab === 'listZ' ? z : r}
            </div>;
        }

        if (group.groupKey === 'vipSold') {
            return z;
        }

        if (group.isTickets) {
            // homogenous
            return <div>
                {tooltips.Z.length > 0
                    ? <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                        {tooltipNames(tooltips.Z)}
                    </div>}>{z}</Tooltip>
                    : null}
                {!!tooltips.Z.length && !!tooltips.R.length && <>&nbsp;/&nbsp;</>}
                {tooltips.R.length > 0
                    ? <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                        {tooltipNames(tooltips.R)}
                    </div>}>{r}</Tooltip>
                    : null}
            </div>;
        }

        return <div>
            {tooltips.Z.length > 0
                ? <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                    {tooltipNames(tooltips.Z)}
                </div>}>{z}</Tooltip>
                : z}
            &nbsp;/&nbsp;
            {tooltips.R.length > 0
                ? <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                    {tooltipNames(tooltips.R)}
                </div>}>{r}</Tooltip>
                : r}
            {group.groupKey === 'areas'
                ? <div style={{paddingTop: '5px', fontWeight: 'normal'}}>{(dayTotals[dayNo]?.['Z'] || 0) + (dayTotals[dayNo]?.['R'] || 0)}</div>
                : null}
        </div>;
    }

    const tooltip = dayItems.length ? tooltipNames(dayItems) : null;

    let value;
    if (group.selectedArea) {
        value = <span style={{color: AreaTypeColors[group.selectedArea]}}>{dayItems.length}</span>;
    } else {
        value = dayItems.length;
    }
    return !!tooltip
        ? <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
            {tooltip}
        </div>}><span>{value}</span></Tooltip>
        : value;
}

type AreaButtonsProps = {
    group?: GroupType,
    onMassAction?: (action: JsonEventPartyMassActionRequestActionEnum, partyIds: number[], selectedArea: AreaType) => void
}

const AreaButtons = (props: AreaButtonsProps) => {
    const {group, onMassAction} = props;

    const t = useAppTranslation();
    const {setFieldValue, values} = useFormikContext<GroupType[]>();
    const [toggle, setToggle] = useState<AreaType | undefined>(undefined);

    if (!group) {
        return <ButtonGroupPlain currentValue={undefined} name={'toggleAllAreas'} options={areaOptions} onChange={(v) => {
            values?.forEach((value, i) => {
                if (value.groupKey === 'unresolved' || value.dayTotals) {
                    return;
                }
                setFieldValue('[' + i + '].selectedArea', toggle === v ? undefined : v);
            });
            setToggle(toggle === v ? undefined : v);
        }}/>;
    }

    if (group.groupKey === 'unresolved' || group.dayTotals) {
        return null;
    }

    const isTickets = !!group.isTickets;
    const partyIds = group?.items?.filter((ep) => !!ep.partyId).map((ep) => ep.partyId);
    const activePartyIds = group?.items?.filter((ep) => !!ep.partyId && ep.status === JsonEventPartyInfoStatusEnum.Active).map((ep) => ep.partyId) || [];
    if (!(partyIds && partyIds.length > 0) && !isTickets) {
        return null;
    }

    if (group.groupKey === 'fixedExtra') {
        const partyIdsR = group?.items?.filter((ep) => activePartyIds.indexOf(ep.partyId) > 0 && ep.areas && ep.areas.indexOf('R') >= 0).map((ep) => ep.partyId) || [];
        const partyIdsZ = group?.items?.filter((ep) => activePartyIds.indexOf(ep.partyId) > 0 && partyIdsR.indexOf(ep.partyId) < 0).map((ep) => ep.partyId) || [];

        return <>
            {partyIdsZ.length > 0 && <Button title={t('Zahájit akreditaci jako {{area}}', {area: 'Z'})} onClick={() => {
                if (onMassAction) {
                    onMassAction(JsonEventPartyMassActionRequestActionEnum.Accred, partyIdsZ as number[], 'Z');
                }
            }} sx={{padding: '2px !important'}}><HowToRegRounded/> <span>Z</span></Button>}
            {partyIdsZ.length > 0 && partyIdsR.length > 0 && <span>&nbsp;/&nbsp;</span>}
            {partyIdsR.length > 0 && <Button title={t('Zahájit akreditaci jako {{area}}', {area: 'R'})} onClick={() => {
                if (onMassAction) {
                    onMassAction(JsonEventPartyMassActionRequestActionEnum.Accred, partyIdsR as number[], 'R');
                }
            }} sx={{padding: '2px !important'}}><HowToRegRounded/> <span>R</span></Button>}
        </>;
    }

    const index = values?.findIndex((g) => g.groupKey === group.groupKey);
    return <>
        <ButtonGroupField name={'[' + index + '].selectedArea'} options={areaOptions}/>
        {!!group.selectedArea && !!onMassAction && !isTickets && activePartyIds.length > 0 &&
            <Button title={t('Zahájit akreditaci jako {{area}}', {area: group.selectedArea})} onClick={() => {
                if (group.selectedArea) {
                    onMassAction(JsonEventPartyMassActionRequestActionEnum.Accred, activePartyIds as number[], group.selectedArea);
                }
            }}
                sx={{position: 'absolute', marginLeft: '20px !important', padding: '2px !important'}}>
                <HowToRegRounded/> <span>{group.selectedArea}</span></Button>}
    </>;
}

const getRowClassNames = (item: GroupType) => {
    if (item.groupKey === 'unresolved') {
        return ['data-grid-total-row'];
    }
    if (item.groupKey === 'areas') {
        return ['data-grid-warning-row', 'data-grid-total-row'];
    }
    if (item.separator) {
        return ['data-grid-separator-row'];
    }
    return undefined;
}

const areaOptions = [
    createOption('Z', 'Z', 'Z, bez R', undefined, 'info'),
    createOption('R', 'R', 'Z + R', undefined, 'error')
]

export const renderGroupTitle = (item: GroupType, t: TAppFunction, withContingent?: boolean) => {
    if (item.title) {
        return item.title + (withContingent && item.contingent && item.contingent !== 'LOC' ? ' (' + item.contingent + ')' : '');
    }
    return !!item.tags?.length && item.tags?.length > 0
        ? <div className={'party-tags'}>{item.tags.map((tag) => {
            return <Chip key={tag.value} size={'small'}
                label={tag.label}
                style={{backgroundColor: (tag.params?.['color'] || 'inherit') as string}}/>
        })}</div>
        : <span title={item.groupKey}>{t('Ostatní hosté bez štítku')}</span>
}

type EventGuestsFormMatrixProps = {
    eventDays: JsonEventDayInfo[],
    tab: TabType,
    onMassAction: (action: JsonEventPartyMassActionRequestActionEnum, partyIds: number[], selectedArea: AreaType) => void
}

const TableGroups = ({groups, tableName, dayNo, partiesByTable, ticketsByTable}: {
    groups: GroupType[],
    tableName: string,
    partiesByTable: { [key in string]: JsonEventPartyInfo[] },
    ticketsByTable: { [key in string]: JsonEventTicketInfo[] },
    dayNo: number
}) => {
    const filteredGroups: GroupType[] = [];
    const seating: SeatingItem[] = [];
    groups?.forEach((group) => {
        if (group.groupKey === 'unresolved') {
            return;
        }
        const items = group.items?.filter((item) => !!partiesByTable[tableName]?.find((ep) => !!ep.partyId && ep.partyId === item.partyId));
        const tickets = group.tickets?.filter((item) => !!ticketsByTable[tableName]?.find((ep) => ep.ticketId === item.ticketId));
        if (items?.length || tickets?.length) {
            filteredGroups.push({
                ...group,
                items,
                tickets
            });
            if (items?.length) {
                seating.push(...items);
            }
            if (tickets?.length) {
                seating.push(...tickets);
            }
        }
    });
    if (!filteredGroups.length) {
        return null;
    }
    return <SeatingPlanGroups
        dayNo={dayNo}
        groups={filteredGroups}
        seating={seating}
        groupsViewMode={'list'}
        // tables={tables}
    />
}

const EventGuestsFormMatrix = (props: EventGuestsFormMatrixProps) => {
    const {eventDays, tab, onMassAction} = props;

    const t = useAppTranslation();
    const {values} = useFormikContext<GroupType[]>();
    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);

    const fixedEventDays = useMemo(() => {
        return eventDays?.filter((ed) => ed.dayNo && FIXED_EVENT_DAY_NOS.indexOf(ed.dayNo) >= 0);
    }, [eventDays]);

    const [dayNo, setDayNo] = useState(
        (fixedEventDays?.find(ed => ed.dayDate === configuration?.today) || fixedEventDays?.[0])?.dayNo)
    const [listDisplayMode, setListDisplayMode] = useState<'groups' | 'tables'>('groups');

    const cols = useMemo(() => {
        const cols: DataGridCol<GroupType, any>[] = [
            createCol('Seskupení hostů / vstupenek', 'tags', 100, 'Štítek nebo skupina', (_, item) => renderGroupTitle(item, t, true))
        ];
        if (!(tab === 'listR' || tab === 'listZ')) {
            cols.push({
                title: 'Osob',
                col: 'items',
                size: 50,
                align: 'center',
                renderValue: (_, group) => {
                    if (!group.items && !group.tickets) {
                        return null;
                    }
                    if (group.isTickets) {
                        return <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                            <table style={{textAlign: 'left'}}>
                                <tbody>{group.tickets?.map((et, i) => <tr key={i}>
                                        <th>{partyName(et)}</th>
                                        <td>{et.barcode}</td>
                                        <td>{et.eventDays}</td>
                                        <td>{et.formatCode}</td>
                                        <td>{et.areas}</td>
                                    </tr>
                                )}</tbody>
                            </table>
                        </div>} PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    maxWidth: '450px'
                                }
                            }
                        }}><span>{group.tickets?.length}</span></Tooltip>;
                    }
                    return <Tooltip title={<div style={{maxHeight: '500px', overflowY: 'auto'}}>
                        <table style={{textAlign: 'left'}}>
                            <tbody>{group.items?.map((ep, i) => <tr key={i}>
                                    <th>{partyName(ep)}</th>
                                    <td>{ep.eventDays}</td>
                                    <td>{ep.formatCode}</td>
                                    <td>{ep.areas}</td>
                                </tr>
                            )}</tbody>
                        </table>
                    </div>} PopperProps={{
                        sx: {
                            "& .MuiTooltip-tooltip": {
                                maxWidth: '450px'
                            }
                        }
                    }}><span>{group.items?.length}</span></Tooltip>;
                }
            });
        }
        const effEventDayNo = (tab === 'listR' || tab === 'listZ') ? dayNo : undefined;
        fixedEventDays.filter(ed => !effEventDayNo || ed.dayNo === effEventDayNo).forEach((ed) => {
            const dayNo = ed.dayNo || 0;
            cols.push({
                title: <span title={dateToGuiAs(ed.dayDate, 'eeee P')}>{dateToGuiAs(ed.dayDate, "eeeee d.M.")}</span>,
                col: 'tagIds',
                size: effEventDayNo ? 500 : ((tab === 'listR' || tab === 'listZ') ? 100 : 40),
                align: 'center',
                renderValue: (_, item) => {
                    return renderEventDayNumber(item, dayNo, tab, effEventDayNo ? 6 : 2);
                }
            });
        });
        if (!(tab === 'listR' || tab === 'listZ')) {
            cols.push({
                title: !(tab === 'model') ? '' : <AreaButtons/>,
                col: 'tagIds',
                size: 60,
                align: 'left',
                renderValue: (_, item) => !(tab === 'model') ? null : <AreaButtons group={item} onMassAction={onMassAction}/>
            });
        }
        return cols;

    }, [dayNo, tab, fixedEventDays, onMassAction, t]);

    const rows: GroupType[] = useMemo(() => {
        if (!values) {
            return [];
        }
        const areas: GroupType = {
            title: 'Celkem Z / R',
            groupKey: 'areas',
            groupOrder: -101,
            dayTotals: {}
        }
        eventDays.forEach((ed) => {
            const dayNo = ed.dayNo;
            if (!dayNo || !areas.dayTotals) {
                return;
            }

            const totals: { [key in AreaType]: number } = {
                'Z': 0,
                'R': 0
            };
            values.forEach((g) => {
                if (g.groupKey === 'otherZR' || g.groupKey === 'fixedExtra' || (!(tab === 'model') && g.groupKey === 'unresolved')) {
                    const done: number[] = [];
                    g.items?.filter((ep) => ep.eventDays?.[dayNo - 1] === '1')?.forEach((ep) => {
                        if (ep.eventPartyId && done.indexOf(ep.eventPartyId) >= 0) {
                            return;
                        }
                        if (ep.areas && ep.areas.indexOf('R') >= 0) {
                            totals['R'] += 1;
                        } else if (ep.areas && ep.areas.indexOf('Z') >= 0) {
                            totals['Z'] += 1;
                        }
                        if (ep.eventPartyId) {
                            done.push(ep.eventPartyId);
                        }
                    });

                } else if (g.selectedArea) {
                    if (g.isTickets) {
                        totals[g.selectedArea] += (g.tickets?.filter((et) => et.eventDays?.[dayNo - 1] === '1') || []).length;
                    } else {
                        totals[g.selectedArea] += (g.items?.filter((ep) => ep.eventDays?.[dayNo - 1] === '1') || []).length;
                    }
                } else if (g.isTickets) {
                    g.tickets?.filter((ep) => ep.eventDays?.[dayNo - 1] === '1')?.forEach((ep) => {
                        if (ep.areas && ep.areas.indexOf('R') >= 0) {
                            totals['R'] += 1;
                        } else if (ep.areas && ep.areas.indexOf('Z') >= 0) {
                            totals['Z'] += 1;
                        }
                    });

                } else {
                    totals['Z'] += g.dayTotals?.[dayNo]?.['Z'] || 0;
                    totals['R'] += g.dayTotals?.[dayNo]?.['R'] || 0;
                }
            })

            areas.dayTotals[dayNo] = totals;
        })

        return [...values, areas];

    }, [tab, eventDays, values]);

    const defaultState = useMemo(() => {
        return {filter: {}}
    }, []);

    const itemsState = useMemo(() => {
        return {items: rows, loading: false, count: 0};
    }, [rows]);

    const eventDayOptions = useMemo(() => {
        return fixedEventDays
            ?.map((ed) => createOption(ed.dayNo || 0, eventDayTitle(ed)));

    }, [fixedEventDays]);

    const listDisplayOptions = useMemo(() => {
        return [
            createOption('groups', 'Dle skupin'),
            createOption('tables', 'Dle stolů'),
        ];
    }, []);

    const seatingMatrix = useMemo(() => {
        if (tab !== 'listR' || !dayNo) {
            return null;
        }
        const partiesByTable: { [key in string]: JsonEventPartyInfo[] } = {};
        const ticketsByTable: { [key in string]: JsonEventTicketInfo[] } = {};
        values.forEach(g => {
            g.items?.forEach(ep => {
                if (ep.eventDays?.[dayNo - 1] !== '1') {
                    return;
                }
                parseTableNames(ep.seating?.tables?.[dayNo])?.forEach((tableName) => {
                    if (!partiesByTable[tableName]) {
                        partiesByTable[tableName] = [];
                    }
                    if (!partiesByTable[tableName].find(item => item.partyId === ep.partyId)) {
                        partiesByTable[tableName].push(ep);
                    }
                });
            });
            g.tickets?.forEach(et => {
                if (et.eventDays?.[dayNo - 1] !== '1') {
                    return;
                }
                parseTableNames(et.seating?.tables?.[dayNo])?.forEach((tableName) => {
                    if (!ticketsByTable[tableName]) {
                        ticketsByTable[tableName] = [];
                    }
                    if (!ticketsByTable[tableName].find(ticket => ticket.ticketId === et.ticketId)) {
                        ticketsByTable[tableName].push(et);
                    }
                });
            });
        });
        const tableNames = [...Object.keys(partiesByTable), ...Object.keys(ticketsByTable)]
            .sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1);

        const tableRows: [string, string, string, string, string][] = [];
        for (let i = 0; i < tableNames.length; i += 5) {
            tableRows.push([tableNames[i], tableNames[i + 1], tableNames[i + 2], tableNames[i + 3], tableNames[i + 4]])
        }

        return <TableContainer component={Paper} sx={{marginTop: '7px'}}>
            <Table className={'data-grid'}>
                <TableHead>
                    <TableRow>
                        {Array.from({length: 5}).map((_, i) => <Fragment key={i}>
                            <TableCell style={{width: '4%', textAlign: 'center'}}><span>{t('Stůl')}</span></TableCell>
                            <TableCell style={{width: '21%', textAlign: 'center'}}><span>{t('Obsazení')}</span></TableCell>
                        </Fragment>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows.map((tableNames, i) => {
                        return <TableRow key={i} style={{pageBreakInside: 'avoid'}}>
                            {tableNames.map((tableName, j) => <Fragment key={j}>
                                <TableCell style={{textAlign: 'center'}}><strong>{tableName}</strong></TableCell>
                                <TableCell style={{padding: '5px'}}>
                                    <TableGroups
                                        dayNo={dayNo}
                                        tableName={tableName}
                                        groups={values}
                                        partiesByTable={partiesByTable}
                                        ticketsByTable={ticketsByTable}
                                    />
                                </TableCell>
                            </Fragment>)}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>;

    }, [values, dayNo, tab, t]);

    return <Grid container>
        {(tab === 'listR') && <Grid item sm={5} xs={12} sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: '-7px'}}>
            <ButtonGroupPlain currentValue={listDisplayMode} options={listDisplayOptions} name={'listDisplay'} onChange={(listDisplayMode) => {
                if (listDisplayMode) {
                    setListDisplayMode(listDisplayMode);
                }
            }}/>
        </Grid>}
        {(tab === 'listR' || tab === 'listZ') && <Grid item sm={tab === 'listZ' ? 12 : 7} xs={12} sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '-7px'}}>
            <ButtonGroupPlain currentValue={dayNo} options={eventDayOptions} name={'eventDay'} onChange={(eventDayNo) => {
                setDayNo(eventDayNo);
            }}/>
        </Grid>}
        {(tab === 'listR') && listDisplayMode === 'tables' && <Grid item xs={12}>
            {seatingMatrix}
        </Grid>}
        {!((tab === 'listR') && listDisplayMode === 'tables') && <Grid item xs={12}>
            <DataGrid className={'guest-matrix guest-event-matrix-' + tab}
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                itemKey={'groupKey'}
                getRowClassNames={getRowClassNames}
            />
        </Grid>}
    </Grid>;
}

const getGroupKey = (ep: JsonEventPartyInfo, sortedTagIds: number[], tab: TabType, isExtra: boolean) => {
    const itemTags = ep.tags?.filter((t) => t !== FIXED_VIP_TAG_ID && t !== FIXED_INVITE_TAG_ID);
    const itemTagIds = (itemTags?.length && itemTags.length > 0 ? itemTags : [FAKE_VALUE_EMPTY]);
    const groupTagIds: number[] = [];
    sortedTagIds.forEach((tagId) => {
        if (itemTagIds.indexOf(tagId) >= 0) {
            groupTagIds.push(tagId);
        }
    });
    const groupKey = tab === 'model' && ((ep.areas || '').indexOf('Z') >= 0 || (ep.areas || '').indexOf('R') >= 0)
        ? isExtra
            ? 'fixedExtra'
            : 'otherZR'
        : groupTagIds.length > 0
            ? groupTagIds.join("-")
            : 'emptyTag';

    return {itemTagIds, groupTagIds, groupKey};
}

const EventGuestsPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const tab: TabType = (!!params.tab ? params.tab as any : undefined) || 'model';

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const {tag} = useAppSelector(selectCodebooks);
    const eventContingents = useAppSelector<ItemsState<JsonContingentInfo>>(selectContingents);
    const [eventParties, setEventParties] = useState<JsonEventPartyInfo[] | undefined>(undefined);
    const [eventTickets, setEventTickets] = useState<JsonEventTicketInfo[] | undefined>(undefined);
    const [eventDays, setEventDays] = useState<JsonEventDayInfo[] | undefined>(undefined);
    const [event, setEvent] = useState<JsonEvent | undefined>(undefined);
    const [showUnconfirmed, setShowUnconfirmed] = useState<boolean>(true);
    const [showContingents, setShowContingents] = useState<boolean>(true);
    const [selectedAreas, setSelectedAreas] = useState<{ [key in string]: AreaType }>({});
    const [massAction, setMassAction] = useState<PartyMassActionValues<any> | undefined>(undefined);
    const eventId = params.eventId ? parseInt(params.eventId) : configuration?.defaultEvent?.eventId as number;

    const handleFetchItems = useCallback(async () => {
        setEventParties(getApiResult(await dispatch(fetchEventPartiesOnline({
            orderCol: 'fullName',
            rows: 9999,
            eventId,
            isGuest: true,
            statuses: [GetEventPartyListUsingGETStatusesEnum.Pending, GetEventPartyListUsingGETStatusesEnum.Active]
        }))));

        setEventTickets(getApiResult(await dispatch(fetchEventTicketsOnline({
            orderCol: 'companyName, lastName, barcode',
            rows: 9999,
            eventId,
            statuses: [GetEventTicketListUsingGETStatusesEnum.Active]
        }))));

    }, [eventId, dispatch]);

    const groups: GroupType[] = useMemo(() => {
        if (eventParties === undefined
            || tag === undefined
            || eventDays === undefined
            || eventContingents?.items === undefined
            || eventContingents.loading
            || eventTickets === undefined) {
            return [];
        }

        const groups: GroupType[] = [];
        const groupsByKey: { [key in string]: GroupTypeWithItems } = {};

        const sortedTagIds: number[] = tag
            ?.filter((p) => p.value !== '' + FIXED_VIP_TAG_ID && p.value !== '' + FIXED_INVITE_TAG_ID)
            ?.map(p => parseInt(p.value || '0', 10)) || [];
        sortedTagIds.push(FAKE_VALUE_RESET);

        const fixedExtra: GroupTypeWithItems = {
            title: 'Doprovod již akreditovaných Z / R',
            groupKey: 'fixedExtra',
            groupOrder: -49,
            items: []
        };
        if (tab === 'model') {
            groups.push(fixedExtra);
        }
        groupsByKey[fixedExtra.groupKey] = fixedExtra;

        const unresolved: GroupTypeWithItems = {
            title: !(tab === 'model') ? 'Celkem akreditováno Z / R' : 'Celkem osob k akreditaci',
            groupKey: 'unresolved',
            groupOrder: -50,
            items: []
        };
        groups.push(unresolved);

        const otherZR: GroupTypeWithItems = {
            title: 'Již akreditované osoby Z / R',
            groupKey: 'otherZR',
            groupOrder: -60,
            items: [],
            dayTotals: {}
        };
        if (tab === 'model') {
            groups.push(otherZR);
        }
        groupsByKey[otherZR.groupKey] = otherZR;

        if (!eventTickets.length) {
            // summaries
            const voucherRZ: GroupType = {
                title: 'Voucher Z / R',
                groupKey: 'voucherRZ',
                groupOrder: -61,
                dayTotals: {},
            };
            groups.push(voucherRZ);

            const vipSold: GroupType = {
                title: 'Vstupenka Z',
                groupKey: 'vipSold',
                groupOrder: -62,
                dayTotals: {},
            };
            groups.push(vipSold);
        }

        const appendGroup = (groupKey: string, itemTagIds: number[], groupTagIds: number[]) => {
            let groupOrder = 0, n = 10000000000;
            sortedTagIds.forEach((tagId, i) => {
                if (itemTagIds.indexOf(tagId) >= 0) {
                    groupOrder += n * (i + 1);
                    n = n / 100;
                }
            });
            const group: GroupTypeWithItems = {
                groupKey,
                groupOrder,
                tagIds: groupTagIds,
                tags: tag.filter((p) => groupTagIds.indexOf(parseInt(p.value || '0')) >= 0),
                items: [],
                selectedArea: !(tab === 'model')
                    ? undefined
                    : selectedAreas[groupKey]
            };
            groupsByKey[groupKey] = group;
            groups.push(group);
        }

        let borderPrinted = false;
        eventContingents?.items?.forEach((contingent, i) => {
            if (contingent.owner === 'LOC' || contingent.owner === 'WORKERS') {
                return;
            }
            if (contingent.zone !== 'Z' && contingent.zone !== 'R') {
                return;
            }
            if (!showContingents || !(tab === 'model')) {
                return;
            }
            const k = 'cont-' + contingent.owner + '-' + contingent.zone;
            let g = groups.find((g) => g.groupKey === k);
            if (!g) {
                const groupKey = 'cont-' + contingent.owner + '-' + contingent.zone;
                g = {
                    title: contingent.owner + ' zbývá Z / R',
                    groupKey,
                    groupOrder: -(100 + i),
                    dayTotals: {},
                    separator: !borderPrinted
                };
                if (!borderPrinted) {
                    borderPrinted = true;
                }
                groups.push(g);
            }
            if (contingent.dayNo && g.dayTotals && !!contingent.limit) {
                if (!g.dayTotals[contingent.dayNo]) {
                    g.dayTotals[contingent.dayNo] = {Z: 0, R: 0}
                }
                g.dayTotals[contingent.dayNo][contingent.zone] = Math.max(0, contingent.limit - (contingent.partyCnt || 0));
            }
        });

        if (!eventTickets.length) {
            const voucherRZ = groups.find(g => g.groupKey === 'voucherRZ');
            const vipSold = groups.find(g => g.groupKey === 'vipSold');
            eventDays?.forEach((ed) => {
                if (!ed.dayNo) {
                    return;
                }
                if (voucherRZ?.dayTotals) {
                    if (!voucherRZ.dayTotals[ed.dayNo]) {
                        voucherRZ.dayTotals[ed.dayNo] = {Z: 0, R: 0};
                    }
                    voucherRZ.dayTotals[ed.dayNo]['Z'] = ed.voucherZ || 0;
                    voucherRZ.dayTotals[ed.dayNo]['R'] = ed.voucherR || 0;
                }
                if (vipSold?.dayTotals) {
                    if (!vipSold.dayTotals[ed.dayNo]) {
                        vipSold.dayTotals[ed.dayNo] = {Z: 0, R: 0};
                    }
                    vipSold.dayTotals[ed.dayNo]['Z'] = ed.vipSold || 0;
                }
            });
        }

        // event parties
        const doneEventParties: { [key in number | string]: JsonEventPartyInfo } = {};
        eventParties?.forEach((ep: JsonEventPartyInfo) => {
            if (!ep.eventPartyId || ep.partyType === JsonEventPartyInfoPartyTypeEnum.T) {
                return;
            }
            if (!(tab === 'model') && !((ep.areas || '').indexOf('Z') >= 0 || (ep.areas || '').indexOf('R') >= 0)) {
                return;
            }
            if (!showUnconfirmed
                && ep.status !== JsonEventPartyInfoStatusEnum.Active
                && !(ep.inviteStatus && ep.inviteStatus !== JsonEventPartyInfoInviteStatusEnum.Pending)) {
                return;
            }
            if ((tab === 'seating' || tab === 'floors') && ep.status !== JsonEventPartyInfoStatusEnum.Active) {
                return;
            }
            let hasAnyDay = false;
            FIXED_EVENT_DAY_NOS.forEach((dayNo) => {
                if (ep.eventDays?.[dayNo - 1] === '1') {
                    hasAnyDay = true;
                }
            });
            if (!hasAnyDay) {
                return;
            }

            const {itemTagIds, groupTagIds, groupKey} = getGroupKey(ep, sortedTagIds, tab, false);
            if (!groupsByKey[groupKey]) {
                appendGroup(groupKey, itemTagIds, groupTagIds);
            }

            const item = {...ep};
            groupsByKey[groupKey].items.push(item);
            if (groupKey !== 'otherZR') {
                unresolved.items.push(item);
            }
            doneEventParties[ep.eventPartyId] = item;
        });

        eventParties?.forEach((ep: JsonEventPartyInfo) => {
            if (!ep.eventPartyId) {
                return;
            }

            const {itemTagIds, groupTagIds, groupKey} = getGroupKey(ep, sortedTagIds, tab, true);
            if (!groupsByKey[groupKey]) {
                appendGroup(groupKey, itemTagIds, groupTagIds);
            }

            ep.extraEventParties?.forEach((extra) => {
                if ((tab === 'seating' || tab === 'floors') && !extra.eventPartyId) {
                    return;
                }
                const k = extra.eventPartyId || extra.photoGuid || JSON.stringify(extra);
                if (doneEventParties[k]) {
                    // extra, already bundled
                    doneEventParties[k].eventDays = mergeEventDays(doneEventParties[k].eventDays, extra.eventDays);
                    if (!doneEventParties[k].areas && ep.areas && (ep.areas.indexOf('Z') >= 0 || ep.areas.indexOf('R') >= 0)) {
                        doneEventParties[k].areas = '(' + ep.areas + ')'; // fixed / inherited
                    }
                    if (groupKey !== 'emptyTag') {
                        groups.forEach((otherGroup) => {
                            if (otherGroup.groupKey === groupKey || !otherGroup.tags) {
                                return;
                            }
                            const otherItems = otherGroup.items;
                            const otherItem = otherItems?.find((e) => e.eventPartyId === extra.eventPartyId);
                            if (otherItems && otherItem) {
                                // extra with their tags (or none at all) => move to the root's group
                                otherItems.splice(otherItems.indexOf(otherItem), 1);
                                groupsByKey[groupKey].items.push(doneEventParties[k]);
                            }
                        });
                    }
                    return;
                }
                const item = {...extra};
                if (ep.areas && (ep.areas.indexOf('Z') >= 0 || ep.areas.indexOf('R') >= 0)) {
                    item.areas = '(' + ep.areas + ')'; // fixed / inherited
                }
                groupsByKey[groupKey].items.push(item);
                if (groupKey !== 'otherZR') {
                    unresolved.items.push(item);
                }
                doneEventParties[k] = item;
            });
        });

        let counter = 0;
        eventTickets?.forEach((et: JsonEventTicketInfo) => {
            if (!et.ticketId) {
                return;
            }
            let hasAnyDay = false;
            FIXED_EVENT_DAY_NOS.forEach((dayNo) => {
                if (et.eventDays?.[dayNo - 1] === '1') {
                    hasAnyDay = true;
                }
            });
            if (!hasAnyDay) {
                return;
            }

            const groupKey = 'ticket-'
                + et.ticketType
                + '-'
                + (et.companyName || ('named-all')); // assume always homogenous
            if (!groupsByKey[groupKey]) {
                appendGroup(groupKey, [], []);
                const g = groupsByKey[groupKey];
                if (et.ticketType === JsonEventTicketInfoTicketTypeEnum.EgTicket) {
                    g.title = 'Vstupenka ' + (et.companyName ? et.companyName : t('na jméno'));
                } else {
                    g.title = 'Voucher ' + (et.companyName || 'na jméno');
                }
                g.contingent = et.contingentOwner;
                g.groupOrder = -70 - counter++;
                g.isTickets = true;
                g.tickets = [];
            }
            const g = groupsByKey[groupKey];
            if (g.tickets) {
                g.tickets.push(et);
            }
        });

        groups.forEach((group) => {
            if (group.items) {
                group.items.sort((a, b) => localCompare(
                    a.lastName + ' ' + a.firstName + ' ' + a.companyName, b.lastName + ' ' + b.firstName + ' ' + b.companyName));
            }
            // group.tickets?
        });

        return groups.filter((g) => g.dayTotals || !!g.items?.length || !!g.tickets?.length)
            .sort((a, b) => a.groupOrder === b.groupOrder ? 0 : (a.groupOrder > b.groupOrder ? -1 : 1));

    }, [showUnconfirmed, showContingents, tab,
        selectedAreas, eventDays, eventParties, eventTickets,
        tag, eventContingents?.items, eventContingents.loading, t]);

    const saveCurrentlySelected = useCallback(async (values: GroupType[], write?: boolean) => {
        const selected: { [key in string]: AreaType } = {};
        const ticketsToUpdate: JsonEventTicketInfo[] = [];
        values.forEach((group) => {
            if (group.selectedArea) {
                selected[group.groupKey] = group.selectedArea;
            }
            if (group.isTickets) {
                const isNewR = group.selectedArea === 'R';
                group.tickets?.forEach((item) => {
                    const isCurrentR = item.areas && item.areas.indexOf('R') >= 0;
                    if (isNewR !== isCurrentR) {
                        ticketsToUpdate.push({...item, areas: isNewR ? item.areas?.replace('Z', 'R Z') : item.areas?.replace('R', '').trim()});
                    }
                })
            }
        });
        if (write) {
            const event = getApiResult<JsonEvent>(await dispatch(fetchEvent(eventId)));
            if (event) {
                if (ticketsToUpdate.length) {
                    await dispatch(massEventTicket({
                        request: {
                            eventId,
                            action: JsonEventTicketMassActionRequestActionEnum.Update,
                            items: ticketsToUpdate.map((et) => ({
                                ...et,
                                ticketType: et.ticketType as any as JsonEventTicketTicketTypeEnum,
                                allowedActions: undefined,
                                status: JsonEventTicketStatusEnum.Active
                            })),
                            ids: []
                        }
                    }));
                }

                if (!event.eventData) {
                    event.eventData = {};
                }
                event.eventData.groupAreas = selected;
                const res = await dispatch(saveEvent(event));
                addApiResultMessage(res, {
                    [ApiChangeType.NO_CHANGE]: 'Rozdělení akreditace uloženo beze změn',
                    [ApiChangeType.UPDATED]: 'Rozdělení akreditace uloženo',
                }, t, dispatch);

                if (ticketsToUpdate.length) {
                    await handleFetchItems();
                }
            }
        }
        setSelectedAreas((s) => ({...s, ...selected}));

    }, [eventId, setSelectedAreas, handleFetchItems, dispatch, t]);

    const handleMassAction = useCallback(async (action: JsonEventPartyMassActionRequestActionEnum, partyIds: number[], selectedArea: AreaType) => {
        if (action === JsonEventPartyMassActionRequestActionEnum.Accred) {
            dispatch(fetchEventPartiesOnline({partyIds, eventId, rows: 50})).then((res) => {
                const eventParties = getApiResult<JsonEventPartyInfo[]>(res) || [];
                const defItem: JsonEventParty = {partyId: FAKE_VALUE_ALL};
                if (selectedArea === 'R') {
                    defItem.formatCode = '414';
                    defItem.reason = 'VIP Reserved Table';
                    defItem.areas = 'R Z';
                    defItem.contingentOwner = 'LOC';
                } else if (selectedArea === 'Z') {
                    defItem.formatCode = '410';
                    defItem.reason = 'VIP of OC';
                    defItem.areas = 'Z';
                    defItem.contingentOwner = 'LOC';
                }

                setMassAction({
                    filter: {},
                    parties: [],
                    eventParties,
                    values: {
                        eventId,
                        action,
                        items: [defItem]
                    }
                });
            });
        }
    }, [eventId, dispatch]);

    const handleSaveMassAction = useCallback(async () => {
        setMassAction(undefined);
        handleFetchItems().then();
    }, [handleFetchItems])

    const handleCancelMassAction = useCallback(() => {
        setMassAction(undefined);
    }, [])

    const handleInit = useCallback(async () => {
        setEventDays(getApiResult<JsonEventDayInfo[]>(await dispatch(fetchEventDays({eventId}))));
        setEvent(getApiResult<JsonEvent>(await dispatch(fetchEvent(eventId))));
        await dispatch(fetchContingents({eventId, orderCol: 'dayNo, owner, zone'}));

        const event = getApiResult<JsonEvent>(await dispatch(fetchEvent(eventId)));
        if (event?.eventData?.groupAreas) {
            setSelectedAreas(event.eventData.groupAreas as { [key in string]: AreaType });
        }
        await handleFetchItems();

    }, [eventId, handleFetchItems, dispatch]);

    const isLoading = !eventDays || !groups || !(groups.length > 1);

    const matrixActions = useCallback(({values, isSubmitting}: FormikProps<GroupType[]>) => {
        if (isLoading || tab !== 'model') {
            return null;
        }
        return <>
            <Grid item sx={{flexGrow: 1, display: 'flex'}}>
                <div style={{marginRight: '10px'}}>
                    <ButtonGroupPlain currentValue={showUnconfirmed} name={'showUnconfirmed'} options={showUnconfirmedOptions} fullWidth onChange={(v) => {
                        saveCurrentlySelected(values).then();
                        setShowUnconfirmed(v);
                    }}/>
                </div>
                <div style={{}}>
                    <ButtonGroupPlain currentValue={showContingents} name={'showContingents'} options={showContingentsOptions} fullWidth onChange={(v) => {
                        saveCurrentlySelected(values).then();
                        setShowContingents(v);
                    }}/>
                </div>
            </Grid>
            <Grid item>
                <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={20}/> : null}
                    <span>{t('Uložit rozdělení')}</span>
                </Button>
            </Grid>
        </>;
    }, [isLoading, tab, saveCurrentlySelected, showContingents, showUnconfirmed, t]);

    const pageHeader = useCallback((onTabChange: (event: React.SyntheticEvent, value: any) => void) => {
        return <Grid container spacing={2} className={'page-content page-tabs'}>
            <Grid item sm={2}>
                <PageHeader title={t('VIP hosté')}/>
            </Grid>
            <Grid item sm={8}>
                <Tabs
                    value={tab}
                    onChange={onTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab key={0} value={'model'} label={t('Modelace')}/>
                    <Tab key={1} value={'detail'} label={t('Akreditováno')}/>
                    <Tab key={2} value={'listZ'} label={t('Seznam Z')}/>
                    <Tab key={3} value={'listR'} label={t('Seznam R')}/>
                    <Tab key={4} value={'floors'} label={t('Plánky')}/>
                    <Tab key={5} value={'seating'} label={t('Usazení')}/>
                </Tabs>
            </Grid>
            <Grid item sm={2} sx={{display: 'flex', alignItems: 'center'}}>
                <EventFilter onSetEventId={(eventId) => {
                    navigate('/guests/' + tab + '/' + eventId);
                }} eventId={eventId}/>
            </Grid>
        </Grid>
    }, [tab, eventId, t, navigate]);

    useEffect(() => {
        handleInit().then();
        return () => {
            setEventParties(undefined);
        }
    }, [handleInit]);

    if (tab === 'seating' || tab === 'floors') {
        return <Container>
            <Grid container>
                <Grid item sm={12}>
                    <form>
                        <div className={'form-container'}>
                            {pageHeader((_, v) => {
                                navigate('/guests/' + v + '/' + eventId);
                            })}
                        </div>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    {!!event && (isLoading ? <><br/><LinearProgress/></> : <SeatingPlan tab={tab} event={event}
                        eventDays={eventDays}
                        groups={groups.filter((g) => !(g.groupOrder && g.groupOrder < 0 && !g.isTickets))}
                        eventParties={eventParties}
                        onSeatingChange={handleFetchItems}
                    />)}
                </Grid>
            </Grid>
        </Container>;
    }

    return <Container>
        <FormContainer item={groups} isReadonly={!(tab === 'model') || isLoading} onSave={(values) => {
            return saveCurrentlySelected(values, true);
        }} children={({values}) => {
            return <>
                <Grid item sm={12}>
                    {pageHeader((_, v) => {
                        if (tab === 'model') {
                            saveCurrentlySelected(values).then();
                        }
                        navigate('/guests/' + v + '/' + eventId);
                    })}
                </Grid>

                <Grid item xs={12}>
                    {isLoading
                        ? <LinearProgress/>
                        : <EventGuestsFormMatrix eventDays={eventDays} tab={tab} onMassAction={handleMassAction}/>
                    }
                </Grid>
            </>;
        }}
            actions={matrixActions}
        />
        {massAction && <PartyMassActionModal
            massAction={massAction}
            onCancel={handleCancelMassAction}
            onSave={handleSaveMassAction}
        />}
    </Container>;
}

export default EventGuestsPage;
