import {Button, ButtonGroup, FormControl, FormHelperText, FormLabel} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {CodebookOptionsProps, createOptions, FormFieldProps, OptionValue} from '../../model/form';
import {useAppTranslation} from "../../services/i18n";
import {useAppSelector} from "../../store";
import {selectCodebooks} from "../../store/selectors";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {localCompare} from "../DataGrid";
import Box from "@mui/material/Box";

interface Props extends FormFieldProps {
    options?: OptionValue[],
    codebookProps?: CodebookOptionsProps,
    isMulti?: boolean,
    fullWidth?: boolean,
    hideLabel?: boolean,
    iconsOnly?: boolean,
    splitBy?: string,
    debounce?: boolean,
    maxPerRow?: number,
    fixedHeight?: number,
}

const INPUT_DELAY = 200;

interface PropsPlain extends Props {
    currentValue: any,
    showError?: boolean,
    error?: string
}

export const ButtonGroupPlain = (props: PropsPlain) => {
    const {
        name, label, options, codebookProps, isMulti, maxPerRow, fixedHeight,
        disabled, iconsOnly, onChange, currentValue, showError, error
    } = props;

    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);

    const btnOptions = useMemo(() => {
        return codebookProps ? createOptions(codebookProps, codebooks) : (options || []);
    }, [codebookProps, codebooks, options]);

    const sx = useMemo(() => {
        if (!maxPerRow || btnOptions.length <= maxPerRow) {
            return undefined;
        }
        return {
            flexFlow: 'column',
            '& > div': {
                display: 'flex',
                // flexWrap: 'wrap',
                width: '100%',
                height: fixedHeight ? (fixedHeight / Math.ceil(btnOptions.length / maxPerRow)) + 'px' : undefined,
                fontSize: '90%'
            },
            '& > div:not(:first-of-type) > button': {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTop: 'none',
            },
            '& > div:not(:last-of-type) > button': {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
            }
        }

    }, [btnOptions.length, maxPerRow, fixedHeight]);

    const buttons = useMemo(() => {
        const items = btnOptions.map((option, i) => {
            const isSelected = isMulti
                ? (currentValue && currentValue.indexOf(option.value) >= 0)
                : currentValue === option.value;
            return <Button key={i} disableRipple
                color={isSelected ? (option.color || 'secondary') : (option.color || 'inherit')}
                variant={isSelected ? 'contained' : undefined}
                disabled={disabled}
                title={option.tooltip}
                onClick={() => {
                    let newValue;
                    if (isSelected) {
                        if (isMulti) {
                            newValue = currentValue ? currentValue.filter((v: any) => v !== option.value) : [];
                        } else {
                            newValue = undefined;
                        }
                    } else {
                        if (isMulti) {
                            newValue = currentValue ? [...currentValue] : [];
                            newValue.push(option.value);
                            newValue.sort();
                        } else {
                            newValue = option.value;
                        }
                    }
                    if (onChange) {
                        onChange(newValue);
                    }
                }}>{option.icon ? <>{option.icon}{iconsOnly || !option.label ? null : <span>{option.label}</span>}</> : option.label}</Button>;
        });
        if (!!maxPerRow && items.length > maxPerRow) {
            const rows: JSX.Element[] = [];
            for (let i = 0; i < Math.ceil(items.length / maxPerRow); i++) {
                rows.push(<Box key={i}>
                    {items.slice(i * maxPerRow, (i + 1) * maxPerRow)}
                </Box>)
            }
            return rows;
        }

        return items;
    }, [btnOptions, currentValue, disabled, isMulti, onChange, iconsOnly, maxPerRow])

    if (codebookProps && codebooks[codebookProps.codebookName] === undefined) {
        return <span>...</span>;
    }

    return <FormControl error={showError} fullWidth={props.fullWidth}>
        {label && <FormLabel>{typeof label === 'string' ? t(label) : undefined}</FormLabel>}
        <ButtonGroup size={'small'} title={typeof label === 'string' ? t(label) : undefined} fullWidth={props.fullWidth} sx={sx}>
            {buttons}
        </ButtonGroup>
        {showError && error && <FormHelperText>{error}</FormHelperText>}
        <input name={name} style={{display: 'none'}}/>
    </FormControl>;
}

export const ButtonGroupField = (props: Props) => {
    const {name, onChange, splitBy, debounce} = props;

    const [field, meta, {setValue}] = useField(name);
    const {submitCount} = useFormikContext();
    const [innerValue, setInnerValue] = useState();

    const showError = !!meta.error && (meta.touched || submitCount > 0);

    const parseValue = useCallback((value: any) => {
        return splitBy && value ? value.split(splitBy) : value;
    }, [splitBy]);

    const createValue = useCallback((value: any) => {
        return value && splitBy ? value.slice().sort(localCompare).join(splitBy) : value;
    }, [splitBy]);

    const formValue = field.value;
    useEffect(() => {
        setInnerValue(formValue);
    }, [formValue]);

    let timeoutHandle = useRef<any>(null);

    const handleOnChange = useCallback((value: any) => {
        const v = createValue(value);
        setInnerValue(v);

        if (!debounce) {
            setValue(v, true);
            if (onChange) {
                onChange(v);
            }
            return;
        }

        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }

        timeoutHandle.current = setTimeout(() => {
            setValue(v, true);
            if (onChange) {
                onChange(v);
            }

        }, timeoutHandle.current ? INPUT_DELAY : 1);
    }, [debounce, createValue, setValue, onChange]);

    return <ButtonGroupPlain {...props}
        currentValue={parseValue(innerValue)}
        onChange={handleOnChange}
        showError={showError}
        error={meta.error}
    />;
}
