import {Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, Table, TableRow} from "@mui/material";
import {Close} from "@mui/icons-material";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import {fetchPartyHistory, fetchPartyInviteHistory} from "../../store/parties";
import {fetchEventPartyDayHistory, fetchEventPartyHistory} from "../../store/eventParties";
import {JsonEventDayInfo, JsonEventParty, JsonEventPartyDay, JsonInvite, JsonParty} from "../../generated-api";
import {getApiResult} from "../../helpers/api";
import {datetimeToGui, dateToGui, dateToGuiAs} from "../../helpers/date";
import {Color, getCodebookLabel} from "../../model/form";
import {selectCodebooks} from "../../store/selectors";
import PartyPhoto, {PartyPhotoTooltip} from "./PartyPhoto";
import {
    eventPartyContractStatusOptions,
    eventPartyStatusOptions,
    partySiwiStatusOptions,
    siwiAccredStatusOptions
} from "../../model/party";
import EventDaysValue from "../EventDaysValue";
import {fetchEventDays} from "../../store/eventDays";
import PartyTags from "../PartyTags";
import {
    inviteStatusOptions,
    inviteTypeAllOptions,
    inviteTypeOptions,
    isInviteUpdate,
    safetyStatusOptions
} from "../../model/invite";

// type HistoryItem = Omit<JsonParty & JsonEventParty & JsonEventPartyDay, 'changes' | 'allowedActions'>;

type JsonBaseHistoryItem = JsonParty | JsonEventParty | JsonEventPartyDay | JsonInvite;

type HistoryItemKey = keyof Omit<JsonParty, 'changes' | 'allowedActions'>
    | keyof Omit<JsonEventParty, 'changes' | 'allowedActions'>
    | keyof Omit<JsonEventPartyDay, 'changes' | 'allowedActions'>
    | keyof Omit<JsonInvite, 'changes' | 'allowedActions'>;

const IGNORED_KEYS: HistoryItemKey[] = ['createdAt', 'createdBy', 'updatedAt', 'updatedBy',
    'partyId',
    'eventPartyId', 'eventPartyGuid', 'eventId',
    'eventPartyDayId', 'foodData', 'eventDayId',
    'siwiUpdatedAt', 'siwiUpdatedBy',
    'hstRowNo', 'isHstDelete',
    'accredStatus', 'siwiSyncStatus', 'realEventDays', 'seating', 'siwiPropFirstName', 'siwiPropLastName',
    'inviteId', 'inviteType', 'isActive', 'token',
    'inviteData'
    /*'accredStatus', 'accredAt', 'siwiAreas', 'siwiEventDays', 'siwiFormatCode', 'siwiReason', 'siwiUpdatedAt', 'siwiUpdatedBy', 'realEventDays'*/
]

const humanNames = (t: TAppFunction): { [key in HistoryItemKey]: string } => ({
    eventDays: "Dny",
    accredAt: "Akreditace",
    accredStatus: "Stav akr.",
    areas: "Zóny",
    bankAccount: "Banka",
    birthDate: "Dat. nar.",
    cebId: "Ceb ID",
    companyId: "Společnost",
    companyName: "Název",
    companyNumber: "IČO",
    contingentOwner: "Kontingent",
    contractStatus: "Smlouva",
    createdAt: "",
    createdBy: "",
    email: "E-mail",
    eventId: "",
    eventPartyGuid: "",
    eventPartyId: "",
    firstName: "Jméno",
    formatCode: "Formát",
    groupId: "Skupina",
    groupPosition: "Pozice",
    lastName: "Příjmení",
    note: t('Poznámka'),
    partyId: "",
    partyType: "Typ",
    permAddress: "Adresa",
    phone: "Tel.",
    photoGuid: "",
    photoId: "Foto",
    prefAcm: "Zájem ubytování",
    realEventDays: "",
    reason: "Reason",
    seating: "Usazení",
    sex: "Pohlaví",
    siwiAccredStatus: "SIWI Akr.",
    siwiAreas: "SIWI Zóny",
    siwiBarcode: "QR",
    siwiContingentOwner: "SIWI Kont.",
    siwiEventDays: "SIWI Dny",
    siwiFirstName: "SIWI Jméno",
    siwiFormatCode: "SIWI Formát",
    siwiId: "SIWI ID",
    siwiLastName: "SIWI Příjmení",
    siwiPhotoId: "SIWI Foto",
    siwiReason: "SIWI Reason",
    siwiStatus: "SIWI Stav",
    status: "Stav",
    tags: "Štítky",
    updatedAt: "",
    updatedBy: "",
    acmCnt: "Ubytování počet",
    acmId: "Ubytování",
    foodBrId: "Snídaně",
    foodCnt: "Snídaně počet",
    foodData: "Catering",
    foodDiId: "Večeře",
    foodLuId: "Oběd",
    foodSnId: "Snack",
    parkCnt: "Parkování počet",
    parkFlag: "Parkování",
    parkId: "Parkoviště",
    parkPlates: "SPZ",
    parkRec: "SPZ vydáno",
    eventDayId: "",
    eventPartyDayId: "",
    removeTags: "",
    rowNo: "",
    safetyStatus: "BOZP",
    siwiPropFirstName: "",
    siwiPropLastName: "",
    siwiSyncStatus: "Synchronizace",
    siwiUpdatedAt: "",
    siwiUpdatedBy: "",
    user: "Uživatel",
    prefArticles: "",
    hstRowNo: '',
    isHstDelete: '',
    inviteId: '',
    inviteType: '',
    inviteData: '',
    isActive: '',
    processNote: 'Zpracování',
    replyUntil: 'Uzávěrka',
    token: ''
});

interface JsonAuditable extends Pick<JsonParty, 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'> {

}

interface HistorySnapshot extends JsonAuditable {
    party?: JsonParty,
    eventParty?: JsonEventParty,
    eventPartyDays?: { [key in number]: JsonEventPartyDay },
    invites?: { [key in number]: JsonInvite },
}

const SafeValue = <T extends JsonBaseHistoryItem, K extends HistoryItemKey>({name, value, item, eventId}: {
    name: K,
    value: any,
    item: T,
    eventId?: number
}) => {
    const t = useAppTranslation();

    const codebooks = useAppSelector(selectCodebooks);

    return useMemo(() => {
        const format = () => {
            const partyId = 'partyId' in item ? item['partyId'] : undefined;

            switch (name) {
                case 'acmId':
                    return getCodebookLabel(codebooks, 'placeAcm', value, eventId);
                case 'foodBrId':
                case 'foodSnId':
                case 'foodLuId':
                case 'foodDiId':
                    return getCodebookLabel(codebooks, 'placeFood', value, eventId);
                case 'parkId':
                    return getCodebookLabel(codebooks, 'placePark', value, eventId);
                case 'groupId':
                    return getCodebookLabel(codebooks, 'group', value);
                case 'companyId':
                    return getCodebookLabel(codebooks, 'company', value);
                case 'photoId':
                case 'siwiPhotoId':
                    return <PartyPhotoTooltip partyId={partyId} photoId={value} maxHeight={300} maxWidth={300}
                        icon={<div><PartyPhoto partyId={partyId} photoId={value} maxHeight={20} maxWidth={40}/></div>}
                        emptyIcon={<div><PartyPhoto photoGuid={undefined} maxHeight={20} maxWidth={40}/></div>}/>;
                case 'status':
                    if ('token' in item) {
                        return inviteStatusOptions.find(o => o.value === value)?.label || value;
                    }
                    return eventPartyStatusOptions.find(o => o.value === value)?.label || value;
                case 'siwiStatus':
                    return partySiwiStatusOptions.find(o => o.value === value)?.label || value;
                case 'eventDays':
                case 'siwiEventDays':
                    return <Box sx={{
                        display: 'inline',
                        pre: {display: 'inline-block', fontSize: '100%', fontFamily: 'inherit'}
                    }}><EventDaysValue eventDays={value} eventId={eventId}/></Box>;
                case 'contractStatus':
                    return eventPartyContractStatusOptions.find(o => o.value === value)?.label || value;
                case 'siwiAccredStatus':
                    return siwiAccredStatusOptions.find(o => o.value === value)?.label || value;
                case 'tags':
                    return <Box sx={{
                        display: 'inline',
                        '.party-tags': {display: 'inline-block', margin: '-3px 0 0 0'}
                    }}><PartyTags tags={value}/></Box>;
                case 'accredAt':
                    return datetimeToGui(value);
                case 'replyUntil':
                    return dateToGui(value);
                case 'inviteType':
                    return inviteTypeOptions.find(o => o.value === value)?.label || value;
                case 'inviteData':
                    return Object.keys(value).filter(k => k !== 'bodyTop' && k !== 'bodyBottom' && !!value[k]).map(k => k + " " + value[k]).join(', ');
                case 'safetyStatus':
                    return safetyStatusOptions.find(o => o.value === value)?.label || value;
            }
            if (value === true) {
                return t('Ano');
            }
            if (value === false) {
                return t('Ne');
            }

            if (typeof value === 'object') {
                return <span className={'clamped'} title={JSON.stringify(value)}>{Object.keys(value).map(k => value[k]).filter(v => !!v).join(', ') || t('(prázdné)')}</span>;
            }
            return <span className={'clamped'} title={value}>{value}</span>;
        }
        return <>{format()}</>;
    }, [name, value, item, eventId, codebooks, t]);
}

const HistorySnapshotRow = ({item, prev, eventId, eventDays}: {
    item: HistorySnapshot,
    prev: HistorySnapshot[],
    eventId: number,
    eventDays: JsonEventDayInfo[]
}) => {

    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);

    const diffItem = useCallback(<T extends JsonBaseHistoryItem, >(item: T, prev?: T) => {
        type ItemKey = keyof Omit<T, 'changes' | 'allowedActions' | 'hstRowNo'>;

        const keys = ([...Object.keys(item), ...Object.keys(prev || {})] as ItemKey[])
            .filter(k => IGNORED_KEYS.indexOf(k as HistoryItemKey) < 0)
            .filter((k, i, arr) => arr.indexOf(k) === i);

        const diff: { key: ItemKey, node: JSX.Element, color: Color }[] = [];

        for (let key of keys) {
            const prevValue = prev?.[key];
            const value = item[key];
            if (prevValue === value || (typeof prevValue === 'object' && JSON.stringify(prevValue) === JSON.stringify(value))) {
                continue;
            }
            const k = key as HistoryItemKey;
            if (prevValue === undefined) {
                if ((key === 'parkFlag' || key === 'parkCnt') && value === 1) {
                    // ignore generic parking

                } else {
                    diff.push({
                        key,
                        color: 'info',
                        node: <SafeValue name={k} value={value} item={item} eventId={eventId}/>
                    });
                }
            } else if (value === undefined) {
                diff.push({
                    key,
                    color: 'error',
                    node: <SafeValue name={k} value={prevValue} item={item} eventId={eventId}/>
                });
            } else {
                diff.push({
                    key,
                    color: 'primary',
                    node: <>
                        <SafeValue name={k} value={prevValue} eventId={eventId} item={item}/>
                        <b style={{padding: '0 5px'}}>→</b>
                        <SafeValue name={k} value={value} item={item} eventId={eventId}/></>
                });
            }
        }
        if (!diff.length && prev) {
            return null;
        }

        const keyNames = humanNames(t);
        return <Box sx={{'.MuiChip-root': {margin: '0 0 5px 5px'}}}>
            {!prev && <Chip label={'Záznam založen'} color={'success'}/>}
            {item.isHstDelete && <Chip label={'Záznam odstraněn'} color={'error'}/>}
            {diff.map((d, i) =>
                <Chip color={d.color} key={i} sx={{
                    '.MuiChip-label': {
                        display: 'flex',
                        alignItems: 'center',
                        'strong': d.color === 'error' ? {textDecoration: 'line-through'} : undefined
                    },
                    '.clamped': {
                        maxWidth: '300px',
                        overflow: 'hidden'
                    }
                }} label={<><strong>{keyNames[(d.key as HistoryItemKey)] || (d.key as string)}</strong>:&nbsp;{d.node}</>}
                />)}
        </Box>;
    }, [eventId, t]);

    const diff = useMemo(() => {
        const rows: JSX.Element[] = [];

        if (item.party) {
            const diff = diffItem(item.party, prev?.slice().reverse().find(s => !!s.party)?.party);
            if (diff) {
                rows.push(<tr key={'party'}>
                    <td>
                        <em>{t('Osoba')}</em>
                    </td>
                    <td>
                        {diff}
                    </td>
                </tr>);
            }
        }

        if (item.invites) {
            for (let inviteId of Object.keys(item.invites)) {
                const invite = item.invites[+inviteId];
                const diff = diffItem(invite, prev?.slice().reverse().find(s => !!s.invites?.[+inviteId])?.invites?.[+inviteId]);
                if (diff) {
                    rows.push(<tr key={'invite-' + invite.inviteId}>
                        <td title={datetimeToGui(invite.createdAt) + ', ' + invite.token}>
                            <em>{isInviteUpdate(invite) ? t('Výzva') : t('Pozvánka')} {inviteTypeAllOptions.find(o => o.value === invite.inviteType)?.label}</em>
                        </td>
                        <td>
                            {diff}
                        </td>
                    </tr>);
                }
            }
        }

        if (item.eventParty) {
            const diff = diffItem(item.eventParty, prev?.slice().reverse().find(s => !!s.eventParty)?.eventParty);
            if (diff) {
                rows.push(<tr key={'eventParty'}>
                    <td>
                        <em>{t('Osoba v události')}</em>
                    </td>
                    <td>
                        {diff}
                    </td>
                </tr>);
            }
        }

        if (item.eventPartyDays) {
            for (let dayNo of Object.keys(item.eventPartyDays)) {
                const diff = diffItem(item.eventPartyDays[+dayNo], prev?.slice().reverse().find(s => !!s.eventPartyDays?.[+dayNo])?.eventPartyDays?.[+dayNo]);
                const eventDay = eventDays.find(ed => ed.eventDayId === +dayNo);
                if (diff) {
                    rows.push(<tr key={'eventPartyDay-' + dayNo}>
                        <td>
                            {eventDay ? dateToGuiAs(eventDay.dayDate, 'eeeee d.M.') + ' (#' + eventDay.dayNo + ')' : dayNo}
                        </td>
                        <td>
                            {diff}
                        </td>
                    </tr>);
                }
            }
        }

        return rows;
    }, [item, prev, diffItem, eventDays, t]);

    if (!diff.length) {
        return null;
    }

    return <TableRow sx={{
        '& > th, & > td': {
            borderTop: '1px solid #eee',
            padding: '10px',
            '&:nth-of-type(1)': {width: '60px', verticalAlign: 'top'}
        }
    }}>
        <th>{datetimeToGui(item.createdAt)}</th>
        <td>{item.createdBy
            ? getCodebookLabel(codebooks, 'user', item.createdBy)
            : <em>{t('Systém')} {item.eventParty?.siwiUpdatedBy ? <span>({item.eventParty?.siwiUpdatedBy})</span> : null}</em>}
        </td>
        <td>
            <Table sx={{'th, td': {'&:nth-of-type(1)': {width: '80px', verticalAlign: 'top'}}}}>
                <tbody>
                {diff}
                </tbody>
            </Table>
        </td>
    </TableRow>
}

type Props = {
    partyId: number,
    eventPartyId: number,
    eventId: number,
    onCancel?: () => void;
}

export const PartyHistoryModal = ({partyId, eventPartyId, eventId, onCancel}: Props) => {

    const dispatch = useAppDispatch();

    const [snapshots, setSnapshots] = useState<HistorySnapshot[]>();
    const [eventDays, setEventDays] = useState<JsonEventDayInfo[]>();

    const fetchHistory = useCallback(async () => {
        const parties = getApiResult(await dispatch(fetchPartyHistory(partyId)));
        const invites = getApiResult(await dispatch(fetchPartyInviteHistory({partyId, eventId})));
        const eventParties = getApiResult(await dispatch(fetchEventPartyHistory(eventPartyId)));
        const eventPartyDays = getApiResult(await dispatch(fetchEventPartyDayHistory(eventPartyId)));

        const eventDays = getApiResult(await dispatch(fetchEventDays({eventId})));

        const items: HistorySnapshot[] = [];

        const getOrCreateSnapshot = (p: JsonAuditable): HistorySnapshot => {
            let item = items.find(item => item.createdAt === p.createdAt && item.createdBy === p.createdBy);
            if (!item) {
                item = {
                    createdAt: p.createdAt,
                    createdBy: p.createdBy,
                    updatedAt: p.updatedAt,
                    updatedBy: p.updatedBy,
                };
                items.push(item);
            }
            return item;
        }

        parties?.forEach((p) => {
            const item = getOrCreateSnapshot(p);
            if (!item.party) {
                item.party = p;
            } else {
                item.party = {...item.party, ...p}
            }
        });

        eventParties?.forEach((p) => {
            const item = getOrCreateSnapshot(p);
            if (!item.eventParty) {
                item.eventParty = p;
            } else {
                item.eventParty = {...item.eventParty, ...p}
            }
        });

        eventPartyDays?.forEach((p) => {
            const item = getOrCreateSnapshot(p);
            if (!item.eventPartyDays) {
                item.eventPartyDays = {};
            }
            if (!item.eventPartyDays[p.eventDayId!]) {
                item.eventPartyDays[p.eventDayId!] = p;
            } else {
                item.eventPartyDays[p.eventDayId!] = {...item.eventPartyDays[p.eventDayId!], ...p}
            }
        });

        invites?.forEach((p) => {
            const item = getOrCreateSnapshot(p);
            if (!item.invites) {
                item.invites = {};
            }
            if (!item.invites[p.inviteId!]) {
                item.invites[p.inviteId!] = p;
            } else {
                item.invites[p.inviteId!] = {...item.invites[p.inviteId!], ...p}
            }
        });

        setEventDays(eventDays);
        setSnapshots(items.sort((a, b) => {
            if (a.createdAt === b.createdAt || !a.createdAt || !b.createdAt) {
                return 0;
            }
            return a.createdAt > b.createdAt ? 1 : -1;
        }));

    }, [partyId, eventPartyId, eventId, dispatch]);

    const historyTable = useMemo(() => {
        if (!eventDays || !snapshots) {
            return null;
        }
        return <table>
            <tbody>
            {snapshots?.map((p, i) =>
                <HistorySnapshotRow key={i} item={p} prev={i > 0 ? snapshots?.slice(0, i) : []} eventId={eventId} eventDays={eventDays}/>)}
            </tbody>
        </table>

    }, [eventDays, snapshots, eventId]);

    useEffect(() => {
        fetchHistory().then();

    }, [fetchHistory]);

    return <Dialog open={true} maxWidth={'lg'} fullWidth>
        <DialogTitle>
            Historie
            <IconButton
                aria-label="close"
                onClick={onCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.secondary.contrastText,
                }}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {historyTable}
        </DialogContent>
    </Dialog>
}
