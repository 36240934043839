import {GroupType, renderGroupTitle} from "../../pages/EventGuestsPage";
import {Dispatch, MouseEvent, SetStateAction, useCallback, useMemo} from "react";
import {GroupsViewMode} from "./SeatingPlanGroups";
import {useAppTranslation} from "../../services/i18n";
import {JsonEventPartyInfo, JsonEventPartySeating, JsonEventTicketInfo} from "../../generated-api";
import {getChunks, JsonEventPartyInfoWithChunkId} from "../party/PartyAccredToEventForm";
import {doesNameContains} from "../../model/party";
import {Button, Grid} from "@mui/material";
import {AddBoxOutlined, IndeterminateCheckBoxOutlined} from "@mui/icons-material";
import * as React from "react";
import {SeatingPlanGroupItem} from "./SeatingPlanGroupItem";
import {AREA_CODE, isAnyTableMatch, SeatingActions, SeatingItem, TableInfo} from "./svgUtils";

const groupStyle = {
    marginTop: '0 !important',
    '& > .MuiGrid-item': {
        '& > button': {
            fontSize: '80%',
            padding: 0
        },
        '& > .MuiSvgIcon-root': {
            fontSize: '140%',
            opacity: .5,
            marginTop: '3px',
        }
    },
    '&:hover': {
        backgroundColor: '#efefef66'
    }
};

const groupStyleList = {};

const groupArrowStyle = {cursor: 'pointer', textAlign: 'right'};

export const SeatingPlanGroup = (
    {
        group, dayNo, openIds, setOpenIds, onLabelMouseClick, onHandleMouseDown, selectedItems,
        eventDaySeating, search, groupsViewMode, actions, tables
    }: {
        group: GroupType,
        dayNo: number,
        openIds: string[],
        setOpenIds: Dispatch<SetStateAction<string[]>>
        onLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
        onHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void,
        selectedItems?: SeatingItem[],
        eventDaySeating: SeatingItem[],
        search?: string,
        groupsViewMode: GroupsViewMode,
        actions?: SeatingActions,
        tables?: TableInfo[]
    }) => {

    const t = useAppTranslation();

    const isOpen = openIds.indexOf(group.groupKey) >= 0;

    const [chunks, tickets] = useMemo(() => {
        let tickets: [JsonEventTicketInfo, JsonEventPartySeating?][] = [];
        let chunks: JsonEventPartyInfoWithChunkId[] = [];
        if (group.tickets) {
            tickets = group.tickets
                .filter((et) => et.eventDays && et.eventDays[dayNo - 1] === '1')
                .map((et) => [et, eventDaySeating.find((s) => s.ticketId === et.ticketId)?.seating]);
            if (search?.trim()) {
                tickets = tickets.filter(([et, seating]) => !search?.trim()
                    || doesNameContains(search, et.companyName)
                    || isAnyTableMatch(seating?.tables?.[dayNo], search.trim()));
            }
        }
        if (group.items) {
            let mapped: [JsonEventPartyInfo, JsonEventPartySeating?][] = group.items
                .map((ep) => [ep, eventDaySeating.find((s) => s.partyId === ep.partyId)?.seating])
            let filtered: typeof mapped;
            if (search?.trim()) {
                filtered = mapped
                    ?.filter(([ep, seating]) => !search?.trim()
                        || isAnyTableMatch(seating?.tables?.[dayNo], search.trim())
                        || doesNameContains(search, ep.lastName)
                        || doesNameContains(search, ep.orgCompanyName));

            } else {
                filtered = mapped;
            }
            const result = mapped?.filter(([ep]) => filtered.find(([fep]) => fep.partyId === ep.partyId
                || ep.parentEventParties?.find(p => p.partyId === fep.partyId && p.eventDays?.[dayNo - 1] === '1')
                || ep.extraEventParties?.find(extra => extra.partyId === fep.partyId && extra.eventDays?.[dayNo - 1] === '1')
            ));
            if (!!result?.length) {
                [chunks] = getChunks(result.map(([ep]) => ep), dayNo, AREA_CODE);
            }
        }
        return [chunks, tickets];
    }, [group.items, dayNo, eventDaySeating, group.tickets, search]);

    const items = useMemo(() => {
        const items: JSX.Element[] = [];
        if (chunks?.length) {
            let mapped: [JsonEventPartyInfoWithChunkId, JsonEventPartySeating?][] = chunks
                .map((ep) => [ep, eventDaySeating.find((s) => s.partyId === ep.partyId)?.seating])

            mapped?.forEach(([ep, seating], i) => {
                const parent = eventDaySeating.find((s) => s.seating?.tables?.[dayNo]
                    && ep.parentEventParties?.find((p) => p.partyId === s.partyId));

                let extras: SeatingItem[] | undefined = undefined;
                if (!seating?.tables?.[dayNo]) {
                    // parent without table - try to find already seated extras
                    extras = eventDaySeating.filter((s) => s.seating?.tables?.[dayNo]
                        && ep.extraEventParties?.find((extra) => !!s.partyId && s.partyId === extra.partyId));
                }

                items.push(<SeatingPlanGroupItem key={i} dayNo={dayNo}
                    onLabelMouseClick={onLabelMouseClick}
                    onHandleMouseDown={onHandleMouseDown}
                    selectedItems={selectedItems}
                    item={ep}
                    seating={seating}
                    parent={parent}
                    groupsViewMode={groupsViewMode}
                    extras={extras}
                    actions={actions}
                    eventDaySeating={eventDaySeating}
                    tables={tables}
                />);
            });
        }
        if (tickets?.length) {
            const merged = {
                ...tickets[0][0],
                tickets: tickets.map(([et]) => et)
            };
            items.push(<SeatingPlanGroupItem key={'tickets'} dayNo={dayNo}
                onLabelMouseClick={onLabelMouseClick}
                onHandleMouseDown={onHandleMouseDown}
                selectedItems={selectedItems}
                item={merged}
                seating={tickets[0][1]}
                parent={undefined}
                groupsViewMode={groupsViewMode}
                actions={actions}
                eventDaySeating={eventDaySeating}
                tables={tables}
            />);
        }
        return items;

    }, [chunks, tickets, dayNo, onHandleMouseDown, onLabelMouseClick, selectedItems, eventDaySeating, actions, groupsViewMode, tables])

    const onClick = useCallback((e: MouseEvent) => setOpenIds((ids) => {
        e.preventDefault();
        e.stopPropagation();
        if (ids.indexOf(group.groupKey) >= 0) {
            return ids.filter((key) => key !== group.groupKey);
        }
        return [...ids, group.groupKey];
    }), [group.groupKey, setOpenIds]);

    const groupLabelStyle = useMemo(() => ({
        cursor: groupsViewMode === 'list' ? undefined : 'pointer',
        padding: '5px',
        '& .party-tags': {
            margin: groupsViewMode === 'list' ? '-10px 0 0 0' : '-5px 0 -1px -3px'
        }
    }), [groupsViewMode]);

    const groupTicketsStyle = {
        padding: '5px'
    };

    const count = group.isTickets
        ? tickets.length
        : items?.length;
    if (!count) {
        return null;
    }

    return <Grid container sx={groupsViewMode === 'list' ? groupStyleList : groupStyle} columnSpacing={0} columns={8}>
        <Grid item xs={groupsViewMode === 'list' ? 7 : 6}
            sx={group.isTickets ? groupTicketsStyle : groupLabelStyle}
            onClick={(groupsViewMode === 'list' || group.isTickets) ? undefined : onClick}>
            {renderGroupTitle(group, t)}
        </Grid>
        <Grid item xs={1} sx={{padding: '5px 0 0 5px'}}>
            {groupsViewMode === 'list' || groupsViewMode === 'hover'
                ? <Button variant={'text'} size={'small'} color={'inherit'} disabled sx={{color: 'inherit !important'}} fullWidth>{count}</Button>
                : <Button variant={'contained'} size={'small'} color={'inherit'} fullWidth
                    onClick={actions ? (e) => {
                        actions.onMagicWand(group.isTickets ? tickets.map(([et, _]) => et) : chunks, e);
                    } : undefined}>{count}</Button>}
        </Grid>
        {groupsViewMode !== 'list' && <Grid item xs={1} sx={groupArrowStyle} onClick={onClick}>
            {(groupsViewMode === 'tooltip' || groupsViewMode === 'hover')
                ? null
                : (isOpen ? <IndeterminateCheckBoxOutlined/> : <AddBoxOutlined/>)}
        </Grid>}
        {isOpen && <Grid item xs={12}>
            {items}
        </Grid>}
    </Grid>;
}
