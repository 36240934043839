import {JsonFloorItem, JsonFloorShape} from "../../generated-api";
import {BrushType, SetBrushType, SvgShapeDef, SvgShapeItem} from "./svgUtils";
import * as React from "react";
import {MouseEvent, useCallback, useMemo, useRef} from "react";
import {Card, CardContent} from "@mui/material";


const SeatingShapeTool = ({shape, onSetBrush, brush}: {
    shape: JsonFloorShape,
    onSetBrush: SetBrushType,
    brush: BrushType | undefined
}) => {

    const svgRef = useRef<SVGSVGElement | null>(null);

    const handleClick = useCallback((e: MouseEvent, shape: JsonFloorShape) => {
        if (!svgRef.current) {
            return;
        }
        const shapeSvg = (svgRef.current.querySelector('#' + shape.shapeCode)?.children?.[0]) as SVGGraphicsElement;
        if (!shapeSvg) {
            return;
        }

        onSetBrush(brush?.shape?.id === shape.id
            ? undefined
            : {
                shape,
                svgElement: shapeSvg // rect, circle etc
            }
        );

    }, [brush, onSetBrush]);

    const item: JsonFloorItem = {id: -shape.id, shapeCode: shape.shapeCode, x: 5, y: 5};

    return <svg key={shape.id} viewBox="0 0 10 10" width="84" height="84" ref={svgRef}
        id={"type-" + shape.id + "-svg-display"}
        style={{border: "1px solid silver", background: "#fff", margin: "0 5px 0 0"}}
    >
        <defs id={'floor-svg-defs-' + shape.id}>
            <SvgShapeDef shape={shape}/>
        </defs>
        <g id={"floor-furnitures-" + shape.id} className="floor-furnitures" stroke="#888888" fill={brush?.shape?.id === shape.id ? "#ffcc00" : "#dddddd"} strokeWidth="0.25">
            <SvgShapeItem item={item} shape={shape} onMouseDown={(e) => handleClick(e, shape)}/>
        </g>
    </svg>
}

export const SeatingShapeToolbar = ({shapes, onSetBrush, brush}: {
    shapes?: JsonFloorShape[],
    onSetBrush: SetBrushType,
    brush: BrushType | undefined
}) => {

    const tools = useMemo(() => {
        return shapes?.map((shape) => {
            return <SeatingShapeTool key={shape.id} shape={shape} brush={brush} onSetBrush={onSetBrush}/>;
        })
    }, [shapes, brush, onSetBrush]);

    return <>
        {tools}
        <Card sx={{
            fontSize: '85%',
            margin: '10px 15px 0 0',
            '& p': {margin: '5px 0 0 0', lineHeight: '1.2', opacity: .8}
        }}>
            <CardContent style={{padding: '5px 10px 10px 10px'}}>
                <p>Nový tvar: klik na tvar a umisťovat klikáním (jemnější: <kbd>SHIFT</kbd>)</p>
                <p>Posun: klik a poté táhnout, nebo držet a rovnou táhnout</p>
                <p>Výběr více: <kbd>CTRL</kbd> + klik</p>
                <p>Smazání: <kbd>DEL</kbd></p>
                <p>Kopírování: <kbd>CTRL+C</kbd> / <kbd>CTRL+V</kbd></p>
                <p>Posun o krok: <kbd>←↑→↓</kbd> (jemnější: <kbd>SHIFT</kbd>)</p>
                <p>Rotace: <kbd>R</kbd> (30º) / <kbd>SHIFT+R</kbd> (1º)</p>
                <p>Zoom: <kbd>+-</kbd></p>
                <p>Undo/Redo: <kbd>CTRL+Z</kbd> / <kbd>CTRL+Y</kbd></p>
                <p>Storno: <kbd>ESC</kbd> nebo klik pravým</p>
            </CardContent>
        </Card>
    </>
}
