import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {CSSProperties, Dispatch, MouseEvent, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {JsonEventDayInfo, JsonEventFloorDayInfo, JsonEventFloorInfo, JsonFloorItem, JsonFloorShape} from "../../generated-api";
import {OptionValue} from "../../model/form";
import {getLink} from "../../helpers/files";
import {Box, Button, CircularProgress, Grid, Tooltip} from "@mui/material";
import {HelpRounded, RedoOutlined, UndoOutlined} from "@mui/icons-material";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {GroupType} from "../../pages/EventGuestsPage";
import {useModal} from "../../services/modal";
import {BrushType, clampItemsByBoundary, cloneSvgNode, createId, FloorActionType, FloorDirtyValues, FloorItemWithSvg, getNewAngle, getNewScale, getSvgPoint, InputContext, isAnyTableMatch, RewindType, rotateSvgToAngle, SeatingActions, SeatingActionType, SeatingItem, SetBrushType, SvgShapeDef, TableInfo, transform, updateGhostPositions, zoomSvgToScale} from "./svgUtils";
import {SeatingBadge} from "./SeatingBadge";
import {eventDayTitle} from "./SeatingPlan";

const STEP_MOVE = 1;
const STEP_MOVE_SMOOTH = .5;

type ErrorType = {
    text: string,
    itemIds?: number[]
}

type SaveState = {
    isSaving: boolean,
    errors?: ErrorType[],
}

type ShowState = {
    visible: ('plan' | 'text' | 'items')[]
}

const TEXT_SCALE = 0.25;
const TEXT_FONT_SIZE = '12px';
const textStyle: CSSProperties = {
    pointerEvents: 'none',
    fontSize: TEXT_FONT_SIZE,
    userSelect: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'visible',
    // fontFamily: 'Arial Narrow'
    // background: 'rgba(255, 0, 0, .2)',
};

const inputStyle: CSSProperties = {
    fontSize: TEXT_FONT_SIZE,
    border: 'none',
    background: 'rgba(255, 255, 255, 0.5)',
    outline: 'none',
    textAlign: 'center',
    width: '100%',
    marginTop: '-4px',
    padding: '4px 0 0 0',
    display: 'inline-block',
    // fontFamily: 'Arial Narrow'
    // background: 'rgba(255, 0, 0, .2)',
};

const SvgSeatingItem = (
    {
        item,
        shape,
        onMouseDown,
        selectedItems,
        draggedItemIds,
        textItemIds,
        onChangeText,
        visible,
        errors,
        eventFloorDay,
        seatingItems,
        groups,
        selectedSeatingItems,
        onSeatingLabelMouseClick,
        onSeatingHandleMouseDown,
        seatingActions,
        tables
    }: {
        item: JsonFloorItem,
        onMouseDown?: MouseEventHandler,
        shape?: JsonFloorShape,
        selectedItems?: FloorItemWithSvg[],
        draggedItemIds?: number[],
        textItemIds?: number[],
        onChangeText?: (newText: string, nextDir?: 1 | -1) => void,
        visible?: ('plan' | 'text' | 'items')[],
        errors?: ErrorType[],
        seatingItems?: SeatingItem[],
        groups?: GroupType[],
        eventFloorDay?: JsonEventFloorDayInfo,
        selectedSeatingItems?: SeatingItem[],
        onSeatingLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
        onSeatingHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void,
        seatingActions?: SeatingActions,
        tables?: TableInfo[]
    }) => {
    const [textValue, setTextValue] = useState<string>(item.text || '');

    const isTextMode = !!textItemIds?.indexOf && textItemIds.indexOf(item.id) >= 0;
    const isSelected = !!selectedItems?.find((other) => other.item.id === item.id);
    const isDragged = !!draggedItemIds?.indexOf && draggedItemIds.indexOf(item.id) >= 0;

    const text = useMemo(() => {
        if ((isTextMode || item.text) && visible && visible.indexOf('text') >= 0) {
            const t = shape?.text || {x: 0, y: 0, w: 100, h: 100, a: 0};
            const s = TEXT_SCALE / ((item.s || 100) * 0.01);
            const w = t.w * (1 / TEXT_SCALE) * (item.s || 100) * 0.01;
            const h = t.h * (1 / TEXT_SCALE);
            const ta = (t.a || 0);
            const tx = t.x + (ta >= 90 ? (0.3 * (item.s || 100) * 0.01) : 0); // vertical top padding correction
            const ty = t.y + (ta < 90 ? (0.3 * (item.s || 100) * 0.01) : 0); // vertical top padding correction
            if (isTextMode) {
                return <foreignObject id={'item-' + item.id + '-text'}
                    width={w}
                    height={h}
                    style={{
                        fontSize: TEXT_FONT_SIZE,
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        // background: 'rgba(255, 0, 0, .2)',
                    }}
                    transform={'translate(' + tx + ' ' + ty + ')'
                        + ' rotate(' + ta + ' 0 0)'
                        + ' scale(' + s + ' ' + s + ')'}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (onChangeText) {
                            onChangeText(textValue)
                        }
                    }}>
                        <input value={textValue}
                            autoFocus={true}
                            autoComplete={'off'}
                            onFocus={((e) => e.target.select())}
                            onChange={(e) => {
                                setTextValue(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                    if (onChangeText) {
                                        onChangeText(textValue, e.shiftKey ? -1 : 1);
                                    }
                                }
                            }}
                            onBlur={() => {
                                if (onChangeText) {
                                    onChangeText(textValue);
                                }
                            }}
                            style={inputStyle}/>
                    </form>
                </foreignObject>;

            } else {
                return <foreignObject id={'item-' + item.id + '-text'}
                    width={w}
                    height={h}
                    style={textStyle}
                    transform={'translate(' + tx + ' ' + ty + ')'
                        + ' rotate(' + ta + ' 0 0)'
                        + ' scale(' + s + ' ' + s + ')'}
                >
                    {item.text}
                </foreignObject>;
            }
        }
    }, [isTextMode, item.id, item.s, item.text,
        shape?.text, textValue, visible,
        onChangeText]);

    const style = useMemo(() => {
        return {
            opacity: isDragged ? 0.5 : undefined
        }
    }, [isDragged]);

    useEffect(() => {
        if (!isTextMode) {
            setTextValue(item.text || '');
        }
    }, [isTextMode, item.text]);

    return useMemo(() => {
        const g = <g
            id={'item-' + item.id}
            transform={transform(item)}
        >
            {(!visible || visible.indexOf('items') >= 0) && <use id={'item-' + item.id + '-use'}
                xlinkHref={'#' + item.shapeCode}
                fill={!!errors?.length ? '#ffcccc' : (isSelected ? '#ccff00' : undefined)}
                style={style}
                onMouseDown={isTextMode ? undefined : onMouseDown}
                data-capacity={shape?.capacity || undefined}
                data-text={item.text || undefined}
            >{!!errors?.length && <title>{errors?.map((err) => err.text).join(",\n")}</title>}</use>}
            {text}
        </g>;

        return <>
            {g}
            {!!seatingItems?.length && <SeatingBadge item={item}
                seatingItems={seatingItems}
                shape={shape}
                groups={groups}
                eventFloorDay={eventFloorDay}
                onSeatingLabelMouseClick={onSeatingLabelMouseClick}
                onSeatingHandleMouseDown={onSeatingHandleMouseDown}
                selectedSeatingItems={selectedSeatingItems}
                seatingActions={seatingActions}
                tables={tables}
                readonly={!!onMouseDown}
            />}
        </>
    }, [item, text, isSelected, isTextMode,
        shape, style, tables, visible,
        groups, errors, eventFloorDay, seatingItems, seatingActions, selectedSeatingItems,
        onMouseDown, onSeatingHandleMouseDown, onSeatingLabelMouseClick]);
}


type SeatingPlanCanvasProps = {
    eventFloorDay: JsonEventFloorDayInfo,
    eventFloor: JsonEventFloorInfo,
    eventDay: JsonEventDayInfo,
    shapes?: JsonFloorShape[],
    onSetBrush?: SetBrushType,
    brush?: BrushType,
    onSetDirtyValues?: (dirty: FloorDirtyValues) => void,
    dirtyValues: FloorDirtyValues,
    otherFloorsDirtyValues?: FloorDirtyValues[],
    onSaveFloor?: (items: JsonFloorItem[]) => Promise<void>,
    clipboard?: FloorItemWithSvg[] | undefined,
    setClipboard?: Dispatch<SetStateAction<FloorItemWithSvg[] | undefined>>,
    seating?: SeatingItem[],
    originalSeating?: SeatingItem[],
    onSaveSeating?: (seating: SeatingItem[]) => Promise<void>,
    groups?: GroupType[],
    seatingUndoStack?: SeatingActionType[],
    seatingRedoStack?: SeatingActionType[],
    seatingSelectedItems?: SeatingItem[],
    onSeatingLabelMouseClick?: (ep: SeatingItem, e: MouseEvent) => void,
    onSeatingHandleMouseDown?: (ep: SeatingItem, e: MouseEvent) => void,
    seatingActions?: SeatingActions,
    tables?: TableInfo[]
}

export const SeatingPlanCanvas = (props: SeatingPlanCanvasProps) => {

    const {
        eventFloor, eventDay, eventFloorDay, shapes,
        brush, onSetBrush,
        onSetDirtyValues, dirtyValues, otherFloorsDirtyValues, onSaveFloor,
        clipboard, setClipboard,
        seating, originalSeating, onSaveSeating, groups,
        seatingUndoStack, seatingRedoStack,
        seatingSelectedItems, onSeatingLabelMouseClick, onSeatingHandleMouseDown,
        seatingActions, tables
    } = props;

    const t = useAppTranslation();
    const modal = useModal();

    const svgRef = useRef<SVGSVGElement | null>(null);
    const svgDragGroupRef = useRef<SVGGElement | null>(null);
    const pendingItem = useRef<FloorItemWithSvg | null>();
    const ghostItems = useRef<FloorItemWithSvg[] | null>();

    const inputContext = useRef<InputContext<FloorDirtyValues>>({
        mouseAction: undefined,
        mouseActionTimeout: undefined,
        mouseX: 0,
        mouseY: 0,
        dirtyValues
    });

    const [undoStack, setUndoStack] = useState<FloorActionType[]>(dirtyValues.undoStack);
    const [redoStack, setRedoStack] = useState<FloorActionType[]>(dirtyValues.redoStack);
    const [items, setItems] = useState<JsonFloorItem[]>(dirtyValues.items);

    const [selectedItems, setSelectedItems] = useState<FloorItemWithSvg[] | undefined>([]);
    const [draggedItemIds, setDraggedItemIds] = useState<number[] | undefined>([]);
    const [textItemIds, setTextItemIds] = useState<number[] | undefined>([]);

    const [showState, setShowState] = useState<ShowState>({visible: ['items', 'text']});
    const [saveState, setSaveState] = useState<SaveState>({isSaving: false});

    const isFloorMode = !!onSaveFloor;
    const isSeatingMode = !!onSaveSeating;

    const showOptions: OptionValue[] = useMemo(() => {
        const options = [];
        if (eventFloor.planGuid) {
            options.push({value: 'plan', label: t('Předloha')});
        }
        options.push({value: 'items', label: t('Nábytek')});
        options.push({value: 'text', label: t('Text')});
        return options;
    }, [eventFloor.planGuid, t]);

    /**
     * Actions
     */

    const validateFloor = useCallback((items: JsonFloorItem[]) => {
        const errors: ErrorType[] = [];
        const doneTableNames: string[] = [];
        items.forEach((item) => {
            const tableName = item.text?.trim();
            if (!tableName || doneTableNames.indexOf(tableName) >= 0) {
                return;
            }
            const dupl = items.filter((other) => other.text?.trim() === tableName);
            if (dupl.length > 1) {
                errors.push({
                    itemIds: dupl.map((item) => item.id),
                    text: t('Stůl "{{tableName}}" je uveden vícekrát (celkem {{count}}x)', {
                        tableName,
                        count: dupl.length
                    })
                })
                // dupl.forEach((other) => {
                //     errors[other.id] = t('Stůl {{text}}: Duplicitní označení');
                // })
            } else if (!!otherFloorsDirtyValues?.length) {
                otherFloorsDirtyValues.forEach((f) => {
                    const otherDupl = f.items.filter((other) => other.text?.trim() === tableName);
                    if (otherDupl.length > 0) {
                        errors.push({
                            itemIds: [...otherDupl.map((item) => item.id), item.id],
                            text: t('Stůl "{{tableName}}" je uveden také na podlaží {{title}} (celkem {{count}}x)', {
                                tableName,
                                title: f.title,
                                count: otherDupl.length
                            })
                        });
                    }
                });
            }
            doneTableNames.push(tableName);
        });
        seating?.forEach((ep) => {
            if (!eventDay.dayNo) {
                return;
            }
            ep.seating?.tables?.[eventDay.dayNo]?.split(' ')?.forEach((tableName) => {
                if (doneTableNames.indexOf(tableName) >= 0) {
                    return;
                }
                const item = items.find((item) => item.text === tableName);
                if (!item && dirtyValues.originalItems.find((item) => item.text === tableName)) {
                    errors.push({
                        text: t('Chybí stůl "{{tableName}}", na kterém jsou usazení hosté (celkem {{count}})', {
                            tableName, count:
                                seating?.filter((ep) => eventDay.dayNo && ep.seating?.tables?.[eventDay.dayNo] === tableName)?.length || 0
                        })
                    })
                }
                doneTableNames.push(tableName);
            });
        });
        return errors;

    }, [t, eventDay.dayNo, seating, dirtyValues.originalItems, otherFloorsDirtyValues]);

    const handleSaveFloor = useCallback(async () => {

        if (!onSaveFloor) {
            return null;
        }

        setSaveState({isSaving: true});

        const errors = validateFloor(items);

        if (!errors.length) {
            await onSaveFloor(items);
        } else {
            await modal.info({
                title: t('Plán obsahuje chyby'),
                message: <div>
                    <p>{t('Před uložením vyřešte prosím následující problémy:')}</p>
                    <ul>
                        {errors.map((error, i) => {
                            return <li key={i}>{error.text}</li>;
                        })}
                    </ul>
                </div>,
                confirmColor: 'info',
                confirmText: 'Rozumím',
            })
        }

        setSaveState({isSaving: false, errors});

    }, [items, onSaveFloor, validateFloor, modal, t]);

    const handleSaveSeating = useCallback(async () => {

        if (!onSaveSeating || !seating) {
            return null;
        }

        setSaveState({isSaving: true});

        await onSaveSeating(seating);

        setSaveState({isSaving: false});

    }, [seating, onSaveSeating]);

    const setRewind = useCallback((action: FloorActionType, rewindType?: RewindType) => {
        if (!rewindType) {
            setRedoStack([]);
            inputContext.current.dirtyValues.redoStack = [];
        }
        if (rewindType === 'undo') {
            setRedoStack((q) => {
                const merged = [...q.slice(q.length - 1000), action];
                inputContext.current.dirtyValues.redoStack = merged;
                return merged;
            });
        } else {
            setUndoStack((q) => {
                const merged = [...q.slice(q.length - 1000), action];
                inputContext.current.dirtyValues.undoStack = merged;
                return merged;
            });
        }

    }, []);

    const actionAddItem = useCallback((newItems: JsonFloorItem[], boundingBox?: SVGSVGElement, rewindType?: RewindType) => {
        const clamped = clampItemsByBoundary(newItems, boundingBox);
        setItems((items) => {
            const merged = [...items, ...clamped];
            merged.forEach((c) => {
                if (c.id <= 0) {
                    c.id = createId(merged);
                }
            });
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'add',
            newItems: clamped
        }, rewindType);

    }, [setRewind]);

    const actionMoveItem = useCallback((currentItems: JsonFloorItem[], newItems: JsonFloorItem[], boundingBox?: SVGSVGElement, rewindType?: RewindType) => {
        const clamped = clampItemsByBoundary(newItems, boundingBox);
        setItems((items) => {
            const merged = items.map((item) => clamped.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'move',
            oldItems: currentItems.slice(),
            newItems: clamped
        }, rewindType);

    }, [setRewind]);

    const actionRotateItem = useCallback((currentItems: JsonFloorItem[], a?: number, rewindType?: RewindType) => {
        const newItems: JsonFloorItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            a
        }));
        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'rotate',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind]);

    const actionScaleItem = useCallback((currentItems: JsonFloorItem[], s?: number, rewindType?: RewindType) => {
        const newItems: JsonFloorItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            s
        }));
        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'scale',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind]);

    const actionUpdateItem = useCallback((currentItems: JsonFloorItem[], text?: string, rewindType?: RewindType) => {
        const newItems: JsonFloorItem[] = currentItems.map((currentItem) => ({
            ...currentItem,
            text
        }));

        setItems((items) => {
            const merged = items.map((item) => newItems.find((newItem) => newItem.id === item.id) || item);
            inputContext.current.dirtyValues.items = merged;
            setSaveState((s) => ({...s, errors: validateFloor(merged)}));
            return merged;
        });
        setRewind({
            action: 'text',
            oldItems: currentItems.slice(),
            newItems: newItems
        }, rewindType);

    }, [setRewind, setItems, validateFloor]);

    const actionDeleteItem = useCallback((currentItems: JsonFloorItem[], rewindType?: RewindType) => {
        setItems((items) => {
            const merged = items.filter((item) => !currentItems.find((currentItem) => currentItem.id === item.id));
            inputContext.current.dirtyValues.items = merged;
            return merged;
        });
        setRewind({
            action: 'delete',
            oldItems: currentItems.slice(),
        }, rewindType);

    }, [setRewind]);

    /**
     * Drawing
     */

    const cancelAllPendingActions = useCallback((clearBrush: boolean) => {
        if (svgDragGroupRef.current) {
            while (svgDragGroupRef.current?.hasChildNodes() && svgDragGroupRef.current.lastChild) {
                svgDragGroupRef.current.removeChild(svgDragGroupRef.current.lastChild)
            }
        }
        pendingItem.current = undefined;
        ghostItems.current = undefined;

        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }
        inputContext.current.mouseAction = undefined;
        setDraggedItemIds(undefined);
        setTextItemIds(undefined);

        if (clearBrush && onSetBrush) {
            onSetBrush(undefined);
        }

    }, [onSetBrush]);

    const activeItemStartDrag = useCallback((originItem: FloorItemWithSvg, selectedItems?: FloorItemWithSvg[]) => {
        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }

        if (!originItem) {
            inputContext.current.mouseAction = undefined;
            return;
        }
        inputContext.current.mouseAction = 'drag';
        ghostItems.current = [];
        const draggedIds: number[] = [];
        for (let item of [originItem, ...(selectedItems?.filter((other) => other.item.id !== originItem.item.id) || [])]) {
            const current = items.find((currentItem) => currentItem.id === item.item.id) || item.item;
            const ghostItem = {
                item: {...current},
                svgElement: cloneSvgNode(item.svgElement, 'ghost-item')
            };
            ghostItems.current.push(ghostItem);
            svgDragGroupRef.current?.appendChild(ghostItem.svgElement);
            draggedIds.push(current.id);
        }
        setDraggedItemIds(draggedIds);

    }, [items]);

    const activeItemStartText = useCallback((originItem: FloorItemWithSvg) => {
        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }

        pendingItem.current = undefined;

        if (!originItem) {
            inputContext.current.mouseAction = undefined;
            return;
        }

        inputContext.current.mouseAction = 'text';
        setTextItemIds([originItem.item.id]);

    }, []);

    const renderBrush = useCallback((svg: SVGSVGElement, brush: BrushType, pos: { x: number, y: number }) => {
        setSelectedItems(undefined);

        const svgElement = cloneSvgNode(brush.svgElement, 'ghost-shape');
        if (brush.a) {
            rotateSvgToAngle(svg, svgElement, brush.a);
        }
        if (brush.s && brush.s !== 100) {
            zoomSvgToScale(svg, svgElement, brush.s);
        }
        activeItemStartDrag({
            item: {
                id: createId(),
                shapeCode: brush.shape.shapeCode,
                x: pos.x,
                y: pos.y,
                a: brush.a,
                s: brush.s
            },
            svgElement
        });
    }, [activeItemStartDrag]);

    /**
     * Event handlers
     */

    const dragTimeout = useCallback(() => {
        if (pendingItem.current) {
            activeItemStartDrag(pendingItem.current, selectedItems);
        }
    }, [selectedItems, activeItemStartDrag]);

    const handleItemMouseDown = useCallback((item: JsonFloorItem, e: MouseEvent) => {
        if (e.button === 2) {
            // right click
            return;
        }
        const svgElement = (e.target as Node).parentNode as SVGGraphicsElement; // <g>
        if (!svgElement) {
            return;
        }
        if (!e.ctrlKey) {
            // unselect all if not selecting (any) current
            setSelectedItems((selected) => {
                return selected?.find(s => s.item.id === item.id) ? selected : undefined
            });
        }

        pendingItem.current = {
            item,
            svgElement
        };

        inputContext.current.mouseAction = 'click';

        if (inputContext.current.mouseActionTimeout) {
            clearTimeout(inputContext.current.mouseActionTimeout);
        }
        inputContext.current.mouseActionTimeout = setTimeout(dragTimeout, 500);

    }, [dragTimeout]);

    const handleCanvasMouseUp = useCallback((e: MouseEvent) => {
        if (e.button === 2) {
            // right click
            setSelectedItems(undefined);
            cancelAllPendingActions(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (inputContext.current.mouseAction === 'text') {
            if (e.target === svgRef.current) {
                setSelectedItems(undefined);
                cancelAllPendingActions(true);
            }
            return;
        }

        if (!svgRef.current || !(pendingItem.current || ghostItems.current)) {
            setSelectedItems(undefined);
            setDraggedItemIds(undefined);
            return;
        }

        const currentItem = pendingItem.current;
        if (inputContext.current.mouseAction === 'click') {
            if (currentItem) {
                const isAlreadySelected = selectedItems?.find((s) => s.item.id === currentItem.item.id);
                if (isAlreadySelected && !e.ctrlKey) {
                    activeItemStartText(currentItem);
                    setSelectedItems([currentItem]);
                    return; // do not clear
                }
                setSelectedItems((selected) => {
                    if (isAlreadySelected) {
                        return selected?.length === 1 ? undefined : selected?.filter(s => s.item.id !== currentItem.item.id);
                    } else {
                        if (e.ctrlKey) {
                            return selected ? [...selected, currentItem] : [currentItem];
                        } else {
                            return [currentItem];
                        }
                    }
                })
            }

        } else if (ghostItems.current?.[0]) {
            if (selectedItems?.length) {
                actionMoveItem(
                    selectedItems.map((selectedItem) => selectedItem.item),
                    ghostItems.current?.map((ghostItem) => ghostItem.item),
                    svgRef.current);

            } else if (currentItem) {
                actionMoveItem([currentItem.item], [ghostItems.current[0].item]);

            } else {
                actionAddItem(ghostItems.current?.map((ghostItem) => ghostItem.item), svgRef.current);
                if (brush && ghostItems.current?.[0] && onSetBrush) {
                    onSetBrush({
                        ...brush,
                        a: ghostItems.current[0].item?.a,
                        s: ghostItems.current[0].item?.s
                    });
                }
            }
        }
        cancelAllPendingActions(false);

    }, [cancelAllPendingActions, actionMoveItem, actionAddItem, selectedItems, brush, onSetBrush, activeItemStartText]);

    const handleCanvasMouseMove = useCallback((e: MouseEvent) => {
        inputContext.current.mouseX = e.clientX;
        inputContext.current.mouseY = e.clientY;

        if (!svgRef.current || !(ghostItems.current || brush?.svgElement || pendingItem.current)) {
            return;
        }
        const pos = getSvgPoint(e, svgRef.current, e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE);

        if (!ghostItems.current) {
            if (brush?.svgElement) {
                // first entry after toolbar pick
                renderBrush(svgRef.current, brush, pos);

            } else if (pendingItem.current?.item) {
                // pressed and moving immediately (single item only)
                if (Math.abs(pendingItem.current.item.x - pos.x) > 5 || Math.abs(pendingItem.current.item.y - pos.y) > 5) {
                    activeItemStartDrag(pendingItem.current, selectedItems);

                } else {
                    // clicking
                    return;
                }
            }
        }
        if (pos && ghostItems.current) {
            updateGhostPositions(pos, ghostItems.current);
        }

    }, [brush, activeItemStartDrag, selectedItems, renderBrush]);

    const handleChangeText = useCallback((item: JsonFloorItem, text: string, nextDir?: 1 | -1) => {
        if ((item.text || '') !== text) {
            actionUpdateItem([item], text);
        }
        if (nextDir) {
            // find next closest
            setTextItemIds((ids) => {
                const current = ids?.[0] !== undefined
                    ? items.find((current) => current.id === ids[0])
                    : undefined;
                if (!current || !items.length) {
                    return items.length ? [items[0].id] : undefined;
                }
                const isAnyWithoutText = items.find(a => !a.text);

                const sorted = items.filter(a => !a.text || !isAnyWithoutText).sort((a, b) => {
                    const BAND = 5;
                    const ax = (a.x - a.x % BAND);
                    const ay = (a.y - a.y % BAND);
                    const bx = (b.x - b.x % BAND);
                    const by = (b.y - b.y % BAND);
                    if (ay === by) {
                        return ax > bx ? 1 : -1;
                    }
                    return ay > by ? 1 : -1;
                    // const as = a.x * a.x + a.y + a.y;
                    // const bs = b.x * b.x + b.y + b.y;
                    // return as === bs ? 0 : (as > bs ? 1 : -1);
                });
                const i = sorted.indexOf(current) + nextDir;
                if (i < 0) {
                    return [sorted[sorted.length - 1].id];
                }
                return [sorted[i % sorted.length].id];
            });
        } else {
            cancelAllPendingActions(true);
        }
    }, [actionUpdateItem, cancelAllPendingActions, items]);

    const handleRewindAction = useCallback((a: FloorActionType, rewindType: RewindType) => {
        switch (a.action) {
            case 'move':
                if (a.newItems && a.oldItems?.[0]) {
                    actionMoveItem(a.newItems, a.oldItems, undefined, rewindType);
                }
                break;
            case 'add':
                if (a.newItems) {
                    actionDeleteItem(a.newItems, rewindType);
                }
                break;
            case 'delete':
                if (a.oldItems) {
                    actionAddItem(a.oldItems, undefined, rewindType);
                }
                break;
            case 'rotate':
                if (a.newItems && a.oldItems?.[0]) {
                    actionRotateItem(a.newItems, a.oldItems[0].a, rewindType);
                }
                break;
            case 'scale':
                if (a.newItems && a.oldItems?.[0]) {
                    actionScaleItem(a.newItems, a.oldItems[0].s, rewindType);
                }
                break;
            case 'text':
                if (a.newItems && a.oldItems?.[0]) {
                    actionUpdateItem(a.newItems, a.oldItems[0].text, rewindType);
                }
                break;
        }

    }, [actionMoveItem, actionAddItem, actionDeleteItem, actionRotateItem, actionScaleItem, actionUpdateItem]);

    const rewindLastAction = useCallback((isRedo: boolean) => {
        (isRedo ? setRedoStack : setUndoStack)((actions) => {
            if (!actions.length) {
                return [];
            }
            const last = actions[actions.length - 1];
            if (last) {
                handleRewindAction(last, isRedo ? 'redo' : 'undo');
            }
            return actions.filter((a) => a !== last);
        });
    }, [handleRewindAction]);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (!svgRef.current) {
            return;
        }

        switch (e.key) {
            case 'Escape':
                cancelAllPendingActions(true);
                setSelectedItems(undefined);
                break;
        }

        if (inputContext.current.mouseAction === 'text') {
            return;
        }

        const currentItems = items.filter((item) => selectedItems?.find((selected) => selected.item.id === item.id));

        switch (e.key) {
            case 'Delete':
            case 'Backspace':
                if (ghostItems.current) {
                    cancelAllPendingActions(true);
                    break;
                }
                if (currentItems.length) {
                    actionDeleteItem(currentItems);
                }
                setSelectedItems(undefined);
                break;

            case 'z':
            case 'y':
                if (ghostItems.current && !brush) {
                    break;
                }
                if (e.ctrlKey) {
                    rewindLastAction(e.key === 'y');
                    e.preventDefault();
                }
                break;

            case 'a':
                if (ghostItems.current) {
                    return;
                }
                const svgElements = svgRef.current?.querySelectorAll("g[id*=item]");
                const newSelectedItems: FloorItemWithSvg[] = [];
                svgElements.forEach((svgElement) => {
                    const item = items.find((item) => 'item-' + item.id === svgElement.id)
                    if (item && svgElement instanceof SVGGraphicsElement) {
                        newSelectedItems.push({
                            item,
                            svgElement
                        })
                    }
                });
                if (newSelectedItems.length) {
                    setSelectedItems(newSelectedItems);
                    e.preventDefault();
                }
                break;

            case 'r':
            case 'R':
            case 'ArrowRight':
            case 'ArrowLeft':
            case 'ArrowUp':
            case 'ArrowDown':
                if (((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && e.ctrlKey) || e.key === 'r' || e.key === 'R') {
                    if (ghostItems.current) {
                        const a = ghostItems.current[0].item?.a;
                        for (let ghostItem of ghostItems.current) {
                            ghostItem.item.a = getNewAngle(e.key, a, e.shiftKey);
                            rotateSvgToAngle(svgRef.current, ghostItem.svgElement, ghostItem.item.a);
                        }

                    } else if (currentItems.length) {
                        actionRotateItem(currentItems, getNewAngle(e.key, currentItems?.[0]?.a, e.shiftKey));
                    }
                } else if (currentItems.length) {
                    const step = e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE;
                    actionMoveItem(currentItems, currentItems.map((currentItem) => ({
                        ...currentItem,
                        x: (e.key === 'ArrowLeft' ? currentItem.x - step : (e.key === 'ArrowRight' ? currentItem.x + step : currentItem.x)),
                        y: (e.key === 'ArrowUp' ? currentItem.y - step : (e.key === 'ArrowDown' ? currentItem.y + step : currentItem.y)),
                    })), svgRef.current);
                }
                e.preventDefault();
                break;

            case '+':
            case '-':
                if (ghostItems.current) {
                    for (let ghostItem of ghostItems.current) {
                        ghostItem.item.s = getNewScale(e.key, ghostItems.current[0].item?.s);
                        zoomSvgToScale(svgRef.current, ghostItem.svgElement, ghostItem.item.s);
                    }

                } else if (currentItems.length) {
                    const newScale = getNewScale(e.key, currentItems?.[0]?.s);
                    if (Math.abs(newScale - (currentItems?.[0]?.s || 100)) < 1) {
                        break;
                    }
                    actionScaleItem(currentItems, getNewScale(e.key, currentItems?.[0]?.s));
                }
                e.preventDefault();
                break;

            case 'c':
                if (!e.ctrlKey || !currentItems.length || ghostItems.current) {
                    return;
                }
                // setClipboard(currentItems);
                const copy: FloorItemWithSvg[] = [];
                for (let item of currentItems) {
                    const selected = selectedItems?.find((selected) => selected.item.id === item.id);
                    if (!selected) {
                        continue;
                    }
                    copy.push({
                        item,
                        svgElement: selected.svgElement
                    })
                }
                if (copy.length && setClipboard) {
                    setClipboard(copy);
                }
                e.preventDefault();
                break;

            case 'v':
                if (!e.ctrlKey || !clipboard || ghostItems.current) {
                    return;
                }
                setSelectedItems(undefined);
                const newItems: FloorItemWithSvg[] = [];
                const pos = getSvgPoint({
                        clientX: inputContext.current.mouseX,
                        clientY: inputContext.current.mouseY
                    },
                    svgRef.current,
                    e.shiftKey ? STEP_MOVE_SMOOTH : STEP_MOVE);

                for (let item of clipboard) {
                    const newId = createId();
                    newItems.push({
                        item: {
                            ...item.item,
                            id: newId,
                        },
                        svgElement: cloneSvgNode(item.svgElement, 'ghost-' + newId)
                    })
                }
                if (items.length <= 0) {
                    actionAddItem(newItems.map((newItem) => newItem.item), svgRef.current);

                } else {
                    activeItemStartDrag(newItems[0], newItems.slice(1));
                    if (ghostItems.current) {
                        updateGhostPositions(pos, ghostItems.current);
                    }
                }
                e.preventDefault();
                break;

            // case 'i':
            //     if (!e.ctrlKey) {
            //         return;
            //     }
            //     let i = 1;
            //     items
            //         // .filter(x => !x.text)
            //         .forEach((item) => {
            //         actionUpdateItem([item], '' + i++);
            //     });
            //     return;
        }

    }, [cancelAllPendingActions, activeItemStartDrag, rewindLastAction,
        actionDeleteItem, actionRotateItem, actionMoveItem, actionScaleItem, actionAddItem,
        brush, selectedItems, items, clipboard, setClipboard]);

    const isFloorChanged = useMemo(() => {
        return JSON.stringify(eventFloorDay?.floorData?.items) !== JSON.stringify(items);
    }, [eventFloorDay?.floorData?.items, items])

    const isSeatingChanged = useMemo(() => {
        return JSON.stringify(originalSeating) !== JSON.stringify(seating);
    }, [originalSeating, seating]);

    useEffect(() => {
        if (brush) {
            setSelectedItems(undefined);
        }
        if (!brush) {
            cancelAllPendingActions(true);
        }

    }, [brush, cancelAllPendingActions]);

    useEffect(() => {
        if (!isFloorMode) {
            return;
        }
        window.addEventListener('keydown', handleKeyDown);
        const ctx = inputContext.current;
        return () => {
            if (ctx.mouseActionTimeout) {
                clearTimeout(ctx.mouseActionTimeout);
            }
            window.removeEventListener('keydown', handleKeyDown);
            if (onSetDirtyValues) {
                onSetDirtyValues(ctx.dirtyValues);
            }
        }
    }, [handleKeyDown, onSetDirtyValues, isFloorMode]);

    const viewBox = '0 0 ' + (eventFloor?.floorData?.svgWidth || 260) + ' ' + (eventFloor?.floorData?.svgHeight || 138);

    return <div>
        <Box sx={{position: "relative", '& > svg': {border: "1px solid silver", background: "#fff", maxHeight: 'calc(100vh - 100px)', maxWidth: 'calc(100vw - 100px)'}}}>
            {!!eventFloor?.planGuid && showState.visible.indexOf('plan') >= 0 &&
                <img src={getLink(eventFloor.planGuid)} alt={eventFloor.title}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        pointerEvents: 'none',
                        opacity: .25
                    }}/>}
            <svg
                viewBox={viewBox}
                ref={svgRef} id={'floor-svg'}
                onMouseMove={!isFloorMode ? undefined : handleCanvasMouseMove}
                onMouseUp={!isFloorMode ? undefined : handleCanvasMouseUp}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
            >
                <defs id={'floor-svg-defs'}>
                    {shapes?.map((shape) => <SvgShapeDef key={shape.id} shape={shape}/>)}
                </defs>
                {!!eventFloor?.floorData?.svgFixedItems &&
                    <g id="floor-fixed" className="fixed-fixed" dangerouslySetInnerHTML={{__html: eventFloor.floorData.svgFixedItems}} fill="#dddddd">
                    </g>}
                <g id="floor-furnitures" className="floor-furnitures" stroke="#888888" fill="#dddddd" strokeWidth="0.25">
                    {items?.sort((a, b) => a.y === b.y ? (a.x > b.x ? -1 : 1) : (a.y > b.y ? -1 : 1))?.map((item, i) => {
                        const shape = shapes?.find((s) => s.shapeCode === item.shapeCode);
                        return <SvgSeatingItem key={i} item={item} shape={shape}
                            onMouseDown={!isFloorMode ? undefined : (e) => handleItemMouseDown(item, e)}
                            selectedItems={selectedItems}
                            draggedItemIds={draggedItemIds}
                            textItemIds={textItemIds}
                            onChangeText={(text, nextDir) => handleChangeText(item, text, nextDir)}
                            visible={showState.visible}
                            errors={saveState.errors?.filter((err) => err.itemIds && err.itemIds.indexOf(item.id) >= 0)}
                            seatingItems={item.text ? seating?.filter((ep) => eventDay.dayNo && isAnyTableMatch(ep.seating?.tables?.[eventDay.dayNo], item.text)) : undefined}
                            groups={groups}
                            eventFloorDay={eventFloorDay}
                            selectedSeatingItems={seatingSelectedItems}
                            onSeatingLabelMouseClick={onSeatingLabelMouseClick}
                            onSeatingHandleMouseDown={onSeatingHandleMouseDown}
                            seatingActions={seatingActions}
                            tables={tables}
                        />
                    })}
                </g>
                <g id="floor-drag" className="floor-drag" fill="#cc00ff" strokeWidth="1" ref={svgDragGroupRef}>
                </g>
            </svg>
        </Box>
        {isFloorMode && <Grid container sx={{paddingTop: '10px'}}>
            <Grid item xs={4} sx={{'& > button + button': {marginLeft: '10px'}}}>
                <Button variant={'contained'} disabled={!undoStack.length}
                    title={t('O akci zpět ({{length}})', {length: undoStack.length})}
                    onClick={() => rewindLastAction(false)}><UndoOutlined/></Button>
                <Button variant={'contained'} disabled={!redoStack.length}
                    title={t('O akci dopředu ({{length}})', {length: redoStack.length})}
                    onClick={() => rewindLastAction(true)}><RedoOutlined/></Button>
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'center'}}>
                <ButtonGroupPlain currentValue={showState.visible} name={'visible'} options={showOptions} isMulti={true} onChange={(visible) => {
                    setShowState({visible})
                }}/>

            </Grid>
            <Grid item xs={4} sx={{textAlign: 'right'}}>
                {isFloorChanged && <Button variant={'contained'} disabled={saveState.isSaving} onClick={handleSaveFloor}
                >{t('Uložit {{title}}', {title: eventFloor?.title + ' (' + eventDayTitle(eventDay) + ')'})}</Button>}
            </Grid>
        </Grid>}
        {isSeatingMode && seatingActions && <Grid container sx={{paddingTop: '10px'}}>
            <Grid item xs={4} sx={{'& > button + button': {marginLeft: '10px'}}}>
                <Button variant={'contained'} disabled={!seatingUndoStack?.length}
                    title={t('O usazení zpět ({{length}})', {length: seatingUndoStack?.length})}
                    onClick={() => seatingActions.rewindLastSeatingAction(false)}><UndoOutlined/></Button>
                <Button variant={'contained'} disabled={!seatingRedoStack?.length}
                    title={t('O usazení dopředu ({{length}})', {length: seatingRedoStack?.length})}
                    onClick={() => seatingActions.rewindLastSeatingAction(true)}><RedoOutlined/></Button>
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'center'}}>
                <Tooltip title={<div style={{fontSize: '120%', padding: '10px'}}>
                    <ul>
                        <li>
                            Osobu (osoby) pro usazení je možné "do ruky" vybrat v nabídce vlevo nebo v tooltipu u stolu:
                            <ul>
                                <li>kliknutím na šedé tlačítko s číslem (celá skupina)</li>
                                <li>kliknutím na tlačítko s tečkami u jména osoby</li>
                                <li>(více osob je možné vybrat stiknustím <code>CTRL</code>)</li>
                            </ul>
                        </li>
                        <li>
                            Usazení je možné provést:
                            <ul>
                                <li>kliknutím na stůl - usadí vše (bez ohledu na kapacitu) a "odebere z ruky"</li>
                                <li>kliknutím na stůl s podrženým <code>CTRL</code> - usadí vše (bez ohledu na kapacitu) a ponechá "v ruce"</li>
                                <li>kliknutím na stůl s podrženým <code>SHIFT</code> - usadí do maxima volné kapacity a zbytek ponechá "v ruce"</li>
                            </ul>
                        </li>
                        <li>Zrušení usazení kliknutím na tlačítko s názvem stolu u dané osoby (v nabídce vlevo nebo v tooltipu).</li>
                    </ul>
                </div>} PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            maxWidth: 'clamp(200px, 400px, 80vw)'
                        },
                        'li + li': {
                            marginTop: '10px'
                        }
                    }
                }}
                    placement={'top'}><abbr><HelpRounded/></abbr></Tooltip>
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'right'}}>
                {isSeatingChanged &&
                    <Button variant={'contained'} disabled={saveState.isSaving} onClick={handleSaveSeating}
                    >{saveState.isSaving &&
                        <CircularProgress size={20}/>}<span>{t('Uložit místa pro všechny dny')}</span></Button>}
            </Grid>
        </Grid>}
    </div>
}
