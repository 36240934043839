import {useAppDispatch, useAppSelector} from "../store";
import {selectCodebooks} from "../store/selectors";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Chip} from "@mui/material";
import {useAppTranslation} from "../services/i18n";
import {JsonPartyMassActionRequest, JsonPartyMassActionRequestActionEnum} from "../generated-api";
import {massParty} from "../store/parties";
import {getApiResult} from "../helpers/api";
import {FIXED_INVITE_TAG_ID} from "../store/codebooks";
import {shorten} from "../helpers/format";

type PartyTagProps = {
    tags?: number[] | undefined,
    quickEditTagId?: number,
    partyId?: number,
    short?: boolean
}

const PartyTags = (props: PartyTagProps) => {
    const {tags, quickEditTagId, partyId, short} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector(selectCodebooks);
    const [myTags, setMyTags] = useState<number[] | undefined>();
    const tagOptions = codebooks['tag'];

    const handleQuickTagSave = useCallback(async (partyId: number, tagId: number, isAdd: boolean) => {
        const ma: JsonPartyMassActionRequest = {
            action: JsonPartyMassActionRequestActionEnum.UpdateTags,
            items: [{
                partyId,
                tags: isAdd ? [tagId] : undefined,
                removeTags: !isAdd ? [tagId] : undefined
            }]
        }
        const res = await dispatch(massParty({request: ma}));
        const items = getApiResult(res);
        return items?.[0]?.tags;

    }, [dispatch]);

    const items: JSX.Element[] | null = useMemo(() => {
        if (!tagOptions || (!myTags?.length && !quickEditTagId)) {
            return null;
        }
        const hasQuickTag = !!quickEditTagId && myTags?.find((t) => t === quickEditTagId);

        const items: JSX.Element[] = [];
        if (quickEditTagId && !hasQuickTag) {
            const o = tagOptions.find((o) => o?.value === "" + quickEditTagId);
            if (o) {
                items.push(<Chip key={'quick'} size={'small'} className={'party-tags-add'}
                    label={'+'} title={t('Kliknutím přidat štítek {{title}}', {title: o.label})}
                    // style={o.params?.color ? {backgroundColor: o.params.color as any as string} : undefined}
                    onClick={async () => {
                        if (partyId) {
                            setMyTags(await handleQuickTagSave(partyId, quickEditTagId, true));
                        }
                    }}
                />)
            }
        }
        if (myTags && myTags.length && myTags.length > 0) {
            tagOptions
                ?.filter((o, i) => o?.value && myTags?.indexOf(parseInt(o.value, 10)) >= 0)
                .forEach((o, i) => {
                    if (quickEditTagId && o?.value === "" + quickEditTagId) {
                        items.push(<Chip key={i} size={'small'} className={'party-tags-remove'}
                            label={o.label && o.label[0]}
                            title={t('Kliknutím odebrat štítek {{title}}', {title: o.label})}
                            onClick={async () => {
                                if (partyId) {
                                    setMyTags(await handleQuickTagSave(partyId, quickEditTagId, false));
                                }
                            }}
                            style={o.params?.color ? {backgroundColor: o.params.color as any as string} : undefined}/>)
                        return;
                    }
                    items.push(<Chip key={i} size={'small'}
                        label={o.value === '' + FIXED_INVITE_TAG_ID ? o.label?.[0] : (short ? shorten(o.label) : o.label)}
                        title={short ? o.label : undefined}
                        style={o.params?.color ? {backgroundColor: o.params.color as any as string} : undefined}/>)
                });
        }

        return items;

    }, [myTags, quickEditTagId, handleQuickTagSave, partyId, tagOptions, short, t]);

    useEffect(() => {
        setMyTags(tags)
    }, [tags]);

    return items ? <div className={'party-tags'}>{items}</div> : null;
}

export default PartyTags;
