import FormModal from "../components/form/FormModal";
import {JsonTariff} from "../generated-api";
import {FormProps} from "../model/form";
import {useAppTranslation} from "../services/i18n";
import EventTariffForm from "../components/event/EventTariffForm";

const EventTariffModal = (props: FormProps<JsonTariff>) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.item?.tariffId ? t('Úprava tarifu') : t('Nový tarif')} {...props}>
            <EventTariffForm {...props}></EventTariffForm>
        </FormModal>
    );
}

export default EventTariffModal;
