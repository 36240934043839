/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonTariff,
    ApiResultOfJsonTariffFromJSON,
    ApiResultOfJsonTariffToJSON,
    ApiResultOfJsonTariffInfo,
    ApiResultOfJsonTariffInfoFromJSON,
    ApiResultOfJsonTariffInfoToJSON,
    ApiResultOfListOfJsonTariffInfo,
    ApiResultOfListOfJsonTariffInfoFromJSON,
    ApiResultOfListOfJsonTariffInfoToJSON,
    ApiResultOfVoid,
    ApiResultOfVoidFromJSON,
    ApiResultOfVoidToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonTariff,
    JsonTariffFromJSON,
    JsonTariffToJSON,
} from '../models';

export interface DeleteTariffUsingDELETERequest {
    tariffId: number;
    testOnly?: boolean;
}

export interface GetTariffCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetTariffInfoUsingGETRequest {
    tariffId: number;
}

export interface GetTariffListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetTariffUsingGETRequest {
    tariffId: number;
}

export interface SaveTariffUsingPOSTRequest {
    json: JsonTariff;
    testOnly?: boolean;
}

/**
 * 
 */
export class TariffControllerApi extends runtime.BaseAPI {

    /**
     * deleteTariff
     */
    async deleteTariffUsingDELETERaw(requestParameters: DeleteTariffUsingDELETERequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfVoid>> {
        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling deleteTariffUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/tariff/{tariffId}`.replace(`{${"tariffId"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfVoidFromJSON(jsonValue));
    }

    /**
     * deleteTariff
     */
    async deleteTariffUsingDELETE(requestParameters: DeleteTariffUsingDELETERequest, initOverrides?: RequestInit): Promise<ApiResultOfVoid> {
        const response = await this.deleteTariffUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTariffCount
     */
    async getTariffCountUsingGETRaw(requestParameters: GetTariffCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/tariff/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getTariffCount
     */
    async getTariffCountUsingGET(requestParameters: GetTariffCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getTariffCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTariffInfo
     */
    async getTariffInfoUsingGETRaw(requestParameters: GetTariffInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonTariffInfo>> {
        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling getTariffInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/tariff/info/{tariffId}`.replace(`{${"tariffId"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonTariffInfoFromJSON(jsonValue));
    }

    /**
     * getTariffInfo
     */
    async getTariffInfoUsingGET(requestParameters: GetTariffInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonTariffInfo> {
        const response = await this.getTariffInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTariffList
     */
    async getTariffListUsingGETRaw(requestParameters: GetTariffListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonTariffInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/tariff/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonTariffInfoFromJSON(jsonValue));
    }

    /**
     * getTariffList
     */
    async getTariffListUsingGET(requestParameters: GetTariffListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonTariffInfo> {
        const response = await this.getTariffListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTariff
     */
    async getTariffUsingGETRaw(requestParameters: GetTariffUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonTariff>> {
        if (requestParameters.tariffId === null || requestParameters.tariffId === undefined) {
            throw new runtime.RequiredError('tariffId','Required parameter requestParameters.tariffId was null or undefined when calling getTariffUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/tariff/{tariffId}`.replace(`{${"tariffId"}}`, encodeURIComponent(String(requestParameters.tariffId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonTariffFromJSON(jsonValue));
    }

    /**
     * getTariff
     */
    async getTariffUsingGET(requestParameters: GetTariffUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonTariff> {
        const response = await this.getTariffUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveTariff
     */
    async saveTariffUsingPOSTRaw(requestParameters: SaveTariffUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonTariff>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveTariffUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/tariff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonTariffToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonTariffFromJSON(jsonValue));
    }

    /**
     * saveTariff
     */
    async saveTariffUsingPOST(requestParameters: SaveTariffUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonTariff> {
        const response = await this.saveTariffUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
