import {Grid, Link} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {Link as RouterLink} from "react-router-dom";
import {
    GetInviteListUsingGETRequest,
    JsonInviteInfo,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteReply,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyStatusEnum
} from '../../generated-api';
import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridMode,
    DataGridState,
    defaultDataGridFilterState
} from "../DataGrid";
import CodebookValue, {OptionIcon} from "../CodebookValue";
import {partyName} from "../../model/party";
import InviteReviewModal from "../../pages/InviteReviewModal";
import EventDaysValue from "../EventDaysValue";
import {getEffectiveExtras, inviteStatusOptions, renderInviteType} from "../../model/invite";
import {createInviteActions, getInviteRowClassName} from "../../pages/InvitesPage";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {fetchInvitesOnline} from "../../store/invites";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import InfoBox, {InfoBoxAction} from "../layout/InfoBox";
import {saveInviteReply} from "../../store/inviteReplies";
import EventPartyExtrasInfo from "../EventPartyExtrasInfo";
import {selectAuthInfo} from "../../store/selectors";
import {AuthState} from "../../store/auth";

interface InvitesGridFilter extends DataGridFilter, GetInviteListUsingGETRequest {
}

interface InvitesGridState extends DataGridState<JsonInviteInfo, InvitesGridFilter> {
}

const cols: DataGridCol<JsonInviteInfo, InvitesGridFilter>[] = [
    createCol('Kód', 'token', 50, 'Kód pozvánky a odkaz na formulář', (v) => <pre><a href={'/rsvp/' + v} target={'_blank'} rel="noreferrer">{v}</a></pre>),
    createCol('Typ', 'inviteType', '40C', 'Typ pozvánky', renderInviteType),
    createCol('Pozvaná os.', 'fullName', 80, undefined, (v, item) => <Link underline={'hover'} to={`/parties/${item.partyId}`} component={RouterLink}>{v}</Link>),
    createCol('Skupina', 'groupId', 80, 'Pracovní skupina', (v) => v ? <CodebookValue value={v} name={'group'}/> : null),
    {
        title: 'Dny',
        size: 50,
        col: 'inviteData',
        renderValue: (v, item, filter) => {
            const acceptedDays = !!item.replyData?.replyDays
                ? v?.eventDays?.filter((dayNo) => item.replyData?.replyDays?.[dayNo]?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept)
                : v?.eventDays;

            return <EventDaysValue eventDays={acceptedDays} eventId={item.eventId}/>
        }
    },
    {
        title: 'Doprovod',
        size: 180,
        col: 'replyData',
        renderValue: (v, item, filter) => {
            if (item.replyExtras && item.replyExtras.length > 0) {
                return <EventPartyExtrasInfo extras={item.replyExtras} asTooltip={item.inviteType === JsonInviteInfoInviteTypeEnum.Supplier} eventId={item.eventId}/>
            }
            if (!v || !v.replyDays) {
                return null;
            }
            const extras = getEffectiveExtras(item.inviteData, item.replyData);
            return extras?.map((extra, i) => {
                return <div key={i}>{partyName(extra)}</div>;
            });
        }
    },
    createCol('S', 'status', 20, 'Stav pozvánky', (v, item) => {

        return <OptionIcon value={v} options={inviteStatusOptions}/>
    }),
];

const defaultState: InvitesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'inviteId',
        orderDir: 'desc',
    },
};

type Props = {
    eventId: number,
    partyId: number,
    onSave: () => void,
    actions?: InfoBoxAction[]
}

const PartyInvitesList = (props: Props) => {
    const {eventId, partyId, onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const {user, rights} = useAppSelector<AuthState>(selectAuthInfo);
    const [invites, setInvites] = useState<JsonInviteInfo[] | undefined>(undefined);
    const [inviteId, setInviteId] = useState<number | undefined>(undefined);

    const handleFetchItems = useCallback(async () => {
        setInvites(getApiResult<JsonInviteInfo[]>(await dispatch(fetchInvitesOnline({relPartyId: partyId, eventId}))));

    }, [eventId, partyId, dispatch]);

    // TODO: merge
    const handleSaveReply = useCallback(async (reply: JsonInviteReply) => {
        const res = await dispatch(saveInviteReply(reply));
        const item = getApiResult<JsonInviteReply>(res);
        if (item) {
            let m: string = reply.status + '';
            switch (reply.status) {
                case JsonInviteReplyStatusEnum.Accepted:
                    m = 'Pozvánka byla schválena';
                    break;
                case JsonInviteReplyStatusEnum.Rejected:
                    m = 'Pozvánka byla vrácena k doplnění';
                    break;
                case JsonInviteReplyStatusEnum.Deleted:
                    m = 'Pozvánka byla stornována';
                    break;
                case JsonInviteReplyStatusEnum.Sent:
                    m = 'Rozpracovaná pozvánka uložena';
                    break;
            }
            addApiResultMessage(res, {
                [ApiChangeType.CREATED]: m,
                [ApiChangeType.UPDATED]: m,
                [ApiChangeType.NO_CHANGE]: m,
            }, t, dispatch);
            if (reply.status === JsonInviteReplyStatusEnum.Sent) {
                return item;
            }
            await handleFetchItems();
            setInviteId(undefined);
            onSave();
        }
        return item;

    }, [onSave, handleFetchItems, dispatch, t]);

    const actions = useMemo(() => {
        return createInviteActions(t, setInviteId, user, rights)
    }, [user, rights, setInviteId, t]);

    const itemsState: ItemsState<JsonInviteInfo, InvitesGridFilter> = useMemo(() => {
        return {
            items: invites || [],
            count: 0,
            filter: {},
            loading: false
        }
    }, [invites]);

    useEffect(() => {
        handleFetchItems().then();
    }, [handleFetchItems])

    return <Grid item xs={12}>
        <InfoBox title={t('Pozvánky / výzvy')} actions={props.actions}>
            {!!itemsState.items.length
                ? <DataGrid
                    cols={cols}
                    defaultState={defaultState}
                    itemsState={itemsState}
                    mode={DataGridMode.CLIENT}
                    actions={actions}
                    itemKey={'inviteId'}
                    getRowClassNames={getInviteRowClassName}
                />
                : null}

            {!!inviteId && <InviteReviewModal
				inviteId={inviteId}
				onSave={handleSaveReply}
				onCancel={() => setInviteId(undefined)}/>}
        </InfoBox>
    </Grid>;
}

export default PartyInvitesList;
