import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiResultOfJsonEventPartyInfo,
    ApiResultOfJsonImportResultOfstringAndJsonEventPartyImport,
    EventPartyControllerApi,
    GetEventPartyCountUsingGETRequest,
    GetEventPartyListUsingGETRequest,
    JsonEventParty,
    JsonEventPartyInfo,
    JsonEventPartyMassActionRequest,
    PrepareEventPartyImportUsingPOSTRequest, PushEventPartiesToSiwiUsingPOSTRequest
} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventPartiesApi = new EventPartyControllerApi(API_CONFIG);

export const fetchEventParties = createAsyncThunk('eventParties/list', async (filter: GetEventPartyListUsingGETRequest, thunkApi) => {
    return {...await eventPartiesApi.getEventPartyListUsingGET(filter), filter};
});

export const fetchEventPartiesCount = createAsyncThunk('eventParties/count', async (params: GetEventPartyCountUsingGETRequest = {}) => {
    return await eventPartiesApi.getEventPartyCountUsingGET(params);
});

export const fetchEventPartiesOnline = createAsyncThunk('eventParties/online', async (filter: GetEventPartyListUsingGETRequest, thunkApi) => {
    return await eventPartiesApi.getEventPartyListUsingGET(filter);
});

export const fetchEventPartyInfo = createAsyncThunk('eventParty/info', async (eventPartyId: number): Promise<ApiResultOfJsonEventPartyInfo> => {
    return await eventPartiesApi.getEventPartyInfoUsingGET({eventPartyId});
});

export const fetchEventParty = createAsyncThunk('eventParty/fetch', async (eventPartyId: number) => {
    return await eventPartiesApi.getEventPartyUsingGET({eventPartyId});
});

export const saveEventParty = createAsyncThunk('eventParty/save', async (eventParty: JsonEventParty) => {
    return await eventPartiesApi.saveEventPartyUsingPOST({json: eventParty});
});

export const massEventParty = createAsyncThunk('eventParty/mass', async (params: { request: JsonEventPartyMassActionRequest, testOnly?: boolean }) => {
    return await eventPartiesApi.massEventPartyActionUsingPOST({request: params.request, testOnly: params.testOnly});
});

export const prepareEventPartyImport = createAsyncThunk('eventParty/prepareImport', async (arg: PrepareEventPartyImportUsingPOSTRequest): Promise<ApiResultOfJsonImportResultOfstringAndJsonEventPartyImport> => {
    return await eventPartiesApi.prepareEventPartyImportUsingPOST({file: arg.file, eventId: arg.eventId, testOnly: arg.testOnly});
});

export const printEventPartyContract = createAsyncThunk('eventParty/printContract', async (request: JsonEventPartyMassActionRequest) => {
    return await eventPartiesApi.printEventPartyContractUsingPOST({request});
});

export const generateEventPartyPayment = createAsyncThunk('eventParty/generatePayment', async (request: JsonEventPartyMassActionRequest) => {
    return await eventPartiesApi.generateEventPartyPaymentUsingPOST({request});
});

export const pushEventPartiesToSiwi = createAsyncThunk('eventParty/pushToSiwi', async (request: PushEventPartiesToSiwiUsingPOSTRequest) => {
    return await eventPartiesApi.pushEventPartiesToSiwiUsingPOST(request);
});

export const fetchEventPartyHistory = createAsyncThunk('eventParty/history', async (eventPartyId: number) => {
    return await eventPartiesApi.getEventPartyHistoryUsingGET({eventPartyId});
});

export const fetchEventPartyDayHistory = createAsyncThunk('eventParty/eventDaysHistory', async (eventPartyId: number) => {
    return await eventPartiesApi.getEventPartyDaysHistoryUsingGET({eventPartyId});
});

export const eventPartiesSlice = createSlice({
    name: 'eventParties',
    initialState: (): ItemsState<JsonEventPartyInfo> => createDefaultListState(),
    reducers: {
        clearEventParties: state => createDefaultListState(),
    },
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventParties, fetchEventPartiesCount);
    }
});

export const eventPartiesReducer = eventPartiesSlice.reducer;
export const {clearEventParties} = eventPartiesSlice.actions;
