import {useAppDispatch} from "../store";
import * as React from "react";
import {SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {JsonApiEventParty} from "../generated-api";
import {Alert, Box, Card, CardContent, CardHeader, CircularProgress, Container} from "@mui/material";
import {useAppTranslation} from "../services/i18n";
import {CheckOutlined, ClearOutlined, PauseOutlined, QrCodeScannerOutlined} from "@mui/icons-material";
import {dateToGuiAs} from "../helpers/date";
import {fetchEventPartyByBarcode} from "../store/rsvp";
import {getApiResult} from "../helpers/api";

type ScannerLog = {
    timestamp: Date,
    barcode: string;
    state: 'loading' | 'ok' | 'error'
    party?: JsonApiEventParty,
    error?: string
}

type ScannerState = 'waiting' | 'scanning' | 'scanned';

export const AmmoScanner = ({setLogItems, compact, suspended}: {
    setLogItems?: (s: SetStateAction<ScannerLog[]>) => void,
    compact?: boolean,
    suspended?: boolean,
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [isActive, setIsActive] = useState(true);
    const [scannerState, setScannerState] = useState<ScannerState>('waiting');

    const fragment = useRef('');
    const previous = useRef('');

    const handleFocus = useCallback(() => {
        fragment.current = '';
        setIsActive(true);
    }, []);

    const handleBlur = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleKey = useCallback((event: KeyboardEvent) => {
        if (suspended) {
            return;
        }
        if (event.key === 'Enter' || event.key === 'Escape' || event.key === 'Shift') {
            event.preventDefault();
            event.stopPropagation();

            if (event.key === 'Enter' && !!fragment.current?.trim()) {
                const item: ScannerLog = {
                    timestamp: new Date(),
                    barcode: fragment.current,
                    state: 'loading'
                };
                if (setLogItems) {
                    setLogItems(logItems => [item, ...logItems]);
                }

                dispatch(fetchEventPartyByBarcode({
                    barcode: fragment.current,
                    type: 'ammo',
                    token: 'tcfftqwxev', // secret
                })).then((res) => {
                    const party = getApiResult<JsonApiEventParty>(res);
                    if (party) {
                        item.state = 'ok';
                        item.party = party;
                    } else {
                        item.state = 'error';
                        if ('error' in res) {
                            if (res.error.message === 'ENTITY_NOT_FOUND') {
                                item.error = t('Kód nenalezen');
                            } else {
                                item.error = res.error.message;
                            }
                        }
                    }

                    if (setLogItems) {
                        setLogItems(logItems => logItems.map(o => o === item ? item : o));
                    }
                });
                previous.current = fragment.current;
                setScannerState('scanned');
                setTimeout(() => setScannerState('waiting'), 1000);
            } else {
                setScannerState('waiting');
            }

            fragment.current = '';
        }

        if (/^[A-Za-z0-9]$/.test(event.key)) {
            setScannerState('scanning');
            fragment.current += event.key;
            event.preventDefault();
        }

    }, [suspended, setLogItems, dispatch, t]);

    useEffect(() => {
        window.addEventListener("keydown", handleKey);
        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);
        return () => {
            window.removeEventListener("keydown", handleKey);
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("blur", handleBlur);
        }
    }, [handleKey, handleFocus, handleBlur]);

    return <Card className={'scanner-card'}>
        <CardContent>
            {isActive && !suspended
                ? (scannerState === 'scanned'
                    ? <Alert severity={'success'} icon={false}>
                        <h1>{t('Načteno!')} {!!previous.current && <code>{previous.current}</code>}</h1>
                        {!compact && <CheckOutlined/>}
                    </Alert>
                    : <Alert severity={'info'} icon={false}>
                        <h1>{t('Připraveno, čeká se na čtečku ...')}</h1>
                        {!compact && <QrCodeScannerOutlined/>}
                    </Alert>)
                : (suspended
                    ? <Alert severity={'warning'} icon={false}>
                        <h1>{t('Čtení pozastaveno ...')}</h1>
                        {!compact && <PauseOutlined/>}
                    </Alert>
                    : <Alert severity={'error'} icon={false}>
                        <h1>{t('Pro skenování klepněte do okna!')}</h1>
                        {!compact && <ClearOutlined/>}
                    </Alert>)}
        </CardContent>
    </Card>;
}

const AmmoPage = () => {
    const t = useAppTranslation();

    const [logItems, setLogItems] = useState<ScannerLog[]>([]);


    const historyItems = useMemo(() => {
        return logItems?.map((item, index) => <tr key={index}>
            <th>{dateToGuiAs(item.timestamp, 'dd.MM.yyyy HH:mm:ss')}</th>
            <th><code>{item.barcode}</code></th>
            <td>{item.state === 'loading'
                ? <div style={{position: 'relative'}}><CircularProgress size={20}/></div>
                : (item.state === 'ok' && !!item.party
                        ? <strong>
                            {item.party.lastName} {item.party.firstName}
                            {!!item.party.reason && ` [${item.party.reason}]`}
                            {!!item.party.tags && ` (${item.party.tags})`}
                            {!!item.party.note && ` (${item.party.note})`}
                        </strong>
                        : <Alert severity={'error'} icon={false}>{item.error || t('Chyba')}</Alert>
                )}
            </td>
        </tr>)
    }, [logItems, t]);

    return <Container className={'scanner-page'}>
        <Box sx={{marginTop: '64px'}}>
            <AmmoScanner setLogItems={setLogItems}/>
        </Box>
        {!!logItems.length && <Card className={'scanner-history'}>
            <CardHeader title={t('Historie')}/>
            <CardContent>
                <table className={'info-box-table'} style={{width: "100%"}}>
                    <tbody>
                    {historyItems}
                    </tbody>
                </table>
            </CardContent>
        </Card>}
    </Container>;
}

export default AmmoPage;
