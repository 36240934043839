/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleFromJSONTyped,
    JsonArticleToJSON,
} from './JsonArticle';
import {
    JsonEventPartyCompInfo,
    JsonEventPartyCompInfoFromJSON,
    JsonEventPartyCompInfoFromJSONTyped,
    JsonEventPartyCompInfoToJSON,
} from './JsonEventPartyCompInfo';
import {
    JsonEventPartyTravel,
    JsonEventPartyTravelFromJSON,
    JsonEventPartyTravelFromJSONTyped,
    JsonEventPartyTravelToJSON,
} from './JsonEventPartyTravel';
import {
    JsonEventPartyTravelInfo,
    JsonEventPartyTravelInfoFromJSON,
    JsonEventPartyTravelInfoFromJSONTyped,
    JsonEventPartyTravelInfoToJSON,
} from './JsonEventPartyTravelInfo';
import {
    JsonInviteReplyDay,
    JsonInviteReplyDayFromJSON,
    JsonInviteReplyDayFromJSONTyped,
    JsonInviteReplyDayToJSON,
} from './JsonInviteReplyDay';
import {
    JsonInviteReplyExtra,
    JsonInviteReplyExtraFromJSON,
    JsonInviteReplyExtraFromJSONTyped,
    JsonInviteReplyExtraToJSON,
} from './JsonInviteReplyExtra';
import {
    JsonReplyPartyData,
    JsonReplyPartyDataFromJSON,
    JsonReplyPartyDataFromJSONTyped,
    JsonReplyPartyDataToJSON,
} from './JsonReplyPartyData';

/**
 * 
 * @export
 * @interface JsonReplyData
 */
export interface JsonReplyData {
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonReplyData
     */
    articles?: Array<JsonArticle>;
    /**
     * 
     * @type {Array<JsonEventPartyCompInfo>}
     * @memberof JsonReplyData
     */
    eventCompDetails?: Array<JsonEventPartyCompInfo>;
    /**
     * 
     * @type {Array<JsonEventPartyTravelInfo>}
     * @memberof JsonReplyData
     */
    eventTravelDetails?: Array<JsonEventPartyTravelInfo>;
    /**
     * 
     * @type {Array<JsonInviteReplyExtra>}
     * @memberof JsonReplyData
     */
    extras?: Array<JsonInviteReplyExtra>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonReplyData
     */
    isAcm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonReplyData
     */
    isGdpr?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonReplyData
     */
    isSafety?: boolean;
    /**
     * 
     * @type {JsonReplyPartyData}
     * @memberof JsonReplyData
     */
    partyData?: JsonReplyPartyData;
    /**
     * 
     * @type {{ [key: string]: JsonInviteReplyDay; }}
     * @memberof JsonReplyData
     */
    replyDays?: { [key: string]: JsonInviteReplyDay; };
    /**
     * 
     * @type {Array<JsonEventPartyTravel>}
     * @memberof JsonReplyData
     */
    travels?: Array<JsonEventPartyTravel>;
}

export function JsonReplyDataFromJSON(json: any): JsonReplyData {
    return JsonReplyDataFromJSONTyped(json, false);
}

export function JsonReplyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReplyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articles': !exists(json, 'articles') ? undefined : ((json['articles'] as Array<any>).map(JsonArticleFromJSON)),
        'eventCompDetails': !exists(json, 'eventCompDetails') ? undefined : ((json['eventCompDetails'] as Array<any>).map(JsonEventPartyCompInfoFromJSON)),
        'eventTravelDetails': !exists(json, 'eventTravelDetails') ? undefined : ((json['eventTravelDetails'] as Array<any>).map(JsonEventPartyTravelInfoFromJSON)),
        'extras': !exists(json, 'extras') ? undefined : ((json['extras'] as Array<any>).map(JsonInviteReplyExtraFromJSON)),
        'isAcm': !exists(json, 'isAcm') ? undefined : json['isAcm'],
        'isGdpr': !exists(json, 'isGdpr') ? undefined : json['isGdpr'],
        'isSafety': !exists(json, 'isSafety') ? undefined : json['isSafety'],
        'partyData': !exists(json, 'partyData') ? undefined : JsonReplyPartyDataFromJSON(json['partyData']),
        'replyDays': !exists(json, 'replyDays') ? undefined : (mapValues(json['replyDays'], JsonInviteReplyDayFromJSON)),
        'travels': !exists(json, 'travels') ? undefined : ((json['travels'] as Array<any>).map(JsonEventPartyTravelFromJSON)),
    };
}

export function JsonReplyDataToJSON(value?: JsonReplyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articles': value.articles === undefined ? undefined : ((value.articles as Array<any>).map(JsonArticleToJSON)),
        'eventCompDetails': value.eventCompDetails === undefined ? undefined : ((value.eventCompDetails as Array<any>).map(JsonEventPartyCompInfoToJSON)),
        'eventTravelDetails': value.eventTravelDetails === undefined ? undefined : ((value.eventTravelDetails as Array<any>).map(JsonEventPartyTravelInfoToJSON)),
        'extras': value.extras === undefined ? undefined : ((value.extras as Array<any>).map(JsonInviteReplyExtraToJSON)),
        'isAcm': value.isAcm,
        'isGdpr': value.isGdpr,
        'isSafety': value.isSafety,
        'partyData': JsonReplyPartyDataToJSON(value.partyData),
        'replyDays': value.replyDays === undefined ? undefined : (mapValues(value.replyDays, JsonInviteReplyDayToJSON)),
        'travels': value.travels === undefined ? undefined : ((value.travels as Array<any>).map(JsonEventPartyTravelToJSON)),
    };
}

