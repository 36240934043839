import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridItemAction,
    DataGridMode,
    DataGridState,
    defaultDataGridFilterState
} from "../DataGrid";
import {GetTariffListUsingGETRequest, JsonTariff, JsonTariffInfo, JsonTariffTariffTypeEnum} from "../../generated-api";
import {createDefaultListState, ItemsState, useAppDispatch} from "../../store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Edit} from "@mui/icons-material";
import {deleteTariff, fetchTariff, fetchTariffs, saveTariff} from "../../store/tariffs";
import {FAKE_VALUE_EMPTY, formatMoney} from "../../model/form";
import {Button, Tooltip} from "@mui/material";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {useAppTranslation} from "../../services/i18n";
import {fetchCodebooks} from "../../store/codebooks";
import EventTariffModal from "../../pages/EventTariffModal";

interface LocalFilter {
}

interface EventTariffsGridFilter extends DataGridFilter, GetTariffListUsingGETRequest, LocalFilter {
}

interface EventTariffsListGridState extends DataGridState<JsonTariffInfo, EventTariffsGridFilter> {
}

const defaultState: EventTariffsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'title',
    }
};

const EventTariffsList = (props: { eventId: number, maxHeight?: string, minHeight?: string }) => {
    const {eventId, maxHeight, minHeight} = props;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();

    const [itemsState, setItemsState] = useState<ItemsState<JsonTariffInfo, EventTariffsGridFilter>>(createDefaultListState());
    const [editTariff, setEditTariff] = useState<JsonTariff>();

    const handleFetchTariffs = useCallback(() => {
        setItemsState(s => ({...s, loading: true}))
        dispatch(fetchTariffs({eventId})).then((res) => {
            setItemsState(s => ({...s, loading: false, items: getApiResult(res) || []}));
        })
    }, [eventId, dispatch]);

    const handleEditTariff = useCallback((tariffId?: number) => {
        if (tariffId) {
            dispatch(fetchTariff(tariffId)).then((res) => setEditTariff(getApiResult(res)));
        } else {
            setEditTariff({eventId, tariffType: JsonTariffTariffTypeEnum.Reward});
        }
    }, [dispatch, eventId]);

    const handleSaveTariff = useCallback(async (tariff: JsonTariff, isDelete?: boolean) => {
        let res;
        let item;
        if (isDelete) {
            res = await dispatch(deleteTariff(tariff.tariffId!));
            if ('error' in res) {
                return;
            }
            item = tariff;
        } else {
            res = await dispatch(saveTariff({
                ...tariff
            }));
            item = getApiResult(res);
        }
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Tarif {{unitTitle}} ponecháno beze změn',
                [ApiChangeType.UPDATED]: 'Tarif {{unitTitle}} úspěšně upraven',
                [ApiChangeType.DELETED]: 'Tarif {{unitTitle}} úspěšně odstraněn',
                [ApiChangeType.CREATED]: 'Tarif {{unitTitle}} úspěšně vytvořen'
            }, t, dispatch);
            setEditTariff(undefined);
            dispatch(fetchCodebooks());
            handleFetchTariffs();
        }

    }, [handleFetchTariffs, dispatch, t])

    const handleCancelTariff = useCallback(() => {
        setEditTariff(undefined);
    }, []);

    const formatByStatus = useCallback((pending?: number | string, paid?: number | string, total?: number | string) => {
        return <Tooltip title={<table className={'info-box-table'} cellPadding={5} style={{minWidth: '150px'}}>
            <tbody>
            <tr>
                <th>{t('Nevyplaceno')}</th>
                <td style={{textAlign: 'right'}}>{pending}</td>
            </tr>
            <tr>
                <th>{t('Vyplaceno')}</th>
                <td style={{textAlign: 'right'}}>{paid}</td>
            </tr>
            <tr>
                <th>{t('Celkem')}</th>
                <td style={{textAlign: 'right'}}>{total}</td>
            </tr>
            </tbody>
        </table>}><span>{total}</span></Tooltip>
    }, [t]);

    const cols = useMemo((): DataGridCol<JsonTariffInfo>[] => {
        const cols: DataGridCol<JsonTariffInfo>[] = [
            createCol('Název', 'unitTitle', 40, 'Název tarifu'),
            createCol('Sazba', 'unitRate', '10R', 'Sazba tarifu (prázdná = bude vždy zadána)', (v) => formatMoney(v, true)),
            createCol('Osob', 'compCnt', '7R', 'Celkem zadáno u osob', (v, item) => formatByStatus(
                item.pendingCompCnt, item.paidCompCnt, v)),
            createCol('Jedn.', 'unitCnt', '7R', 'Celkem jednotek', (v, item) => formatByStatus(
                item.pendingUnitCnt, item.paidUnitCnt, v)),
            createCol('Suma', 'compAmount', '15R', 'Celkem suma', (v, item) => formatByStatus(
                formatMoney(item.pendingCompAmount, true), formatMoney(item.paidCompAmount, true), formatMoney(v, true))),
        ];
        return cols;
    }, [formatByStatus]);

    const totalRow = useCallback((items: JsonTariffInfo[]): JsonTariffInfo => {
        return {
            tariffId: FAKE_VALUE_EMPTY,
            unitTitle: t('Celkem'),
            unitRate: undefined,
            compCnt: undefined,
            unitCnt: undefined,
            compAmount: items.reduce((acc, item) => acc + (item.compAmount || 0), 0),
            pendingCompAmount: items.reduce((acc, item) => acc + (item.pendingCompAmount || 0), 0),
            paidCompAmount: items.reduce((acc, item) => acc + (item.paidCompAmount || 0), 0)
        }
    }, [t]);

    const actions = useMemo((): DataGridItemAction<JsonTariffInfo>[] => [
        {
            title: t('Upravit tarif'),
            icon: <Edit/>,
            callback: (item) => handleEditTariff(item.tariffId),
        }
    ], [handleEditTariff, t]);

    useEffect(() => {
        handleFetchTariffs();
    }, [handleFetchTariffs]);

    return <>
        <div className={'info-box-actions'}>
            <Button variant={'contained'} size={'small'} color={'primary'} onClick={() => handleEditTariff()}>{t('Nový tarif')}</Button>
        </div>
        <DataGrid
            cols={cols}
            defaultState={defaultState}
            itemsState={itemsState}
            mode={DataGridMode.CLIENT}
            // filterFields={filterFields}
            actions={actions}
            tableProps={{maxHeight, minHeight}}
            getRowClassNames={(item) => item.tariffId === FAKE_VALUE_EMPTY ? ['data-grid-total-row'] : undefined}
            totalRow={totalRow}
        />
        {editTariff && <EventTariffModal
            item={editTariff}
            onCancel={handleCancelTariff}
            onSave={handleSaveTariff}
            onDelete={editTariff.tariffId ? (tariff) => handleSaveTariff(tariff, true) : undefined}
            deleteConfirmText={'Skutečně trvale odstranit tarif {{unitTitle}}?'}
        />}
    </>
}

export default EventTariffsList;
