import {Alert, AppBar, Avatar, Box, Button, Card, CardContent, Container, LinearProgress, Modal, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {getLocale, setLocale, useAppTranslation} from '../services/i18n';
import {fetchRsvp, saveRsvp} from '../store/rsvp';
import {useParams} from "react-router-dom";
import {JsonInviteReply, JsonRsvp} from '../generated-api';
import {useAppDispatch, useAppSelector} from "../store";
import {getApiResult} from "../helpers/api";
import {selectAppMessages} from "../store/selectors";
import {removeMessage} from "../store/localApp";
import logo from "../assets/images/ems_logo_2024.png";
import Toolbar from "@mui/material/Toolbar";
import {ModalProvider} from "../services/modal";
import RsvpInvite from "../components/rsvp/RsvpInvite";
import {LockOutlined} from "@mui/icons-material";
import {TextFormField} from "../components/form/TextFormField";
import {Formik, FormikErrors} from "formik";
import {isEmailValid} from "../helpers/validators";

const style = {
    // fontFamily: 'ProximaNovaWebRegular',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '100%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

type EmailValues = {
    email?: string
}

const MessageModal = ({setEmail}: { setEmail: (email: string) => void }) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const messages = useAppSelector(selectAppMessages);

    const validate = useCallback((values: EmailValues) => {
        const errors = {} as FormikErrors<EmailValues>;
        if (!values.email) {
            errors.email = t('Zadejte email');
        } else if (!isEmailValid(values.email)) {
            errors.email = t('Neplatný email');
        }
        return errors;
    }, [t]);

    let isFatal = false, hasError = false, isEmailNeeded = false, isEmailInvalid = false;
    const items: JSX.Element[] = [];
    messages?.forEach((m) => {
        let body = m.title;
        if (m.severity === 'error') {
            if (m.code === 'GENERAL_ERROR') {
                body = t('Omlouváme se, ale služba selhala - kód chyby: ') + m.action + ', ' + m.code + ', ' + body;
            } else {
                body = m.action + ', ' + m.code + ', ' + body;
            }
            switch (m.action) {
                case 'rsvp/save':
                    if (body.indexOf('INVITE_NOT_ACTIVE') >= 0) {
                        body = t('Pozvánka je již ve zpracování a nelze upravovat');
                    }
                    break;
                case 'rsvp/file':
                default:
            }

            hasError = true;
        }
        if (m.action === 'rsvp/fetch') {
            isFatal = true;
            switch (m.title) {
                case 'TOKEN_INVALID':
                    body = t('Odkaz není platný - zkuste jej prosím z emailové zprávy zkopírovat do adresního řádku ručně.');
                    break;
                case 'EMAIL_NEEDED':
                    isEmailNeeded = true;
                    break;
                case 'EMAIL_INVALID':
                    isEmailInvalid = true;
                    break;
            }
        }

        items.push(<Alert key={m.id} severity={m.severity}>{body}</Alert>)
    });

    if (isEmailNeeded || isEmailInvalid) {
        return <Formik
            initialValues={{
                email: '',
            } as EmailValues}
            validate={validate}
            onSubmit={(values) => {
                if (values.email) {
                    setEmail(values.email);
                }
            }}>
            {(props) => (
                <form onSubmit={props.handleSubmit} className={'login-form'} style={{'marginTop': '50px'}}>
                    <Card>
                        <CardContent>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Avatar sx={{m: 1}}>
                                    <LockOutlined/>
                                </Avatar>
                                <Typography component="h1" variant="h5" sx={{marginBottom: "20px"}}>
                                    {t('Stránka vyžaduje ověření')}
                                </Typography>
                                <p>{t('Pro pokračování prosím zadejte emailovou adresu, na kterou vám byl zaslán odkaz na tuto stránku.')}</p>
                            </Box>
                            {isEmailInvalid && <Alert severity={'error'}>
								<div>{t('Zadaný email neodpovídá této stránce.')}</div>
							</Alert>}
                            <TextFormField name="email" label={t('E-mail')} autoFocus/>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px 0'}}>
                                <Button variant="contained" type="submit" fullWidth disabled={props.isSubmitting}>{t('Odemknout stránku')}</Button>
                            </Box>
                            <Alert severity={'info'}>
                                <div dangerouslySetInnerHTML={{
                                    __html: t('V případě problémů se prosím obracejte na podporu <a href="mailto:ems@biatlon.cz?subject=Problém na pozvánce">ems@biatlon.cz</a>.')
                                }}/>
                            </Alert>
                        </CardContent>
                    </Card>
                </form>
            )}
        </Formik>;
    }

    if (!items.length) {
        return null;
    }

    if (isFatal) {
        const isInfo = window.location.href.indexOf('/info/') >= 0;
        return <Box>
            <h1>{t(isInfo ? 'Omlouváme se, ale informace nelze otevřít' : 'Omlouváme se, ale pozvánku nelze otevřít')}</h1>
            <Card>
                <CardContent>
                    <div style={{marginBottom: '10px'}}>
                        {items}
                    </div>
                    <Alert severity={'info'}>
                        <div dangerouslySetInnerHTML={{
                            __html: t('V případě, že problémy přetrvávají, obraťte se prosím na podporu <a href="mailto:ems@biatlon.cz?subject=Problém na pozvánce">ems@biatlon.cz</a>.')
                        }}/>
                    </Alert>
                </CardContent>
            </Card>
        </Box>;
    }

    const onModalClose = (e: any, reason?: any) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        messages.forEach((m) => dispatch(removeMessage(m)));
    }

    return <Modal
        open={true}
        onClose={onModalClose}
    >
        <Box sx={style} className={'rsvp-modal'}>
            <h2 style={{margin: '10px 0'}}>{hasError
                ? t('Omlouváme se, ale nastala chyba')
                : t('Děkujeme za odeslání')}</h2>
            <div style={{marginBottom: '10px'}}>
                {items}
            </div>
            <Alert severity={'info'} style={{marginBottom: '20px'}}>
                <div dangerouslySetInnerHTML={{
                    __html: hasError
                        ? t('V případě, že problémy přetrvávají, obraťte se prosím na podporu <a href="mailto:ems@biatlon.cz?subject=Problém na pozvánce">ems@biatlon.cz</a>.')
                        : t('V případě dotazu prosím kontaktujte podporu na emailu <a href="mailto:ems@biatlon.cz?subject=Dotaz k pozvánce">ems@biatlon.cz</a>.')
                }}/>
            </Alert>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" color={'primary'} sx={{width: 150}} onClick={onModalClose}>{t('Rozumím')}</Button>
            </div>
        </Box>
    </Modal>
}

const renderLangButton = (lang: string, currentLang: string, title: string) => {
    if (lang === currentLang) {
        return <Button variant={'text'} disabled>{title}</Button>
    }
    return <Button variant={'text'} onClick={() => setLocale(lang)}>{title}</Button>
}

const RsvpPage = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const lang = getLocale();

    const [isLoading, setIsLoading] = useState(true);
    const [rsvp, setRsvp] = useState<JsonRsvp | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(window?.sessionStorage?.getItem('INFO_' + params?.token) || undefined);

    const token = params.token as string;

    const handleFetchRsvp = useCallback(() => {
        if (token) {
            dispatch(fetchRsvp({token, email})).then((res) => {
                setRsvp(getApiResult(res));
                setIsLoading(false);

                if (email && window?.sessionStorage?.setItem) {
                    window?.sessionStorage?.setItem('INFO_' + token, email);
                }
            });
        } else {
            setRsvp(undefined);
            setIsLoading(false);
        }

    }, [token, email, dispatch]);

    const handleSaveRsvp = useCallback(async (reply: JsonInviteReply, isSend: boolean) => {
        const res = await dispatch(saveRsvp({token, reply, isSend}));
        const item = getApiResult(res);
        if (isSend && item) {
            handleFetchRsvp();
        }
        return item;

    }, [token, handleFetchRsvp, dispatch])

    useEffect(() => {
        handleFetchRsvp();
    }, [handleFetchRsvp])

    if (isLoading) {
        return <LinearProgress/>
    }

    return <>
        <AppBar position="static">
            <Container className={'rsvp'}>
                <Toolbar disableGutters>
                    <Box>
                        <img alt={'Biatlon EMS'} src={logo} style={{height: '44px', margin: '20px 0 10px 0', alignSelf: 'center'}}/>
                    </Box>
                    <Box className={'lang-select'}>
                        {renderLangButton('cs', lang, 'Česky')}
                        <span>|</span>
                        {renderLangButton('en', lang, 'English')}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Container className={'rsvp'}>
            <ModalProvider>
                <RsvpInvite rsvp={rsvp} token={token} onSave={handleSaveRsvp}/>
            </ModalProvider>
            <MessageModal setEmail={setEmail}/>
        </Container>
    </>
}

export default RsvpPage;
