import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridItemAction, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {
    GetEventGroupListUsingGETGroupTypeEnum,
    GetEventGroupListUsingGETRequest,
    JsonEvent,
    JsonEventGroup,
    JsonEventGroupInfo,
    JsonEventInfo,
    JsonEventPartyStatusEnum,
    JsonEventStatusEnum
} from "../../generated-api";
import {createDefaultListState, ItemsState, useAppDispatch, useAppSelector} from "../../store";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {fetchEventGroup, fetchEventGroups, saveEventGroup} from "../../store/eventGroups";
import {Edit} from "@mui/icons-material";
import EventGroupModal from "../../pages/EventGroupModal";
import {useAppTranslation} from "../../services/i18n";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {fetchCodebooks} from "../../store/codebooks";
import {datetimeToGui} from "../../helpers/date";
import {Box, Button, Grid, Link, Paper, Tooltip} from "@mui/material";
import EventModal from "../../pages/EventModal";
import {Link as RouterLink} from "react-router-dom";
import {getCodebookLabel} from "../../model/form";
import {selectCodebooks} from "../../store/selectors";

interface EventGroupsGridFilter extends DataGridFilter, GetEventGroupListUsingGETRequest {
}

interface EventGroupsListGridState extends DataGridState<JsonEventGroupInfo, EventGroupsGridFilter> {
}

const tooltip = (s: string | undefined, limit: number) => {
    return <Tooltip
        title={<pre style={{whiteSpace: 'break-spaces'}}>{s}</pre>}
        PopperProps={{sx: {'& .MuiTooltip-tooltip': {maxWidth: 'clamp(300px, 600px, 80vw)'}}}}><pre style={{maxHeight: '50px', overflowY: 'hidden'}}>
                    {s?.length && s?.length > limit
                        ? (s.substring(0, limit) + "...")
                        : s}
                </pre>
    </Tooltip>
}

const eventGroupCols: DataGridCol<JsonEventGroupInfo>[] = [
    createCol('Skupina', 'title', 10),
    createCol('Text', 'settings', 50, undefined, (s) => {
        return tooltip(s?.infoText, 200);
    }),
    createCol('Poslední změna', 'updatedAt', '10C', 'Kdy došlo k poslední změně textu', (v, row) => datetimeToGui(v || row.createdAt)),
    createCol('Poslední notif.', 'notifLastAt', '10C', 'Kdy došlo k poslední plošné notifikaci', (v, row) => {
        return <Box sx={{color: (theme) => theme.palette[!v || v < (row.updatedAt || row.createdAt || '') ? 'warning' : 'success'].main}}>{v ? datetimeToGui(v) : null}</Box>
    }),
    createCol('Osob', 'eventPartyCount', 5, 'Aktivních osob v události v této skupině', (v, row) => {
        return !!v ? <Link underline={'hover'}
            to={`/event-parties/?eventId=${row.eventId}&groupIds=${row.groupId}&statuses=${JsonEventPartyStatusEnum.Active}`}
            component={RouterLink}>{v}</Link> : v;
    }),
    createCol('Notif', 'notifPartyCount', 5, 'Notifikováno celkem'),
];

const eventGroupsDefaultState: EventGroupsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'title',
    },
};

const EventGroupsList = (props: { event: JsonEventInfo, onSaveEvent: (event: JsonEvent) => Promise<void> }) => {
    const {event, onSaveEvent} = props;
    const {eventId, eventData} = event;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);

    const [itemsState, setItemsState] = useState<ItemsState<JsonEventGroupInfo, EventGroupsGridFilter>>(createDefaultListState());
    const [editEventGroup, setEditEventGroup] = useState<JsonEventGroup | undefined>(undefined);
    const [editEvent, setEditEvent] = useState<JsonEvent | undefined>(undefined);

    const handleFetchEventGroups = useCallback(() => {
        setItemsState((s) => ({...s, loading: true}));
        if (eventId) {
            dispatch(fetchEventGroups({eventId, groupType: GetEventGroupListUsingGETGroupTypeEnum.Org})).then((res) => {
                const all = getApiResult(res) || [];
                const items: typeof all = [];
                for (const item of all) {
                    if (item.groupSettings?.managedGroupIds) {
                        const children = all.filter(o => item.groupSettings!.managedGroupIds!.indexOf(o.groupId!) >= 0);
                        items.push({...item,
                            title: item.title + ' (+' + children.length + ')',
                            eventPartyCount: ((item.eventPartyCount || 0) + (children.reduce((count, ch) => count + (ch.eventPartyCount || 0), 0)) || 0) || undefined,
                            notifPartyCount: ((item.notifPartyCount || 0) + (children.reduce((count, ch) => count + (ch.notifPartyCount || 0), 0)) || 0) || undefined
                        });
                    } else if (!all.find(o => o.groupSettings?.managedGroupIds && o.groupSettings!.managedGroupIds!.indexOf(item.groupId!) >= 0)) {
                        items.push(item);
                    }
                }

                setItemsState((s) => ({
                    ...s,
                    loading: false,
                    items
                }));
            });
        } else {
            setItemsState((s) => ({...s, loading: false, items: []}));
            setEditEventGroup(undefined);
        }

    }, [eventId, dispatch]);

    const handleEditEventGroup = useCallback((item: JsonEventGroupInfo) => {
        if (item.eventGroupId && item.eventGroupId > 0) {
            dispatch(fetchEventGroup(item.eventGroupId)).then((res) => setEditEventGroup(getApiResult(res)));
        } else {
            setEditEventGroup({
                eventId: item.eventId,
                groupId: item.groupId,
                settings: {},
            });
        }
    }, [dispatch]);

    const handleSaveEventGroup = useCallback(async (eventGroup: JsonEventGroup, isDelete?: boolean) => {
        const res = await dispatch(saveEventGroup({...eventGroup}));
        const item = getApiResult<JsonEventGroup>(res);
        if (item) {
            let m = () => ', bez odeslání emailů.';
            const count = item.changes?.filter(c => c.name === 'Notification[*]').length || 0;
            if (count) {
                m = () => ', ' + t('email odeslány (celkem ' + count + ')')
            }

            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: ['Skupina ponechána beze změn{{title}}', m],
                [ApiChangeType.UPDATED]: [isDelete ? 'Skupina  úspěšně odstraněna{{title}}' : 'Skupina úspěšně upravena{{title}}', m],
                [ApiChangeType.CREATED]: ['Skupina úspěšně uložena{{title}}', m]
            }, t, dispatch);

            setEditEventGroup(undefined);
            dispatch(fetchCodebooks());
            handleFetchEventGroups();
        }

    }, [handleFetchEventGroups, dispatch, t])

    const handleCancelEventGroup = useCallback(() => {
        setEditEventGroup(undefined);
    }, []);

    const handleCancelEvent = useCallback(() => {
        setEditEvent(undefined);
    }, []);

    const handleSaveEvent = useCallback(async (event: JsonEvent) => {
        await onSaveEvent(event);
        handleCancelEvent();

    }, [onSaveEvent, handleCancelEvent])

    useEffect(() => {
        handleFetchEventGroups();
    }, [handleFetchEventGroups]);

    const actions = [
        {
            title: 'Upravit skupinu',
            icon: <Edit/>,
            callback: (item) => handleEditEventGroup(item)
        }
    ] as DataGridItemAction<JsonEventGroupInfo>[];

    return <>
        <Grid container rowSpacing={2}>
            <Grid item xs={3}>
                {t('Obecné informace pro všechny:')}
            </Grid>
            <Grid item xs={6}>
                {!!eventData?.orgGroupText?.trim() ? <Box component={Paper} sx={{padding: '10px'}}>{tooltip(eventData?.orgGroupText, 50)}</Box> : <em>({t('nevyplněno')})</em>}
            </Grid>
            <Grid item xs={3} sx={{textAlign: 'right'}}>
                <Button variant="contained" type="button" onClick={() => setEditEvent({...event, allowedActions: undefined, status: event.status as any as JsonEventStatusEnum})}
                >{t('Upravit obecné informace')}</Button>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    cols={eventGroupCols}
                    defaultState={eventGroupsDefaultState}
                    itemsState={itemsState}
                    mode={DataGridMode.CLIENT}
                    actions={actions}
                />
            </Grid>
        </Grid>
        {editEventGroup && <EventGroupModal
			title={getCodebookLabel(codebooks, 'group', editEventGroup.groupId)}
			item={editEventGroup}
			onCancel={handleCancelEventGroup}
			onSave={handleSaveEventGroup}
		/>}
        {editEvent && <EventModal
			mode={'orgInfoText'}
			item={editEvent}
			onCancel={handleCancelEvent}
			onSave={handleSaveEvent}
		/>}
    </>
}

export default EventGroupsList;
